import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiveThemedDirectiveModule, SendDetailViewModule } from 'uikit';
import { DevicePreviewViewModule } from './device-preview-view.module';
import { SendsPreviewComponent } from '../components/organisms/sends-preview/sends-preview.component';

@NgModule({
  declarations: [SendsPreviewComponent],
  imports: [
    CommonModule,
    LiveThemedDirectiveModule,
    DevicePreviewViewModule,
    SendDetailViewModule
  ],
  exports: [SendsPreviewComponent]
})
export class SendsPreviewModule {}
