<div class="relative">
  <div
    *ngIf="shouldShowPopup"
    [@zoomBigMotion]="'active'"
    class="rounded-sm absolute p-4 flex flex-row bottom-9 bg-white z-50 shadow-ant-default w-72"
    [ngClass]="{
      'left-0': popupDisplayDirection === 'left',
      'right-0': popupDisplayDirection !== 'left'
    }"
  >
    <div>
      <p class="text-neutral-900 font-medium mb-0.5 text-xs">
        Bitly links may be filtered or blocked
      </p>
      <p class="text-neutral-700 text-xs">
        It looks like you're adding a Bitly link, which may be blocked or
        filtered by carriers. Try inserting the original link instead and we'll
        automatically shorten and track it for you.
      </p>
      <div class="flex flex-row">
        <a
          nz-button
          nzType="primary"
          nzSize="small"
          href="https://help.withnorby.com/do-i-need-to-use-bitly-or-other-link-tracking-on-norby"
          target="_blank"
          rel="noreferrer"
          [trackProductAnalytics]="TRACK_CLICK_EVENT"
          [properties]="KNOWLEDGE_BASE"
        >
          <div class="xl:hidden md:block">Learn more</div>
          <div class="hidden xl:block md:hidden">More</div>
        </a>
      </div>
    </div>
    <div>
      <button (click)="handleClose()" class="hover:cursor-pointer">
        <norby-icon
          class="pl-2 justify-start items-start"
          name="x"
        ></norby-icon>
      </button>
    </div>
  </div>
</div>
