import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

import dayjs from 'dayjs';

import {
  ContentSignup,
  IPromptResponse,
  IUserContent,
  Platform,
  PromptResponseChanges,
  RegisterableLayoutTypeEnum
} from 'models';
import { rootLock, rootCalendar, rootUnlock } from '../../../icons';
import { IconService, MockContentInteractionsService } from '../../services';
import { THEME_CLASSES } from '../../constants';
import {
  RegisterableDetailClassicLeftViewComponent,
  RegisterableDetailClassicRightViewComponent,
  RegisterableDetailClassicVerticalComponent,
  RegisterableDetailLargeImageViewComponent
} from '../registerable-detail';

@Component({
  selector: 'lib-drop-detail-view',
  templateUrl: './drop-detail-view.component.html',
  styleUrls: ['./drop-detail-view.component.less']
})
export class DropDetailViewComponent
  implements AfterViewInit, OnChanges, DoCheck
{
  @Input() drop: ContentSignup;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() shouldDisableButtons = false;
  @Input() applyTheme = true;
  @Input() platform: Platform;
  @Input() shouldAutoTriggerRsvpFlow: boolean;
  @Input() isAnonymousUser: boolean;

  @Output() onSubmitAnother = new EventEmitter<void>();

  @ViewChild('classicLeftTpl')
  classicLeftViewTemplate: TemplateRef<RegisterableDetailClassicLeftViewComponent>;
  @ViewChild('classicRightTpl')
  classicRightViewTemplate: TemplateRef<RegisterableDetailClassicRightViewComponent>;
  @ViewChild('classicVerticalTpl')
  classicVerticalTemplate: TemplateRef<RegisterableDetailClassicVerticalComponent>;
  @ViewChild('largeImageTpl')
  largeImageTemplate: TemplateRef<RegisterableDetailLargeImageViewComponent>;

  stagedPromptResponses: IPromptResponse[];
  registrationCloseDate: string;
  registrationCloseTime: string;
  priceStr: string;
  isFormInvalid: boolean = false;
  dropLayoutTemplate: TemplateRef<any>;

  private _cachedSignupHash: number;
  private _isRsvpHandoffPreviouslyTriggered: boolean = false;

  constructor(
    private _iconService: IconService,
    private _cdr: ChangeDetectorRef,
    private _interactions: MockContentInteractionsService,
    @Inject(THEME_CLASSES) private _wrapperClasses
  ) {
    this._iconService.registerIcons([rootLock, rootCalendar, rootUnlock]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.drop) {
      this._updateProps();
      this._handlePotentialRsvpHandoff();
    }
  }

  ngDoCheck(): void {
    if (this.drop && this.drop.hash !== this._cachedSignupHash) {
      this._updateProps();
      this._updateLayout();
      this._cachedSignupHash = this.drop.hash;
      this._cdr.markForCheck();
    }
  }

  ngAfterViewInit(): void {
    this._updateLayout();
  }

  get remainingCount(): number {
    return Math.max(this.drop?.rsvpRestrictions?.remaining, 0);
  }

  private _updateProps() {
    this.registrationCloseDate = this.drop?.registrationCloseDate
      ? dayjs(this.drop.registrationCloseDate).format('MMM DD, YYYY')
      : null;
    this.registrationCloseTime = this.drop?.registrationCloseDate
      ? dayjs(this.drop.registrationCloseDate).format('h:mm A')
      : null;

    this.priceStr =
      this.drop?.tickets?.length > 0
        ? `$${(this.drop.tickets[0]?.price ?? 0) / 100}`
        : null;

    return null;
  }

  handleSubmitAnother() {
    this.onSubmitAnother.emit();
  }

  isCurrentDate(registrationCloseDate: string): boolean {
    return registrationCloseDate === dayjs().format('MMM DD, YYYY');
  }

  get dateTimePrompt(): string {
    return this.drop?.tickets?.length > 0 ? 'Purchase by' : 'Sign up by';
  }

  get shouldShowForm() {
    return this.drop.signupType === 'form';
  }

  private async _handlePotentialRsvpHandoff() {
    if (this.drop) {
      const triggerRsvpHandoff =
        this.shouldAutoTriggerRsvpFlow &&
        !this.userContent?.rsvpEnabled &&
        !this._isRsvpHandoffPreviouslyTriggered;
      if (triggerRsvpHandoff && !this.isAnonymousUser) {
        this._interactions.registerForContent(
          this.drop,
          this._wrapperClasses,
          this.userContent?.promptResponses,
          true
        );
        this._isRsvpHandoffPreviouslyTriggered = true;
      }
    }
  }

  private _updateLayout() {
    switch (this.drop?.layout) {
      case RegisterableLayoutTypeEnum.CLASSIC_LEFT:
        this.dropLayoutTemplate = this.classicLeftViewTemplate;
        break;
      case RegisterableLayoutTypeEnum.CLASSIC_RIGHT:
        this.dropLayoutTemplate = this.classicRightViewTemplate;
        break;
      case RegisterableLayoutTypeEnum.CLASSIC_VERTICAL:
        this.dropLayoutTemplate = this.classicVerticalTemplate;
        break;
      case RegisterableLayoutTypeEnum.LARGE_IMAGE:
        this.dropLayoutTemplate = this.largeImageTemplate;
        break;
      default:
        this.dropLayoutTemplate = this.classicLeftViewTemplate;
    }
  }

  handlePromptResponseChanges(userContentChanges: PromptResponseChanges): void {
    this.stagedPromptResponses = userContentChanges?.promptResponses;
    this.isFormInvalid = !userContentChanges?.valid;
  }
}
