import { ModalOptions } from 'ng-zorro-antd/modal';

export const FULL_SCREEN_MODAL_SETTINGS: ModalOptions = {
  nzClosable: false,
  nzFooter: null,
  nzTitle: null,
  nzWidth: '100vw',
  nzBodyStyle: {
    padding: '0px',
    margin: '0px'
  },
  nzStyle: {
    maxWidth: '100vw',
    top: '0px',
    margin: '0px',
    padding: '0px',
    bottom: '0px'
  },
  nzClassName: 'flush-modal',
  nzAutofocus: null
};

export const DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS: ModalOptions = {
  nzClosable: false,
  nzFooter: null,
  nzTitle: null,
  nzWidth: '700px',
  nzBodyStyle: {
    padding: '0px',
    margin: '0px'
  },
  nzStyle: {
    maxWidth: '100vw'
  },
  nzClassName: 'responsive-modal',
  nzAutofocus: null
};
