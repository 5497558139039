<form [formGroup]="formGroup">
  <norby-alert type="info">
    Publish your changes to see updates to Calendly block settings take effect.
  </norby-alert>

  <norby-collapsible title="Basic settings" [opened]="false">
    <div class="mb-4">
      <norby-switch-button
        label="Hide page details"
        formControlName="hidePageDetails"
      >
      </norby-switch-button>
    </div>

    <div class="mb-4">
      <norby-switch-button
        class="mb-4"
        label="Hide cookie banner"
        formControlName="hideCookieBanner"
      >
      </norby-switch-button>
    </div>
  </norby-collapsible>

  <norby-collapsible title="Advanced customization" [opened]="false">
    <div class="mb-8">
      <norby-ant-color-picker
        label="Background Color"
        formControlName="bookingPageBackgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Text Color"
        formControlName="bookingPageTextColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Link Color"
        formControlName="bookingPageButtonLinkColor"
      >
      </norby-ant-color-picker>
    </div>

    <norby-alert type="info">
      Advanced customization options are only available on certain Calendly
      plans. Please check your Calendly account details for further information.
    </norby-alert>
  </norby-collapsible>
</form>
