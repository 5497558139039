import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import {
  FormModule,
  IconModule,
  NorbyButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyRadioButtonGroupModule,
  NorbyRadioButtonModule,
  NorbySelectModule,
  NorbySwitchButtonModule,
  NorbyTabModule,
  NorbyTabsModule,
  SpinModule,
  TooltipModule
} from 'uikit';
import { EventNotificationsStepComponent } from '../components/templates/events/event-notifications-step';
import { NorbyRichTextEditorViewModule } from './norby-rich-text-editor-view.module';
import { NorbySegmentCounterModule } from './norby-segment-counter.module';
import { LinkShortenerPopoverViewModule } from './link-shortener-popover-view.module';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';

@NgModule({
  declarations: [EventNotificationsStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyButtonModule,
    NorbyFormcontrolLabelModule,
    NorbyRadioButtonGroupModule,
    NorbyRadioButtonModule,
    NorbyRichTextEditorViewModule,
    NorbySelectModule,
    NorbySegmentCounterModule,
    TooltipModule,
    NzCardModule,
    NzDividerModule,
    NzListModule,
    NzFormModule,
    NorbyTabsModule,
    NorbyTabModule,
    SpinModule,
    NzButtonModule,
    NzUploadModule,
    IconModule,
    NorbyCustomMessageTooltipModule,
    LinkShortenerPopoverViewModule,
    NorbySwitchButtonModule
  ],
  exports: [EventNotificationsStepComponent]
})
export class EventNotificationsStepModule {}
