<div
  class="landing-page-view-container"
  @initalRenderNoop
  [@.disabled]="!showAsAbstract"
  live-themed
  [applyThemeStyles]="applyTheme"
  [theme]="page?.theme"
>
  <div
    class="item"
    *ngFor="let block of page?.blocks; let i = index; trackBy: trackBy"
    #block
    [class.narrow]="
      block?.blockType !== 'header' && block?.blockType !== 'embed'
    "
    [@slideUpInDownOut]="block?.blockType !== 'header' ? 'slide' : null"
  >
    <lib-adaptable-block-view
      (contentClick)="handleClick($event)"
      [block]="block"
      [showAsAbstract]="showAsAbstract"
      [accountInfo]="accountInfo"
      [pageId]="page?.id"
    ></lib-adaptable-block-view>
  </div>
</div>
