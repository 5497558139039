<root-spin [spinning]="isSearchingRecipient">
  <div class="divide-y">
    <div *ngIf="contentFilterOptions?.length" class="p-2">
      <h5 class="text-neutral-600 text-xs uppercase font-medium py-2">
        Content
      </h5>
      <div *ngFor="let option of contentFilterOptions; let i = index">
        <div class="flex items-center py-2">
          <norby-icon
            class="flex-initial"
            [name]="getContentIcon(option.content.contentType)"
          ></norby-icon>
          <div
            class="flex-auto"
            [innerHtml]="option.highlightedTitle || option.content.title"
          ></div>
        </div>
        <div class="ml-3 border-l">
          <div
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="
              handleAddBasicContentFilter(option, filterTypes.REGISTERED)
            "
          >
            Registered
            <div
              class="flex"
              [rootTooltip]="getToolTip(option?.content['_registered'])"
            >
              {{ getCount(option, 'registered') }}
              <norby-icon name="user"></norby-icon>
            </div>
          </div>
          <div
            *ngIf="option.content.contentType === 'event'"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="handleAddBasicContentFilter(option, filterTypes.ATTENDED)"
          >
            Attended
            <div
              class="flex"
              [rootTooltip]="getToolTip(option?.content['_attended'])"
            >
              {{ getCount(option, 'attended') }}
              <norby-icon name="user"></norby-icon>
            </div>
          </div>
          <div
            *ngIf="option.content.contentType === 'event'"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="handleAddBasicContentFilter(option, filterTypes.SKIPPED)"
          >
            Skipped
            <div
              class="flex"
              [rootTooltip]="getToolTip(option?.content['_skipped'])"
            >
              {{ getCount(option, 'skipped') }}
              <norby-icon name="user"></norby-icon>
            </div>
          </div>
          <div
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="
              handleAddBasicContentFilter(
                option,
                filterTypes.VISITED_CONTENT_PAGE
              )
            "
          >
            Visited Page
          </div>
          <div
            [addFilter]="true"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            [customFilter]="contentFilterCustomizerTpl"
            position="right"
            (click)="handleCustomizeContentFilter(option)"
            (mouseOnDropdown)="handleChildDropdownMouseEvent($event)"
          >
            Custom
            <div class="flex">
              <norby-icon name="chevron_right"></norby-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="contactListFilterOptions?.length" class="p-2">
      <h5 class="text-neutral-600 text-xs uppercase font-medium py-2">Lists</h5>
      <div *ngFor="let option of contactListFilterOptions; let i = index">
        <div
          class="flex items-center py-2 rounded-sm"
          [ngClass]="{
            'text-neutral-600 cursor-not-allowed':
              option.list.visibility !== 'public',
            'hover:bg-neutral-300 cursor-pointer':
              option.list.visibility === 'public'
          }"
          [rootTooltip]="
            option.list.visibility !== 'public'
              ? 'Only shared lists can be used as filters.'
              : ''
          "
          (click)="handleAddContactList(option.list)"
        >
          <norby-icon class="flex-none" name="list"></norby-icon>
          <div class="flex flex-auto items-center min-w-0">
            <div class="flex flex-1 mr-2 min-w-0">
              <div class="mr-2 truncate flex-initial">In {{ option.name }}</div>
            </div>
            <div
              class="flex flex-none"
              [rootTooltip]="getToolTip(option?.list['_counts'])"
            >
              {{ option.list.size }}
              <norby-icon name="user"></norby-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="engagementFilterOptions?.length" class="p-2">
      <h5 class="text-neutral-600 text-xs uppercase font-medium py-2">
        Engagement
      </h5>
      <div *ngFor="let option of engagementFilterOptions; let i = index">
        <div class="flex items-center py-2">
          <norby-icon
            class="flex-initial"
            [name]="getMessageIcon(option?.send?.deliveryType)"
          ></norby-icon>
          <div
            class="flex-auto"
            [rootTooltip]="getEngagementTooltip(option)"
            [innerHtml]="
              truncateStringDisplay(
                option.send.subject || option.highlightedText
              ) ?? ''
            "
          ></div>
        </div>
        <div class="ml-3 border-l">
          <div
            *ngIf="option?.send?.deliveryType === 'email'"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="
              handleAddBasicEngagementFilter(
                option,
                filterTypes.OPENED_SPECIFIC_EMAIL
              )
            "
          >
            Opened
          </div>
          <div
            *ngIf="option?.send?.deliveryType === 'email'"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="
              handleAddBasicEngagementFilter(
                option,
                filterTypes.NOT_OPENED_SPECIFIC_EMAIL
              )
            "
          >
            Not opened
          </div>
          <div
            *ngIf="option.send?._embeddedLinks?.length > 0"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            (click)="
              handleAddBasicEngagementFilter(
                option,
                filterTypes.CLICKED_ANY_SEND_LINK
              )
            "
          >
            Clicked any link
          </div>
          <div
            *ngIf="option.send?._embeddedLinks?.length > 0"
            [addFilter]="true"
            class="flex flex-1 justify-between cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            [customFilter]="engagementFilterCustomizerTpl"
            position="right"
            (click)="handleCustomizeEngagementFilter(option)"
            (mouseOnDropdown)="handleChildDropdownMouseEvent($event)"
          >
            Custom
            <div class="flex">
              <norby-icon name="chevron_right"></norby-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="landingPageFilterOptions?.length" class="p-2">
      <h5 class="text-neutral-600 text-xs uppercase font-medium py-2">
        Landing Pages
      </h5>
      <div *ngFor="let option of landingPageFilterOptions; let i = index">
        <div class="flex items-center py-2">
          <norby-icon class="flex-initial" name="layout"></norby-icon>
          <div
            class="flex-auto cursor-pointer"
            (click)="
              handleAddLandingPageFilter(
                option,
                filterTypes.VISITED_LANDING_PAGE
              )
            "
          >
            Visited {{ option.title }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isEventUpdates && tagText?.length > 0" class="p-2">
      <h5 class="text-neutral-600 text-xs uppercase font-medium py-2">Tags</h5>
      <div
        (click)="handleAddTagFilter()"
        class="flex items-center py-2 cursor-pointer hover:bg-neutral-300 rounded-sm"
      >
        <norby-icon class="flex-initial" name="tag"></norby-icon>
        <div class="flex flex-auto">Contacts tagged "{{ tagText }}"</div>
      </div>
      <div
        (click)="handleAddNotTaggedFilter()"
        class="flex items-center py-2 cursor-pointer hover:bg-neutral-300 rounded-sm"
      >
        <norby-icon class="flex-initial" name="slash"></norby-icon>
        <div class="flex flex-auto">Contacts not tagged "{{ tagText }}"</div>
      </div>
    </div>
  </div>
</root-spin>

<root-dropdown-menu #contentFilterCustomizerTpl>
  <norby-custom-content-filter
    [content]="content"
    (onAddFilter)="handleAddFilter($event)"
  ></norby-custom-content-filter>
</root-dropdown-menu>

<root-dropdown-menu #engagementFilterCustomizerTpl>
  <norby-custom-engagement-filter
    [sendSearchResult]="sendSearchResult"
    (onAddFilter)="handleAddFilter($event)"
  ></norby-custom-engagement-filter>
</root-dropdown-menu>
