import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

// Libs
import { slideIn } from 'customer-uikit';
import { Stage, VALID_COUNTRY_CODES, isPhoneNumberValid } from 'models';

@Component({
  selector: 'lib-phone-login-form',
  templateUrl: './phone-login-form.component.html',
  styleUrls: ['./phone-login-form.component.less'],
  animations: [slideIn]
})
export class PhoneLoginFormComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() isResendingCode: boolean;
  @Input() currentStage: Stage;
  @Input() title: string;
  @Input() subtitle: string;

  @Output() handlePhoneSubmit = new EventEmitter<{
    phoneNumber: string;
    countryCode: string;
  }>();
  @Output() handleCodeSubmit = new EventEmitter<{ verificationCode: string }>();
  @Output() handleResendCodeSubmit = new EventEmitter<void>();

  phoneFormGroup: UntypedFormGroup;
  codeFormGroup: UntypedFormGroup;
  phonePlaceholder = '(123) 456-7890'; // Country-dependent placeholder

  readonly VALID_COUNTRY_CODES = VALID_COUNTRY_CODES;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  // Comparator for keyvalue pipe to force Angular to preserve
  // the order of the country codes map
  preserveOrder = (a, b): number => 0;

  ngOnInit(): void {
    this._initForms();
  }

  submitPhone() {
    this.handlePhoneSubmit.emit(this.phoneFormGroup.value);
  }

  submitCode() {
    this.handleCodeSubmit.emit(this.codeFormGroup.value);
  }

  submitResendCode() {
    this.handleResendCodeSubmit.emit();
  }

  handleCountryCodeChanged(code) {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  private _initForms() {
    this.phoneFormGroup = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required]
      },
      { validators: this._isPhoneNumberValid }
    );

    this.codeFormGroup = this._formBuilder.group({
      verificationCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberValid(
    group: UntypedFormGroup
  ): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }
}
