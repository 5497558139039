<div class="event-container">
  <div class="event-wrapper">
    <div>
      <h1>Summary</h1>

      <p class="text-neutral-600">
        Review your event and make sure everything looks right.
      </p>
    </div>

    <lib-summary-card-view title="Details" [items]="detailsItems"
      ><norby-button
        text="Edit"
        buttonType="minimal"
        (buttonClicked)="onGoToStep.emit(1)"
      ></norby-button
    ></lib-summary-card-view>

    <lib-summary-card-view title="Registration" [items]="registrationItems"
      ><norby-button
        text="Edit"
        buttonType="minimal"
        (buttonClicked)="onGoToStep.emit(2)"
      ></norby-button
    ></lib-summary-card-view>
  </div>
</div>
