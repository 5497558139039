<div class="banner h-16 bg-white flex items-center px-4">
  <div class="flex-none" *ngIf="closeIcon">
    <root-button
      type="ghost"
      [isOnCard]="true"
      name="Close"
      aria-label="Close"
      role="button"
      (handleClick)="closeClick.next()"
    >
      <root-icon name="x"></root-icon>
    </root-button>
  </div>

  <div class="flex-auto px-2 min-w-0">
    <h4 *ngIf="title" class="m-0 truncate">{{ title }}</h4>
  </div>

  <div class="flex-none">
    <ng-content></ng-content>
  </div>
</div>
