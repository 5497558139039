import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoViewComponent } from '../views/logo-view';

@NgModule({
  declarations: [LogoViewComponent],
  imports: [CommonModule],
  exports: [LogoViewComponent]
})
export class LogoViewModule {}
