<form [formGroup]="formGroup">
  <norby-icon-radio-button-group
    label="Alignment"
    formControlName="alignment"
    size="default"
    class="flex-row"
  >
    <norby-icon-radio-button
      iconName="align_left"
      buttonValue="left"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_center"
      buttonValue="center"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_right"
      buttonValue="right"
    ></norby-icon-radio-button>
  </norby-icon-radio-button-group>
  <norby-text-area
    label="Quote Text"
    placeholder="Insert text here..."
    formControlName="quote"
    [autoSize]="true"
    [rows]="3"
  ></norby-text-area>
  <norby-input
    label="Quote Font Size"
    type="number"
    formControlName="quoteFontSize"
  ></norby-input>
  <norby-input
    label="Attribution Text"
    type="text"
    placeholder="Insert text here..."
    formControlName="attribution"
  ></norby-input>
  <norby-input
    label="Attribution Font Size"
    type="number"
    formControlName="attributionFontSize"
  ></norby-input>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
