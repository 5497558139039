<div
  class="ant-btn-animation rounded-full border border-neutral-400 overflow-hidden flex items-center min-w-0 -my-px"
  [ngClass]="{
    'border-neutral-400 text-neutral-700': !isActive,
    'border-mustard text-mustard bg-latte-400': isActive
  }"
>
  <div class="py-1 px-3 text-xs truncate flex-initial">
    {{ title }}
  </div>
  <button
    *ngIf="isRemoveable"
    class="ant-btn-animation border-l flex-none flex justify-center items-center opacity-60 hover:opacity-100"
    [ngClass]="{
      'border-neutral-400': !isActive,
      'border-mustard': isActive
    }"
    (click)="handleClick()"
  >
    <root-icon name="x" size="small"></root-icon>
  </button>
</div>
