import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';

// App
import { EditProfileComponent } from './edit-profile.component';
import { IconModule, SoftImageViewModule } from 'uikit';

@NgModule({
  declarations: [EditProfileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SoftImageViewModule,
    FormsModule,

    NzFormModule,
    NzInputModule,
    NzUploadModule,
    NzIconModule,
    NzButtonModule,

    IconModule
  ],
  exports: [EditProfileComponent]
})
export class EditProfileModule {}
