import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';

import { BaseComponent, DO_NOT_TRACK_ANALYTICS } from 'uikit';
import {
  Platform,
  IAccountInfo,
  LandingPage,
  Theme,
  extractPageBackground,
  compareThemes
} from 'models';

@Component({
  selector: 'lib-landing-page-preview',
  templateUrl: './landing-page-preview.component.html',
  styleUrls: ['./landing-page-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DO_NOT_TRACK_ANALYTICS, useValue: true }]
})
export class LandingPagePreviewComponent
  extends BaseComponent
  implements OnChanges, DoCheck, AfterContentInit
{
  @Input() page: LandingPage;
  @Input() platform: Platform = 'mobile';
  @Input() accountInfo?: IAccountInfo;
  @Input() theme?: Theme; // Account level theme
  @Input() showAsAbstract = false;
  @Input() isInteractive = true;
  @Input() isScrollable = true;

  @Output() onContentBlocksUpdate = new EventEmitter<ElementRef[]>();

  backgroundCss: string;
  height: string;
  isBrowser = false;

  private _cachedPageHash: number;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit(): void {
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const didUpdateBackground = this._didUpdateBackground();
    const didUpdateTheme =
      changes.theme && !compareThemes(changes.theme?.previousValue, this.theme);
    const didUpdateAccountInfo = changes?.accountInfo;

    const shouldDetectChanges =
      didUpdateTheme || didUpdateBackground || didUpdateAccountInfo;

    if (shouldDetectChanges) {
      this._cdr.detectChanges();
    }
  }

  ngDoCheck() {
    if (this.page && this.page.hash !== this._cachedPageHash) {
      this._didUpdateBackground();
      this._cachedPageHash = this.page.hash;
      this._cdr.markForCheck();
    }
  }

  private _didUpdateBackground(): boolean {
    const oldCss = this.backgroundCss;
    this.backgroundCss = extractPageBackground(this.page, this.theme);

    return oldCss !== this.backgroundCss;
  }

  handleContentBlocksUpdated(blocks: ElementRef[]): void {
    this.onContentBlocksUpdate.emit(blocks);
  }
}
