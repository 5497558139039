import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

// 3rd party
import { filter, take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { NzDrawerOptions } from 'ng-zorro-antd/drawer';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';

// Libs
import {
  UiService,
  UserService,
  AnalyticsService,
  DrawerService
} from 'shared';
import {
  LandingPage,
  DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS,
  ContentRegisterable,
  SETTINGS_EVENTS,
  Theme,
  ProductAnalyticsEventTypes,
  IAccountIntegration
} from 'models';

// App
import { TimeDimensionType } from '@customer/types/dateFilter';
import { ContactCount } from '@customer/entry-points/contact-analytics';
import { ProductAnalyticsService } from '../product-analytics';
import { IAdminModalService } from './admin-modal.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AdminModalService implements IAdminModalService {
  // Lazy loaded entry points
  sideMenuComponent;
  finishOnboardingComponent;
  newsletterAnalyticsComponent;
  linkClicksDetailComponent;
  copyBlockDialogComponent;
  themePreviewComponent;
  contactAnalyticsComponent;
  instagramPermissionsComponent;
  instagramMessageInstructionsComponent;
  purgeInstagramComponent;
  referFriendsComponent;
  askAnExpertComponent;

  constructor(
    private _drawer: DrawerService,
    private _dialog: NzModalService,
    private _analytics: AnalyticsService,
    private _productAnalytics: ProductAnalyticsService,
    private _router: Router,
    private _user: UserService,
    private _ui: UiService
  ) {}

  async launchFinishOnboarding() {
    if (!this.finishOnboardingComponent) {
      this._ui.setLoading(true);
      const { FinishOnboardingComponent } = await import(
        '../../entry-points/finish-onboarding'
      );
      this.finishOnboardingComponent = FinishOnboardingComponent;
      this._ui.setLoading(false);
    }

    const component = this.finishOnboardingComponent;
    const dialogRef = this._dialog.create<typeof component>({
      ...DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS,
      nzContent: component,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzMaskStyle: { backgroundColor: '#fcfaf7' }
    });

    const ret = await firstValueFrom(dialogRef.afterClose);

    this._productAnalytics.trackProductAnalytics(
      ProductAnalyticsEventTypes.COMPLETED_PRODUCTIZED_ONBOARDING
    );
    this._user.markTipSeen('finishedOnboarding');

    return ret;
  }

  // Launch a modal to invite a user to the current organization
  async launchAskAnExpert(): Promise<boolean> {
    this._analytics.track(SETTINGS_EVENTS.userBeganAskAnExpertFlow);

    if (!this.askAnExpertComponent) {
      this._ui.setLoading(true);
      const { AskAnExpertModalComponent } = await import(
        '../../entry-points/ask-an-expert'
      );

      this.askAnExpertComponent = AskAnExpertModalComponent;
      this._ui.setLoading(false);
    }

    const component = this.askAnExpertComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Talk to an expert'
    });

    const result = await firstValueFrom(dialogRef.afterClose);

    this._analytics.track(SETTINGS_EVENTS.userFinishedAskAnExpertFlow);

    return result;
  }

  // Launch the main left side menu visible to admins
  // on tablet/mobile
  async launchMainMenu(): Promise<any> {
    if (!this.sideMenuComponent) {
      this._ui.setLoading(true);
      const { SideMenuComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/side-menu'
      );

      this.sideMenuComponent = SideMenuComponent;
      this._ui.setLoading(false);
    }

    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        take(1)
      )
      .subscribe((e) => this._drawer.closeDrawer());

    const component = this.sideMenuComponent;
    const fromLeft = window.innerWidth > 566;
    const opts: NzDrawerOptions = fromLeft
      ? { nzPlacement: 'left', nzClosable: false, nzWidth: '300px' }
      : { nzClosable: false };
    await this._drawer.createDrawer(component, opts);
  }

  async launchLinkClicksDetail(
    content: ContentRegisterable,
    link: string,
    trackingEvents: string[]
  ) {
    if (!this.linkClicksDetailComponent) {
      this._ui.setLoading(true);
      const { LinkClicksDetailComponent } = await import(
        '../../entry-points/link-clicks-detail'
      );

      this.linkClicksDetailComponent = LinkClicksDetailComponent;
      this._ui.setLoading(false);
    }

    const component = this.linkClicksDetailComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { content, link, trackingEvents }
    });
  }

  // Launch the copy block dialogue flow the first time a user performs an action (views the admin dashboard, publish an event/drop)
  // TODO: remove unused?
  async launchCopyBlockDialogFlow(
    tooltipKey: string,
    ctaRedirectUrl?: string[],
    height?: string
  ): Promise<any> {
    const tipSeen = await this._user.checkTipSeen(tooltipKey);
    if (tipSeen) return;

    if (!this.copyBlockDialogComponent) {
      this._ui.setLoading(true);
      const { CopyBlockDialogComponent } = await import(
        '../../entry-points/copy-block-dialog'
      );

      this.copyBlockDialogComponent = CopyBlockDialogComponent;
      this._ui.setLoading(false);
    }

    const component = this.copyBlockDialogComponent;
    const ref = this._dialog.create<typeof component>({
      nzFooter: null,
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzData: { tooltipKey, height },
      nzBodyStyle: {
        padding: '0px'
      },
      nzWidth: '480px',
      nzStyle: {
        top: '50px'
      }
    } as ModalOptions);

    const ret = await firstValueFrom(ref.afterClose);
    if (ctaRedirectUrl && ret) {
      this._router.navigate(ctaRedirectUrl);
    }

    this._user.markTipSeen(tooltipKey);
    return ret;
  }

  async launchThemePreview(
    theme: Theme,
    collection?: LandingPage
  ): Promise<void> {
    if (!this.themePreviewComponent) {
      this._ui.setLoading(true);
      const { ThemePreviewComponent } = await import(
        '../../entry-points/theme-preview'
      );

      this.themePreviewComponent = ThemePreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.themePreviewComponent;
    this._drawer.createDrawer(component, {
      nzContentParams: { theme, collection }
    });
  }

  // Launch all up analytics for a specific contact
  async launchContactAnalytics(
    contact: ContactCount,
    currentTimeDimension?: TimeDimensionType
  ): Promise<string> {
    if (!this.contactAnalyticsComponent) {
      this._ui.setLoading(true);
      const { ContactAnalyticsComponent } = await import(
        '../../entry-points/contact-analytics'
      );

      this.contactAnalyticsComponent = ContactAnalyticsComponent;
      this._ui.setLoading(false);
    }

    const component = this.contactAnalyticsComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { contact, currentTimeDimension }
    });
  }

  async launchInstagramPermissions(
    accounts: IAccountIntegration[]
  ): Promise<void> {
    if (!this.instagramPermissionsComponent) {
      const { InstagramPermissionsComponent } = await import(
        '../../entry-points/instagram-permissions'
      );
      this.instagramPermissionsComponent = InstagramPermissionsComponent;
    }

    const component = this.instagramPermissionsComponent;
    this._dialog.create<typeof component, void>({
      nzContent: component,
      nzData: { accounts },
      nzTitle: 'Instagram Permissions'
    } as ModalOptions);
  }

  async launchInstagramMessageInstructions(): Promise<void> {
    if (!this.instagramMessageInstructionsComponent) {
      const { InstagramMessageInstructionsComponent } = await import(
        '../../entry-points/instagram-message-instructions'
      );
      this.instagramMessageInstructionsComponent =
        InstagramMessageInstructionsComponent;
    }

    const component = this.instagramMessageInstructionsComponent;
    this._dialog.create<typeof component, void>({
      nzContent: component,
      nzFooter: null,
      nzBodyStyle: { 'padding-top': '40px' }
    });
  }

  async launchInstagramPermissionsError(): Promise<void> {
    this._dialog.create({
      nzTitle: 'Error integrating with Instagram',
      nzContent:
        'Looks like there was an error linking to Instagram. Please try again',
      nzCancelText: null
    });
  }

  async launchReferFriends(): Promise<void> {
    if (!this.referFriendsComponent) {
      this._ui.setLoading(true);
      const { ReferFriendsComponent } = await import(
        '../../entry-points/refer-friends'
      );
      this.referFriendsComponent = ReferFriendsComponent;
      this._ui.setLoading(false);
    }

    const component = this.referFriendsComponent;
    const dialogRef = this._dialog.create<typeof component>({
      ...DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS,
      nzContent: component,
      nzFooter: null,
      nzClosable: false,
      nzBodyStyle: {
        padding: '0px'
      }
    });

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchPurgeInstagram(accountId: string): Promise<boolean> {
    if (!this.purgeInstagramComponent) {
      this._ui.setLoading(true);
      const { PurgeInstagramComponent } = await import(
        '../../entry-points/purge-instagram'
      );
      this.purgeInstagramComponent = PurgeInstagramComponent;
      this._ui.setLoading(false);
    }

    const component = this.purgeInstagramComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzClosable: false,
      nzOkText: 'Confirm',
      nzTitle: 'Disconnect Instagram',
      nzCancelText: 'Cancel',
      nzData: { accountId }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }
}
