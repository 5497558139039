import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NorbyInputModule,
  NorbyFormcontrolLabelModule,
  FormModule,
  NorbyAlertModule,
  NorbySwitchButtonModule,
  NorbyFileUploadModule
} from 'uikit';
import { NorbyEditLandingPageComponent } from '../components/organisms/norby-edit-landing-page';

@NgModule({
  declarations: [NorbyEditLandingPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyInputModule,
    NorbyFormcontrolLabelModule,
    FormModule,
    NorbyAlertModule,
    NorbySwitchButtonModule,
    NorbyFileUploadModule
  ],
  exports: [NorbyEditLandingPageComponent]
})
export class NorbyEditLandingPageModule {}
