<form [formGroup]="formGroup">
  <div class="mb-6">
    <norby-content-selector
      [value]="blockEvent"
      (onCreateClick)="handleCreateClick()"
      formControlName="event"
      label="Event"
      contentType="event"
      placeholder="Select an option..."
      class="py-2"
    >
    </norby-content-selector>
  </div>
  <norby-slider
    formControlName="borderRadius"
    [floor]="0"
    [ceil]="100"
    [allowNull]="true"
    label="Border radius"
  ></norby-slider>
  <norby-slider
    formControlName="borderWidth"
    [floor]="0"
    [ceil]="100"
    [allowNull]="true"
    label="Border width"
  ></norby-slider>
  <norby-drop-shadow-picker
    label="Drop shadow"
    formControlName="dropShadow"
    placeholder="6px 6px 0px #000"
    formControlName="dropShadow"
  ></norby-drop-shadow-picker>
  <norby-ant-color-picker
    label="Background Color"
    formControlName="backgroundColor"
  >
  </norby-ant-color-picker>
  <norby-ant-color-picker label="Text Color" formControlName="textColor">
  </norby-ant-color-picker>
  <norby-ant-color-picker label="Border Color" formControlName="borderColor">
  </norby-ant-color-picker>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>

  <div *ngIf="blockEvent" class="mt-5">
    <norby-button
      text="Edit Event details..."
      buttonType="secondary"
      (buttonClicked)="handleEditDetails()"
    ></norby-button>
  </div>
</form>
