import { Injectable } from '@angular/core';

// 3rd party
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

// Libs
import { DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS } from 'models';
import { DrawerService, UiService } from 'shared';

export const PAGE_DRAWER_LEVEL_OVERLAP = 32;

@Injectable({
  providedIn: 'root'
})
export class NotificationsModalService {
  notificationsComponent;
  notificationPreferencesModalComponent;

  constructor(
    private _dialog: NzModalService,
    private _ui: UiService,
    private _drawer: DrawerService
  ) {}

  async launchNotifications(): Promise<void> {
    if (!this.notificationsComponent) {
      this._ui.setLoading(true);
      const { NotificationsMenuComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/notifications-menu'
      );

      this.notificationsComponent = NotificationsMenuComponent;
      this._ui.setLoading(false);
    }

    const component = this.notificationsComponent;
    return this._drawer.createDrawer(component, {});
  }

  async launchNotificationPreferences(): Promise<void> {
    if (!this.notificationPreferencesModalComponent) {
      this._ui.setLoading(true);
      const { NotificationPreferencesModalComponent } = await import(
        '../../entry-points/notification-preferences-modal'
      );

      this.notificationPreferencesModalComponent =
        NotificationPreferencesModalComponent;
      this._ui.setLoading(false);
    }

    const component = this.notificationPreferencesModalComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      ...DESKTOP_MODAL_MOBILE_FULLSCREEN_SETTINGS,
      nzContent: component,
      nzClosable: true,
      nzCloseIcon: 'feather/x'
    });

    return await firstValueFrom(dialogRef.afterClose);
  }
}
