<nz-layout>
  <h3>{{ title }}</h3>
  <nz-content class="content-area">
    <form
      *ngIf="step === 'phone'"
      [formGroup]="phoneNumberForm"
      nz-form
      nzLayout="vertical"
    >
      <nz-form-item>
        <nz-input-group nzCompact>
          <nz-select
            style="width: 30%"
            (nzOpenChange)="!$event ? numberInput.focus() : null"
            (ngModelChange)="handleCountryCodeChanged($event)"
            formControlName="countryCode"
          >
            <nz-option
              [nzLabel]="country.value?.flag + ' +' + country.value?.value"
              nzCustomContent
              *ngFor="
                let country of VALID_COUNTRY_CODES | keyvalue : preserveOrder
              "
              [nzValue]="country.key"
            >
              {{ country.value?.flag + ' ' + country.value?.label }}
            </nz-option>
          </nz-select>
          <input
            style="width: 70%"
            type="tel"
            nz-input
            #numberInput
            [placeholder]="phonePlaceholder"
            formControlName="phoneNumber"
            required
          />
        </nz-input-group>
      </nz-form-item>

      <div class="action-button">
        <button
          (click)="next()"
          [disabled]="!phoneNumberForm.valid"
          [nzLoading]="isLoading"
          nz-button
          nzType="primary"
        >
          Next
        </button>
      </div>
    </form>

    <form *ngIf="step === 'code'" [formGroup]="codeForm">
      <p>Please enter the code we just sent you.</p>

      <nz-form-item>
        <nz-input-group [nzSuffix]="resendButton">
          <input
            nz-input
            type="number"
            name="verificationCode"
            formControlName="verificationCode"
            placeholder="Your verification code"
            required
            minLength="6"
            maxLength="6"
          />
        </nz-input-group>

        <ng-template #resendButton>
          <div class="stagger">
            <button
              (click)="resendPhoneCode()"
              [disabled]="!phoneNumberForm.valid"
              [nzLoading]="isResendingCode"
              nz-button
              nzType="primary"
              type="button"
            >
              <i nz-icon nzType="redo"></i>
              Resend
            </button>
          </div>
        </ng-template>
      </nz-form-item>

      <div class="action-button">
        <button
          (click)="submitCode()"
          [disabled]="!codeForm.valid"
          [nzLoading]="isLoading"
          nz-button
          nzType="primary"
        >
          Submit
        </button>
      </div>
    </form>
  </nz-content>
</nz-layout>
