<form [formGroup]="formGroup">
  <norby-custom-message-tooltip
    action="use HTML blocks"
    [trackProductAnalytics]="UPSELL_EVENT"
    [properties]="UPSELL_CUSTOM_HTML_BLOCK"
    (onMessageUpdated)="htmlBlockInput.isDisabled = !!$event"
  >
    <norby-text-area
      #htmlBlockInput
      [placeholder]="placeholder"
      [maxLength]="maxLength"
      [rows]="rows"
      formControlName="embedCode"
    ></norby-text-area>
  </norby-custom-message-tooltip>
</form>
