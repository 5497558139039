import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDividerModule } from 'ng-zorro-antd/divider';

// Libs
import { IconModule, NorbyCopyBlockModule } from 'uikit';
import {
  EmailLoginFormComponent,
  PhoneLoginFormComponent
} from '../components';

@NgModule({
  declarations: [PhoneLoginFormComponent, EmailLoginFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzSkeletonModule,
    NzSelectModule,
    NzDividerModule,

    NorbyCopyBlockModule,
    IconModule
  ],
  exports: [PhoneLoginFormComponent, EmailLoginFormComponent]
})
export class LoginFormsModule {}
