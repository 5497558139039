<div class="wrapper" #wrapper [style.height]="height">
  <div
    class="site-preview-container"
    [class.desktop-container]="platform === 'desktop'"
    [class.portrait-container]="platform === 'portrait'"
    [class.scrollable]="isScrollable"
    [class.interactive]="isInteractive"
    [class.fixed-height]="useFixedDeviceHeight"
    [style]="containerCss"
  >
    <div
      class="background"
      [style]="backgroundCss"
      *ngIf="!showAsAbstract"
    ></div>
    <div
      class="inner"
      [class.fixed-height]="useFixedDeviceHeight"
      cdkScrollable
      *ngIf="!showAsAbstract"
    >
      <ng-content></ng-content>
    </div>
    <img *ngIf="showAsAbstract" [src]="abstractSvg" class="w-full h-auto" />
  </div>
</div>
