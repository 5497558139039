import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FormModule,
  NorbyInputModule,
  IconModule,
  NorbyIconButtonWithLabelModule,
  NorbyButtonModule,
  SpinModule
} from 'uikit';
import { SignupChatStepComponent } from '../components/templates/signups/signup-chat-step';

@NgModule({
  declarations: [SignupChatStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,

    NorbyInputModule,
    NorbyIconButtonWithLabelModule,
    NorbyButtonModule,
    SpinModule,
    IconModule
  ],
  exports: [SignupChatStepComponent]
})
export class SignupChatStepModule {}
