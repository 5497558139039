import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';

import { ContentRowViewComponent } from '../views/content-row-view';

@NgModule({
  declarations: [ContentRowViewComponent],
  imports: [CommonModule, NzSkeletonModule, NzAvatarModule],
  exports: [ContentRowViewComponent]
})
export class ContentRowViewModule {}
