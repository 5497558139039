import { Injectable } from '@angular/core';

import { FirebaseStorage, getStorage, Storage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class BucketService {
  private _buckets: { [k: string]: FirebaseStorage } = {};

  constructor(private _storage: Storage) {}

  getBucket(name: string): FirebaseStorage {
    const bucket = this._buckets[name];

    if (!bucket) {
      // if bucket isn't instantiated yet, create one and cache it
      const storage = getStorage(this._storage.app, name);
      this._buckets[name] = storage;
      return storage;
    }

    return bucket;
  }
}
