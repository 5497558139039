import { Component, Input } from '@angular/core';
import {
  ClickedUpsellProductAnalyticsLocations,
  ClickedUpsellProductAnalyticsTypes,
  ProductAnalyticsEventTypes
} from 'models';

@Component({
  selector: 'lib-usage-tracker',
  templateUrl: './usage-tracker.component.html',
  styleUrls: ['./usage-tracker.component.less']
})
export class UsageTrackerComponent {
  @Input() usage: number;
  @Input() limit: number = 10;
  @Input() slugStr: string;

  readonly UPSELL_EVENT = ProductAnalyticsEventTypes.CLICKED_UPSELL;
  readonly UPSELL_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.USAGE_TRACKER,
    cta: 'Upgrade for more.'
  };

  get remainingSmsCount() {
    return Math.max(this.limit - this.usage, 0);
  }

  get usagePercentage() {
    return Math.round((this.usage / this.limit) * 100);
  }
}
