import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';

import { BaseComponent } from '../../../models/base-component';
import { NorbyStepsMenuItemComponent } from '../norby-steps-menu-item';

@Component({
  selector: 'norby-steps-menu',
  templateUrl: './norby-steps-menu.component.html',
  styleUrls: ['./norby-steps-menu.component.less']
})
export class NorbyStepsMenuComponent
  extends BaseComponent
  implements AfterContentInit, OnChanges
{
  @Input() activeIndex?: number;
  @Input() title: string = '';
  @Input() steps: string[];

  @Output() onMenuItemClicked: EventEmitter<number> =
    new EventEmitter<number>();

  @ContentChildren(NorbyStepsMenuItemComponent) items:
    | QueryList<NorbyStepsMenuItemComponent>
    | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.items?.forEach((item) => {
      item.isSelected = false;
    });

    this._setActiveItem();

    if (this.items) {
      this._initItemSubscriptions();
    }
  }

  ngAfterContentInit(): void {
    this._setActiveItem();
    this._initItemSubscriptions();
  }

  private _initItemSubscriptions(): void {
    this.items.forEach((item) =>
      item.onMenuItemClicked.pipe(this.takeUntilChanges).subscribe((index) => {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i]?.isSelected) {
            this.items[i].isSelected = false;
            break;
          }
        }

        item.isSelected = true;
        this.onMenuItemClicked.emit(item?.index);
      })
    );
  }

  private _setActiveItem() {
    const activeItem = this.items?.find(
      (item) => item.index === this.activeIndex
    );

    if (activeItem) {
      activeItem.isSelected = true;
    }
  }
}
