export enum EventDropAnalyticsTabs {
  OVERVIEW = 'overview',
  NOTIFICATIONS = 'notifications',
  USAGE = 'usage',
  REMINDER = 'reminder',
  CONFIRMATION = 'confirmation',
  START_NOTIFICATION = 'start notification'
}

export type EventDropAnalyticsTabsType = `${EventDropAnalyticsTabs}`;
