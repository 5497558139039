import { Injectable } from '@angular/core';

// 3rd party
import { firstValueFrom, map } from 'rxjs';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';

// Libs
import { UiService, AccountStoreService } from 'shared';
import { IFont } from 'models';
import { IThemeCustomizerServiceInterface } from 'uikit';

@Injectable({
  providedIn: 'root'
})
export class CustomerThemeCustomizerService
  implements IThemeCustomizerServiceInterface
{
  private _importFontDialog;
  private _inputAlertModalComponent;

  status$ = this._account.accountStatus$.pipe(
    map((status) => ({
      canUpdateFavicon:
        status?.isGrowthTier || status?.isProTier || status?.isEnterpriseTier
    }))
  );

  constructor(
    private _account: AccountStoreService,
    private _ui: UiService,
    private _dialog: NzModalService
  ) {}

  async addCardStyle(): Promise<string> {
    if (!this._inputAlertModalComponent) {
      this._ui.setLoading(true);
      const { InputAlertModalComponent } = await import(
        '../../entry-points/input-alert-modal'
      );
      this._inputAlertModalComponent = InputAlertModalComponent;
      this._ui.setLoading(false);
    }

    const component = this._inputAlertModalComponent;
    const ref = await this._dialog.create<typeof component, string>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzTitle: 'Create a new style',
      nzOkText: 'Done',
      nzData: {
        message: 'Give your custom style a name.',
        placeholder: 'Custom style name'
      }
    } as ModalOptions);

    return firstValueFrom(ref.afterClose);
  }

  async importCustomFont(): Promise<IFont> {
    if (!this._importFontDialog) {
      this._ui.setLoading(true);
      const { CustomFontConfigComponent } = await import(
        '../../entry-points/custom-font-config'
      );
      this._importFontDialog = CustomFontConfigComponent;
      this._ui.setLoading(false);
    }

    const component = this._importFontDialog;
    const ref = await this._dialog.create<typeof component, IFont>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzTitle: 'Import a custom font',
      nzOkText: 'Done'
    });

    return firstValueFrom(ref.afterClose);
  }
}
