<div class="buttons-placeholder" *ngIf="visible && buttons?.length"></div>
<div class="buttons" [@fadeInOut]="visible" *ngIf="visible && buttons?.length">
  <nz-alert
    *ngIf="messageState !== 'none'"
    [nzType]="messageState"
    nzBanner
    [nzMessage]="message"
  >
  </nz-alert>
  <div class="buttons-inner">
    <button
      nz-button
      *ngFor="let button of buttons"
      [nzType]="button.type"
      nzSize="large"
      [class.wide]="button.type === 'primary'"
      [disabled]="disabled && button.respectsDisabled"
      [nzLoading]="loading && button.respectsLoading"
      (click)="button.onClick()"
    >
      <root-icon *ngIf="button.icon" [name]="button.icon"></root-icon>
      {{ button.label }}
    </button>
  </div>
</div>
