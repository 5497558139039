<div live-themed [theme]="drop?.theme" [applyThemeStyles]="applyTheme">
  <ng-container
    *ngIf="dropLayoutTemplate"
    [ngTemplateOutlet]="dropLayoutTemplate"
  >
  </ng-container>

  <ng-template #classicLeftTpl>
    <lib-registerable-detail-classic-left-view
      [content]="drop"
      [userContent]="userContent"
      [platform]="platform"
      [mainButtonTemplate]="mainButtonTpl"
      [metadataTemplate]="metadataTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-left-view>
  </ng-template>

  <ng-template #classicRightTpl>
    <lib-registerable-detail-classic-right-view
      [content]="drop"
      [userContent]="userContent"
      [platform]="platform"
      [mainButtonTemplate]="mainButtonTpl"
      [metadataTemplate]="metadataTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-right-view>
  </ng-template>

  <ng-template #classicVerticalTpl>
    <lib-registerable-detail-classic-vertical
      [content]="drop"
      [userContent]="userContent"
      [platform]="platform"
      [mainButtonTemplate]="mainButtonTpl"
      [metadataTemplate]="metadataTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-vertical>
  </ng-template>

  <ng-template #largeImageTpl>
    <lib-registerable-detail-large-image
      [content]="drop"
      [userContent]="userContent"
      [platform]="platform"
      [mainButtonTemplate]="mainButtonTpl"
      [metadataTemplate]="metadataTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-large-image>
  </ng-template>

  <ng-template #metadataTpl>
    <div
      *ngIf="
        drop?.isFull ||
        (drop?.hasCapacity && drop?.rsvpRestrictions?.displayLimit) ||
        drop?.registrationCloseDate
      "
    >
      <root-tag
        *ngIf="drop?.isFull || drop?.isPastRegistrationDeadline"
        class="mr-3"
      >
        <root-icon size="small" name="lock"></root-icon>
        <span>Signups are closed</span>
      </root-tag>
      <root-tag
        *ngIf="
          drop?.registrationCloseDate &&
          !drop?.isPastRegistrationDeadline &&
          !drop?.isFull
        "
        class="mr-3"
      >
        <root-icon size="small" name="calendar"></root-icon>
        <span *ngIf="isCurrentDate(registrationCloseDate)">
          {{ dateTimePrompt }}
          {{ registrationCloseTime }}</span
        >
        <span
          *ngIf="!isCurrentDate(registrationCloseDate)"
          rootTooltip="{{ 'at ' + registrationCloseTime }}"
        >
          {{ dateTimePrompt }}
          {{ registrationCloseDate }}
        </span>
      </root-tag>
      <root-tag *ngIf="drop?.rsvpRestrictions?.displayLimit">
        <root-icon size="small" name="unlock"></root-icon>
        <span>{{ remainingCount }} left</span>
      </root-tag>
    </div>
    <h2 class="title card-text-color">
      {{ drop?.title }}
    </h2>
    <p *ngIf="drop?.subtitle" class="subtitle card-text-color">
      {{ drop.subtitle }}
    </p>
    <div *ngIf="priceStr" class="price card-text-color">
      <root-alert [message]="priceStr"> </root-alert>
    </div>

    <lib-drop-form-prompts-view
      *ngIf="shouldShowForm"
      [isLoading]="isLoading"
      [prompts]="drop.prompts"
      [promptResponses]="userContent?.promptResponses"
      [isDisabled]="userContent?.rsvpEnabled"
      (onPromptResponseChanges)="handlePromptResponseChanges($event)"
    ></lib-drop-form-prompts-view>
  </ng-template>

  <ng-template #mainButtonTpl>
    <lib-drop-main-button-view
      [drop]="drop"
      [userContent]="userContent"
      [promptResponses]="stagedPromptResponses"
      [isLoading]="isLoading"
      [forceDisabled]="shouldDisableButtons || isFormInvalid"
    >
    </lib-drop-main-button-view>
  </ng-template>
</div>
