import { Injectable } from '@angular/core';
import { Firestore, doc, docData, getDoc } from '@angular/fire/firestore';

// 3rd party
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  FIREBASE_COLLECTIONS,
  FIRESTORE_DEBOUNCE_TIME,
  ISlugMetadata,
  OnboardingCopyBlock
} from 'models';
import { DeviceService } from '../device';

@Injectable({
  providedIn: 'root'
})
export class AdminQueriesService {
  constructor(
    private _device: DeviceService,
    private _firestore: Firestore
  ) {}

  getCurrentSlugMetadata$(): Observable<ISlugMetadata> {
    return this._device.currentSlug$.pipe(
      switchMap((slug) =>
        docData(
          doc(this._firestore, FIREBASE_COLLECTIONS.slugs.metadata, slug)
        ).pipe(
          debounceTime(FIRESTORE_DEBOUNCE_TIME),
          map((s) => plainToClass(ISlugMetadata, s))
        )
      )
    );
  }

  async getOnboardingCopyById(id: string): Promise<OnboardingCopyBlock> {
    try {
      const ref = doc(this._firestore, 'onboardingCopy', id);
      const snapshot = await getDoc(ref);
      return snapshot.data() as OnboardingCopyBlock;
    } catch (e) {
      return null;
    }
  }
}
