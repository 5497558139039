<div class="payment-form-container">
  <root-spin [spinning]="isLoading">
    <!-- <h3>Choose your payment method</h3>

    <nz-radio-group
      [(ngModel)]="paymentMode"
      (ngModelChange)="handlePaymentModeChanged($event)"
    >
      <label nz-radio nzValue="card">Card</label>
      <label nz-radio nzValue="gPay">Google Pay</label>
    </nz-radio-group> -->

    <lib-card-payment-form
      (handleClearError)="handleClearError()"
      (handlePaymentCallback)="paymentCallback($event)"
      (handleSetError)="handleSetError($event)"
      *ngIf="paymentMode === 'card'"
      [elements]="elements"
      [name]="name"
      [slug]="slug"
      [paymentIntentSecret]="paymentIntentSecret"
      [stripe]="stripe"
      [ticket]="ticket"
      [themeClasses]="themeClasses"
    ></lib-card-payment-form>

    <div
      id="payment-request-button"
      [ngStyle]="{ display: paymentMode === 'gPay' ? 'block' : 'none' }"
    ></div>
  </root-spin>
</div>
