import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';

// 3rd party
import { filter, startWith } from 'rxjs/operators';

// Libs
import { BaseComponent, MockDeviceService } from 'uikit';
import { Platform } from 'models';

const MOBILE_FIXED_WIDTH = 375.0;
const MOBILE_FIXED_HEIGHT = 812.0;

const DESKTOP_FIXED_WIDTH = 1024.0;
const DESKTOP_FIXED_HEIGHT = 768.0;

const PORTRAIT_FIXED_WIDTH = 768.0;
const PORTRAIT_FIXED_HEIGHT = 1024.0;

@Component({
  selector: 'lib-device-preview-view',
  templateUrl: './device-preview-view.component.html',
  styleUrls: ['./device-preview-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePreviewViewComponent
  extends BaseComponent
  implements OnChanges, AfterContentInit
{
  @Input() platform: Platform = 'mobile';
  @Input() useFixedDeviceHeight = true;
  @Input() showAsAbstract = false;
  @Input() isInteractive = true;
  @Input() isScrollable = true;
  @Input() backgroundCss: string;

  @Output() onChangedDeviceScaleMultiplier = new EventEmitter<number>();

  @ViewChild('wrapper') wrapper: ElementRef;

  containerCss: string;
  height: string;
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _device: MockDeviceService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this._device.resize$
        .pipe(
          startWith([null]),
          filter(() => this._didUpdateLayout()),
          this.takeUntilDestroy
        )
        .subscribe(() => this._cdr.detectChanges());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const didUpdateLayout = changes.platform && this._didUpdateLayout();

    if (didUpdateLayout) {
      this._cdr.detectChanges();
    }
  }

  get abstractSvg(): string {
    return this.platform === 'mobile'
      ? 'assets/svg/abstract_detail.svg'
      : 'assets/svg/abstract_detail_desktop.svg';
  }

  private _didUpdateLayout(): boolean {
    const oldContainerCss = this.containerCss;
    const oldHeight = this.height;

    let deviceWidth = 0;
    let deviceHeight = 0;

    if (this.platform === 'mobile') {
      deviceWidth = MOBILE_FIXED_WIDTH;
      deviceHeight = MOBILE_FIXED_HEIGHT;
    } else if (this.platform === 'desktop') {
      deviceWidth = DESKTOP_FIXED_WIDTH;
      deviceHeight = DESKTOP_FIXED_HEIGHT;
    } else if (this.platform === 'portrait') {
      deviceWidth = PORTRAIT_FIXED_WIDTH;
      deviceHeight = PORTRAIT_FIXED_HEIGHT;
    }

    if (
      !this.isBrowser ||
      !this.wrapper?.nativeElement?.offsetWidth ||
      !deviceWidth ||
      !deviceHeight
    ) {
      this.containerCss = 'display: none';
      this.height = '0px';
    } else {
      const width = this.wrapper.nativeElement.offsetWidth;
      const multiplier = width / deviceWidth;

      this.containerCss = `transform: scale(${multiplier})`;
      this.height = this.useFixedDeviceHeight
        ? `${multiplier * deviceHeight}px`
        : `${100 / multiplier}%`;

      this.onChangedDeviceScaleMultiplier.emit(multiplier);
    }

    const containerCssChanged = oldContainerCss !== this.containerCss;
    const heightChanged = oldHeight !== this.height;

    return containerCssChanged || heightChanged;
  }
}
