<div class="editor-container">
  <div class="flex flex-1">
    <div class="editor-background" #editorContent>
      <lib-device-preview-view
        platform="portrait"
        [useFixedDeviceHeight]="false"
        (onChangedDeviceScaleMultiplier)="
          handleDeviceScaleMultiplierChanged($event)
        "
      >
        <div class="drag-container-wrapper">
          <!-- TODO: Reenable auto scroll and take into account scaled scroll coordinates -->
          <div
            class="drag-container"
            #dropList
            cdkDropList
            cdkDropListOrientation="vertical"
            [cdkDropListData]="send?.blocks"
            [cdkDropListAutoScrollDisabled]="true"
            (cdkDropListDropped)="handleDrop($event)"
          >
            <div
              *ngIf="send?.hasBeenSent"
              [innerHTML]="send?.renderedBody | safeHtml"
            ></div>
            <div *ngIf="!send?.hasBeenSent">
              <div
                *ngFor="
                  let block of send?.blocks;
                  let i = index;
                  trackBy: trackBy
                "
                class="relative block-item"
              >
                <div
                  class="send-block-list-item-drag bg-white"
                  cdkDrag
                  [cdkDragDisabled]="activeIndex === i"
                  (cdkDragStarted)="handleDragStarted($event)"
                >
                  <!-- Edit mode view for rich text blocks -->
                  <div
                    *ngIf="activeIndex === i && isTextBlock(send.blocks[i])"
                    class="cursor-pointer"
                  >
                    <edit-email-text-block-view
                      [block]="send?.blocks[i]"
                      (onUpdatedBlock)="handleUpdatedBlock($event)"
                    >
                    </edit-email-text-block-view>
                  </div>
                  <!-- Edit mode view for all other blocks -->
                  <div
                    *ngIf="!isTextBlock(send?.blocks[i]) && activeIndex === i"
                    class="cursor-pointer"
                  >
                    <ng-container
                      [ngTemplateOutlet]="editBlockTpl"
                    ></ng-container>
                  </div>
                  <!-- View mode for all blocks -->
                  <ng-container
                    *ngIf="activeIndex !== i"
                    [ngTemplateOutlet]="blockDisplayTpl"
                  ></ng-container>

                  <!-- Drag preview for all blocks with device transform applied -->
                  <ng-template cdkDragPreview [matchSize]="true">
                    <div class="flex">
                      <div
                        class="flex-1 bg-white rounded-sm overflow-hidden shadow-ant-default"
                      >
                        <div
                          class="origin-top-left"
                          [style]="dragPreviewContainerCss"
                        >
                          <div [style]="dragPreviewCss">
                            <ng-container
                              [ngTemplateOutlet]="blockDisplayTpl"
                            ></ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <!-- Inline template for view mode for all blocks -->
                  <ng-template #blockDisplayTpl>
                    <div
                      *ngIf="isMobile"
                      cdkDragHandle
                      class="absolute top-0 bottom-0 mt-auto mb-auto flex block-list-item-handle cursor-grab opacity-50 hover:opacity-100"
                    >
                      <div class="flex items-center justify-start">
                        <root-icon
                          name="more_vertical"
                          class="-mx-1"
                        ></root-icon>
                        <root-icon
                          name="more_vertical"
                          class="-mr-2 -ml-4"
                        ></root-icon>
                      </div>
                    </div>
                    <div
                      [ngClass]="
                        isMobile
                          ? 'block-display-mobile'
                          : 'block-display-desktop'
                      "
                      (click)="handleUpdateActiveIndex(i)"
                    >
                      <div
                        class="block-outline flex justify-center text-base view-email-block"
                        [ngClass]="{
                          'text-neutral-500 py-4': getBlockHasPlaceholder(i)
                        }"
                        [innerHTML]="getBlockViewDisplay(i) | safeHtml"
                      ></div>
                    </div>
                  </ng-template>

                  <!-- Inline template for view mode for all blocks -->
                  <ng-template #editBlockTpl>
                    <div
                      class="flex justify-center text-base edit-email-block"
                      [ngClass]="{
                        'text-neutral-500 py-4': getBlockHasPlaceholder(i)
                      }"
                      [innerHTML]="getBlockViewDisplay(i) | safeHtml"
                    ></div>
                  </ng-template>

                  <!-- Placeholder while dragging for all blocks -->
                  <div
                    class="item-placeholder-transition bg-mustard-200 rounded-sm"
                    [style.height.px]="draggingPlaceholderHeight"
                    *cdkDragPlaceholder
                  ></div>
                </div>
                <div
                  class="absolute flex left-0 -top-3 h-6 right-0 hide-when-dragging add-block-index"
                  (mouseover)="handleMouseOverFab(i)"
                  (mouseleave)="handleMouseLeaveFab()"
                >
                  <div
                    *ngIf="hoverIndex === i"
                    class="flex flex-1 justify-center"
                  >
                    <norby-fab
                      class="z-10 scale-75 hover:scale-100 transition-all opacity-50 hover:opacity-100"
                      [ngClass]="{
                        'opacity-100': hoverIndex === i && popoverHover,
                        'scale-100': hoverIndex === i && popoverHover
                      }"
                      iconName="plus"
                      nz-popover
                      nzPopoverTrigger="click"
                      [nzPopoverContent]="addBlockTemplate"
                      nzPopoverOverlayClassName="popover-flush"
                      nzPopoverPlacement="right"
                    ></norby-fab>
                    <div
                      class="absolute top-0 h-3 right-0 left-0 z-0 border-b border-border-color-split"
                    ></div>
                  </div>
                </div>
                <div
                  class="absolute top-1/2 left-full flex flex-col -translate-y-1/2 block-action-container hide-when-dragging"
                >
                  <div class="block-actions">
                    <button
                      type="button"
                      rootTooltip="Remove block"
                      class="opacity-50 hover:opacity-100 pb-3"
                      (click)="handleRemoveBlock(i)"
                    >
                      <root-icon name="trash"></root-icon>
                    </button>
                    <button
                      type="button"
                      rootTooltip="Duplicate"
                      class="opacity-50 hover:opacity-100"
                      (click)="handleDuplicateBlock(i)"
                    >
                      <root-icon name="copy"></root-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative" *ngIf="!send?.hasBeenSent">
              <div
                class="absolute flex left-0 -top-3 h-6 right-0 hide-when-dragging add-block-index"
                (mouseover)="handleMouseOverFab(send.blocks?.length || 0)"
                (mouseleave)="handleMouseLeaveFab()"
              >
                <div
                  *ngIf="hoverIndex === send.blocks?.length"
                  class="flex flex-1 justify-center"
                >
                  <norby-fab
                    class="z-10 scale-75 hover:scale-100 transition-all opacity-50 hover:opacity-100"
                    [ngClass]="{
                      'opacity-100':
                        hoverIndex === send.blocks?.length && popoverHover,
                      'scale-100':
                        hoverIndex === send.blocks?.length && popoverHover
                    }"
                    iconName="plus"
                    nz-popover
                    nzPopoverTrigger="click"
                    [nzPopoverContent]="addBlockTemplate"
                    nzPopoverOverlayClassName="popover-flush"
                    nzPopoverPlacement="right"
                  ></norby-fab>
                  <div
                    class="absolute top-0 h-3 right-0 left-0 z-0 border-b border-border-color-split"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </lib-device-preview-view>
    </div>
  </div>
  <div *ngIf="!isMobile" class="settings-container" cdkScrollable>
    <ng-container *ngTemplateOutlet="settings"></ng-container>
  </div>
  <lib-expandable-bottom-sheet-view
    *ngIf="isMobile"
    [collapsible]="true"
    [collapsedText]="bottomSheetCollapsedText"
    [flush]="true"
    [showDropShadow]="true"
    [expandedPosition]="56"
    [enableResizeListener]="false"
    backgroundColor="white"
    class="settings__small-screen"
  >
    <!-- TODO: Remove scrollable directive and class -->
    <div class="pb-8 mobile-scroll-container" cdkScrollable>
      <ng-container *ngTemplateOutlet="settings"></ng-container>
    </div>
  </lib-expandable-bottom-sheet-view>

  <div *ngIf="isMobile" class="fixed bottom-0 left-0 right-0 z-50">
    <norby-footer
      [activeIndex]="activeStepIndex"
      [steps]="steps"
      [isMobile]="isMobile"
      (onNavigationButtonClicked)="handleNavigationButtonClicked($event)"
    ></norby-footer>
  </div>

  <ng-template #settings>
    <div class="settings-breadcrumb">
      <norby-icon name="home" (click)="handleHomeClick()"></norby-icon>
      <norby-icon
        name="chevron-right"
        size="small"
        *ngIf="getBlockDisplay(activeIndex)"
      ></norby-icon>

      <label class="truncate ...">{{
        getBlockDisplay(activeIndex) | camelCaseToWords | titlecase
      }}</label>
    </div>
    <div class="px-4 pb-4">
      <div *ngIf="activeIndex < 0" class="block-list-item">
        <!-- <div class="rounded-t bg-azure-800 px-6 py-4">
          <img
            [src]="UI_MOCK_URL"
            alt="Email builder"
            width="1440"
            height="1040"
            class="max-w-full h-auto"
          />
        </div>
        <div class="rounded-b bg-neutral-100 p-5 text-neutral-700">
          <h3>Get started</h3>
          <p>
            Welcome to Norby's new email builder! Get started in a few simple
            steps:
          </p>

          <h4 class="font-bold">Add some blocks</h4>
          <p>
            Hover over a block to duplicate or delete it using the floating
            buttons on the right. Add a new block anywhere by hovering between
            two blocks and clicking the + button or add a new block to the
            bottom of the template using the + button in the bottom right
            corner.
          </p>

          <h4 class="font-bold">Adjust block settings</h4>
          <p>
            Click a block to make it active and show its settings. To return to
            this screen, click outside the active block to deselect it or click
            the Home icon right above this message.
          </p>

          <h4 class="font-bold">Share feedback</h4>
          <p>
            We want to hear from you! If you have any questions, comments, or
            other feedback on the new builder, shoot us an email at
            <a href="mailto:support@withnorby.com" target="_blank"
              >support@withnorby.com</a
            >.
          </p>
        </div> -->
        <div class="appearance-container">
          <div class="theme-selector-container">
            <norby-theme-selector
              placeholder="Select a theme"
              [themes]="themes"
              [isDisabled]="send.hasBeenSent"
              (onValueChange)="handleNewThemeSelected($event)"
            >
            </norby-theme-selector>
          </div>
          <div class="mb-4">
            <norby-theme-customizer
              [theme]="send?.theme"
              [allowComplexBackgrounds]="false"
              [sections]="THEME_CUSTOMIZER_SECTIONS"
              [isDisabled]="send.hasBeenSent"
              (onThemeChange)="handleEmailThemeUpdated($event)"
            ></norby-theme-customizer>
          </div>
          <norby-button
            *ngIf="send?.theme"
            text="Reset email theme"
            buttonType="secondary"
            (buttonClicked)="handleResetEmailTheme()"
          ></norby-button>
        </div>
      </div>
      <!-- Edit mode controls for all blocks -->
      <div *ngIf="isTextBlock(send?.blocks[activeIndex])">
        <edit-email-text-block
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        ></edit-email-text-block>
      </div>

      <div *ngIf="isHeaderBlock(send?.blocks[activeIndex])">
        <edit-email-header-block
          [block]="send?.blocks[activeIndex]"
          [slug]="slug"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-header-block>
      </div>
      <div *ngIf="isImageBlock(send?.blocks[activeIndex])">
        <edit-email-image-block
          [block]="send?.blocks[activeIndex]"
          [slug]="slug"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-image-block>
      </div>
      <div *ngIf="isSpacerBlock(send?.blocks[activeIndex])">
        <edit-email-spacer-block
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-spacer-block>
      </div>
      <div *ngIf="isDividerBlock(send?.blocks[activeIndex])">
        <edit-email-divider-block
          [slug]="slug"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-divider-block>
      </div>
      <div *ngIf="isButtonBlock(send?.blocks[activeIndex])">
        <edit-email-button-block
          [slug]="slug"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-button-block>
      </div>
      <div *ngIf="isYoutubeBlock(send?.blocks[activeIndex])">
        <edit-email-youtube-block
          [block]="send?.blocks[activeIndex]"
          [slug]="slug"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-youtube-block>
      </div>
      <div *ngIf="isQuoteBlock(send?.blocks[activeIndex])">
        <edit-email-quote-block
          [slug]="slug"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-quote-block>
      </div>
      <div *ngIf="isFooterBlock(send?.blocks[activeIndex])">
        <edit-email-footer-block
          [slug]="slug"
          [block]="send?.blocks[activeIndex]"
          [isMobile]="isMobile"
          (onUpdatedBlock)="handleUpdatedBlock($event)"
        >
        </edit-email-footer-block>
      </div>
      <div *ngIf="isEventBlock(send?.blocks[activeIndex])">
        <edit-email-event-block
          [slug]="slug"
          [send]="send"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedEventBlock($event)"
          (onCreateClick)="handleCreateClick(SINGLE_SEND_BLOCK_TYPE_ENUM.EVENT)"
          (onEditDetailsClick)="
            handleEditClick(SINGLE_SEND_BLOCK_TYPE_ENUM.EVENT, $event)
          "
        ></edit-email-event-block>
      </div>
      <div *ngIf="isSignupBlock(send?.blocks[activeIndex])">
        <edit-email-signup-block
          [slug]="slug"
          [send]="send"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedBlock)="handleUpdatedEventBlock($event)"
          (onCreateClick)="
            handleCreateClick(SINGLE_SEND_BLOCK_TYPE_ENUM.SIGNUP)
          "
          (onEditDetailsClick)="
            handleEditClick(SINGLE_SEND_BLOCK_TYPE_ENUM.SIGNUP, $event)
          "
        ></edit-email-signup-block>
      </div>
      <div *ngIf="isUpcomingEventsBlock(send?.blocks[activeIndex])">
        <edit-email-upcoming-events-block
          [slug]="slug"
          [send]="send"
          [block]="send?.blocks[activeIndex]"
          (onUpdatedUpcomingEventsBlock)="
            handleUpdatedUpcomingEventsBlock($event)
          "
        ></edit-email-upcoming-events-block>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #addBlockTemplate>
  <norby-flyout-menu
    (mouseover)="handleMouseOverFlyout()"
    (mouseleave)="handleMouseLeaveFlyout()"
    [options]="BLOCK_OPTION_GROUPS"
    (onOptionClicked)="handleCreateOptionClicked($event)"
  ></norby-flyout-menu>
</ng-template>
