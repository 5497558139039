import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignupType } from 'models';

@Component({
  selector: 'norby-signups-flow-form-selector',
  templateUrl: './norby-signups-flow-form-selector.component.html',
  styleUrls: ['./norby-signups-flow-form-selector.component.less']
})
export class NorbySignupsFlowFormSelectorComponent {
  @Input() signupType: SignupType;

  @Output() onSignupTypeChange: EventEmitter<SignupType> =
    new EventEmitter<SignupType>();

  handleButtonClicked(signupType: SignupType) {
    this.onSignupTypeChange.emit(signupType);
  }
}
