<norby-formcontrol-label
  [label]="label"
  [infoTooltip]="infoTooltip"
  [isRequired]="false"
></norby-formcontrol-label>

<div class="flex flex-row flex-wrap w-full mr-3">
  <norby-shape-selector-item
    *ngFor="let mask of availableMasks"
    class="mr-6"
    [label]="mask.name"
    [url]="mask.url"
    [selected]="mask.url === activeMaskUrl"
    (click)="handleMaskSelection(mask)"
  ></norby-shape-selector-item>
</div>
