import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-shape-selector',
  templateUrl: './norby-shape-selector.component.html',
  styleUrls: ['./norby-shape-selector.component.less']
})
export class NorbyShapeSelectorComponent {
  @Input() availableMasks: any[];
  @Input() selectedMaskUrl?: string;
  @Input() label?: string;
  @Input() infoTooltip?: string;
  @Output() onSelectedMaskUrlChanged: EventEmitter<string> =
    new EventEmitter<string>();

  constructor() {}

  get activeMaskUrl(): string {
    return this.selectedMaskUrl ?? this.availableMasks?.[0]?.url;
  }

  handleMaskSelection(mask) {
    this.selectedMaskUrl = mask.url;
    this.onSelectedMaskUrlChanged.emit(mask.url);
  }
}
