import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzIconModule } from 'ng-zorro-antd/icon';

// App
import {
  NorbyRadioButtonGroupModule,
  NorbyRadioButtonModule,
  TooltipModule,
  SpinModule
} from 'uikit';
import { AddContactListComponent } from './add-contact-list.component';
import { UserRowModule } from '../../modules/user-row.module';

@NgModule({
  declarations: [AddContactListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzDividerModule,
    NzPopconfirmModule,
    NzIconModule,

    NorbyRadioButtonModule,
    NorbyRadioButtonGroupModule,
    TooltipModule,
    UserRowModule,
    SpinModule
  ]
})
export class AddContactListModule {}
