import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

// Lib
import { NorbySelectorTypesEnum, SelectorValueType } from 'models';

@Component({
  selector: 'norby-configurable-selector',
  templateUrl: './norby-configurable-selector.component.html',
  styleUrls: ['./norby-configurable-selector.component.less']
})
export class NorbyConfigurableSelectorComponent implements OnChanges {
  @Input() selectorType: NorbySelectorTypesEnum; // for styling

  // Data
  @Input() items: SelectorValueType[] = []; // items displayed in results
  @Input() value?: SelectorValueType; // current value
  @Input() placeholder: string; // placeholder when input is empty
  @Input() label?: string; // key used for display/filtering label of a given result
  @Input() key?: string; // key used for comparing object values

  // State
  @Input() isDisabled: boolean = false;
  @Input() isClearable: boolean = false;
  @Input() isSearchable: boolean = true;
  @Input() isLoading: boolean = false;

  // Behavior
  @Input() filterLocalResults: boolean = true; // disable local filtering when async search is being used
  @Input() optionHeightPx: number = 32; // by default ant select sets 32px for each option
  @Input() optionOverflowSize: number = 8; // by default ant select allows 8 option then make it scrollable

  // Templates
  @Input() optionTemplate: TemplateRef<any>; // template for item results
  @Input() customOptionTemplate: TemplateRef<any> = null; // template for selected option
  @Input() footerTemplate: TemplateRef<any> = null; // template for footer / additional content

  @Output() onValueChange = new EventEmitter();
  @Output() onCreateClick = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  val: SelectorValueType; // internal value, double bound to select

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.value) {
      this.val = null;
    } else if (changes.value) {
      const idx = this.items.findIndex((item) =>
        this.compareFn(item, this.value)
      );

      if (idx === -1) {
        this.items = [this.value, ...this.items];
        this.val = this.value;
      } else {
        this.val = this.items[idx];
      }
    }
  }

  handleChange(item: SelectorValueType): void {
    this.onValueChange.emit(item);
  }

  handleOnSearch(value: string): void {
    this.onSearch.emit(value);
  }

  compareFn = (item1: SelectorValueType, item2: SelectorValueType): boolean =>
    item1 === item2 ||
    (item1 && item1[this.key] === item2) ||
    (item1 && item2 && item1[this.key] === item2[this.key]);
}
