import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { EventDetailViewComponent } from '../views/event-detail-view';
import { RsvpConfirmationViewModule } from './rsvp-confirmation-view.module';
import { LiveThemedDirectiveModule } from './live-themed-directive.module';
import { AlertModule } from './alert.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { EventMainButtonViewModule } from './event-main-button-view.module';
import { IconModule } from './icon.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { TagModule } from './tag.module';
import { RegisterableDetailViewModule } from './registerable-detail-view.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [EventDetailViewComponent],
  imports: [
    CommonModule,
    RouterModule,
    LiveThemedDirectiveModule,
    EventMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    RsvpConfirmationViewModule,
    RegisterableDetailViewModule,
    SafeHtmlPipeModule,
    TagModule,
    TooltipModule,
    IconModule,
    CardModule,
    AlertModule,
    SkeletonModule,
    CarouselModule
  ],
  exports: [EventDetailViewComponent]
})
export class EventDetailViewModule {}
