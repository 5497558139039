import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SingleSend } from 'models';

@Component({
  selector: 'norby-sends-confirmation-view',
  templateUrl: './norby-sends-confirmation-view.component.html',
  styleUrls: ['./norby-sends-confirmation-view.component.less']
})
export class NorbySendsConfirmationViewComponent implements OnInit {
  @Input() send: SingleSend;
  @Output() onReturnToDashboard: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  get deliveryType() {
    return this.send?.deliveryType === 'email' ? 'Email' : 'SMS';
  }

  handleReturnToDashboard(): void {
    this.onReturnToDashboard.emit();
  }
}
