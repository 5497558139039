import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';

// Libs
import {
  formatPhoneNumber,
  isPhoneNumberValid,
  VALID_COUNTRY_CODES
} from 'models';
import {
  CaptchaComponent,
  ErrorService,
  MessageService,
  MessageType
} from 'uikit';
import { AuthService } from '../../services';

@Component({
  selector: 'lib-add-user-phone-number',
  templateUrl: './add-user-phone-number.component.html',
  styleUrls: ['./add-user-phone-number.component.less']
})
export class AddUserPhoneNumberComponent
  extends CaptchaComponent
  implements OnInit
{
  phoneNumberForm: UntypedFormGroup;
  codeForm: UntypedFormGroup;
  isLoading = false;
  isResendingCode = false;
  step: 'phone' | 'code' = 'phone';
  phonePlaceholder = '(123) 456-7890';

  readonly VALID_COUNTRY_CODES = VALID_COUNTRY_CODES;

  constructor(
    private _dialogRef: NzModalRef<AddUserPhoneNumberComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _message: MessageService,
    private _auth: AuthService,
    private _error: ErrorService
  ) {
    super();
  }

  preserveOrder = (a, b): number => 0;

  get title(): string {
    return this.step === 'phone'
      ? 'Enter your phone number'
      : 'Enter your verification code';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initForms();
  }

  handleCountryCodeChanged(code): void {
    const country = VALID_COUNTRY_CODES[code];
    this.phonePlaceholder = country?.placeholder;
  }

  async resendPhoneCode() {
    const { phoneNumber } = this.phoneNumberForm?.value;
    if (!phoneNumber) return;

    this.isResendingCode = true;

    try {
      await this._sendVerificationCode();
      this.isResendingCode = false;
    } catch (e) {
      this.isResendingCode = false;
      this._error.displayError(e);
    }
  }

  async next() {
    if (!this.phoneNumberForm?.valid) return;
    this.isLoading = true;

    try {
      const ret = await this._sendVerificationCode();
      this.step = 'code';
    } catch (e) {
      this._error.displayError(e);
    }

    this.isLoading = false;
  }

  async submitCode() {
    this.isLoading = true;
    const { phoneNumber, countryCode } = this.phoneNumberForm.value;
    const { verificationCode } = this.codeForm.value;
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);
    try {
      await this._auth.completePhoneLogin({
        phoneNumber: formattedPhoneNumber,
        verificationCode: verificationCode?.toString()
      });
      this._message.show({
        text: 'Successfully verified phone number',
        type: MessageType.SUCCESS
      });
      this._dialogRef.close(formattedPhoneNumber);
    } catch (e) {
      this._message.show({
        text: 'Error verifying your phone number',
        type: MessageType.ERROR
      });
    }

    this.isLoading = false;
  }

  private async _sendVerificationCode() {
    const { countryCode, phoneNumber } = this.phoneNumberForm.value;

    try {
      const captchaResponse = await this.executeCaptcha();

      const ret = await this._auth.initiatePhoneSignInFlow(
        formatPhoneNumber(phoneNumber, countryCode),
        captchaResponse,
        true
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  private _initForms() {
    this.phoneNumberForm = this._formBuilder.group(
      {
        phoneNumber: ['', Validators.required],
        countryCode: ['US', Validators.required]
      },
      { validators: this._isPhoneNumberValid }
    );

    this.codeForm = this._formBuilder.group({
      verificationCode: [
        undefined,
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
      ]
    });
  }

  // Form group validator to ensure phone number checks out
  private _isPhoneNumberValid(
    group: UntypedFormGroup
  ): ValidationErrors | null {
    const { phoneNumber, countryCode } = group.value;
    return isPhoneNumberValid(phoneNumber, countryCode);
  }
}
