<div class="w-full rounded-sm border border-neutral-200 mb-5">
  <div
    class="flex flex-row px-4 py-2 justify-between items-center border-b border-neutral-200"
  >
    <h3 class="m-0">{{ title }}</h3>

    <ng-content></ng-content>
  </div>

  <div
    class="flex flex-row m-4 justify-between flex-wrap gap-8"
    *ngIf="items?.length"
  >
    <div *ngFor="let item of items">
      <div class="text-xs text-neutral-600">{{ item.title }}</div>
      <div class="text-base text-neutral-900">{{ item.value }}</div>
    </div>
  </div>

  <div *ngIf="additionalItems" class="m-4">
    <ng-container *ngTemplateOutlet="additionalItems"></ng-container>
  </div>

  <div class="m-4"></div>
</div>
