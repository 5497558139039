<div class="p-2">
  <div class="pb-2">
    <span> Clicked embedded links</span>
  </div>
  <div class="flex">
    <button
      type="button"
      (click)="handleOpenFilterDropdown()"
      class="flex items-center justify-between rounded-sm mb-3 px-2 py-2 border border-neutral-300 hover:border-neutral-500 flex-1 bg-white text-neutral-900 leading-5 whitespace-nowrap w-80 truncate"
      [ngClass]="selectedLink ? 'text-neutral-900' : 'text-neutral-600'"
      [customFilter]="customFilterTpl"
      [closeOnClick]="true"
      position="center"
    >
      <span>{{ selectedLink || 'Select link' }}</span
      ><norby-icon name="chevron_down"></norby-icon>
    </button>
  </div>
  <norby-button
    class="flex add-filter-button"
    text="Add Filter"
    [isDisabled]="isButtonDisabled"
    (buttonClicked)="handleAddFilter()"
  ></norby-button>
</div>
<root-dropdown-menu #customFilterTpl>
  <div class="p-2 custom-filter">
    <div
      class="px-2 ml-3 border-l cursor-pointer"
      *ngFor="let link of sendSearchResult?.send?._embeddedLinks"
      (click)="handleSelectLink(link)"
    >
      {{ link }}
    </div>
  </div>
</root-dropdown-menu>
