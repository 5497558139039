<nz-list
  [nzLoading]="isLoadingFirstBatch || isDoingUserAction"
  nzSize="large"
  class="user-list"
>
  <nz-list-item
    *ngFor="let user of filteredItems; let i = index; trackBy: trackBy"
  >
    <lib-user-row [userId]="user?.userId" [subtitle]="user?.role | titlecase">
      <div class="trailing">
        <div
          class="rounded-full text-xs py-0.5 px-2"
          [ngClass]="{
            'bg-mustard-200 text-mustard': user?.status == 'pending',
            'bg-mustard text-white': user?.status == 'active'
          }"
        >
          {{ user?.status | titlecase }}
        </div>
        <button
          *ngIf="userIsAdmin"
          nz-button
          nzPlacement="bottomRight"
          aria-label="Settings"
          nzSize="small"
          nzType="text"
          nz-dropdown
          [nzDropdownMenu]="menu"
        >
          <root-icon name="chevron_down"></root-icon>
        </button>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li
            *ngIf="user?.status == 'pending'"
            nz-menu-item
            nz-popconfirm
            nzPopconfirmTitle="Are you sure?"
            nzOkText="Reinvite"
            (nzOnConfirm)="reinviteUser(user)"
          >
            <div class="flex items-center">
              <root-icon name="rotate_cw"></root-icon> Reinvite
            </div>
          </li>

          <li
            (nzOnConfirm)="transferOwnership(user)"
            *ngIf="userOwnerOfCurrentSite && user?.role !== 'owner'"
            nz-menu-item
            nz-popconfirm
            nzOkText="Transfer"
            nzPopconfirmTitle="Are you sure? You won't be able to undo this."
          >
            <div class="flex items-center">
              <root-icon name="rotate_cw"></root-icon> Transfer ownership
            </div>
          </li>

          <li
            nz-menu-item
            nzDanger
            nz-popconfirm
            nzPopconfirmTitle="Are you sure?"
            nzOkText="Delete"
            (nzOnConfirm)="removeUser(user)"
          >
            <div class="flex items-center">
              <root-icon name="trash"></root-icon>
              Remove...
            </div>
          </li>
        </ul>
      </nz-dropdown-menu>
    </lib-user-row>
  </nz-list-item>

  <nz-list-item *ngIf="isLoadingNextBatch">
    <nz-spin nzSimple></nz-spin>
  </nz-list-item>

  <div class="empty" *ngIf="filteredItems?.length === 0">
    <nz-empty [nzNotFoundContent]="contentTpl">
      <ng-template #contentTpl>
        <span>No users here.</span>
      </ng-template>
    </nz-empty>
  </div>
</nz-list>
