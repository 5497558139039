<div class="content-row-container" [class.invert-text]="invert">
  <div class="avatar leading">
    <div class="avatar-wrapper" *ngIf="avatarIsLeft">
      <ng-container *ngTemplateOutlet="avatar"></ng-container>
    </div>
    <ng-content *ngIf="avatarIsRight"></ng-content>
  </div>

  <div class="content">
    <div *ngIf="isLoading" style="width: 130px">
      <nz-skeleton
        [nzLoading]="isLoading"
        [nzAvatar]="false"
        [nzParagraph]="{ rows: 2 }"
        [nzTitle]="false"
      >
      </nz-skeleton>
    </div>
    <h6 *ngIf="!isLoading && header">{{ header }}</h6>
    <h4 *ngIf="!isLoading && title && isStringTitle" [class.muted]="disabled">
      {{ title }}
    </h4>
    <div *ngIf="!isLoading && title && !isStringTitle">
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div>
    <p *ngIf="!isLoading && subtitle && isStringSubtitle">{{ subtitle }}</p>
    <div *ngIf="!isLoading && subtitle && !isStringSubtitle">
      <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    </div>
  </div>

  <div class="avatar trailing">
    <div class="avatar-wrapper" *ngIf="avatarIsRight">
      <ng-container *ngTemplateOutlet="avatar"></ng-container>
    </div>
    <ng-content *ngIf="avatarIsLeft"></ng-content>
  </div>
</div>

<ng-template #avatar>
  <nz-avatar
    [nzSize]="avatarSize"
    [nzSrc]="imageUrl"
    [nzIcon]="avatarIcon"
    [class.inverse]="contentType"
    [style.color]="avatarIconColor ? avatarIconColor : ''"
    [style.background-color]="avatarColor ? avatarColor : ''"
    [nzShape]="avatarShape"
  >
  </nz-avatar>
</ng-template>
