<div *ngIf="currentStage?.type === 'phone'" @slideIn>
  <form
    nz-form
    [formGroup]="phoneFormGroup"
    nzLayout="vertical"
    (ngSubmit)="submitPhone()"
  >
    <lib-norby-copy-block
      [title]="title"
      [subtitle]="subtitle"
      color="#140936"
    ></lib-norby-copy-block>

    <nz-form-item class="small-margin-top">
      <nz-input-group nzCompact>
        <nz-select
          style="width: 30%"
          (nzOpenChange)="!$event ? numberInput.focus() : null"
          (ngModelChange)="handleCountryCodeChanged($event)"
          formControlName="countryCode"
        >
          <nz-option
            [nzLabel]="country.value?.flag + ' +' + country.value?.value"
            nzCustomContent
            *ngFor="
              let country of VALID_COUNTRY_CODES | keyvalue : preserveOrder
            "
            [nzValue]="country.key"
          >
            {{ country.value?.flag + ' ' + country.value?.label }}
          </nz-option>
        </nz-select>
        <input
          style="width: 70%"
          type="tel"
          nz-input
          #numberInput
          [placeholder]="phonePlaceholder"
          formControlName="phoneNumber"
          required
        />
      </nz-input-group>
    </nz-form-item>

    <button
      nz-button
      nzBlock
      nzType="primary"
      [disabled]="!phoneFormGroup.valid"
      [nzLoading]="isLoading"
      type="submit"
    >
      Next
    </button>
  </form>
</div>

<!-- STEP 1B: ENTER CONFIRMATION CODE -->
<div *ngIf="currentStage?.type === 'phoneVerification'" @slideIn>
  <form
    nz-form
    [formGroup]="codeFormGroup"
    nzLayout="vertical"
    (ngSubmit)="submitCode()"
  >
    <lib-norby-copy-block
      title="Enter your code"
      subtitle="Please enter the code we just sent to your phone."
      color="#140936"
    ></lib-norby-copy-block>

    <nz-form-item class="small-margin-top">
      <nz-input-group [nzSuffix]="resendButton">
        <input
          nz-input
          type="number"
          name="verificationCode"
          formControlName="verificationCode"
          placeholder="Your verification code"
          required
          minLength="6"
          maxLength="6"
        />
      </nz-input-group>

      <ng-template #resendButton>
        <button
          nz-button
          type="button"
          (click)="submitResendCode()"
          nzSize="small"
          nzType="text"
          [nzLoading]="isResendingCode"
        >
          <root-icon name="rotate_ccw"></root-icon>
          Resend
        </button>
      </ng-template>
    </nz-form-item>

    <button
      nz-button
      nzBlock
      [nzLoading]="isLoading"
      nzType="primary"
      [disabled]="!codeFormGroup.valid"
      type="submit"
    >
      Next
    </button>
  </form>
</div>
