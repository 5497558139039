import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

// Service to handle links generated through markdown parsing
// This allows links in markdown to be intercepted by the Angular
// router if they point to an internal page so they don't cause
// a page reload. If the link is external, it'll open in a new
// window
@Injectable({
  providedIn: 'root'
})
export class ClickInterceptorService {
  constructor(
    private _locationStrategy: LocationStrategy,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  // Intercept clicks on anchors to use router when href is an internal
  // URL not handled by routerLink directive.
  interceptClick(event: Event) {
    const target = event.target;
    const parent = (target as Node).parentNode;
    let element = target;
    if (
      !(element instanceof HTMLAnchorElement) &&
      !((element = parent) instanceof HTMLAnchorElement)
    ) {
      return;
    }

    const href = element.getAttribute('href');

    // If this is an Angular-controlled node, ignore
    if (!href || !this._shouldIntercept(element)) {
      return;
    }

    event.preventDefault();

    // Handle external URLs
    if (this._isExternalUrl(href)) {
      window.open(href);
    }

    // Handle internal URLs
    else {
      this.navigate(href);
    }
  }

  // Navigate to URL using Angular router
  navigate(url: string, replaceUrl = false) {
    const urlTree = this._getUrlTree(url);
    this._router.navigated = false;
    this._router.navigateByUrl(urlTree, { replaceUrl });
  }

  // Transform a relative URL to its absolute representation according to current router state
  normalizeExternalUrl(url: string): string {
    if (this._isExternalUrl(url)) return url;

    const urlTree = this._getUrlTree(url);
    const serializedUrl = this._router.serializeUrl(urlTree);
    return this._locationStrategy.prepareExternalUrl(serializedUrl);
  }

  // Scroll view to the anchor corresponding to current route fragment
  // Enables scrolling to elements in markdown anchors
  scrollToAnchor() {
    const url = this._router.parseUrl(this._router.url);
    if (url.fragment) {
      this.navigate(this._router.url, true);
    }
  }

  private _getUrlTree(url: string): UrlTree {
    const urlPath =
      this._stripFragment(url) || this._stripFragment(this._router.url);
    const urlFragment = this._router.parseUrl(url).fragment;
    return this._router.createUrlTree([urlPath], {
      relativeTo: this._route,
      fragment: urlFragment
    });
  }

  private _isExternalUrl(url: string): boolean {
    const isUrl = /^(?!http(s?):\/\/).+$/.exec(url) == null;
    const isSms = url.startsWith('sms:');
    const isEmail = url.startsWith('mailto:');
    return isUrl || isSms || isEmail;
  }

  private _shouldIntercept(element: HTMLAnchorElement): boolean {
    const attrs = element?.getAttributeNames() ?? [];
    let isAngular = false;
    let shouldIntercept = false;
    attrs.forEach((attr) => {
      isAngular = isAngular || attr.startsWith('_ngcontent');
      shouldIntercept = shouldIntercept || attr == 'intercept';
    });
    return !isAngular || shouldIntercept;
  }

  private _stripFragment(url: string): string {
    return /[^#]*/.exec(url)[0];
  }
}
