<div class="mb-3 w-full">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
    [isRequired]="isRequired"
  ></norby-formcontrol-label>

  <norby-configurable-selector
    key="importUrl"
    label="displayName"
    [selectorType]="NORBY_SELECTOR_TYPES_ENUM.FONT_SELECTOR"
    [placeholder]="placeholder || ''"
    [items]="fonts || []"
    [value]="val"
    [isDisabled]="isDisabled"
    [optionHeightPx]="40"
    [optionOverflowSize]="6"
    [optionTemplate]="optionTemplate"
    [customOptionTemplate]="customOptionTemplate"
    [footerTemplate]="footerTemplate"
    (onCreateClick)="onImportClick.emit()"
    (onValueChange)="handleChange($event)"
  ></norby-configurable-selector>

  <ng-template #optionTemplate let-item>
    <div class="my-1">
      <div class="flex flex-row">
        <img
          *ngIf="item?.imageUrl"
          class="object-cover h-4 max-w-full"
          [src]="item.imageUrl"
        />
        <span
          class="text-base"
          *ngIf="item && !item.imageUrl && item.displayName"
          >{{ item.displayName }}</span
        >
      </div>
    </div>
  </ng-template>

  <ng-template #customOptionTemplate let-selected>
    <div class="truncate my-1">
      <div class="flex flex-row">
        <img
          *ngIf="selected?.nzValue?.imageUrl"
          class="object-cover h-4 max-w-full"
          [src]="selected.nzValue.imageUrl"
        />
        <span
          class="text-base"
          *ngIf="
            selected &&
            selected.nzValue &&
            !selected.nzValue.imageUrl &&
            selected.nzValue.displayName
          "
          >{{ selected.nzValue.displayName }}</span
        >
      </div>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="flex my-2 justify-center">
      <button
        type="button"
        class="flex-1 text-sm text-neutral-600 hover:text-neutral-800"
        (click)="onImportClick.next()"
      >
        Import
      </button>
    </div>
  </ng-template>
</div>
