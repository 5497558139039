<form [formGroup]="formGroup">
  <label class="mb-2">Filter</label>
  <norby-input
    label="Tag"
    type="text"
    helperText="Only show events with this tag."
    placeholder="exampletag"
    formControlName="tag"
  ></norby-input>
  <norby-input
    label="Limit"
    type="number"
    helperText="Set a maximum number of events to show."
    formControlName="limit"
  ></norby-input>
  <div class="mb-5">
    <label class="mb-2">Sort</label>
    <norby-switch-button
      [value]="block?.reverse"
      helperText="Show past events instead of upcoming events."
      formControlName="reverse"
    ></norby-switch-button>
  </div>

  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
