import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule, NorbyIconModule } from 'uikit';
import { TrackProductAnalyticsDirectiveModule } from './track-product-analytics-directive.module';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { LinkShortenerPopoverViewComponent } from '../views/link-shortener-popover-view';

@NgModule({
  declarations: [LinkShortenerPopoverViewComponent],
  imports: [
    CommonModule,
    IconModule,

    NzButtonModule,
    NzIconModule,

    NorbyIconModule,
    TrackProductAnalyticsDirectiveModule
  ],
  exports: [LinkShortenerPopoverViewComponent]
})
export class LinkShortenerPopoverViewModule {}
