import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyFormcontrolLabelModule } from 'uikit';
import { NorbyFontSelectorComponent } from '../components/atoms/norby-font-selector';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';

@NgModule({
  declarations: [NorbyFontSelectorComponent],
  imports: [
    CommonModule,
    NorbyFormcontrolLabelModule,
    NorbyConfigurableSelectorModule,
    NorbyFormcontrolLabelModule
  ],
  exports: [NorbyFontSelectorComponent]
})
export class NorbyFontSelectorModule {}
