import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NorbyFileUploadModule,
  NorbySwitchButtonModule,
  NorbyIconModule
} from 'uikit';
import { NorbyEditImageBlockComponent } from '../components/templates/content-block-editors/norby-edit-image-block';

@NgModule({
  declarations: [NorbyEditImageBlockComponent],
  imports: [
    CommonModule,
    NorbyFileUploadModule,
    NorbySwitchButtonModule,
    NorbyIconModule
  ],
  exports: [NorbyEditImageBlockComponent]
})
export class NorbyEditImageBlockModule {}
