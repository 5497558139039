import { Inject, Injectable, Optional } from '@angular/core';

// 3rd party
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Firebase
import { User } from '@angular/fire/auth';

// App
import {
  IUserContext,
  IUserRole,
  ENDPOINTS,
  ApiSurfaces,
  IS_NORBY_NEXT
} from 'models';
import { IPermissionsService } from './permissions.service.interface';
import { ApiService } from '../api';
import { AuthService } from '../auth';
import { DeviceService } from '../device';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService implements IPermissionsService {
  constructor(
    @Optional() @Inject(IS_NORBY_NEXT) private _isNorbyNext: boolean,
    private _auth: AuthService,
    private _api: ApiService,
    private _device: DeviceService
  ) {}

  async logout(): Promise<void> {
    // Signal for the server to kill the refresh token cookie.
    await this._api.post(ApiSurfaces.AUTH, ENDPOINTS.auth.logout);
    return this._auth.logout();
  }

  // Whether the user is a global admin
  userIsGlobalAdmin$(): Observable<boolean> {
    return this._auth.user$.pipe(
      switchMap(async (u) => this._userIsGlobalAdmin(u))
    );
  }

  userIsGlobalEditor$(): Observable<boolean> {
    return this._auth.user$.pipe(
      switchMap(async (u) => this._userIsGlobalEditor(u))
    );
  }

  async userIsGlobalAdmin(): Promise<boolean> {
    const user = await firstValueFrom(this._auth.user$);
    return this._userIsGlobalAdmin(user);
  }

  async userIsGlobalEditor(): Promise<boolean> {
    const user = await firstValueFrom(this._auth.user$);
    return this._userIsGlobalEditor(user);
  }

  async userIsGlobalDeveloper(): Promise<boolean> {
    const user = await firstValueFrom(this._auth.user$);
    return this._userIsGlobalDeveloper(user);
  }

  private async _userIsGlobalAdmin(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    return (tokenResult?.claims as IUserContext)?.admin;
  }

  private async _userIsGlobalEditor(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    const claims = tokenResult?.claims as IUserContext;
    return claims?.admin || claims?.editor;
  }

  private async _userIsGlobalDeveloper(user: User) {
    if (!user) return false;
    const tokenResult = await user?.getIdTokenResult();
    const claims = tokenResult?.claims as IUserContext;
    return claims?.developer;
  }

  userCanViewDashboard$(slug: string): Observable<boolean> {
    return combineLatest([
      this._auth.userRoles$(),
      this.userIsGlobalAdmin$()
    ]).pipe(
      map(
        ([roles, isGlobalAdmin]) =>
          isGlobalAdmin || this._userHasRoleOnSite(roles, slug)
      )
    );
  }

  async userCanViewDashboard(
    slug = this._device.currentSlug
  ): Promise<boolean> {
    return this._auth
      .userRoles()
      .then(
        (roles) =>
          this._userHasRoleOnSite(roles, slug) || this.userIsGlobalAdmin()
      );
  }

  private _userHasRoleOnSite(roles: IUserRole[], slug: string) {
    return !!roles?.find(
      (role) =>
        role?.slug === slug && !!role?.isNorbyNext === !!this._isNorbyNext
    );
  }

  // Whether the current user is an editor or admin and is allowed to
  // edit the site itself or publish content
  userCanEditSite$(): Observable<boolean> {
    return combineLatest([
      this._auth.userRoles$(),
      this._device.currentSlug$,
      this.userIsGlobalAdmin$()
    ]).pipe(
      map(
        ([roles, slug, isGlobalAdmin]) =>
          isGlobalAdmin || this._userHasEditRoleOnSite(roles, slug)
      )
    );
  }

  async userCanEditSite(): Promise<boolean> {
    return this._auth
      .userRoles()
      .then(
        (roles) =>
          this._userHasEditRoleOnSite(roles, this._device.currentSlug) ||
          this.userIsGlobalAdmin()
      );
  }

  private _userHasEditRoleOnSite(roles: IUserRole[], slug: string) {
    return !!roles?.find(
      (role) =>
        role &&
        role.slug === slug &&
        (role.role === 'owner' ||
          role.role === 'administrator' ||
          role.role === 'editor')
    );
  }

  userIsAdmin$(requireOwner = false): Observable<boolean> {
    return combineLatest([
      this._auth.userRoles$(),
      this._device.currentSlug$,
      this.userIsGlobalAdmin$()
    ]).pipe(
      map(
        ([roles, slug, isGlobalAdmin]) =>
          isGlobalAdmin || this._userIsAdminOnSite(requireOwner, roles, slug)
      )
    );
  }

  async userIsAdmin(requireOwner = false): Promise<boolean> {
    return this._auth
      .userRoles()
      .then(
        (roles) =>
          this._userIsAdminOnSite(
            requireOwner,
            roles,
            this._device.currentSlug
          ) || this.userIsGlobalAdmin()
      );
  }

  private _userIsAdminOnSite(requireOwner, roles: IUserRole[], slug: string) {
    return !!roles?.find(
      (role) =>
        role &&
        role.slug === slug &&
        (role.role === 'owner' ||
          (role.role === 'administrator' && !requireOwner))
    );
  }

  isUserOwnerOfCurrentSite$(): Observable<boolean> {
    return this.userIsAdmin$(true);
  }
}
