import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

// 3rd party
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { startWith } from 'rxjs';

// Lib
import { CaptchaComponent } from 'uikit';
import { ContactList, isCustomView } from 'models';
import { AuthService, ContactsService } from '../../services';

@Component({
  selector: 'app-add-contact-list',
  templateUrl: './add-contact-list.component.html',
  styleUrls: ['./add-contact-list.component.less']
})
export class AddContactListComponent
  extends CaptchaComponent
  implements OnInit
{
  form: UntypedFormGroup;
  editList: ContactList;
  saveAsNew = false;
  currentUserId: string;

  isDataReady = false;
  isExportLoading = false;
  isDeleteLoading = false;
  isSaveCopyLoading = false;

  constructor(
    @Inject(NZ_MODAL_DATA) private _modalData,
    private _formBuilder: UntypedFormBuilder,
    private _contactService: ContactsService,
    private _modalRef: NzModalRef<AddContactListComponent>,
    private _auth: AuthService
  ) {
    super();
    this.editList = this._modalData.editList;
    this.saveAsNew = this._modalData.saveAsNew;
  }

  get isAll(): boolean {
    return this.editList?.isDefault && this.editList?.id === 'all';
  }

  get isEmail(): boolean {
    return this.editList?.isDefault && this.editList?.id === 'email';
  }

  get isSms(): boolean {
    return this.editList?.isDefault && this.editList?.id === 'sms';
  }

  get canSaveCurrentList(): boolean {
    return (
      (this.editList &&
        isCustomView(this.editList) &&
        this.editList.createdBy === this.currentUserId) ||
      !this.editList ||
      this.saveAsNew
    );
  }

  get hasReference(): boolean {
    return this.canSaveCurrentList && this.editList?.references?.length > 0;
  }

  get referenceTooltipMessage(): string {
    return this.hasReference
      ? 'Visibility cannot be changed because this list is currently in use by another list or send.'
      : '';
  }

  get referenceDeleteMessage(): string {
    return this.hasReference
      ? 'List cannot be deleted because it is currently in use by another list or send.'
      : '';
  }

  get tooltipMessage(): string {
    return this.editList?.isDefault
      ? 'This is a default list managed by Norby'
      : !this.canSaveCurrentList
        ? 'This list is owned by another user. Save a copy of it to make changes.'
        : '';
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._modalRef.updateConfig({
      nzOnOk: () => this._submitForm(),
      nzOnCancel: () => this._modalRef.close({ list: null })
    });
    this._initForm();
    this._initUserSubscriptions();
  }

  private _initForm() {
    this.form = this._formBuilder.group({
      name: [this.editList?.title ?? '', [Validators.required]],
      visibility: [
        this.editList?.visibility ?? 'private',
        [Validators.required]
      ]
    });

    this.form.valueChanges
      .pipe(startWith(null), this.takeUntilDestroy)
      .subscribe(() => {
        this._modalRef.updateConfig({
          nzOkDisabled: this.form.invalid
        });
      });
  }

  private async _submitForm(): Promise<void> {
    if (!this.canSaveCurrentList) {
      this._modalRef.close({ list: null });
      return;
    }

    this._modalRef.updateConfig({
      nzOkLoading: true
    });

    const { name, visibility } = this.form.value;

    let newView;

    // Update an existing list
    if (this.editList?.id && !this.saveAsNew) {
      newView = await this._contactService.updateContactList(this.editList.id, {
        name,
        visibility
      });
    }

    // Clone a list with some new changes
    else if (this.editList?.id && this.saveAsNew) {
      const { columns, segment } = this.editList;
      const filterGroups = segment.filterGroups.map((group) =>
        group.toCreateDTO()
      );
      const { includedContactIds, excludedContactIds } =
        segment?.toObject() ?? {};

      newView = await this._contactService.cloneContactList(this.editList.id, {
        name,
        visibility,
        pinned: true,
        ...(columns && { columns }),
        ...(filterGroups && { filterGroups }),
        ...(includedContactIds && { includedContactIds }),
        ...(excludedContactIds && { excludedContactIds })
      });
    }

    // Create a new list
    else {
      newView = await this._contactService.createContactList({
        name,
        visibility,
        pinned: true
      });
    }

    this._modalRef.updateConfig({
      nzOkLoading: false
    });

    this._modalRef.close({ list: newView });
  }

  private _initUserSubscriptions(): void {
    this._auth.user$.pipe(this.takeUntilDestroy).subscribe((user) => {
      this.currentUserId = user?.uid;
      if (!this.canSaveCurrentList && !this.saveAsNew) {
        this.form.disable();
      }
      this.isDataReady = true;
    });
  }

  async handleSaveCopy() {
    this.isSaveCopyLoading = true;

    const { columns, segment, title, visibility } = this.editList;
    const filterGroups = segment.filterGroups.map((group) =>
      group.toCreateDTO()
    );
    const { includedContactIds, excludedContactIds } =
      segment?.toObject() ?? {};

    const list = await this._contactService.cloneContactList(this.editList.id, {
      name: `${title} (copy)`,
      visibility,
      pinned: true,
      ...(columns && { columns }),
      ...(filterGroups && { filterGroups }),
      ...(includedContactIds && { includedContactIds }),
      ...(excludedContactIds && { excludedContactIds })
    });

    this.isSaveCopyLoading = false;

    setTimeout(() => this._modalRef.close({ list }), 200);
  }

  async handleExportClick(): Promise<void> {
    this.isExportLoading = true;
    const captchaResponse = await this.executeCaptcha();
    await this._contactService.exportCsv(
      this.editList.id,
      true,
      captchaResponse
    );
    this.isExportLoading = false;
  }

  async handleDeleteClick() {
    this.isDeleteLoading = true;
    this._modalRef.updateConfig({
      nzOkDisabled: true
    });
    const wasDeleted = await this._contactService.deleteContactList({
      listId: this.editList.id
    });
    this.isDeleteLoading = false;

    if (wasDeleted) {
      setTimeout(() => this._modalRef.close({ shouldDelete: true }), 200);
    }
  }
}
