import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';

// 3rd party
import { plainToInstance } from 'class-transformer';

// Libs
import {
  BaseComponent,
  IconService,
  rootCopy,
  rootMoreVertical,
  rootTrash
} from 'uikit';
import {
  SingleSend,
  UpdatedSendBlockEvent,
  TextSingleSendBlock,
  SingleSendBlock,
  HeaderSingleSendBlock,
  OptionGroup,
  ISlug,
  isEmailHeaderBlock,
  isEmailTextBlock,
  isEmailImageBlock,
  UI_MOCK_URLS,
  ImageSingleSendBlock,
  DRAG_CONTAINER_WIDTH,
  DEFAULT_PLACEHOLDER_HEIGHT,
  isEmailSpacerBlock,
  isEmailFooterBlock,
  isEmailButtonBlock,
  isEmailDividerBlock,
  isEmailYoutubeBlock,
  isEmailQuoteBlock,
  isEmailSignupBlock,
  isEmailUpcomingEventsBlock,
  SingleSendBlockTypeEnum,
  SpacerSingleSendBlock,
  FooterSingleSendBlock,
  DividerSingleSendBlock,
  ButtonSingleSendBlock,
  YoutubeSingleSendBlock,
  isEmailEventBlock,
  EventSingleSendBlock,
  QuoteSingleSendBlock,
  SignupSingleSendBlock,
  UpcomingEventsSingleSendBlock,
  UpdatedUpcomingEventsSendBlock,
  Content,
  ThemeChange,
  compareThemes,
  Theme
} from 'models';

// App
import { ExpandableBottomSheetViewComponent } from '../../../../views/expandable-bottom-sheet-view';
import { DropListHijacker } from './util';
import { THEME_CUSTOMIZER_EMAIL_SECTIONS } from '../../../organisms/norby-theme-customizer/types';

@Component({
  selector: 'norby-sends-design-view',
  templateUrl: './norby-sends-design-view.component.html',
  styleUrls: ['./norby-sends-design-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbySendsDesignViewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() send: SingleSend;
  @Input() slug: ISlug;
  @Input() isMobile: boolean;
  @Input() activeStepIndex: number;
  @Input() steps: string[];
  @Input() themes: Theme[];

  @Output() onUpdateBlock: EventEmitter<UpdatedSendBlockEvent> =
    new EventEmitter<UpdatedSendBlockEvent>();
  @Output()
  onUpdateUpcomingEventsBlock: EventEmitter<UpdatedUpcomingEventsSendBlock> =
    new EventEmitter<UpdatedUpcomingEventsSendBlock>();
  @Output() onAddBlock: EventEmitter<UpdatedSendBlockEvent> =
    new EventEmitter<UpdatedSendBlockEvent>();
  @Output() onDeleteBlock: EventEmitter<number> = new EventEmitter<number>();
  @Output() onNavigationButtonClicked: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() onMoveBlock = new EventEmitter();
  @Output() onCreateClick = new EventEmitter<{
    blockType: SingleSendBlockTypeEnum;
    activeIndex: number;
  }>();
  @Output() onEditClick = new EventEmitter<{
    blockType: SingleSendBlockTypeEnum;
    updatedSendBlockEvent: UpdatedSendBlockEvent;
  }>();
  @Output() onEmailThemeUpdated: EventEmitter<Theme> =
    new EventEmitter<Theme>();

  @ViewChild(ExpandableBottomSheetViewComponent)
  bottomSheet: ExpandableBottomSheetViewComponent;
  @ViewChild('editorContent') editorContent: ElementRef;
  @ViewChild('dropList') dropList: ElementRef;
  activeIndex = -1;
  hoverIndex = -1;
  popoverHover = false;

  draggingPlaceholderHeight = DEFAULT_PLACEHOLDER_HEIGHT;
  dragPreviewCss: string;
  dragPreviewContainerCss: string;
  currentScaleMultiplier = 1.0;

  private _closeTimer;

  readonly UI_MOCK_URL = UI_MOCK_URLS.marketing;
  readonly SINGLE_SEND_BLOCK_TYPE_ENUM = SingleSendBlockTypeEnum;

  @HostListener('click', ['$event'])
  handleContainerClick(event: Event): void {
    const element = event.target as Element;
    if (element.tagName === 'IMG' || element.closest('a')) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.activeIndex > -1) {
      this._deselectBlock(element);
    }
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _cdr: ChangeDetectorRef,
    private _iconService: IconService,
    private _scrollDispatcher: ScrollDispatcher
  ) {
    super();
    this._iconService.registerIcons([rootCopy, rootTrash, rootMoreVertical]);
  }

  readonly BLOCK_OPTION_GROUPS: OptionGroup[] = [
    {
      label: 'Layout',
      value: 'layout',
      children: [
        {
          label: 'Header',
          value: SingleSendBlockTypeEnum.HEADER,
          icon: 'layout'
        },
        {
          label: 'Footer',
          value: SingleSendBlockTypeEnum.FOOTER,
          icon: 'layout'
        },
        {
          label: 'Spacer',
          value: SingleSendBlockTypeEnum.SPACER,
          icon: 'square'
        },
        {
          label: 'Divider',
          value: SingleSendBlockTypeEnum.DIVIDER,
          icon: 'minus'
        }
      ]
    },
    {
      label: 'Basic',
      value: 'basic',
      children: [
        {
          label: 'Text',
          value: SingleSendBlockTypeEnum.TEXT,
          icon: 'edit'
        },
        {
          label: 'Image',
          value: SingleSendBlockTypeEnum.IMAGE,
          icon: 'image'
        },
        {
          label: 'Button',
          value: SingleSendBlockTypeEnum.BUTTON,
          icon: 'plus-square'
        },
        {
          label: 'Quote',
          value: SingleSendBlockTypeEnum.QUOTE,
          icon: 'quote'
        }
      ]
    },
    {
      label: 'Content',
      value: 'content',
      children: [
        {
          label: 'Event',
          value: SingleSendBlockTypeEnum.EVENT,
          icon: 'calendar'
        },
        {
          label: 'Signup',
          value: SingleSendBlockTypeEnum.SIGNUP,
          icon: 'edit'
        },
        {
          label: 'List of Events',
          value: SingleSendBlockTypeEnum.UPCOMING_EVENTS,
          icon: 'server'
        }
      ]
    },
    {
      label: 'Embed',
      value: 'embed',
      children: [
        {
          label: 'Youtube',
          value: SingleSendBlockTypeEnum.YOUTUBE,
          icon: 'youtube'
        }
      ]
    }
  ];

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_EMAIL_SECTIONS;

  ngOnInit() {
    DropListHijacker.hijackDropListRefStart();
  }

  ngOnDestroy() {
    DropListHijacker.restoreDefaults();
    super.ngOnDestroy();
  }

  get isValidActiveIndex(): boolean {
    return this.activeIndex > -1 && this.activeIndex < this.currentPageSize;
  }

  get currentPageSize(): number {
    return this.send.blocks?.length ?? 0;
  }

  get bottomSheetCollapsedText(): string {
    if (!this.isMobile) {
      return null;
    }

    return this.isValidActiveIndex
      ? 'Swipe up to edit block settings'
      : 'Swipe up to edit email settings';
  }

  private _resetFab() {
    this.hoverIndex = -1;
    this.popoverHover = false;
    this._closeTimer = null;
    this._cdr.detectChanges();
  }

  private _deselectBlock(element: Element) {
    if (!element) {
      return;
    }
    if (element.matches('.block-item')) {
      return;
    }
    if (element === this.editorContent.nativeElement) {
      this.activeIndex = -1;
      return;
    }
    this._deselectBlock(element.parentElement);
  }

  getBlockViewDisplay(i: number): string {
    const block = this.send.blocks[i];

    if (isEmailTextBlock(block) && !block.body) {
      return 'Insert text here...';
    }

    return block.getHtml(this.slug, this.send);
  }

  getBlockHasPlaceholder(i: number): boolean {
    const block = this.send.blocks[i];
    return isEmailTextBlock(block) && !block.body;
  }

  getBlockDisplay(i: number): string {
    if (i > -1 && i < this.currentPageSize) {
      return this.send?.blocks[i].blockType;
    }
    return null;
  }

  isHeaderBlock(x: SingleSendBlock): boolean {
    return isEmailHeaderBlock(x);
  }

  isTextBlock(x: SingleSendBlock): boolean {
    return isEmailTextBlock(x);
  }

  isImageBlock(x: SingleSendBlock): boolean {
    return isEmailImageBlock(x);
  }

  isSpacerBlock(x: SingleSendBlock): boolean {
    return isEmailSpacerBlock(x);
  }

  isFooterBlock(x: SingleSendBlock): boolean {
    return isEmailFooterBlock(x);
  }

  isDividerBlock(x: SingleSendBlock): boolean {
    return isEmailDividerBlock(x);
  }

  isButtonBlock(x: SingleSendBlock): boolean {
    return isEmailButtonBlock(x);
  }

  isYoutubeBlock(x: SingleSendBlock): boolean {
    return isEmailYoutubeBlock(x);
  }

  isEventBlock(x: SingleSendBlock): boolean {
    return isEmailEventBlock(x);
  }

  isQuoteBlock(x: SingleSendBlock): boolean {
    return isEmailQuoteBlock(x);
  }

  isSignupBlock(x: SingleSendBlock): boolean {
    return isEmailSignupBlock(x);
  }

  isUpcomingEventsBlock(x: SingleSendBlock): boolean {
    return isEmailUpcomingEventsBlock(x);
  }

  handleDeviceScaleMultiplierChanged(multiplier: number) {
    this.currentScaleMultiplier = multiplier || 1.0;
    this.dragPreviewCss = `width: ${DRAG_CONTAINER_WIDTH}px`;
    this.dragPreviewContainerCss = `transform: scale(${this.currentScaleMultiplier});`;
    this._cdr.detectChanges();
  }

  handleHomeClick(): void {
    this.activeIndex = -1;
    this._cdr.detectChanges();
  }

  handleCreateOptionClicked(option: string) {
    let newBlock;

    switch (option) {
      case SingleSendBlockTypeEnum.TEXT:
        newBlock = TextSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.HEADER:
        newBlock = HeaderSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.IMAGE:
        newBlock = ImageSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.SPACER:
        newBlock = SpacerSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.FOOTER:
        newBlock = FooterSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.DIVIDER:
        newBlock = DividerSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.BUTTON:
        newBlock = ButtonSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.YOUTUBE:
        newBlock = YoutubeSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.EVENT:
        newBlock = EventSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.QUOTE:
        newBlock = QuoteSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.SIGNUP:
        newBlock = SignupSingleSendBlock.new();
        break;
      case SingleSendBlockTypeEnum.UPCOMING_EVENTS:
        newBlock = UpcomingEventsSingleSendBlock.new();
        break;
    }

    const index =
      this.hoverIndex > -1 ? this.hoverIndex : this.send.blocks.length;
    this.onAddBlock.emit({
      block: newBlock,
      index
    });
    this.activeIndex = index;
    this.popoverHover = false;
    this.hoverIndex = -1;
    this._cdr.detectChanges();
  }

  handleDuplicateBlock(i: number): void {
    const block = this.send.blocks[i];
    this.onAddBlock.emit({ block, index: i });
    this.activeIndex = i + 1;
  }

  handleRemoveBlock(i: number): void {
    this.onDeleteBlock.emit(i);
  }

  handleUpdateActiveIndex(i: number) {
    this.activeIndex = i;
    this._cdr.detectChanges();
  }

  handleNavigationButtonClicked(index: number) {
    this.onNavigationButtonClicked.emit(index);
  }

  trackBy(idx: number, item): number {
    return this.send?.blocks[idx]?.hash;
  }

  handleMouseOverFab(idx: number) {
    if (this._closeTimer) {
      clearTimeout(this._closeTimer);
    }
    this.hoverIndex = idx > -1 ? idx : -1;
    this._cdr.detectChanges();
  }

  handleMouseLeaveFab() {
    this._closeTimer = setTimeout(() => {
      if (!this.popoverHover) {
        this._resetFab();
      }
    }, 400);
  }

  handleMouseOverFlyout() {
    this.popoverHover = true;
  }

  handleMouseLeaveFlyout() {
    this._resetFab();
  }

  handleDragStarted(event: CdkDragStart) {
    const preview =
      this._document.getElementsByClassName('cdk-drag-preview')?.[0];
    this.draggingPlaceholderHeight =
      preview?.getBoundingClientRect()?.height / this.currentScaleMultiplier ||
      DEFAULT_PLACEHOLDER_HEIGHT;
    this._cdr.detectChanges();

    const scrollContainers = this._scrollDispatcher.getAncestorScrollContainers(
      this.dropList
    );
    const scrollContainer = scrollContainers?.[0];
    DropListHijacker.hijackDropListRefSortItem(
      this.currentScaleMultiplier,
      scrollContainer
    );
  }

  handleDrop(event: CdkDragDrop<SingleSendBlock[]>) {
    const currIdx = event.currentIndex;
    const prevIdx = event.previousIndex;

    if (currIdx === prevIdx) {
      return;
    }

    const blocks = this.send?.blocks?.slice();
    moveItemInArray(blocks, prevIdx, currIdx);
    this.onMoveBlock.next({ blocks });
    this.activeIndex = -1;
    this._cdr.detectChanges();
  }

  handleUpdatedBlock(block: SingleSendBlock) {
    this.onUpdateBlock.emit({ block, index: this.activeIndex });
  }

  handleUpdatedEventBlock(event) {
    this.onUpdateBlock.emit({
      block: event.block,
      index: this.activeIndex,
      ...(event.content && { content: event.content })
    });
  }

  handleUpdatedUpcomingEventsBlock(
    event: UpdatedUpcomingEventsSendBlock
  ): void {
    this.onUpdateUpcomingEventsBlock.emit({
      ...event,
      index: this.activeIndex
    });
  }

  handleCreateClick(blockType: SingleSendBlockTypeEnum) {
    this.onCreateClick.emit({ blockType, activeIndex: this.activeIndex });
  }

  handleEditClick(blockType: SingleSendBlockTypeEnum, content: Content) {
    const block = plainToInstance(EventSingleSendBlock, {
      ...this.send.blocks[this.activeIndex]
    });
    this.onEditClick.emit({
      blockType,
      updatedSendBlockEvent: {
        block,
        index: this.activeIndex,
        content
      }
    });
  }

  handleNewThemeSelected(themeIdentifier: string): void {
    const theme = this.themes?.find((t) => t.id === themeIdentifier);
    this.onEmailThemeUpdated.emit(theme);
  }

  handleEmailThemeUpdated(themeChange: ThemeChange): void {
    if (!compareThemes(themeChange.theme, this.send.theme)) {
      this.onEmailThemeUpdated.emit(themeChange.theme);
    }
  }

  handleResetEmailTheme(): void {
    this.onEmailThemeUpdated.emit(null);
  }
}
