import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges
} from '@angular/core';

// Libs
import { IUserPublicMetadata } from 'models';
import { BaseComponent } from 'uikit';
import { QueriesService } from '../../services';

@Component({
  selector: 'lib-user-row',
  templateUrl: './user-row.component.html',
  styleUrls: ['./user-row.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRowComponent extends BaseComponent implements OnChanges {
  @Input() userId: string;
  @Input() displayName: string;
  @Input() photoURL: string;
  @Input() subtitle: string;
  @Input() size: 'default' | 'small' | 'large' = 'large';
  @Input() invert = false;

  user: IUserPublicMetadata;
  isLoading = false;
  color: string;

  constructor(
    private _queries: QueriesService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  get uiName() {
    return this.displayName || this.user?.displayName;
  }

  get uiAvatarUrl() {
    return this.photoURL || this.user?.photoURL;
  }

  ngOnChanges(): void {
    super.ngOnChanges();
    this.user = null;

    if (this.userId) {
      this.isLoading = true;
      this._cdr.detectChanges();

      this._queries
        .getUserById$(this.userId)
        .pipe(this.takeUntilChanges)
        .subscribe((user) => {
          this.user = user;
          this.isLoading = false;
          this._cdr.detectChanges();
        });
    } else {
      this.isLoading = false;
      this._cdr.detectChanges();
    }
  }
}
