import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySignupsFlowFormSelectorComponent } from '../components/molecules/norby-signups-flow-form-selector/norby-signups-flow-form-selector.component';
import {
  NorbyImageRadioButtonGroupModule,
  NorbyImageRadioButtonModule
} from 'uikit';

@NgModule({
  declarations: [NorbySignupsFlowFormSelectorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyImageRadioButtonGroupModule,
    NorbyImageRadioButtonModule
  ],
  exports: [NorbySignupsFlowFormSelectorComponent]
})
export class NorbySignupsFlowFormSelectorModule {}
