/*
  Helper method to hit a URI through a form
*/
export const hitUriThroughForm = (
  uri: string,
  method: 'POST' | 'GET' = 'POST',
  fields: any = {},
  target: string = '_self'
) => {
  const dummyForm = document.createElement('form');
  dummyForm.method = method;
  dummyForm.action = uri;
  dummyForm.style.display = 'none';
  dummyForm.target = target;
  dummyForm.append('Content-Type', 'application/x-www-form-urlencoded');
  dummyForm.enctype = 'application/x-www-form-urlencoded';
  dummyForm.append(
    'Accept',
    'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8'
  );
  _attachFieldsToFormAsInputElements({
    form: dummyForm,
    fields
  });
  document.body.appendChild(dummyForm);
  dummyForm.submit();
};

function _attachFieldsToFormAsInputElements({
  fields,
  form
}: {
  form: HTMLFormElement;
  fields: any;
}) {
  Object.keys(fields).forEach((field) => {
    const value = fields[field];
    const input = document.createElement('INPUT');
    input.setAttribute('name', field);
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', value);
    form.appendChild(input);
  });
}
