import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

// Lib
import {
  ISlug,
  SOCIAL_LINKS,
  SOCIAL_TYPES,
  VisibleLink,
  NorbySelectorTypesEnum
} from 'models';

@Component({
  selector: 'norby-social-links-selector',
  templateUrl: './norby-social-links-selector.component.html',
  styleUrls: ['./norby-social-links-selector.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbySocialLinksSelectorComponent),
      multi: true
    }
  ]
})
export class NorbySocialLinksSelectorComponent implements OnChanges {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() slug: ISlug;
  @Input() value?: string;
  @Input() isDisabled: boolean = false;
  @Output() onValueChange: EventEmitter<SOCIAL_TYPES> =
    new EventEmitter<SOCIAL_TYPES>();

  readonly NORBY_SELECTOR_TYPES_ENUM = NorbySelectorTypesEnum;
  val: string;
  links: VisibleLink[] = [];

  private _onChanged: any = (_: any) => {};
  private _onTouched: any = () => {};
  private _touched = false;

  constructor() {}

  ngOnChanges(): void {
    this.val = this.value;
    this.links = Object.keys(SOCIAL_LINKS).map((key) => ({
      key,
      icon: SOCIAL_LINKS[key].icon,
      label: SOCIAL_LINKS[key].label,
      url: this.slug?.accountInfo?.links?.[key] || SOCIAL_LINKS[key].placeholder
    }));
  }

  handleChange(item: VisibleLink) {
    this._markAsTouched();
    this.onValueChange.emit(item.key as SOCIAL_TYPES);
    this._onChanged(item.key);
  }

  writeValue(value: string) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
