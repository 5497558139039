<nav
  class="flex flex-row justify-between items-center bg-white px-4 border-b border-neutral-300 border-opacity-90 h-14 leading-normal"
>
  <div class="flex flex-row items-center min-w-0 sm:flex-auto">
    <norby-icon-button
      iconName="arrow_left"
      commandName="back"
      class="text-mustard mr-4"
      (onButtonClicked)="handleBackClicked()"
    ></norby-icon-button>

    <label
      *ngIf="titleText"
      class="shrink grow-0 block lg:inline-block lg:mt-0 mr-4 text-neutral-800 font-medium truncate"
      >{{ titleText }}</label
    >

    <label
      class="shrink-0 grow basis-0 block lg:inline-block lg:mt-0 mr-4 text-neutral-700 truncate"
    >
      {{ subtitle }}
    </label>
  </div>

  <div
    class="flex flex-row items-center basis-1/3 justify-end gap-x-3 text-neutral-800 sm:flex-initial"
  >
    <ng-content></ng-content>
  </div>
</nav>
