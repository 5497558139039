<form [formGroup]="formGroup">
  <div class="mb-6">
    <norby-content-selector
      [value]="selectedBlock?.content"
      (onCreateClick)="handleCreateClick()"
      formControlName="content"
      [label]="contentLabel"
      [contentType]="contentType"
      placeholder="Select an option..."
      class="py-2"
    >
    </norby-content-selector>
  </div>

  <div *ngIf="selectedBlock?.content">
    <label class="form-label inline-block pb-2 text-xs">Title</label>
    <div rootNestedInputGroup class="mb-6">
      <norby-input
        [placeholder]="inputPlaceholder"
        formControlName="title"
        type="text"
        [isNestedButton]="true"
      ></norby-input>
      <norby-icon
        *ngIf="selectedBlock?.hasDefaultTitle"
        class="text-lilac-base"
        name="zap-fill"
      ></norby-icon>
    </div>
  </div>

  <div *ngIf="contentLabel === 'Event' && selectedBlock?.content">
    <label class="form-label inline-block pb-2 text-xs">Date and time</label>
    <div rootNestedInputGroup class="mb-6">
      <norby-input
        placeholder="Event date and time"
        type="text"
        [value]="summary"
        [isDisabled]="true"
        [isNestedButton]="true"
      ></norby-input>
      <!-- Icon for when we want to add event date editing -->
      <norby-icon
        *ngIf="false"
        class="text-lilac-base"
        name="zap-fill"
      ></norby-icon>
    </div>
  </div>

  <div *ngIf="selectedBlock?.content?.imageUrl">
    <label class="form-label inline-block pb-2 text-xs">Thumbnail image</label>
    <div rootNestedInputGroup class="justify-center relative mb-6">
      <norby-image
        [imageUrl]="selectedBlock?.content?.image?.url"
        imageLabel="No image"
        width="100%"
      ></norby-image>
      <!-- Icon for when we want to add content image editing -->
      <norby-icon
        *ngIf="false"
        class="absolute top-4 right-2 text-lilac-base"
        name="zap-fill"
      ></norby-icon>
    </div>
  </div>

  <div *ngIf="selectedBlock?.content" class="mb-6">
    <norby-button
      text="Edit details..."
      buttonType="secondary"
      (buttonClicked)="handleEditDetails()"
    ></norby-button>
  </div>
</form>
