import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

import dayjs from 'dayjs';

import {
  ContentEvent,
  IUserContent,
  Platform,
  RegisterableLayoutTypeEnum
} from 'models';
import { IconService, MockContentInteractionsService } from '../../services';
import {
  rootLock,
  rootCalendar,
  rootUnlock,
  rootXCircle
} from '../../../icons';
import { THEME_CLASSES } from '../../constants';
import {
  RegisterableDetailClassicLeftViewComponent,
  RegisterableDetailClassicRightViewComponent,
  RegisterableDetailClassicVerticalComponent,
  RegisterableDetailLargeImageViewComponent
} from '../registerable-detail';

@Component({
  selector: 'lib-event-detail-view',
  templateUrl: './event-detail-view.component.html',
  styleUrls: ['./event-detail-view.component.less']
})
export class EventDetailViewComponent implements OnChanges, DoCheck {
  @Input() event: ContentEvent;
  @Input() userEvent: IUserContent;
  @Input() isLoading = false;
  @Input() shouldDisableButtons = false;
  @Input() applyTheme = true;
  @Input() platform: Platform;
  @Input() shouldAutoTriggerRsvpFlow: boolean;
  @Input() isAnonymousUser: boolean;

  @Output() onSubmitAnother = new EventEmitter<void>();

  @ViewChild('classicLeftTpl')
  classicLeftViewTemplate: TemplateRef<RegisterableDetailClassicLeftViewComponent>;
  @ViewChild('classicRightTpl')
  classicRightViewTemplate: TemplateRef<RegisterableDetailClassicRightViewComponent>;
  @ViewChild('classicVerticalTpl')
  classicVerticalTemplate: TemplateRef<RegisterableDetailClassicVerticalComponent>;
  @ViewChild('largeImageTpl')
  largeImageTemplate: TemplateRef<RegisterableDetailLargeImageViewComponent>;

  registrationCloseDate: string;
  registrationCloseTime: string;
  priceStr: string;
  eventLayoutTemplate: TemplateRef<any>;

  private _cachedEventHash: number;
  private _isRsvpHandoffPreviouslyTriggered: boolean;

  constructor(
    private _iconService: IconService,
    private _cdr: ChangeDetectorRef,
    private _interactions: MockContentInteractionsService,
    @Inject(THEME_CLASSES) private _wrapperClasses
  ) {
    this._iconService.registerIcons([
      rootLock,
      rootCalendar,
      rootUnlock,
      rootXCircle
    ]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event) {
      this._updateProps();
      this._handlePotentialRsvpHandoff();
    }
  }

  ngDoCheck(): void {
    if (this.event && this.event.hash !== this._cachedEventHash) {
      this._updateProps();
      this._updateLayout();
      this._cachedEventHash = this.event.hash;
      this._cdr.markForCheck();
    }
  }

  ngAfterViewInit(): void {
    this._updateLayout();
  }

  handleSubmitAnother() {
    this.onSubmitAnother.emit();
  }

  get remainingCount(): number {
    return Math.max(this.event?.rsvpRestrictions?.remaining, 0);
  }

  private _updateProps() {
    this.registrationCloseDate = this.event?.registrationCloseDate
      ? dayjs(this.event.registrationCloseDate).format('MMM DD, YYYY')
      : null;
    this.registrationCloseTime = this.event?.registrationCloseDate
      ? dayjs(this.event?.registrationCloseDate).format('h:mm A')
      : null;

    this.priceStr =
      this.event?.tickets?.length > 0
        ? `$${(this.event.tickets[0]?.price ?? 0) / 100}`
        : null;

    return null;
  }

  isCurrentDate(registrationCloseDate: string): boolean {
    return registrationCloseDate === dayjs().format('MMM DD, YYYY');
  }

  private async _handlePotentialRsvpHandoff() {
    if (this.event) {
      const triggerRsvpHandoff =
        this.shouldAutoTriggerRsvpFlow &&
        !this.userEvent?.rsvpEnabled &&
        !this._isRsvpHandoffPreviouslyTriggered;
      if (triggerRsvpHandoff && !this.isAnonymousUser) {
        this._interactions.registerForContent(
          this.event,
          this._wrapperClasses,
          [],
          true
        );
        this._isRsvpHandoffPreviouslyTriggered = true;
      }
    }
  }

  private _updateLayout() {
    switch (this.event?.layout) {
      case RegisterableLayoutTypeEnum.CLASSIC_LEFT:
        this.eventLayoutTemplate = this.classicLeftViewTemplate;
        break;
      case RegisterableLayoutTypeEnum.CLASSIC_RIGHT:
        this.eventLayoutTemplate = this.classicRightViewTemplate;
        break;
      case RegisterableLayoutTypeEnum.CLASSIC_VERTICAL:
        this.eventLayoutTemplate = this.classicVerticalTemplate;
        break;
      case RegisterableLayoutTypeEnum.LARGE_IMAGE:
        this.eventLayoutTemplate = this.largeImageTemplate;
        break;
      default:
        this.eventLayoutTemplate = this.classicLeftViewTemplate;
    }
  }
}
