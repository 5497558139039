import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import {
  SegmentFilter,
  SingleSend,
  AccountStatus,
  ISlug,
  SegmentPreviewResponseDTO
} from 'models';
import { SummaryCardViewItem } from '../../../../views';

@Component({
  selector: 'norby-sends-summary-view',
  templateUrl: './norby-sends-summary-view.component.html',
  styleUrls: ['./norby-sends-summary-view.component.less']
})
export class NorbySendsSummaryViewComponent implements OnChanges {
  @Input() send: SingleSend;
  @Input() segmentPreviewResponse: SegmentPreviewResponseDTO;
  @Input() isLoading: boolean;
  @Input() accountStatus: AccountStatus;
  @Input() slug: ISlug;

  @Output() onViewPreviewList = new EventEmitter<void>();
  @Output() onToggleWebHosted = new EventEmitter<boolean>();
  @Output() onSendTestMessage = new EventEmitter<void>();
  @Output() onGoToStep: EventEmitter<number> = new EventEmitter<number>();

  isWebHosted: boolean = false;

  detailsItems: SummaryCardViewItem[] = [];
  recipientsItems: SummaryCardViewItem[] = [];

  constructor() {}

  ngOnChanges(): void {
    this.isWebHosted = !!this.send.webhosted;
    this._updateItems();
  }

  get isSms(): boolean {
    return this.send?.deliveryType === 'sms';
  }

  get isSent(): boolean {
    return !!this.send?.hasBeenSent;
  }

  get segmentPreviewCountDisplay(): string {
    const isOverLimit = this.segmentPreviewResponse?.segmentExceedsPreviewLimit;
    const maxCount = 5000;
    const count = this.segmentPreviewResponse?.segmentPreviewCount;
    const countLabel = isOverLimit ? `${maxCount}+` : count;
    const plural = count === 1 ? '' : 's';
    return !this.isLoading ? `${countLabel} recipient${plural}` : '';
  }

  private _updateItems() {
    this.detailsItems = this.isSms
      ? [
          {
            title: 'Sending from',
            value: this.accountStatus.sms.primaryPhoneNumber
          },
          {
            title: 'Alphanumeric sender',
            value: this.accountStatus.sms.alphaSender
          }
        ]
      : [
          { title: 'Subject', value: this.send.subject },
          {
            title: 'Sender name',
            value: this.send.senderName || this.slug?.accountInfo?.title
          },
          {
            title: 'Reply-to email',
            value: this.send?.replyTo || this.slug?.mailing?.replyTo
          }
        ];

    this.recipientsItems = [
      {
        title: 'Estimated recipients',
        value: this.segmentPreviewCountDisplay
      }
    ];
  }

  handleToggleWebHosted() {
    this.onToggleWebHosted.emit(!this.isWebHosted);
  }

  handleViewPreviewList() {
    this.onViewPreviewList.emit();
  }

  handleSendTestMessage() {
    this.onSendTestMessage.emit();
  }

  handleEditClicked(stepIndex: number) {
    this.onGoToStep.emit(stepIndex);
  }
}
