import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IContactNote } from 'models';
import { IconService, rootTrash } from 'uikit';

@Component({
  selector: 'lib-note-view',
  templateUrl: './note-view.component.html',
  styleUrls: ['./note-view.component.less']
})
export class NoteViewComponent {
  @Input() note: IContactNote;

  @Output() handleDelete: EventEmitter<string> = new EventEmitter();

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootTrash]);
  }
}
