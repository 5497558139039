import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { map } from 'rxjs';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

// Libs
import {
  BaseComponent,
  IconService,
  MessageService,
  MessageType,
  MockAnalyticsService,
  MockContentModalService,
  MockFileUploadService,
  rootPlus
} from 'uikit';
import {
  ClickedUpsellProductAnalyticsLocations,
  ClickedUpsellProductAnalyticsTypes,
  ContentNotificationType,
  DeliveryType,
  ContentSignup,
  ContentSignupMetadata,
  INotificationDTO,
  ISendTestMessageContentData,
  ISlug,
  NotificationDefaults,
  ProductAnalyticsEventTypes,
  SignupMetadataChanges,
  OFFSET_UNITS,
  MINUTES,
  HOURS,
  DAYS,
  MmsAttachment,
  CONTENT_EVENTS,
  MMS_ATTACHMENT_LIMIT,
  ACCEPTED_MMS_MIME_TYPES_STR,
  INotification
} from 'models';
import { THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS } from '../../../organisms/norby-theme-customizer';
import { MockDrawerService } from '../../../../services';

@Component({
  selector: 'lib-signup-notifications-step',
  templateUrl: './signup-notifications-step.component.html',
  styleUrls: ['./signup-notifications-step.component.less']
})
export class SignupNotificationsStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() signup: ContentSignup;
  @Input() signupMetadata: ContentSignupMetadata;
  @Input() notificationDefaults: NotificationDefaults;
  @Input() slug: ISlug;
  @Input() authedUserHasPhoneNumber: boolean = false;
  @Input() hideAiSMSPopup = false;

  @Output() onToggleAiSMSPopup = new EventEmitter<void>();
  @Output() onUpdatedSignupMetadata = new EventEmitter<SignupMetadataChanges>();
  @Output() onComponentLoaded = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  items: any[] = [];
  signupReminderOffsetQt: number = 1;
  signupReminderOffsetUnitsMultiplier: number = -1;
  isUploading = false;
  attachmentFileList: NzUploadFile[] = [];
  hasAnyFileOperationHappened = false;
  showingPopup: boolean = false;
  shouldDisableSmsFields = false;

  // upsell analytics
  readonly UPSELL_SIGNUP = ProductAnalyticsEventTypes.CLICKED_UPSELL;
  readonly UPSELL_CUSTOM_FIELDS_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_CUSTOM_FIELDS,
    cta: 'Upgrade your plan to use custom fields.'
  };
  readonly UPSELL_KEYWORD_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_KEYWORDS,
    cta: 'Upgrade your plan to use keywords.'
  };
  readonly UPSELL_NOTIFICATION_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_NOTIFICATIONS,
    cta: 'Upgrade your plan to use custom notifications.'
  };

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS;
  readonly OFFSET_UNITS = OFFSET_UNITS;
  readonly UPSELL_ATTACHMENTS_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location:
      ClickedUpsellProductAnalyticsLocations.SIGNUP_NOTIFICATIONS_ATTACHMENTS_TOOLTIP,
    cta: 'Upgrade your plan to send attachments.'
  };
  readonly ATTACHMENT_LIMIT = MMS_ATTACHMENT_LIMIT;
  readonly ATTACHMENT_MIME_TYPES = ACCEPTED_MMS_MIME_TYPES_STR;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _contentModal: MockContentModalService,
    private _drawer: MockDrawerService,
    private _iconService: IconService,
    private _analytics: MockAnalyticsService,
    private _message: MessageService,
    private _cdr: ChangeDetectorRef,
    private _upload: MockFileUploadService
  ) {
    super();
    this._iconService.registerIcons([rootPlus]);
  }

  get sendEmailConfirmation(): boolean {
    return this.formGroup?.get('confirmationMedium')?.value === 'email';
  }

  get sendSmsConfirmation(): boolean {
    return this.formGroup?.get('confirmationMedium')?.value === 'sms';
  }

  get confirmationBodySms(): string {
    return this.formGroup?.get('confirmationBodySms')?.value;
  }

  get smsEnabledForSignup(): boolean {
    return !!this.signup?.privateUserInfoRequirements?.phoneNumber?.required;
  }

  get emailEnabledForSignup(): boolean {
    return !!this.signup?.privateUserInfoRequirements?.email?.required;
  }

  get disableSendTest(): boolean {
    return !this.formGroup.valid || !this.signup.title;
  }

  ngOnInit(): void {
    this._initForm();
    this._updateAttachments();
    this.onComponentLoaded.emit();
  }

  private _initForm() {
    const notifications = this._buildNotificationsMap(
      this.signupMetadata?.notifications
    );

    this.formGroup = this._formBuilder.group({
      confirmationMedium: [notifications?.rsvpConfirmationMedium],
      confirmationSubject: [notifications?.rsvpConfirmationSubject],
      confirmationBodyEmail: [
        notifications?.rsvpConfirmationMedium === 'email'
          ? notifications?.rsvpConfirmationBody
          : ''
      ],
      confirmationBodySms: [
        notifications?.rsvpConfirmationMedium === 'sms'
          ? notifications?.rsvpConfirmationBody
          : ''
      ],
      confirmationOffset: [0]
    });

    this.formGroup.valueChanges
      .pipe(
        map((value) => {
          const notifications = this._extractNotifications();
          return ContentSignupMetadata.fromObject({
            ...this.signupMetadata,
            notifications
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((signupMetadata) => {
        // Once the user touches any notifications field, we consider
        // all notifications user controlled and we disable backend
        // management of notification defaults
        if (signupMetadata.managedNotifications) {
          signupMetadata.updateProperties({ managedNotifications: false });
        }

        this.onUpdatedSignupMetadata.emit({
          isDirty: this.formGroup.dirty,
          isNotificationsStepValid: this.formGroup.valid,
          signupMetadata
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const notifications = this._buildNotificationsMap(
      this.signupMetadata?.notifications
    );

    this.formGroup?.patchValue(
      {
        confirmationMedium: notifications?.rsvpConfirmationMedium,
        confirmationSubject: notifications?.rsvpConfirmationSubject,
        confirmationBodyEmail: notifications?.rsvpConfirmationBody,
        confirmationBodySms: this.shouldDisableSmsFields
          ? this.notificationDefaults?.rsvpConfirmation?.sms?.body
          : notifications?.rsvpConfirmationBody,
        confirmationOffset: 0
      },
      { emitEvent: false }
    );
  }

  private _buildNotificationsMap(notifications?: INotificationDTO[]) {
    const defaultSettings = {
      rsvpConfirmationMedium: 'none',
      rsvpConfirmationSubject: '',
      rsvpConfirmationBody: '',
      rsvpConfirmationOffset: 0
    };

    if (!notifications) {
      return defaultSettings;
    }

    return notifications.reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.type + 'Medium']: curr?.deliveryType,
        [curr?.type + 'Subject']: curr?.subject,
        [curr?.type + 'Body']: curr?.message,
        [curr?.type + 'Offset']: curr?.offset
      }),
      defaultSettings
    );
  }

  async sendTest(type: DeliveryType, signupType: ContentNotificationType) {
    const notification = this._extractNotifications()?.find(
      (n) => n.deliveryType === type && n.type === signupType
    );
    if (!notification) return;

    const contentMetadata = ContentSignup.fromObject({
      ...this.signup,
      ...this.formGroup.value
    }).toCreateDTO(this.signupMetadata);

    const payload: ISendTestMessageContentData = {
      contentMetadata,
      notification,
      messageType: 'content',
      contentId: this.signup?.contentId
    };

    if (type == 'email') {
      this._contentModal.launchContentTestFormDialog(payload);
    } else {
      if (this.authedUserHasPhoneNumber) {
        this._contentModal.launchContentTestFormDialog(payload);
      } else {
        this._drawer.launchAddUserPhoneNumberDialog().then((result) => {
          if (result) {
            this._contentModal.launchContentTestFormDialog(payload);
          }
        });
      }
    }
  }

  // Marshal form fields into notifications payload for create/update
  private _extractNotifications(): INotificationDTO[] {
    const {
      confirmationMedium,
      confirmationSubject,
      confirmationBodyEmail,
      confirmationBodySms,
      confirmationOffset
    } = this.formGroup.value;

    const notifications = new Array<INotificationDTO>();

    if (confirmationMedium === 'email' || confirmationMedium === 'sms') {
      let notification: INotificationDTO = {
        type: 'rsvpConfirmation',
        offset: confirmationOffset || 0,
        deliveryType: confirmationMedium,
        message:
          confirmationMedium === 'email'
            ? confirmationBodyEmail
            : confirmationBodySms,
        subject: confirmationSubject
      };
      if (confirmationMedium === 'sms') {
        notification.attachments =
          this.signupMetadata.notifications?.find(
            (n) => n.type === notification.type
          )?.attachments || [];
      }
      notifications.push(notification);
    }

    return notifications;
  }

  handleOffsetUnitsChange(signup, qt?: number) {
    switch (+signup) {
      case -1:
        this.items = MINUTES;
        break;
      case -60:
        this.items = HOURS;
        break;
      case -1440:
        this.items = DAYS;
        break;
    }
    this.signupReminderOffsetQt = qt || 1;
  }

  handleConfirmationMediumChange(medium) {
    const defaults = this.notificationDefaults?.rsvpConfirmation;
    if (defaults) {
      this.formGroup.patchValue({
        confirmationSubject: defaults.email.subject,
        confirmationBodyEmail: defaults.email.body,
        confirmationBodySms: defaults.sms.body
      });
    }
  }

  private _updateAttachments() {
    this.attachmentFileList =
      this.signupMetadata.notifications
        ?.find((notification) => notification.type === 'rsvpConfirmation') // find rsvpConfirmation notification
        ?.attachments?.map((attachment, idx) => ({
          uid: `${idx}`,
          name: `Attachment ${idx + 1}`,
          status: 'done',
          url: attachment.source,
          size: attachment.contentSize,
          type: attachment.contentType
        })) ?? [];
    this._cdr.detectChanges();
  }

  // Called by file picker event emitter in UI
  uploadFile = (file: NzUploadFile): boolean => {
    this.hasAnyFileOperationHappened = true;
    const filename = file.name;
    const updatedAttachmentFileList = this.attachmentFileList.concat(file);

    const errorMessage = this._upload.fileSizeValidation(
      file,
      updatedAttachmentFileList
    );

    if (errorMessage) {
      this._message.show({
        text: errorMessage,
        type: MessageType.ERROR
      });
      return;
    }

    this.isUploading = true;
    this._cdr.detectChanges();

    this._upload
      .uploadFile(file as any)
      .then((source) => {
        this._analytics.track(
          CONTENT_EVENTS.userUploadedMmsAttachmentsToSignup
        );

        const remoteFile: MmsAttachment = {
          source,
          filename,
          sourceType: 'url',
          contentType: file.type || 'application/octet-stream',
          contentSize: file.size
        };

        const notificationToUpdate: INotification =
          this.signupMetadata.notifications?.find(
            (notification) => notification.type === 'rsvpConfirmation'
          );

        if (notificationToUpdate) {
          const updatedNotification: INotification = {
            ...notificationToUpdate,
            attachments: [
              ...(notificationToUpdate.attachments || []),
              remoteFile
            ]
          };

          const idxToUpdate: number =
            this.signupMetadata.notifications?.findIndex(
              (notification) => notification.type === 'rsvpConfirmation'
            );

          const signupMetadataCopy: ContentSignupMetadata =
            this.signupMetadata.clone();

          signupMetadataCopy.notifications.splice(
            idxToUpdate,
            1,
            updatedNotification
          );

          const signupMetadata: ContentSignupMetadata =
            ContentSignupMetadata.fromObject({
              ...this.signupMetadata,
              notifications: signupMetadataCopy.notifications,
              managedNotifications: false
            });

          this.onUpdatedSignupMetadata.emit({
            isDirty: this.formGroup.dirty || this.hasAnyFileOperationHappened,
            isNotificationsStepValid: this.formGroup.valid,
            signupMetadata
          });

          this._updateAttachments();
        }
      })
      .catch((e) => {
        this._message.show({
          text: 'There was an error uploading your attachment.',
          type: MessageType.ERROR
        });
        return null;
      })
      .finally(() => {
        this.isUploading = false;
        this._cdr.detectChanges();
      });

    return false;
  };

  handleUpdatedSmsFieldStatus(disabledMessage: string) {
    this.shouldDisableSmsFields = !!disabledMessage;

    if (this.notificationDefaults && this.shouldDisableSmsFields) {
      const { rsvpConfirmation } = this.notificationDefaults;
      this.formGroup.patchValue({
        confirmationBodySms: rsvpConfirmation.sms.body
      });
    }
  }

  /**
   * Handles File Removals
   */
  handleAttachmentUploadChange({ fileList }: NzUploadChangeParam): void {
    this.hasAnyFileOperationHappened = true;
    const attachments: MmsAttachment[] = fileList
      ?.filter((file) => file.status === 'done')
      ?.map((file) => ({
        source: file.url,
        sourceType: 'url',
        contentType: file.type || 'application/octet-stream',
        contentSize: file.size
      }));

    const notificationToUpdate: INotification =
      this.signupMetadata.notifications?.find(
        (notification) => notification.type === 'rsvpConfirmation'
      );

    if (notificationToUpdate) {
      const updatedNotification: INotification = {
        ...notificationToUpdate,
        attachments
      };

      const idxToUpdate: number = this.signupMetadata.notifications?.findIndex(
        (notification) => notification.type === 'rsvpConfirmation'
      );

      const signupMetadataCopy: ContentSignupMetadata =
        this.signupMetadata.clone();

      signupMetadataCopy.notifications.splice(
        idxToUpdate,
        1,
        updatedNotification
      );

      const signupMetadata: ContentSignupMetadata =
        ContentSignupMetadata.fromObject({
          ...this.signupMetadata,
          notifications: signupMetadataCopy.notifications
        });

      this.onUpdatedSignupMetadata.emit({
        isDirty: this.formGroup.dirty || this.hasAnyFileOperationHappened,
        isNotificationsStepValid: this.formGroup.valid,
        signupMetadata
      });

      this._updateAttachments();
      this._cdr.detectChanges();
    }
  }

  handleTogglePopup() {
    this.onToggleAiSMSPopup.emit();
  }

  handleShortenedMessage(confirmationBodySms: string) {
    this.formGroup.patchValue({ confirmationBodySms });
    this.onToggleAiSMSPopup.emit();
  }

  handleShowingPopup(showingPopup: boolean) {
    this.showingPopup = showingPopup;
  }
}
