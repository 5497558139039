import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyFileUploadModule, NorbyInputModule } from 'uikit';
import { NorbyEditNewsletterSignupBlockComponent } from '../components/templates/content-block-editors/norby-edit-newsletter-signup-block';
import { NorbyTagSelectorModule } from './norby-tag-selector.module';

@NgModule({
  declarations: [NorbyEditNewsletterSignupBlockComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyInputModule,
    NorbyFileUploadModule,
    NorbyTagSelectorModule
  ],
  exports: [NorbyEditNewsletterSignupBlockComponent]
})
export class NorbyEditNewsletterSignupBlockModule {}
