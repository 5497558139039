import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import {
  PageBlock,
  OptionGroup,
  InsertPageBlockEvent,
  UpdatePageBlockEvent,
  MovePageBlockEvent,
  LandingPage
} from 'models';

import { IconService } from '../../../services';
import { rootTrash, rootMoreVertical } from '../../../../icons/build';

@Component({
  selector: 'norby-content-container',
  templateUrl: './norby-content-container.component.html',
  styleUrls: ['./norby-content-container.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbyContentContainerComponent implements DoCheck {
  private _cachedPageHash: number;
  private _closeTimer;

  @Input() page: LandingPage;
  @Input() activeIndex: number = -1;

  @Output() onBlockSelected: EventEmitter<UpdatePageBlockEvent> =
    new EventEmitter<UpdatePageBlockEvent>();
  @Output() onBlockDeleted: EventEmitter<UpdatePageBlockEvent> =
    new EventEmitter<UpdatePageBlockEvent>();
  @Output() onCreateBlock: EventEmitter<InsertPageBlockEvent> =
    new EventEmitter<InsertPageBlockEvent>();
  @Output() onBlocksChanged = new EventEmitter<MovePageBlockEvent>();

  popoverHover = false;
  hoverIndex = -1;

  get blocks() {
    return this.page?.blocks ?? [];
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootMoreVertical, rootTrash]);
  }

  readonly BLOCK_OPTION_GROUPS: OptionGroup[] = [
    {
      label: 'Basic',
      value: 'basic',
      children: [
        {
          label: 'Rich Text',
          value: 'text',
          icon: 'type'
        },
        {
          label: 'HTML',
          value: 'embed',
          icon: 'code'
        },
        {
          label: 'Image',
          value: 'image',
          icon: 'image'
        },
        {
          label: 'Profile',
          value: 'profile',
          icon: 'user'
        },
        {
          label: 'Social Icons',
          value: 'socialIcons',
          icon: 'circle'
        }
      ]
    },
    {
      label: 'Content',
      value: 'content',
      children: [
        {
          label: 'Event',
          value: 'event',
          icon: 'calendar'
        },
        {
          label: 'List of events',
          value: 'upcomingEvents',
          icon: 'server'
        },
        {
          label: 'Newsletter signup',
          value: 'newsletterSignup',
          icon: 'user-plus'
        },
        {
          label: 'Link',
          value: 'link',
          icon: 'link'
        },
        {
          label: 'Signup',
          value: 'drop',
          icon: 'edit'
        }
      ]
    },
    {
      label: 'Embed',
      value: 'embed',
      children: [
        {
          label: 'YouTube',
          value: 'youtube',
          icon: 'youtube'
        },
        {
          label: 'Vimeo',
          value: 'vimeo',
          icon: 'video'
        },
        {
          label: 'Spotify',
          value: 'spotify',
          icon: 'spotify'
        },
        {
          label: 'Calendly',
          value: 'calendly',
          icon: 'calendly'
        }
      ]
    },
    {
      label: 'Layout',
      value: 'layout',
      children: [
        {
          label: 'Header',
          value: 'header',
          icon: 'home'
        },
        {
          label: 'Spacer',
          value: 'spacer',
          icon: 'square'
        }
      ]
    }
  ];

  ngDoCheck() {
    if (this.page && this.page.hash !== this._cachedPageHash) {
      this._cachedPageHash = this.page.hash;
      this._cdr.markForCheck();
    }
  }

  handleCreateOptionClicked(option: string) {
    this.onCreateBlock.emit({
      type: option,
      index: this.hoverIndex
    });
    this.popoverHover = false;
    this.hoverIndex = -1;
    this._cdr.detectChanges();
  }

  handleBlockClick(block: PageBlock, index: number) {
    this.onBlockSelected.emit({ block, index });
  }

  trackBy(idx: number, item: PageBlock) {
    return item?.hash;
  }

  handleMouseOverFab(idx: number) {
    if (this._closeTimer) {
      clearTimeout(this._closeTimer);
    }
    this.hoverIndex = idx > -1 ? idx : 0;
  }

  handleMouseLeaveFab() {
    this._closeTimer = setTimeout(() => {
      if (!this.popoverHover) {
        this._resetFab();
      }
    }, 400);
  }

  handleMouseOverFlyout() {
    this.popoverHover = true;
  }

  handleMouseLeaveFlyout() {
    this._resetFab();
  }

  handleDrop(event: CdkDragDrop<PageBlock[]>) {
    const currIdx = event.currentIndex;
    const prevIdx = event.previousIndex;

    if (currIdx === prevIdx) {
      return;
    }

    moveItemInArray(this.blocks, prevIdx, currIdx);
    this.onBlocksChanged.next({ blocks: this.blocks, index: currIdx });
    this._cdr.detectChanges();
  }

  handleRemoveBlock(block: PageBlock, index: number) {
    this.onBlockDeleted.emit({ block, index });
  }

  private _resetFab() {
    this.hoverIndex = -1;
    this.popoverHover = false;
    this._closeTimer = null;
    this._cdr.detectChanges();
  }
}
