import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDropShadowValues, parseDropShadowString } from 'models';

@Component({
  selector: 'norby-drop-shadow-picker',
  templateUrl: './norby-drop-shadow-picker.component.html',
  styleUrls: ['./norby-drop-shadow-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyDropShadowPickerComponent),
      multi: true
    }
  ]
})
export class NorbyDropShadowPickerComponent
  implements ControlValueAccessor, OnInit
{
  @Input() label?: string;
  @Input() value?: string;
  @Input() placeholder?: string = '6px 6px 6px #000000';
  @Input() helperText?: string = '';
  @Input() infoTooltip?: string = '';
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  isButtonDisabled = true;
  val: string;
  colorVal: string;

  constructor() {}

  ngOnInit(): void {
    this.val = this.value;
    this.colorVal = this.dropShadowColor;
  }

  get dropShadowColor(): string {
    return this.dropShadowValues?.color ?? '#000000';
  }

  get dropShadowValues(): IDropShadowValues {
    return parseDropShadowString(this.val);
  }

  writeValue(value: string) {
    if (value !== null) {
      this.isButtonDisabled = false;
    }
    this.val = value;
    this.colorVal = this.dropShadowColor;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  private _updateAndEmit(shadow: string) {
    this.val = shadow;
    this._onChanged(shadow);
    this._markAsTouched();
  }

  handleColorPickerChange(event) {
    this.isButtonDisabled = false;
    const color = event.color.toHexString();
    this.colorVal = color;
    const { h, v, blur } = this.dropShadowValues;
    const shadow = `${h ?? 0}px ${v ?? 0}px ${blur ?? 0}px ${
      color ?? '#000000'
    }`;
    this._updateAndEmit(shadow);
  }

  handleShadowChange(dropShadow) {
    this._updateAndEmit(dropShadow);
  }

  handleResetValueClick() {
    this.isButtonDisabled = true;
    this.val = null;
    this.colorVal = null;
    this._markAsTouched();
    this._onChanged(this.val);
  }
}
