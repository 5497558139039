<ng-container *ngFor="let icon of iconNames; let i = index">
  <button
    type="button"
    [ngClass]="{
      'text-neutral-900 bg-neutral-100': selectedIndex === i,
      'text-neutral-400': selectedIndex !== i
    }"
    class="rounded-sm p-1 hover:text-neutral-900"
    (click)="handleSelection(i)"
    [disabled]="disabled"
  >
    <norby-icon [name]="icon"></norby-icon>
  </button>
</ng-container>
