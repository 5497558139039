import { Component, Input, TemplateRef } from '@angular/core';

export type SummaryCardViewItem = { title: string; value: string };

@Component({
  selector: 'lib-summary-card-view',
  templateUrl: './summary-card-view.component.html',
  styleUrls: ['./summary-card-view.component.less']
})
export class SummaryCardViewComponent {
  @Input() title: string;
  @Input() items: SummaryCardViewItem[];
  @Input() additionalItems: TemplateRef<any>;
}
