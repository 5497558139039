import { Component, HostListener, OnInit } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';

// 3rd party
import { User } from 'firebase/auth';
import { combineLatest, filter } from 'rxjs';

// Lib
import {
  AccountStoreService,
  AnalyticsService,
  AuthService,
  ClickInterceptorService,
  SlugService,
  UiService
} from 'shared';
import { BaseComponent } from 'uikit';
import { ISlug, AccountStatus } from 'models';

// App
import { ActionQueriesService } from './services/action-queries';
import { HubspotService } from './services/hubspot';
import { ProductAnalyticsService } from './services/product-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent implements OnInit {
  private _cachedIdentifyPayload = '';

  // For intercepting relative link clicks from injected html
  // Prevents the whole page from reloading and reroutes href to Angular router
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    this._clickService.interceptClick(event);
  }

  constructor(
    private _analytics: AnalyticsService, // Init plugin-based analytics tracking
    private _actionQuery: ActionQueriesService, // Listen for url param actions
    private _auth: AuthService, // Kickstart auth
    private _clickService: ClickInterceptorService,
    private _hubspot: HubspotService,
    private _productAnalytics: ProductAnalyticsService, // Init page tracking
    private _router: Router,
    private _slug: SlugService,
    private _accountStore: AccountStoreService,
    private _ui: UiService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initRouterSubscription();
    this._initAuthListener();
  }

  private _initAuthListener() {
    combineLatest([
      this._auth.user$,
      this._slug.getCurrentSlug$(),
      this._accountStore.accountStatus$
    ])
      .pipe(
        filter(([u]) => !!u?.uid && !u.isAnonymous),
        this.takeUntilDestroy
      )
      .subscribe(([u, s, a]) => this._updateUser(u, s, a));
  }

  private _initRouterSubscription() {
    // Global router listener allows us to hook in and show a
    // loading indicator when chunks take a long time to load
    this._router.events.pipe(this.takeUntilDestroy).subscribe((e) => {
      // Set global loading indicator state
      // Indicator is only shown if load takes more than N ms
      if (e instanceof RouteConfigLoadStart) this._ui.setLoading(true);
      else if (e instanceof RouteConfigLoadEnd) this._ui.setLoading(false);
    });
  }

  private _updateUser(u: User, s: ISlug, a: AccountStatus) {
    const payload = {
      name: u?.displayName,
      email: u?.email,
      avatar: u?.photoURL,
      phone: u?.phoneNumber,
      createdAt: u?.metadata?.creationTime,
      customerId: a?.stripe?.stripeCustomerId,
      company: {
        uid: s?.slug,
        created: a?.dates?.trialStart?.toDate(),
        name: s?.accountInfo?.title,
        trial_ends: a?.dates?.trialEnd?.toDate(),
        plan: a?.projectTier,
        period: a?.projectPeriod
      }
    };

    const newCachedPayload = JSON.stringify(payload);
    if (newCachedPayload !== this._cachedIdentifyPayload) {
      this._cachedIdentifyPayload = newCachedPayload;
      this._analytics.identify(u.uid, payload);
      this._hubspot.refresh();
    }
  }
}
