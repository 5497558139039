import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// 3rd party
import { NzSelectModule } from 'ng-zorro-antd/select';

// App
import { NorbyConfigurableSelectorComponent } from '../components/atoms/norby-configurable-selector';

@NgModule({
  declarations: [NorbyConfigurableSelectorComponent],
  imports: [CommonModule, FormsModule, NzSelectModule],
  exports: [NorbyConfigurableSelectorComponent]
})
export class NorbyConfigurableSelectorModule {}
