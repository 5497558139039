<norby-formcontrol-label
  [label]="label"
  [infoTooltip]="infoTooltip"
  [isRequired]="isRequired"
></norby-formcontrol-label>

<norby-configurable-selector
  key="contentId"
  label="title"
  [filterLocalResults]="false"
  [selectorType]="NORBY_SELECTOR_TYPES_ENUM.CONTENT_SELECTOR_BY_ID"
  [placeholder]="placeholder"
  [items]="options"
  [value]="val"
  [isDisabled]="isDisabled"
  [isLoading]="isSearching"
  [optionHeightPx]="72"
  [optionOverflowSize]="4"
  [optionTemplate]="optionTemplate"
  [footerTemplate]="footerTemplate"
  (onCreateClick)="onCreateClick.emit()"
  (onValueChange)="handleChange($event)"
  (onSearch)="handleSearch($event)"
></norby-configurable-selector>

<ng-template #optionTemplate let-item>
  <div class="my-2">
    <div class="flex flex-row">
      <div class="flex-none" *ngIf="!!item?.imageUrl">
        <img class="w-10 h-10 px-0.5" [src]="item?.imageUrl" />
      </div>
      <norby-icon
        name="link"
        size="large"
        class="flex-none w-10 h-10 px-0.5 text-neutral-800"
        *ngIf="!item?.imageUrl"
      ></norby-icon>
      <div class="flex flex-col flex-auto px-2 justify-center overflow-hidden">
        <span class="text-sm truncate">
          {{ item?.title }}
        </span>
        <span class="text-xs truncate text-neutral-700">{{
          item?.summary
        }}</span>
      </div>
      <div class="flex-grow"></div>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div class="flex my-2 justify-center">
    <button
      type="button"
      class="flex-1 text-sm text-neutral-600 hover:text-neutral-800"
      (click)="onCreateClick.emit()"
    >
      {{ buttonText | titlecase }}
    </button>
  </div>
</ng-template>
