import { Injectable } from '@angular/core';

// 3rd party
import { Observable, filter, switchMap } from 'rxjs';

// Libs
import { LandingPage } from 'models';
import { ILandingPageStoreService } from './landing-page-store.service.interface';
import { LandingPageService } from '../landing-page/landing-page.service';
import {
  ObservableDataService,
  QuerySummary,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  LANDING_PAGE_UPDATED,
  RealtimeServerSocketMessage,
  LANDING_PAGE_DELETED,
  LANDING_PAGE_UPDATED_DEFAULT_IN_SLUG,
  LANDING_PAGE_UPDATED_WITH_LABEL,
  LANDING_PAGE_DELETED_WITH_LABEL
} from '../socket';
import { DeviceService } from '../device';

@Injectable({
  providedIn: 'root'
})
export class LandingPageStoreService implements ILandingPageStoreService {
  constructor(
    private _service: LandingPageService,
    private _ods: ObservableDataService,
    private _device: DeviceService
  ) {}

  getLandingPageById$(id: string): Observable<QuerySummary<LandingPage>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: LANDING_PAGE_UPDATED,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          LandingPage.fromObject(event?.data)
      },
      {
        event: LANDING_PAGE_DELETED,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          LandingPage.fromObject(event?.data)
      }
    ];

    return this._ods.document$<LandingPage>({
      handlers,
      lookup: this._service.getLandingPageById,
      args: id,
      isPublic: true
    });
  }

  getDefaultLandingPage$(): Observable<QuerySummary<LandingPage>> {
    return this._device.currentSlug$.pipe(
      filter((slug) => !!slug),
      switchMap((resourceId) =>
        this._ods.document$<LandingPage>({
          handlers: [
            {
              event: LANDING_PAGE_UPDATED_DEFAULT_IN_SLUG,
              payload: { resourceId },
              transformer: (event: RealtimeServerSocketMessage) =>
                LandingPage.fromObject(event?.data)
            }
          ],
          lookup: this._service.getDefaultLandingPage,
          isPublic: true
        })
      )
    );
  }

  getLandingPageByLabel$(label: string): Observable<QuerySummary<LandingPage>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: LANDING_PAGE_UPDATED_WITH_LABEL,
        payload: {
          resourceId: label
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          LandingPage.fromObject(event?.data)
      },
      {
        event: LANDING_PAGE_DELETED_WITH_LABEL,
        payload: {
          resourceId: label
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          LandingPage.fromObject(event?.data)
      }
    ];

    return this._ods.document$<LandingPage>({
      handlers,
      lookup: this._service.getLandingPageByLabel,
      args: label,
      isPublic: true
    });
  }

  // getLandingPages$(
  //   limit: number,
  //   sort: string
  // ): Observable<PaginatedQuerySummary<LandingPage>> {
  //   // Function used to fetch pages
  //   const args = { limit, sort };
  //   const lookup = this._service.getLandingPages;

  //   // Function used to transform lookup results and
  //   // incorporate them into the stream
  //   const transformer = (res: IQueryResult, currentValue: LandingPage[]) => {
  //     const hasNextPage = res?.pageInfo?.hasNextPage;
  //     const pages =
  //       res?.edges?.map((edge) => LandingPage.fromObject(edge?.node)) ?? [];

  //     return {
  //       items: [...(currentValue ?? []), ...pages],
  //       cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
  //     };
  //   };

  //   // Socket events to listen for and how to handle them
  //   const handlers: RealtimeSocketEventHandler[] = [
  //     {
  //       event: LANDING_PAGE_CREATED,
  //       payload: { resourceId: null },
  //       transformer: (
  //         event: RealtimeServerSocketMessage,
  //         currentValue: LandingPage[]
  //       ) => {
  //         const page = LandingPage.fromObject(event.data);
  //         return [page, ...currentValue];
  //       }
  //     },
  //     {
  //       event: LANDING_PAGE_UPDATED,
  //       payload: { resourceId: null },
  //       transformer: (
  //         event: RealtimeServerSocketMessage,
  //         currentValue: LandingPage[]
  //       ) => {
  //         const page = LandingPage.fromObject(event.data);
  //         const idx = currentValue?.findIndex((p) => p.id === page.id);

  //         if (idx >= 0) {
  //           currentValue[idx] = page;
  //           return [...currentValue];
  //         }

  //         return [page, ...currentValue];
  //       }
  //     },
  //     {
  //       event: LANDING_PAGE_DELETED,
  //       payload: { resourceId: null },
  //       transformer: (
  //         event: RealtimeServerSocketMessage,
  //         currentValue: LandingPage[]
  //       ) => {
  //         const page = LandingPage.fromObject(event.data);
  //         const idx = currentValue?.findIndex((p) => p.id === page.id);

  //         if (idx >= 0) {
  //           currentValue?.splice(idx, 1);
  //           return [...currentValue];
  //         }

  //         return currentValue;
  //       }
  //     }
  //   ];

  //   return this._ods.query$<LandingPage>({
  //     args,
  //     lookup,
  //     transformer,
  //     handlers
  //   });
  // }
}
