import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDoc,
  query,
  where
} from '@angular/fire/firestore';

// 3rd party
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  FIREBASE_COLLECTIONS,
  IUserPublicMetadata,
  IUserRole,
  IUserSlugNotification
} from 'models';
import { DeviceService } from '../device';
import { IQueriesService } from './queries.service.interface';

@Injectable({
  providedIn: 'root'
})
export class QueriesService implements IQueriesService {
  constructor(
    private _device: DeviceService,
    private _firestore: Firestore
  ) {}

  getUserById$(id: string): Observable<IUserPublicMetadata> {
    if (!id?.length) {
      return from([null]);
    }

    const ref = doc(this._firestore, FIREBASE_COLLECTIONS.users.all, id);
    return docData(ref) as Observable<IUserPublicMetadata>;
  }

  userRoleForUser({ userId, slug }: { userId: string; slug: string }) {
    return userId?.length && slug?.length
      ? doc(this._firestore, 'userRoles', `${slug}_${userId}`)
      : null;
  }

  getUserRole(userId: string): Promise<IUserRole> {
    if (!userId) {
      return Promise.resolve(null);
    }

    return getDoc(
      this.userRoleForUser({ userId, slug: this._device.currentSlug })
    ).then((res) => res?.data() as IUserRole);
  }

  getUserRole$(userId: string): Observable<IUserRole> {
    if (!userId) {
      return from([null]);
    }

    return this._device.currentSlug$.pipe(
      switchMap(
        (slug) =>
          docData(
            this.userRoleForUser({
              userId,
              slug
            })
          ) as Observable<IUserRole>
      )
    );
  }

  getUserSlugNotification({ userId }: { userId: string }) {
    if (!userId?.length) {
      return null;
    }

    const slug = this._device.currentSlug;
    const ref = collection(this._firestore, 'userSlugNotifications');
    const constraints = [
      where('slug', '==', slug),
      where('userId', '==', userId)
    ];
    return query(ref, ...constraints);
  }

  getUserSlugNotifications$({
    userId
  }: {
    userId: string;
  }): Observable<IUserSlugNotification[]> {
    return this._device.currentSlug$.pipe(
      switchMap(() =>
        collectionData(
          this.getUserSlugNotification({
            userId
          })
        )
      ),
      map((list) => list.map((c) => plainToClass(IUserSlugNotification, c)))
    );
  }
}
