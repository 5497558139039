import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';

import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ExpandableBottomSheetViewComponent } from '../views/expandable-bottom-sheet-view';

@NgModule({
  declarations: [ExpandableBottomSheetViewComponent],
  imports: [CommonModule, ObserversModule, NzSpinModule],
  exports: [ExpandableBottomSheetViewComponent]
})
export class ExpandableBottomSheetViewModule {}
