import { Injectable } from '@angular/core';

import {
  ICreateFeatureFlagDto,
  IFeatureFlag,
  IFeatureFlagResults,
  ENDPOINTS,
  ApiSurfaces,
  IUpdateFeatureFlagDto
} from 'models';
import { ErrorService } from 'uikit';
import { IFeatureFlagsService } from './feature-flags.service.interface';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService implements IFeatureFlagsService {
  constructor(
    private _api: ApiService,
    private _error: ErrorService
  ) {}

  getFeatureFlags = (filters?: {
    after?: string;
    offset?: string;
    limit?: number;
  }): Promise<IFeatureFlagResults> => {
    return this._api.get<any>(ApiSurfaces.API, ENDPOINTS.featureFlags, filters);
  };

  getFeatureById = ({ id }: { id: string }): Promise<IFeatureFlag> => {
    return this._api.get<any>(
      ApiSurfaces.API,
      `${ENDPOINTS.featureFlags}/${id}`
    );
  };

  getFeatureByName = ({ name }: { name: string }): Promise<IFeatureFlag> => {
    return this._api.get<any>(
      ApiSurfaces.API,
      `${ENDPOINTS.featureFlags}/name/${name}`
    );
  };

  createFeatureFlag(args: ICreateFeatureFlagDto): Promise<IFeatureFlag> {
    return this._api.post<any>(ApiSurfaces.API, ENDPOINTS.featureFlags, args);
  }

  updateFeatureFlag(
    id: string,
    args: IUpdateFeatureFlagDto
  ): Promise<IFeatureFlag> {
    try {
      return this._api.patch<any>(
        ApiSurfaces.API,
        `${ENDPOINTS.featureFlags}/${id}`,
        args
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  deleteFeatureFlag(id: string): Promise<void> {
    try {
      return this._api.delete<any>(
        ApiSurfaces.API,
        `${ENDPOINTS.featureFlags}/${id}`
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }
}
