import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { IconModule } from 'uikit';
import { PhonePipeModule } from 'customer-uikit';
import { UserRowViewModule } from './user-row-view.module';
import { ContactTableComponent } from '../components/contact-table';

@NgModule({
  declarations: [ContactTableComponent],
  exports: [ContactTableComponent],
  imports: [
    CommonModule,
    NzPaginationModule,
    NzTableModule,
    NzTagModule,
    NzToolTipModule,
    NzButtonModule,
    UserRowViewModule,
    PhonePipeModule,
    IconModule
  ]
})
export class ContactTableModule {}
