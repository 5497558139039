import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

// App
import { SafeHtmlPipeModule } from 'uikit';
import { ThemeCardViewComponent } from '../views/theme-card-view';

@NgModule({
  declarations: [ThemeCardViewComponent],
  imports: [CommonModule, SafeHtmlPipeModule, NzCardModule, NzSkeletonModule],
  exports: [ThemeCardViewComponent]
})
export class ThemeCardViewModule {}
