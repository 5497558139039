<div class="notification-detail-view-container-wrapper">
  <div class="notification-detail-view-container">
    <div class="space-wrapper">
      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
        *ngIf="isEmail"
      >
        <div class="header">
          <div class="meta-wrapper">
            <div *ngIf="notification" class="meta">
              <p>From: <strong>Norby</strong></p>
              <p *ngIf="isEmail && notification?.subject">
                Subject: <strong>{{ notification.subject }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
        *ngIf="isEmail"
      >
        <div class="notification-body ql-readonly-body text-base">
          <div [innerHtml]="notification?.message | safeHtml"></div>
        </div>
      </div>

      <div *ngIf="!isEmail">
        <div
          *ngIf="notification?.attachments?.length > 0"
          class="flex flex-row flex-wrap"
        >
          <div
            *ngFor="
              let attachment of notification.attachments;
              let i = index;
              trackBy: attachmentTrackBy
            "
            class="mr-3 mb-3 w-24 h-24 rounded-lg bg-neutral-200 flex justify-center items-center overflow-hidden"
          >
            <lib-soft-image-view
              [imageUrl]="attachment.source"
              [alt]="attachment.filename"
            >
            </lib-soft-image-view>
          </div>
        </div>

        <div
          class="rounded-tl-lg rounded-tr-lg rounded-br-lg bg-neutral-200 text-lg text-neutral-900 p-6 mr-6 whitespace-pre-wrap"
        >
          {{ notification?.message }}
        </div>
      </div>
    </div>
  </div>
</div>
