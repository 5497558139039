import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyToggleButtonsComponent } from '../components/molecules/norby-toggle-buttons';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyToggleButtonsComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyToggleButtonsComponent]
})
export class NorbyToggleButtonsModule {}
