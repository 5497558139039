import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { NorbyIconButtonModule } from 'uikit';
import { NorbyFooterComponent } from '../components/molecules/norby-footer';

@NgModule({
  declarations: [NorbyFooterComponent],
  imports: [CommonModule, NorbyIconButtonModule, NzDropDownModule],
  exports: [NorbyFooterComponent]
})
export class NorbyFooterModule {}
