<div
  class="mb-1 hover:text-neutral-800 font-normal"
  [ngClass]="{
    'text-neutral-800': isSelected,
    'text-neutral-700': !isSelected
  }"
>
  <div
    class="relative flex items-center border border-transparent rounded-sm py-4 h-9"
    [ngClass]="{
      'bg-mustard-200 border-mustard-400': isSelected,
      'cursor-not-allowed': isDisabled,
      'cursor-pointer': !isDisabled
    }"
    (click)="onMenuItemClick(index)"
  >
    <norby-icon
      [name]="iconName"
      *ngIf="iconName"
      class="h-5 w-6 ml-3 mr-2"
    ></norby-icon>

    <span
      class="h-5 w-5 ml-3 mr-2 flex items-center justify-center rounded-full text-white text-xs"
      [ngClass]="{
        'bg-mustard-400': isDisabled,
        'bg-mustard': !isDisabled && isValid,
        'bg-red-ui': !isDisabled && !isValid
      }"
      *ngIf="badgeContent"
    >
      {{ badgeContent }}
    </span>

    <span class="h-5 w-5 ml-3 mr-2" *ngIf="!badgeContent && !iconName"> </span>

    <label
      [ngClass]="{
        'font-medium': isSelected,
        'text-neutral-600': isDisabled && isValid,
        'text-red-ui': !isValid,
        'cursor-not-allowed': isDisabled,
        'cursor-pointer': !isDisabled
      }"
    >
      {{ label }}
    </label>
  </div>
</div>
