import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySliderModule } from 'uikit';
import { NorbyEditSpacerBlockComponent } from '../components/templates/content-block-editors/norby-edit-spacer-block';

@NgModule({
  declarations: [NorbyEditSpacerBlockComponent],
  imports: [CommonModule, ReactiveFormsModule, NorbySliderModule],
  exports: [NorbyEditSpacerBlockComponent]
})
export class NorbyEditSpacerBlockModule {}
