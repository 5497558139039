export type RealtimeSocketMessages =
  | 'contact/updated'
  | 'contact/deleted'
  | 'contact/created_in_slug'
  | 'contact/updated_in_slug'
  | 'contact/deleted_in_slug'
  | 'contact/seen'
  | 'contact/unseen'
  | 'contact/summary'
  | 'import/created'
  | 'import/updated'
  | 'import/deleted'
  | 'inbox_item/updated'
  | 'inbox_item/deleted'
  | 'inbox_item/created_for_contact'
  | 'inbox_item/updated_for_contact'
  | 'inbox_item/deleted_for_contact'
  | 'account_integration/updated'
  | 'account_integration/deleted'
  | 'account_integration/created_in_slug'
  | 'account_integration/updated_in_slug'
  | 'account_integration/deleted_in_slug'
  | 'account_addon/updated'
  | 'account_addon/deleted'
  | 'account_addon/created_in_slug'
  | 'account_addon/updated_in_slug'
  | 'account_addon/deleted_in_slug'
  | 'feature_flag/created'
  | 'feature_flag/updated'
  | 'feature_flag/deleted'
  | 'feature_flag/updated_with_id'
  | 'feature_flag/deleted_with_id'
  | 'feature_flag/updated_with_name'
  | 'feature_flag/deleted_with_name'
  | 'contact/with_medium_updated'
  | 'contact/with_medium_deleted'
  | 'contact/with_medium_created_in_slug'
  | 'contact/with_medium_updated_in_slug'
  | 'contact/with_medium_deleted_in_slug'
  | 'single_send/updated'
  | 'single_send/deleted'
  | 'single_send/created_in_slug'
  | 'single_send/updated_in_slug'
  | 'single_send/deleted_in_slug'
  | 'content_private/updated'
  | 'content_private/deleted'
  | 'content_private/created_in_slug'
  | 'content_private/updated_in_slug'
  | 'content_private/deleted_in_slug'
  | 'content_public/updated'
  | 'content_public/deleted'
  | 'content_public/created_in_slug'
  | 'content_public/updated_in_slug'
  | 'content_public/deleted_in_slug'
  | 'landing_page/created'
  | 'landing_page/updated'
  | 'landing_page/updated_with_label'
  | 'landing_page/updated_default_in_slug'
  | 'landing_page/deleted'
  | 'landing_page/deleted_with_label'
  | 'contact_list/updated'
  | 'contact_list/deleted'
  | 'contact_list/created_in_slug'
  | 'contact_list/updated_in_slug'
  | 'contact_list/deleted_in_slug'
  | 'contact_list/summary'
  | 'ai_conversation/updated'
  | 'ai_conversation/deleted'
  | 'ai_conversation/created_in_slug'
  | 'ai_conversation/updated_in_slug'
  | 'ai_conversation/deleted_in_slug'
  | 'ai_conversation_message/created';

export const SINGLE_SEND_UPDATED_TOPIC: RealtimeSocketMessages =
  'single_send/updated';
export const SINGLE_SEND_DELETED_TOPIC: RealtimeSocketMessages =
  'single_send/deleted';
export const SINGLE_SEND_CREATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'single_send/created_in_slug';
export const SINGLE_SEND_UPDATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'single_send/updated_in_slug';
export const SINGLE_SEND_DELETED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'single_send/deleted_in_slug';

export const CONTACT_UPDATED_TOPIC: RealtimeSocketMessages = 'contact/updated';
export const CONTACT_DELETED_TOPIC: RealtimeSocketMessages = 'contact/deleted';

export const CONTACT_CREATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'contact/created_in_slug';
export const CONTACT_UPDATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'contact/updated_in_slug';
export const CONTACT_DELETED_IN_SLUG_TOPIC: RealtimeSocketMessages =
  'contact/deleted_in_slug';

export const CONTACT_WITH_MEDIUM_UPDATED_TOPIC = 'contact/with_medium_updated';
export const CONTACT_WITH_MEDIUM_DELETED_TOPIC = 'contact/with_medium_deleted';
export const CONTACT_WITH_MEDIUM_CREATED_IN_SLUG_TOPIC =
  'contact/with_medium_created_in_slug';
export const CONTACT_WITH_MEDIUM_UPDATED_IN_SLUG_TOPIC =
  'contact/with_medium_updated_in_slug';
export const CONTACT_WITH_MEDIUM_DELETED_IN_SLUG_TOPIC =
  'contact/with_medium_deleted_in_slug';

export const CONTACT_SEEN_TOPIC: RealtimeSocketMessages = 'contact/seen';
export const CONTACT_UNSEEN_TOPIC: RealtimeSocketMessages = 'contact/unseen';
export const CONTACT_SUMMARY_TOPIC: RealtimeSocketMessages = 'contact/summary';

export const IMPORT_CREATED_TOPIC: RealtimeSocketMessages = 'import/created';
export const IMPORT_UPDATED_TOPIC: RealtimeSocketMessages = 'import/updated';
export const IMPORT_DELETED_TOPIC: RealtimeSocketMessages = 'import/deleted';

export const INBOX_ITEM_UPDATED_TOPIC: RealtimeSocketMessages =
  'inbox_item/updated';
export const INBOX_ITEM_DELETED_TOPIC: RealtimeSocketMessages =
  'inbox_item/deleted';

export const INBOX_ITEM_CREATED_FOR_CONTACT_TOPIC: RealtimeSocketMessages =
  'inbox_item/created_for_contact';
export const INBOX_ITEM_UPDATED_FOR_CONTACT_TOPIC: RealtimeSocketMessages =
  'inbox_item/updated_for_contact';
export const INBOX_ITEM_DELETED_FOR_CONTACT_TOPIC: RealtimeSocketMessages =
  'inbox_item/deleted_for_contact';

export const ACCOUNT_INTEGRATION_UPDATED_TOPIC = 'account_integration/updated';
export const ACCOUNT_INTEGRATION_DELETED_TOPIC = 'account_integration/deleted';
export const ACCOUNT_INTEGRATION_CREATED_IN_SLUG_TOPIC =
  'account_integration/created_in_slug';
export const ACCOUNT_INTEGRATION_UPDATED_IN_SLUG_TOPIC =
  'account_integration/updated_in_slug';
export const ACCOUNT_INTEGRATION_DELETED_IN_SLUG_TOPIC =
  'account_integration/deleted_in_slug';

export const ACCOUNT_ADDON_UPDATED_TOPIC = 'account_addon/updated';
export const ACCOUNT_ADDON_DELETED_TOPIC = 'account_addon/deleted';
export const ACCOUNT_ADDON_CREATED_IN_SLUG_TOPIC =
  'account_addon/created_in_slug';
export const ACCOUNT_ADDON_UPDATED_IN_SLUG_TOPIC =
  'account_addon/updated_in_slug';
export const ACCOUNT_ADDON_DELETED_IN_SLUG_TOPIC =
  'account_addon/deleted_in_slug';

export const FEATURE_FLAG_CREATED = 'feature_flag/created';
export const FEATURE_FLAG_UPDATED = 'feature_flag/updated';
export const FEATURE_FLAG_DELETED = 'feature_flag/deleted';
export const FEATURE_FLAG_UPDATED_WITH_ID = 'feature_flag/updated_with_id';
export const FEATURE_FLAG_DELETED_WITH_ID = 'feature_flag/deleted_with_id';
export const FEATURE_FLAG_UPDATED_WITH_NAME = 'feature_flag/updated_with_name';
export const FEATURE_FLAG_DELETED_WITH_NAME = 'feature_flag/deleted_with_name';

export const GLOBAL_TOPIC_IDENTIFIER = 'GLOBAL';

export type RealtimePublishAction = 'added' | 'updated' | 'removed';

export type RealtimeServerSocketMessage = {
  topic: RealtimeSocketMessages;
  data: any;
  identifier: string;
  action: RealtimePublishAction;
};

export type SocketMessages =
  | 'interactions/page_view'
  | 'interactions/dashboard_page_view'
  | RealtimeSocketMessages;

export type SocketConnectionState = {
  connected: boolean;
  error: Error;
  uid: string;
};

export interface ISocketMessage {
  message: SocketMessages;
  payload?: any;
}

export type SocketAction = 'start' | 'stop';

export interface PageViewSocketMessage extends ISocketMessage {
  message: 'interactions/page_view' | 'interactions/dashboard_page_view';
  payload: {
    action: SocketAction;
    domReferrer?: string;
    path: string;
  };
}

export type SocketSubscriptionListenState = 'start' | 'stop';
export const SocketSubscriptionListenStates: SocketSubscriptionListenState[] = [
  'start',
  'stop'
];

export type ISocketRealtimeSubscription = {
  resourceId: string;
  listen: SocketSubscriptionListenState;
};

export interface RealtimeSocketMessage extends ISocketMessage {
  message: RealtimeSocketMessages;
  payload: ISocketRealtimeSubscription;
}

export type SocketMessage = PageViewSocketMessage | RealtimeSocketMessage;

// Landing pages ODS TOPICS
export const LANDING_PAGE_CREATED: RealtimeSocketMessages =
  'landing_page/created';
export const LANDING_PAGE_UPDATED: RealtimeSocketMessages =
  'landing_page/updated';
export const LANDING_PAGE_UPDATED_WITH_LABEL: RealtimeSocketMessages =
  'landing_page/updated_with_label';
export const LANDING_PAGE_UPDATED_DEFAULT_IN_SLUG: RealtimeSocketMessages =
  'landing_page/updated_default_in_slug';
export const LANDING_PAGE_DELETED: RealtimeSocketMessages =
  'landing_page/deleted';
export const LANDING_PAGE_DELETED_WITH_LABEL: RealtimeSocketMessages =
  'landing_page/deleted_with_label';

// Content ODS TOPICS
export const CONTENT_PRIVATE_UPDATED: RealtimeSocketMessages =
  'content_private/updated';
export const CONTENT_PRIVATE_DELETED: RealtimeSocketMessages =
  'content_private/deleted';

export const CONTENT_PRIVATE_CREATED_IN_SLUG: RealtimeSocketMessages =
  'content_private/created_in_slug';
export const CONTENT_PRIVATE_UPDATED_IN_SLUG: RealtimeSocketMessages =
  'content_private/updated_in_slug';
export const CONTENT_PRIVATE_DELETED_IN_SLUG: RealtimeSocketMessages =
  'content_private/deleted_in_slug';

export const CONTENT_PUBLIC_UPDATED: RealtimeSocketMessages =
  'content_public/updated';
export const CONTENT_PUBLIC_DELETED: RealtimeSocketMessages =
  'content_public/deleted';

export const CONTENT_PUBLIC_CREATED_IN_SLUG: RealtimeSocketMessages =
  'content_public/created_in_slug';
export const CONTENT_PUBLIC_UPDATED_IN_SLUG: RealtimeSocketMessages =
  'content_public/updated_in_slug';
export const CONTENT_PUBLIC_DELETED_IN_SLUG: RealtimeSocketMessages =
  'content_public/deleted_in_slug';
// Contact list ODS topics
export const CONTACT_LIST_UPDATED_TOPIC: RealtimeSocketMessages =
    'contact_list/updated',
  CONTACT_LIST_DELETED_TOPIC: RealtimeSocketMessages = 'contact_list/deleted',
  CONTACT_LIST_CREATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'contact_list/created_in_slug',
  CONTACT_LIST_UPDATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'contact_list/updated_in_slug',
  CONTACT_LIST_DELETED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'contact_list/deleted_in_slug',
  CONTACT_LIST_SUMMARY: RealtimeSocketMessages = 'contact_list/summary';

export const CONVERSATION_UPDATED_TOPIC: RealtimeSocketMessages =
    'ai_conversation/updated',
  CONVERSATION_DELETED_TOPIC: RealtimeSocketMessages =
    'ai_conversation/deleted',
  CONVERSATION_CREATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'ai_conversation/created_in_slug',
  CONVERSATION_UPDATED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'ai_conversation/updated_in_slug',
  CONVERSATION_DELETED_IN_SLUG_TOPIC: RealtimeSocketMessages =
    'ai_conversation/deleted_in_slug',
  AI_CONVERSATION_MESSAGE_TOPIC = 'ai_conversation_message/created';
