import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySendsConfirmationViewComponent } from '../components/templates/sends/norby-sends-confirmation-view';
import { NorbySendsScheduleViewComponent } from '../components/templates/sends/norby-sends-schedule-view';
import { NorbySendsScheduleConfirmationViewComponent } from '../components/templates/sends/norby-sends-schedule-confirmation-view';
import { NorbySendsVericationViewComponent } from '../components/templates/sends/norby-sends-verification-view';
import { NorbyButtonModule, NorbyDateTimePickerModule } from 'uikit';

@NgModule({
  declarations: [
    NorbySendsScheduleViewComponent,
    NorbySendsScheduleConfirmationViewComponent,
    NorbySendsConfirmationViewComponent,
    NorbySendsVericationViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyDateTimePickerModule
  ],
  exports: [
    NorbySendsScheduleViewComponent,
    NorbySendsScheduleConfirmationViewComponent,
    NorbySendsConfirmationViewComponent,
    NorbySendsVericationViewComponent
  ]
})
export class NorbySendsConfirmationModule {}
