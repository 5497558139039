<div
  class="social-icons-container"
  *ngIf="accountInfo?.links && iconOnly"
  [class.center]="center"
>
  <ng-container *ngFor="let link of links">
    <a
      [attr.aria-label]="link.label"
      [href]="link.url"
      class="text-color"
      rel="noreferrer"
      target="_blank"
    >
      <root-button
        type="ghost"
        [size]="size"
        name="social"
        [ariaLabel]="link.label"
        [rootTooltip]="link.label"
        (handleClick)="handleClick($event, link.url)"
      >
        <root-icon [name]="link.icon"></root-icon>
      </root-button>
    </a>
  </ng-container>
</div>

<div
  class="social-icons-container"
  *ngIf="accountInfo?.links && !iconOnly"
  [class.center]="center"
>
  <ng-container *ngFor="let link of links">
    <a
      [attr.aria-label]="link.label"
      [href]="link.url"
      class="text-color"
      rel="noreferrer"
      target="_blank"
    >
      <root-button
        type="ghost"
        [size]="size"
        name="social"
        [ariaLabel]="link.label"
        (handleClick)="handleClick($event, link.url)"
        [text]="link.label"
      >
        <root-icon [name]="link.icon"></root-icon>
      </root-button>
    </a>
  </ng-container>
</div>
