import { Injectable } from '@angular/core';

import {
  ApiSurfaces,
  ENDPOINTS,
  ILandingPage,
  IQueryResult,
  LandingPage,
  PageInfo,
  PaginatedQueryFilters
} from 'models';
import { ApiService } from '../api';

export type LandingPagesFilterArgs = {
  sort?: 'asc' | 'desc';
} & PaginatedQueryFilters;

export class LandingPageResultEdge {
  cursor?: string;
  node: ILandingPage;
}

export class LandingPageResults extends IQueryResult {
  pageInfo: PageInfo;
  edges: LandingPageResultEdge[];
}

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(private _api: ApiService) {}

  getRawLandingPageById = (id: string): Promise<ILandingPage> =>
    this._api.get<ILandingPage>(
      ApiSurfaces.END_USER,
      `${ENDPOINTS.landingPage}/${id}`
    );

  getLandingPageById = (id: string): Promise<LandingPage> =>
    this.getRawLandingPageById(id).then((page) => LandingPage.fromObject(page));

  getRawDefaultLandingPage = (): Promise<ILandingPage> =>
    this._api.get<ILandingPage>(
      ApiSurfaces.END_USER,
      `${ENDPOINTS.landingPage}/default`,
      {},
      {
        'Cache-Control': 'max-age=0, no-cache, no-store, must-revalidate'
      }
    );

  getDefaultLandingPage = (): Promise<LandingPage> =>
    this.getRawDefaultLandingPage().then((page) =>
      LandingPage.fromObject(page)
    );

  getRawLandingPageByLabel = (label: string): Promise<ILandingPage> =>
    this._api.get<ILandingPage>(
      ApiSurfaces.END_USER,
      `${ENDPOINTS.landingPage}/label/${label}`,
      {},
      {
        'Cache-Control': 'max-age=0, no-cache, no-store, must-revalidate'
      }
    );

  getLandingPageByLabel = (label: string): Promise<LandingPage> =>
    this.getRawLandingPageByLabel(label).then((page) =>
      LandingPage.fromObject(page)
    );

  getLandingPages = (
    args: LandingPagesFilterArgs
  ): Promise<LandingPageResults> =>
    this._api.get<LandingPageResults>(
      ApiSurfaces.END_USER,
      ENDPOINTS.landingPage,
      args
    );
}
