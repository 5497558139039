<div class="signup-container">
  <form [formGroup]="formGroup" class="signup-wrapper">
    <h1>Monetization</h1>

    <p class="text-neutral-600">Charge for access to your signup.</p>

    <div class="flex flex-row justify-between mt-2">
      <h3>Monetization</h3>
      <norby-switch-button
        [value]="isTicketingEnabled"
        (valueChange)="handleTicketingChange($event)"
        [isDisabled]="!stripeIsConnected"
      >
      </norby-switch-button>
    </div>

    <p class="text-neutral-600">
      Enable monetization to charge users for registering. Users can pay with
      Apple Pay, Google Pay, or credit card.
    </p>

    <p *ngIf="!stripeIsConnected" class="underline">
      <a [href]="integrationsLink" target="_blank"
        >Link your Stripe account in Integrations to enable ticketing.
      </a>
    </p>

    <div *ngIf="isTicketingEnabled">
      <norby-formcontrol-label
        label="Price"
        infoTooltip="Please enter a price for this ticket."
      ></norby-formcontrol-label>
      <nz-form-item>
        <nz-form-control
          nzErrorTip="Please enter a valid price for this ticket."
        >
          <nz-input-group nzSuffix="USD" nzPrefix="$">
            <input
              type="number"
              nz-input
              formControlName="ticketPrice"
              placeholder="10"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <norby-input
        label="Description"
        placeholder="Royal Ice Cream Ticket"
        formControlName="ticketStatementDescription"
        infoTooltip="Please enter a statement description for this ticket."
        [isRequired]="true"
      ></norby-input>

      <norby-select
        formControlName="refundPolicy"
        label="Refund policy"
        infoTooltip="Choose whether to allow users to request refunds. Refund
                requests will require your approval."
      >
        <option value="noRefunds" label="No refunds"></option>
        <option value="allowRequests" label="Allow refund requests"></option>
      </norby-select>
      <norby-collapsible-group>
        <norby-collapsible title="Promo Codes">
          <p class="text-neutral-600">Add promo codes for your signup</p>
          <button
            #button
            type="button"
            class="ant-btn-animation flex items-center w-full rounded-sm px-5 py-2 border border-neutral-900 bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 mb-3 justify-center hover:bg-neutral-700 hover:text-white"
            (click)="handleAddPromoCode()"
          >
            <norby-icon name="plus"></norby-icon>Add a promo code
          </button>
          <div class="w-full mb-3" formGroupName="promoCodes">
            <div
              *ngFor="
                let control of promoCodesControls;
                index as i;
                trackBy: trackBy
              "
            >
              <div
                [formGroupName]="control?.controlInstance"
                class="flex items-center"
              >
                <div
                  *ngIf="!control?.delete"
                  class="flex flex-1 flex-row gap-4 flex-wrap"
                >
                  <norby-input
                    label="Code"
                    [isRequired]="true"
                    placeholder="Enter a code"
                    [maxLength]="22"
                    formControlName="promoCode"
                  ></norby-input>
                  <norby-number-input
                    label="Discount Percentage"
                    class="mb-2"
                    formControlName="discountPercentage"
                    placeholder="%"
                  ></norby-number-input>
                  <norby-icon-button
                    class="mt-9"
                    iconName="trash"
                    (click)="handleMarkCodeDeleted(control)"
                  ></norby-icon-button>
                </div>
              </div>
            </div>
          </div>
        </norby-collapsible>
      </norby-collapsible-group>
    </div>

    <!--KEEP for V3
  <button
    *ngIf="isTicketingEnabled"
    type="button"
    class="flex items-center max-h-10 rounded-sm px-5 py-2 border border-neutral-900 bg-white focus:bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 mb-3 justify-center hover:bg-neutral-700 hover:text-white"
    (click)="handleAddTicketClicked()"
  >
    <norby-icon name="plus"></norby-icon>Add ticket
  </button>-->

    <div class="flex flex-row justify-between mt-2">
      <h3>Donations</h3>
      <norby-switch-button
        [value]="isDonateUrlEnabled"
        (valueChange)="handleDonationsChange($event)"
      >
      </norby-switch-button>
    </div>

    <p class="text-neutral-600">
      Enable donations to prompt people to support via Paypal, Venmo, Cash App,
      or Patreon.
    </p>

    <norby-input
      *ngIf="isDonateUrlEnabled"
      label="Donate URL"
      placeholder="https://"
      infoTooltip="Users will see a donate button pointing to this URL."
      formControlName="donateUrl"
      [isUrl]="true"
    ></norby-input>
  </form>
</div>
