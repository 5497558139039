<nav
  class="flex flex-row items-center bg-white px-4 border-t border-neutral-300 border-opacity-90 h-14 leading-normal"
  [ngClass]="isMobile ? 'justify-between' : 'justify-end'"
>
  <button
    class="text-neutral-600 p-0 hover:text-neutral-800"
    *ngIf="isMobile"
    nz-dropdown
    [nzDropdownMenu]="menu"
    [nzDisabled]="isDisabled"
  >
    Jump to...
  </button>

  <div class="flex flex-row gap-2">
    <norby-icon-button
      (onButtonClicked)="handlePreviousButtonClicked()"
      buttonType="bordered"
      iconName="arrow_left"
      size="small"
      [isDisabled]="isDisabled || activeIndex <= firstStepIndex"
    ></norby-icon-button>

    <norby-icon-button
      (onButtonClicked)="handleAdvanceButtonClicked()"
      buttonType="bordered"
      iconName="arrow_right"
      iconPlacement="right"
      size="small"
      [isDisabled]="
        isDisabled || activeIndex >= steps.length + firstStepIndex - 1
      "
    ></norby-icon-button>
  </div>
</nav>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li
      nz-menu-item
      *ngFor="let step of steps; let i = index"
      (click)="handleMenuItemClicked(i + firstStepIndex)"
    >
      {{ step }}
    </li>
  </ul>
</nz-dropdown-menu>
