<form [formGroup]="formGroup">
  <label class="mb-2">Filter</label>
  <div class="mb-6">
    <norby-input
      label="Tag"
      placeholder="exampletag"
      helperText="Only show events with this tag."
      formControlName="tag"
      type="text"
    ></norby-input>
  </div>

  <norby-number-input
    label="Limit"
    helperText="Set a maximum number of events to show."
    formControlName="limit"
  ></norby-number-input>

  <hr class="my-6" />

  <label class="mb-2">Sort</label>
  <norby-switch-button
    formControlName="reverse"
    label="Reverse"
    helperText="Show past events instead of upcoming events."
  ></norby-switch-button>
</form>
