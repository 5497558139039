import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyTextAreaModule } from 'uikit';
import { NorbyEditHtmlBlockComponent } from '../components/templates/content-block-editors/norby-edit-html-block';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';

@NgModule({
  declarations: [NorbyEditHtmlBlockComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyTextAreaModule,
    NorbyCustomMessageTooltipModule
  ],
  exports: [NorbyEditHtmlBlockComponent]
})
export class NorbyEditHtmlBlockModule {}
