import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { isPlatformServer } from '@angular/common';

// 3rd party
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Lib
import { PermissionsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _router: Router,
    private _permissions: PermissionsService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    if (isPlatformServer(this._platform)) {
      return from([true]);
    }

    const slug = segments?.length ? segments[0]?.path : null;
    return this._permissions
      .userCanViewDashboard$(slug)
      .pipe(tap((canView) => !canView && this._router.navigate(['/'])));
  }
}
