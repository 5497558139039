import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';

import { BaseComponent, initialNoop, slideUpInDownOut } from 'uikit';
import { ContentClick, IAccountInfo, PageBlock, LandingPage } from 'models';

@Component({
  selector: 'lib-landing-page-view',
  templateUrl: './landing-page-view.component.html',
  styleUrls: ['./landing-page-view.component.less'],
  animations: [slideUpInDownOut, initialNoop]
})
export class LandingPageViewComponent
  extends BaseComponent
  implements AfterViewInit
{
  @Output() contentClick = new EventEmitter<ContentClick>();
  @Output() onContentBlocksUpdate = new EventEmitter<ElementRef[]>();

  @Input() page: LandingPage;
  @Input() accountInfo: IAccountInfo;
  @Input() showAsAbstract = false;
  @Input() applyTheme = true;

  @ViewChildren('block') blocks: QueryList<ElementRef>;

  ngAfterViewInit(): void {
    this.onContentBlocksUpdate.emit(this.blocks.toArray());
    this.blocks.changes.pipe(this.takeUntilDestroy).subscribe(() => {
      this.onContentBlocksUpdate.emit(this.blocks.toArray());
    });
  }

  handleClick(contentClick: ContentClick) {
    if (!contentClick?.content) return;
    this.contentClick.next(contentClick);
  }

  trackBy(idx: number, item: PageBlock) {
    return item.hash;
  }
}
