import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { UpcomingEventsComponent } from '../components/upcoming-events/upcoming-events.component';

// Zorrro
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { EventDropCardModule } from './event-drop-card.module';

@NgModule({
  declarations: [UpcomingEventsComponent],
  imports: [CommonModule, RouterModule, EventDropCardModule, NzSkeletonModule],
  exports: [UpcomingEventsComponent]
})
export class UpcomingEventsModule {}
