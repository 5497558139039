import { Injectable } from '@angular/core';

// 3rd party
import { Observable, map, switchMap } from 'rxjs';
import { plainToInstance } from 'class-transformer';

// Libs
import {
  ContactList,
  ContactListListSummaryResponse,
  ContactView,
  IQueryResult,
  PaginatedQuerySummary
} from 'models';

// App
import { IContactsListStoreService } from './contacts-list-store.service.interface';
import { ContactsService } from '../contacts/contacts.service';
import { AuthService } from '../auth';
import { ObservableDataService, QuerySummary } from '../observable-data';
import {
  CONTACT_LIST_SUMMARY,
  RealtimeServerSocketMessage,
  CONTACT_LIST_CREATED_IN_SLUG_TOPIC,
  CONTACT_LIST_UPDATED_IN_SLUG_TOPIC,
  CONTACT_LIST_DELETED_IN_SLUG_TOPIC,
  CONTACT_LIST_UPDATED_TOPIC,
  CONTACT_LIST_DELETED_TOPIC
} from '../socket';

@Injectable({
  providedIn: 'root'
})
export class ContactsListStoreService implements IContactsListStoreService {
  constructor(
    private _auth: AuthService,
    private _obs: ObservableDataService,
    private _contacts: ContactsService
  ) {}

  // getContactListSummary$ and getContactLists$ are unique to both the
  // user and the slug:
  //
  // - Contacts are unique to the slug
  // - Pinned and visibility status are unique to the user
  //
  // We therefore supply the current user's uid as the resourceId for
  // the topics handled here. The current slug is always included in
  // headers.

  // Query pinned tabs and their sizes in order to display the contacts
  // table tab header
  getContactListSummary$(): Observable<
    QuerySummary<ContactListListSummaryResponse>
  > {
    return this._auth.user$.pipe(
      map((user) => user?.uid),
      switchMap((resourceId) =>
        this._obs.document$<ContactListListSummaryResponse>({
          handlers: [
            {
              event: CONTACT_LIST_SUMMARY,
              payload: { resourceId },
              transformer: (event: RealtimeServerSocketMessage) =>
                plainToInstance(ContactListListSummaryResponse, event.data)
            }
          ],
          lookup: this._contacts.getContactListSummary
        })
      )
    );
  }

  // Query all lists available to the current user (including non-pinned)
  getContactLists$(): Observable<PaginatedQuerySummary<ContactList>> {
    return this._auth.user$.pipe(
      map((user) => user?.uid),
      switchMap((resourceId) =>
        this._obs.query$<ContactList>({
          handlers: [
            {
              event: CONTACT_LIST_CREATED_IN_SLUG_TOPIC,
              payload: { resourceId },
              transformer: (
                event: RealtimeServerSocketMessage,
                currentValue: any
              ) => {
                // TO DO: Implement, test, add types
                console.log(
                  'getContactLists$: CONTACT_LIST_CREATED_IN_SLUG_TOPIC'
                );
                console.log(event);
                return currentValue;
              }
            },
            {
              event: CONTACT_LIST_UPDATED_IN_SLUG_TOPIC,
              payload: { resourceId },
              transformer: (
                event: RealtimeServerSocketMessage,
                currentValue: any
              ) => {
                // TO DO: Implement, test, add types
                console.log(
                  'getContactLists$: CONTACT_LIST_UPDATED_IN_SLUG_TOPIC'
                );
                console.log(event);
                return currentValue;
              }
            },
            {
              event: CONTACT_LIST_DELETED_IN_SLUG_TOPIC,
              payload: { resourceId },
              transformer: (
                event: RealtimeServerSocketMessage,
                currentValue: any
              ) => {
                // TO DO: Implement, test, add types
                console.log(
                  'getContactLists$: CONTACT_LIST_DELETED_IN_SLUG_TOPIC'
                );
                console.log(event);
                return currentValue;
              }
            }
          ],
          lookup: this._contacts.getContactLists,
          transformer: (res: IQueryResult, currentValue: ContactList[]) => {
            const hasNextPage = res?.pageInfo?.hasNextPage;
            const items = res?.edges?.map((p) => p?.node) ?? [];

            return {
              items: [...(currentValue ?? []), ...items],
              cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
            };
          }
        })
      )
    );
  }

  // Query a specific contact list
  getContactList$(resourceId: string): Observable<QuerySummary<ContactView>> {
    return this._obs.document$<ContactView>({
      handlers: [
        {
          event: CONTACT_LIST_UPDATED_TOPIC,
          payload: { resourceId },
          transformer: (
            event: RealtimeServerSocketMessage,
            currentValue: any
          ) => {
            // TO DO: Implement, test, add types
            console.log('getContactList$: CONTACT_LIST_UPDATED_TOPIC');
            console.log(event);
            return currentValue;
          }
        },
        {
          event: CONTACT_LIST_DELETED_TOPIC,
          payload: { resourceId },
          transformer: (
            event: RealtimeServerSocketMessage,
            currentValue: any
          ) => {
            // TO DO: Implement, test, add types
            console.log('getContactList$: CONTACT_LIST_DELETED_TOPIC');
            console.log(event);
            return currentValue;
          }
        }
      ],
      lookup: this._contacts.getContactList,
      args: { listId: resourceId }
    });
  }
}
