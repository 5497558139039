import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Lib
import { VimeoPageBlock, UrlValidator } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-vimeo-block',
  templateUrl: './norby-edit-vimeo-block.component.html',
  styleUrls: ['./norby-edit-vimeo-block.component.less']
})
export class NorbyEditVimeoBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: VimeoPageBlock;

  @Output() onUpdatedBlock: EventEmitter<VimeoPageBlock> =
    new EventEmitter<VimeoPageBlock>();

  formGroup: UntypedFormGroup;

  readonly LABEL: string = 'URL';
  readonly PLACEHOLDER: string = 'https://vimeo.com/512776212';
  readonly HELPER_TEXT: string = 'Publish to view the rendered Vimeo video.';
  readonly TOOLTIP: string = 'The link to your Vimeo video';

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        url: this.selectedBlock?.url,
        width: null,
        height: null
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      url: [this.selectedBlock?.url, [Validators.required, UrlValidator]],
      width: [null],
      height: [null]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(VimeoPageBlock, {
            ...this.selectedBlock,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
