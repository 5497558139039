import { Component, Input } from '@angular/core';
import { IconService, rootHelpCircle } from 'uikit';

@Component({
  selector: 'lib-statistic-view',
  templateUrl: './statistic-view.component.html',
  styleUrls: ['./statistic-view.component.less']
})
export class StatisticViewComponent {
  @Input() statIcon: string = '';
  @Input() label: string = '';
  @Input() helperText: string = '';
  @Input() stat: number = 0;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootHelpCircle]);
  }
}
