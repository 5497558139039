import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyEditSocialIconsBlockComponent } from '../components';
import { NorbyEditSocialIconsModule } from './norby-edit-social-icons.module';

@NgModule({
  declarations: [NorbyEditSocialIconsBlockComponent],
  imports: [CommonModule, NorbyEditSocialIconsModule],
  exports: [NorbyEditSocialIconsBlockComponent]
})
export class NorbyEditSocialIconsBlockModule {}
