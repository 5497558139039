import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { DropDetailViewComponent } from '../views/drop-detail-view';
import { RsvpConfirmationViewModule } from './rsvp-confirmation-view.module';
import { LiveThemedDirectiveModule } from './live-themed-directive.module';
import { AlertModule } from './alert.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';
import { DropMainButtonViewModule } from './drop-main-button-view.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { IconModule } from './icon.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { TagModule } from './tag.module';
import { DropFormPromptsViewModule } from './drop-form-prompts-view.module';
import { TooltipModule } from './tooltip.module';
import { RegisterableDetailViewModule } from './registerable-detail-view.module';

@NgModule({
  declarations: [DropDetailViewComponent],
  imports: [
    AlertModule,
    CardModule,
    CarouselModule,
    CommonModule,
    DropFormPromptsViewModule,
    DropMainButtonViewModule,
    EventDropShareButtonViewModule,
    IconModule,
    LiveThemedDirectiveModule,
    RegisterableDetailViewModule,
    RouterModule,
    RsvpConfirmationViewModule,
    SafeHtmlPipeModule,
    SkeletonModule,
    SoftImageViewModule,
    TagModule,
    TooltipModule
  ],
  exports: [DropDetailViewComponent]
})
export class DropDetailViewModule {}
