import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Theme } from 'models';

@Component({
  selector: 'lib-theme-card-view',
  templateUrl: './theme-card-view.component.html',
  styleUrls: ['./theme-card-view.component.less']
})
export class ThemeCardViewComponent {
  @Input() theme: Theme;
  @Input() isActive = false;
  @Output() onClick = new EventEmitter<Theme>();

  get themeImage(): string {
    return this.theme?.responsiveImage?.large?.url
      ? `url(${this.theme.responsiveImage.large.url})`
      : '';
  }

  handleOnClick(): void {
    this.onClick.emit(this.theme);
  }
}
