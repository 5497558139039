import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { MyNorbyViewComponent } from '../views';
import { LandingPagePreviewModule } from './landing-page-preview.module';
import { IconModule } from 'uikit';
import {
  TrackProductAnalyticsDirectiveModule,
  PhonePipeModule
} from 'customer-uikit';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [MyNorbyViewComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    TrackProductAnalyticsDirectiveModule,

    NzToolTipModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,

    IconModule,
    LandingPagePreviewModule,
    PhonePipeModule
  ],
  exports: [MyNorbyViewComponent]
})
export class MyNorbyViewViewModule {}
