<norby-filters-build-view
  [contentFilterOptions]="contentFilterOptions"
  [includedContactOptions]="includedContactOptions"
  [contactListFilterOptions]="contactListFilterOptions"
  [excludedContactOptions]="excludedContactOptions"
  [engagementFilterOptions]="engagementFilterOptions"
  [landingPageFilterOptions]="landingPageFilterOptions"
  [tagText]="tagText"
  [isDisabled]="isDisabled"
  [segment]="segment"
  [deliveryType]="deliveryType"
  [isSearchingFilter]="isSearchingFilter"
  [isSearchingIncludeContact]="isSearchingIncludeContact"
  [isSearchingExcludeContact]="isSearchingExcludeContact"
  [isSendBuilder]="isSendBuilder"
  (onSearchInputChange)="handleSearchInput($event)"
  (onIncludedContactInputChange)="handleIncludedContactInput($event)"
  (onExcludedContactInputChange)="handleExcludedContactInput($event)"
  (onUpdateFilterGroup)="handleUpdateFilterGroup($event)"
  (onDeleteFilter)="handleDeleteFilter($event)"
  (onAddContact)="handleAddContact($event)"
  (onExcludeContact)="handleExcludeContact($event)"
  (onRemoveIncludedContact)="handleRemoveIncludedContact($event)"
  (onRemoveExcludedContact)="handleRemoveExcludedContact($event)"
></norby-filters-build-view>
