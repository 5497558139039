import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageBlock } from 'models';

@Component({
  selector: 'norby-content-item',
  templateUrl: './norby-content-item.component.html',
  styleUrls: ['./norby-content-item.component.less']
})
export class NorbyContentItemComponent {
  @Input() block: PageBlock;
  @Input() isActive = false;
  @Output() itemClick: EventEmitter<PageBlock> = new EventEmitter<PageBlock>();

  constructor() {}

  get title(): string {
    return this.block?.headline ?? 'New Block';
  }

  get avatarIcon(): string {
    return this.block?.icon ?? '';
  }

  get blockChanged(): boolean {
    return false; // TODO: to be implemented
  }

  handleItemClick() {
    this.itemClick.emit(this.block);
  }
}
