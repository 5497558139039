import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NorbyInputModule,
  NorbyNumberInputModule,
  NorbySwitchButtonModule
} from 'uikit';
import { NorbyEditEventsListBlockComponent } from '../components/templates/content-block-editors/norby-edit-events-list-block';

@NgModule({
  declarations: [NorbyEditEventsListBlockComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyInputModule,
    NorbyNumberInputModule,
    NorbySwitchButtonModule
  ],
  exports: [NorbyEditEventsListBlockComponent]
})
export class NorbyEditEventsListBlockModule {}
