<form [formGroup]="formGroup">
  <norby-alert
    *ngIf="shouldDisplayUrlBreakingWarning"
    type="alert"
    title="Warning"
  >
    Changing the URL for this page will break any links that point to it. If you
    change the label, you'll need to update any link blocks or external links
    that point to this page.
  </norby-alert>

  <norby-formcontrol-label
    label="Label"
    required="true"
  ></norby-formcontrol-label>

  <div rootFormItem>
    <div rootInputGroup>
      <norby-input
        class="grouped input"
        [value]="rootUrl + '/'"
        [isReadOnly]="true"
        [isJoinedRight]="true"
      ></norby-input>

      <norby-input
        class="grouped input"
        placeholder="yourpage"
        formControlName="label"
        [isRequired]="false"
        [isJoinedLeft]="true"
      ></norby-input>
    </div>
  </div>

  <div class="-mt-3 mb-4 text-xs text-neutral-700">
    Viewable at
    <strong>
      {{ rootUrl }}/
      <span *ngIf="landingPage?.label?.length > 0">{{
        landingPage?.label
      }}</span>
      <em *ngIf="landingPage?.label?.length === 0">yourpage</em>
    </strong>
    <span *ngIf="landingPage?.isDefault">
      and <strong>{{ rootUrl }}</strong></span
    >
  </div>

  <div class="mb-4">
    <norby-input
      label="Title"
      placeholder="e.g. 'My latest press' or 'My upcoming events'"
      formControlName="title"
      [isRequired]="true"
    ></norby-input>
  </div>

  <div class="mb-4">
    <norby-input
      label="Description"
      placeholder="All the latest from me in one place"
      formControlName="description"
    ></norby-input>
  </div>

  <div class="mb-4">
    <norby-switch-button
      *ngIf="!isDefaultPage"
      formControlName="isDefault"
      label="Make this the default page"
    ></norby-switch-button>
  </div>

  <norby-file-upload
    label="Preview image"
    [images]="previewImage"
    (onUpdatedImages)="handlePreviewImageUpdated($event)"
  >
  </norby-file-upload>

  <div class="my-5">
    <div class="rounded bg-azure-800 p-5 text-neutral-800">
      The title, description, and preview image will be used for SEO, in link
      previews, and when the page is shared on social media.
    </div>
  </div>
</form>
