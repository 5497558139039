import { ContentType } from 'models';

export type ThemeCustomizerSectionType =
  | 'background'
  | 'fonts'
  | 'text'
  | 'links'
  | 'cards'
  | 'buttons'
  | 'tooltips'
  | 'dropdowns'
  | 'snackbars'
  | 'textinputs'
  | 'selects'
  | 'checkboxes'
  | 'metadata';

export type ThemeCustomizerSection = {
  id: ThemeCustomizerSectionType;
  title: string;
  description?: string;
  contentType?: ContentType;
};

export const THEME_CUSTOMIZER_BACKGROUND: ThemeCustomizerSection = {
  id: 'background',
  title: 'Background'
};

export const THEME_CUSTOMIZER_FONTS: ThemeCustomizerSection = {
  id: 'fonts',
  title: 'Fonts',
  description: 'Choose from supported Google Fonts or import your own.'
};

export const THEME_CUSTOMIZER_TEXT: ThemeCustomizerSection = {
  id: 'text',
  title: 'Text'
};

export const THEME_CUSTOMIZER_LINKS: ThemeCustomizerSection = {
  id: 'links',
  title: 'Links'
};

export const THEME_CUSTOMIZER_CARDS: ThemeCustomizerSection = {
  id: 'cards',
  title: 'Cards',
  description:
    'A card is a foreground block that displays information such as images and text in a flexible container.'
};

export const THEME_CUSTOMIZER_LINK_CARDS: ThemeCustomizerSection = {
  id: 'cards',
  title: 'Cards',
  contentType: 'link'
};

export const THEME_CUSTOMIZER_BUTTONS: ThemeCustomizerSection = {
  id: 'buttons',
  title: 'Buttons',
  description:
    'Primary buttons are used for CTAs like signup, register, and purchase buttons. Secondary buttons are used for other actions like sharing and adding to calendar.'
};

export const THEME_CUSTOMIZER_TOOLTIPS: ThemeCustomizerSection = {
  id: 'tooltips',
  title: 'Tooltips',
  description:
    'Tooltips are small messages that appear when a user hovers or clicks on certain elements to provide additional information or context. They usually appear as a box or bubble next to the element and disappear when the user moves the mouse.'
};

export const THEME_CUSTOMIZER_DROPDOWNS: ThemeCustomizerSection = {
  id: 'dropdowns',
  title: 'Dropdowns',
  description:
    'A dropdown displays a list of options when clicked, tapped, or hovered and is used for actions like sharing or adding to a calendar.'
};

export const THEME_CUSTOMIZER_SNACKBARS: ThemeCustomizerSection = {
  id: 'snackbars',
  title: 'Snackbars',
  description:
    'A snack bar is a brief message that appears at the top of the screen to provide feedback or deliver a message about an action the user just took.'
};

export const THEME_CUSTOMIZER_TEXT_INPUTS: ThemeCustomizerSection = {
  id: 'textinputs',
  title: 'Text Inputs',
  description:
    'A text input is a field for entering and editing text and is used for actions like filling out forms and capturing email addresses.'
};

export const THEME_CUSTOMIZER_SELECTS: ThemeCustomizerSection = {
  id: 'selects',
  title: 'Selects',
  description:
    'A select is an element that allows you to choose from a list of options and is used in custom fields for signups and events.'
};

export const THEME_CUSTOMIZER_CHECKBOXES: ThemeCustomizerSection = {
  id: 'checkboxes',
  title: 'Checkboxes'
};

export const THEME_CUSTOMIZER_METADATA: ThemeCustomizerSection = {
  id: 'metadata',
  title: 'Metadata',
  description:
    'Your favicon is the small icon that appears in your browser tab or bookmarks list to represent your website.'
};

export type ThemeCustomizerSections = {
  [id in ThemeCustomizerSectionType]?: ThemeCustomizerSection;
};

export const THEME_CUSTOMIZER_DEFAULT_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_BACKGROUND.id]: THEME_CUSTOMIZER_BACKGROUND,
  [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
  [THEME_CUSTOMIZER_TEXT.id]: THEME_CUSTOMIZER_TEXT,
  [THEME_CUSTOMIZER_LINKS.id]: THEME_CUSTOMIZER_LINKS,
  [THEME_CUSTOMIZER_CARDS.id]: THEME_CUSTOMIZER_CARDS,
  [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS,
  [THEME_CUSTOMIZER_TOOLTIPS.id]: THEME_CUSTOMIZER_TOOLTIPS,
  [THEME_CUSTOMIZER_DROPDOWNS.id]: THEME_CUSTOMIZER_DROPDOWNS,
  [THEME_CUSTOMIZER_SNACKBARS.id]: THEME_CUSTOMIZER_SNACKBARS,
  [THEME_CUSTOMIZER_TEXT_INPUTS.id]: THEME_CUSTOMIZER_TEXT_INPUTS,
  [THEME_CUSTOMIZER_SELECTS.id]: THEME_CUSTOMIZER_SELECTS,
  [THEME_CUSTOMIZER_CHECKBOXES.id]: THEME_CUSTOMIZER_CHECKBOXES,
  [THEME_CUSTOMIZER_METADATA.id]: THEME_CUSTOMIZER_METADATA
};

export const THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT: ThemeCustomizerSection = {
  id: 'cards',
  title: 'Card'
};

export const THEME_CUSTOMIZER_CONTENT_BLOCK_WITH_BUTTON_SECTIONS: ThemeCustomizerSections =
  {
    [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
    [THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT.id]:
      THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT,
    [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS
  };

export const THEME_CUSTOMIZER_CONTENT_BLOCK_WITHOUT_BUTTON_SECTIONS: ThemeCustomizerSections =
  {
    [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
    [THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT.id]:
      THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT
  };

export const THEME_CUSTOMIZER_LINK_BLOCK_VARIANT: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
  [THEME_CUSTOMIZER_LINK_CARDS.id]: THEME_CUSTOMIZER_LINK_CARDS
};

export const THEME_CUSTOMIZER_CARDS_IMAGE_BLOCK_VARIANT: ThemeCustomizerSection =
  {
    id: 'cards',
    title: 'Background'
  };

export const THEME_CUSTOMIZER_IMAGE_BLOCK_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_CARDS_IMAGE_BLOCK_VARIANT.id]:
    THEME_CUSTOMIZER_CARDS_IMAGE_BLOCK_VARIANT
};

export const THEME_CUSTOMIZER_NEWSLETTER_SIGNUP_SECTIONS: ThemeCustomizerSections =
  {
    [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
    [THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT.id]:
      THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT,
    [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS,
    [THEME_CUSTOMIZER_TEXT_INPUTS.id]: THEME_CUSTOMIZER_TEXT_INPUTS
  };

export const THEME_CUSTOMIZER_PROFILE_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
  [THEME_CUSTOMIZER_TEXT.id]: THEME_CUSTOMIZER_TEXT,
  [THEME_CUSTOMIZER_LINKS.id]: THEME_CUSTOMIZER_LINKS
};

export const THEME_CUSTOMIZER_RICH_TEXT_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
  [THEME_CUSTOMIZER_TEXT.id]: THEME_CUSTOMIZER_TEXT,
  [THEME_CUSTOMIZER_LINKS.id]: THEME_CUSTOMIZER_LINKS
};

export const THEME_CUSTOMIZER_EVENT_DETAIL_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_BACKGROUND.id]: THEME_CUSTOMIZER_BACKGROUND,
  [THEME_CUSTOMIZER_CARDS.id]: THEME_CUSTOMIZER_CARDS,
  [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
  [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS,
  [THEME_CUSTOMIZER_DROPDOWNS.id]: THEME_CUSTOMIZER_DROPDOWNS,
  [THEME_CUSTOMIZER_SNACKBARS.id]: THEME_CUSTOMIZER_SNACKBARS,
  [THEME_CUSTOMIZER_TEXT_INPUTS.id]: THEME_CUSTOMIZER_TEXT_INPUTS,
  [THEME_CUSTOMIZER_SELECTS.id]: THEME_CUSTOMIZER_SELECTS,
  [THEME_CUSTOMIZER_CHECKBOXES.id]: THEME_CUSTOMIZER_CHECKBOXES,
  [THEME_CUSTOMIZER_LINKS.id]: THEME_CUSTOMIZER_LINKS
};

export const THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS: ThemeCustomizerSections =
  {
    [THEME_CUSTOMIZER_BACKGROUND.id]: THEME_CUSTOMIZER_BACKGROUND,
    [THEME_CUSTOMIZER_CARDS.id]: THEME_CUSTOMIZER_CARDS,
    [THEME_CUSTOMIZER_FONTS.id]: THEME_CUSTOMIZER_FONTS,
    [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS,
    [THEME_CUSTOMIZER_DROPDOWNS.id]: THEME_CUSTOMIZER_DROPDOWNS,
    [THEME_CUSTOMIZER_SNACKBARS.id]: THEME_CUSTOMIZER_SNACKBARS,
    [THEME_CUSTOMIZER_TEXT_INPUTS.id]: THEME_CUSTOMIZER_TEXT_INPUTS,
    [THEME_CUSTOMIZER_SELECTS.id]: THEME_CUSTOMIZER_SELECTS,
    [THEME_CUSTOMIZER_CHECKBOXES.id]: THEME_CUSTOMIZER_CHECKBOXES,
    [THEME_CUSTOMIZER_LINKS.id]: THEME_CUSTOMIZER_LINKS
  };

export const THEME_CUSTOMIZER_EMAIL_SECTIONS: ThemeCustomizerSections = {
  [THEME_CUSTOMIZER_BACKGROUND.id]: THEME_CUSTOMIZER_BACKGROUND,
  [THEME_CUSTOMIZER_TEXT.id]: THEME_CUSTOMIZER_TEXT,
  [THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT.id]:
    THEME_CUSTOMIZER_CARDS_SINGULAR_VARIANT,
  [THEME_CUSTOMIZER_BUTTONS.id]: THEME_CUSTOMIZER_BUTTONS
};
