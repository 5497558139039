import { BehaviorSubject, defer, finalize, Observable } from 'rxjs';

import {
  IQueryResult,
  PaginatedQueryFilters,
  PaginatedQuerySummary
} from 'models';
import { hashStrings } from './utils';
import {
  PaginatedQueryTransformerPayload,
  QueryInput,
  QuerySummary,
  RealtimeSocketEventHandler,
  ObserversChangedCallback,
  PaginatedQueryInput
} from './types';

// A special BehaviorSubject wrapped with callbacks that
// fire when subscribers are added and removed
export class NotifierSubject<T> {
  stream: Observable<QuerySummary<T>>;
  protected _innerStream = new BehaviorSubject<QuerySummary<T>>(null);
  private readonly _key: string;

  constructor(protected readonly _input: QueryInput) {
    this._key = NotifierSubject.constructQueryKey(_input);
  }

  // Hash key for a given query
  static constructQueryKey(input: QueryInput) {
    const { handlers, args } = input;
    const events = handlers.map((h) => `${h.event}_${h?.payload?.resourceId}`);
    const argsArray = Object.entries(args ?? {}).map((arg) => arg.join('_'));
    return hashStrings(events, argsArray);
  }

  // Attach subscriber notification callbacks
  // No op if already enabled
  enable(
    onObserverAdded?: ObserversChangedCallback,
    onObserverRemoved?: ObserversChangedCallback
  ) {
    let refCount = this.numberOfObservers;
    this.stream =
      this.stream ||
      defer(() => {
        onObserverAdded?.(refCount, ++refCount);
        return this._innerStream;
      }).pipe(finalize<T>(() => onObserverRemoved?.(refCount, --refCount)));
  }

  // Exposing `next` allows subscribers to mutate the stream with
  // synchronized changes broadcast to all other subscribers
  //
  // This is used primarily for optimistically rendering updated documents
  // When confirmation of the changes are received via the event handlers,
  // the optimistic document can be replaced with the real one
  next(data?: T) {
    this._innerStream.next({
      data,
      next: (val) => this.next(val)
    });
  }

  complete() {
    this._innerStream.complete();
  }

  get key(): string {
    return this._key;
  }

  get handlers(): RealtimeSocketEventHandler[] {
    return this._input.handlers;
  }

  get lookup(): (args?: any) => Promise<any> {
    return this._input.lookup;
  }

  get args(): any {
    return this._input.args;
  }

  get isPublic(): boolean {
    return this._input.isPublic;
  }

  get numberOfObservers(): number {
    return this._innerStream.observers?.length ?? 0;
  }

  get currentValue(): T | T[] {
    return this._innerStream.value?.data;
  }

  // Generates the current fetch function for a given document lookup
  get fetch(): () => Promise<void> {
    return async () => {
      return this.lookup
        ? this.lookup(this.args)
            .then((data: T) => this.next(data))
            .catch((e) => console.log('Error fetching: ' + e))
        : this.next(null);
    };
  }
}

export class PaginatedQueryNotifierSubject<T> extends NotifierSubject<T> {
  stream: Observable<PaginatedQuerySummary<T>>;
  protected _innerStream = new BehaviorSubject<PaginatedQuerySummary<T>>(null);
  maxCursor: string;

  constructor(protected readonly _input: PaginatedQueryInput) {
    super(_input);
  }

  next(items) {
    this._innerStream.next({
      items,
      fetch: this.maxCursor ? this.fetch : null,
      next: (val) => this.next(val)
    });
  }

  get currentValue(): T | T[] {
    return this._innerStream.value?.items;
  }

  get lookup(): (args: PaginatedQueryFilters) => Promise<IQueryResult> {
    return this._input.lookup;
  }

  get transformer(): (
    result: IQueryResult,
    currentValue: T | T[]
  ) => PaginatedQueryTransformerPayload<T> {
    return this._input.transformer;
  }

  get args(): PaginatedQueryFilters {
    return this._input.args;
  }

  // Generates the current fetch function for a given paginated query
  get fetch(): () => Promise<void> {
    return async () => {
      // If there's no maxCursor, we're loading the first page so the
      // maxCursor param will be left out and the currentValue will not
      // be supplied to the transformer function
      const isInitialPage = !this.maxCursor;
      const adjustedArgs = {
        ...this.args,
        ...(!isInitialPage && { after: this.maxCursor })
      };
      return this.lookup
        ? this.lookup(adjustedArgs)
            .then((response) =>
              this.transformer(
                response,
                isInitialPage ? null : this.currentValue
              )
            )
            .then((result) => {
              this.maxCursor = result?.cursor;
              this.next(result?.items);
            })
            .catch((e) => console.log('Error fetching: ' + e))
        : this.next([]);
    };
  }
}
