import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';
import {
  IUploadService,
  MMS_ATTACHMENT_COMBINED_BYTE_LIMIT,
  MMS_ATTACHMENT_COMBINED_MEGABYTE_LIMIT,
  MMS_ATTACHMENT_INDIVIDUAL_KILOBYTE_LIMIT,
  MMS_ATTACHMENT_LIMIT,
  uuidv4
} from 'models';
import { LoggerService } from '../logger';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({
  providedIn: 'root'
})
export class FirestoreFileUploadService implements IUploadService {
  constructor(
    private _logger: LoggerService,
    private _storage: Storage
  ) {}

  private _getNameForFile(file: File): string {
    const extension = file.name?.split('.')?.pop();
    return `${uuidv4()}.${extension}`;
  }

  async uploadFile(file: File): Promise<string> {
    if (!file) {
      return '';
    }

    const filename = this._getNameForFile(file);
    const storageRef = ref(this._storage, `images/${filename}`);

    try {
      const snapshot = await uploadBytes(storageRef, file as any);
      return await getDownloadURL(snapshot.ref);
    } catch (e) {
      this._logger.logError(e, {
        tags: {
          feature: 'FirestoreFileUploadService.uploadFile'
        }
      });
    }
  }

  fileSizeValidation(file: NzUploadFile, attachments: NzUploadFile[]) {
    // throw error if individual file size is too big
    if (file.size / 1000 > MMS_ATTACHMENT_INDIVIDUAL_KILOBYTE_LIMIT) {
      return `Error uploading ${file.name}. File size must be less than ${MMS_ATTACHMENT_INDIVIDUAL_KILOBYTE_LIMIT}KB.`;
    }

    // prevent uploading attachments beyond the combined limit or beyond 10 attachments
    if (
      this._totalAttachmentFilesSize(attachments) + file.size >
        MMS_ATTACHMENT_COMBINED_BYTE_LIMIT ||
      attachments.length > MMS_ATTACHMENT_LIMIT
    ) {
      return `Total attachment size must be less than ${MMS_ATTACHMENT_COMBINED_MEGABYTE_LIMIT} MBs with a limit of ${MMS_ATTACHMENT_LIMIT} attachments.`;
    }
  }

  private _totalAttachmentFilesSize(fileList: NzUploadFile[]): number {
    return fileList.reduce((acc, curr) => {
      return acc + (curr.size ?? 0);
    }, 0);
  }
}
