<form [formGroup]="formGroup">
  <div class="mb-2">
    <norby-switch-button
      formControlName="showTitle"
      rightLabel="Show Title"
    ></norby-switch-button>
  </div>

  <div class="mb-2">
    <norby-switch-button
      formControlName="showPronouns"
      rightLabel="Show Pronouns"
    ></norby-switch-button>
  </div>

  <div class="mb-2">
    <norby-switch-button
      formControlName="showBio"
      rightLabel="Show Bio"
    ></norby-switch-button>
  </div>

  <div>
    <norby-switch-button
      formControlName="showLocation"
      rightLabel="Show Location"
    ></norby-switch-button>
  </div>
</form>
