<div class="sends-summary-detail-wrapper">
  <div class="flex-1">
    <root-spin [spinning]="isLoading">
      <div class="sends-summary-detail-content">
        <h1>Summary</h1>

        <lib-summary-card-view
          title="Details"
          [items]="detailsItems"
          [additionalItems]="sendTestTpl"
        >
          <norby-button
            text="Edit"
            *ngIf="!isSent"
            buttonType="minimal"
            (buttonClicked)="handleEditClicked(0)"
          ></norby-button>
          <ng-template #sendTestTpl>
            <norby-button
              (buttonClicked)="handleSendTestMessage()"
              [isDisabled]="send.hasBeenSent"
              buttonType="secondary"
              text="Send a test..."
            ></norby-button>
          </ng-template>
        </lib-summary-card-view>

        <lib-summary-card-view
          title="Recipients"
          [items]="recipientsItems"
          [additionalItems]="recipientsAdditionalTpl"
        >
          <norby-button
            *ngIf="!isSent"
            text="Edit"
            buttonType="minimal"
            (buttonClicked)="handleEditClicked(isSms ? 1 : 2)"
          ></norby-button>
          <ng-template #recipientsAdditionalTpl>
            <div class="py-3">
              <norby-button
                (buttonClicked)="handleViewPreviewList()"
                buttonType="secondary"
                text="Preview list"
              ></norby-button>
            </div>
            <div class="mt-3 text-xs text-neutral-600">
              This is an estimated recipient count. This message will not be
              sent to any contacts who are unsubscribed from this type of
              communication.
            </div>
            <div
              *ngIf="segmentPreviewResponse?.segmentExceedsPreviewLimit"
              class="mt-3 text-xs text-mustard"
            >
              List previews are limited to 5000 contacts. The actual number of
              recipients may be higher.
            </div>
          </ng-template>
        </lib-summary-card-view>

        <div class="w-full border border-neutral-200 rounded-sm mb-5 p-4">
          <div class="flex items-center justify-between">
            <h3 class="m-0">Publish as a webpage</h3>
            <norby-switch-button
              [value]="isWebHosted"
              [isDisabled]="send.hasBeenSent"
              (valueChange)="handleToggleWebHosted()"
            ></norby-switch-button>
          </div>
          <div class="mt-2 text-neutral-600">
            Publish your send on a hosted webpage. Anyone with the link will be
            able to view your message in a browser.
          </div>
        </div>
      </div>
    </root-spin>
  </div>
</div>
