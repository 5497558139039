import { Component, Input } from '@angular/core';
import { INotificationDTO, MmsAttachment } from 'models';

@Component({
  selector: 'norby-notification-detail-view',
  templateUrl: './notification-detail-view.component.html',
  styleUrls: ['./notification-detail-view.component.less']
})
export class NotificationDetailViewComponent {
  @Input() notification: INotificationDTO;
  @Input() isEmail: boolean;

  constructor() {}

  attachmentTrackBy(idx: number, item: MmsAttachment) {
    return item?.source;
  }

  trackBy(idx: number, item) {
    return item.hash;
  }
}
