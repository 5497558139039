<div class="event-container">
  <form [formGroup]="formGroup" class="event-wrapper">
    <div>
      <h1>Registration</h1>

      <p class="text-neutral-600">Customize your registration flow.</p>
    </div>

    <div class="mb-6">
      <norby-formcontrol-label
        label="Contact information"
      ></norby-formcontrol-label>

      <p class="text-neutral-600">
        Make sure to require email addresses or phone numbers if you want to
        send text messages or emails to attendees.
      </p>

      <div class="flex flex-row gap-2">
        <norby-checkbox
          class="w-1/3"
          label="Name"
          formControlName="collectName"
          [framed]="true"
        ></norby-checkbox>
        <norby-checkbox
          class="w-1/3"
          label="Email"
          formControlName="collectEmail"
          [framed]="true"
        ></norby-checkbox>
        <norby-checkbox
          class="w-1/3"
          label="Phone"
          formControlName="collectPhone"
          [framed]="true"
        ></norby-checkbox>
      </div>
    </div>

    <div class="mb-2">
      <norby-formcontrol-label label="Custom fields"></norby-formcontrol-label>

      <p class="text-neutral-600">
        Add custom form fields to collect additional information from attendees.
      </p>

      <div formGroupName="prompts">
        <div
          *ngIf="promptsControls?.length"
          cdkDropList
          cdkDropListOrientation="vertical"
          [cdkDropListData]="promptsControls"
          (cdkDropListDropped)="handleDrop($event)"
        >
          <div
            class="item flex flex-row py-2"
            *ngFor="
              let control of promptsControls;
              let i = index;
              trackBy: trackBy
            "
            cdkDrag
            (cdkDragStarted)="handleDragStarted($event)"
          >
            <div
              class="w-full flex flex-col"
              [formGroupName]="control.controlInstance"
            >
              <p>{{ getCustomFieldTitle(control.controlInstance) }}</p>
              <div class="flex flex-row gap-4 flex-wrap">
                <div class="flex flex-col">
                  <norby-formcontrol-label
                    label="Required"
                  ></norby-formcontrol-label>
                  <norby-switch-button
                    class="mt-4"
                    formControlName="required"
                  ></norby-switch-button>
                </div>
                <norby-input
                  label="Title"
                  [isRequired]="true"
                  placeholder="Ask a question..."
                  formControlName="prompt"
                ></norby-input>
                <norby-select
                  class="min-w-28"
                  label="Type"
                  formControlName="type"
                >
                  <option value="text">Text</option>
                  <option value="select">Select</option>
                  <option value="checkbox">Checkbox</option>
                </norby-select>
                <norby-icon-button
                  class="mt-9"
                  iconName="trash"
                  (click)="handleRemovePrompt(control)"
                ></norby-icon-button>
                <norby-icon-button
                  class="mt-9"
                  cdkDragHandle
                  iconName="move"
                ></norby-icon-button>
              </div>

              <div
                *ngIf="
                  promptsForm.get(control.controlInstance)?.get('type')
                    ?.value !== 'text'
                "
                class="add-registration-option"
              >
                <norby-formcontrol-label
                  label="Options"
                ></norby-formcontrol-label>
                <ul>
                  <li *ngFor="let option of control.options" class="flex">
                    <button
                      (click)="handleRemoveOptionFromPrompt(option, control)"
                    >
                      <root-icon name="minus_circle"></root-icon>
                    </button>
                    <span>{{ option }}</span>
                  </li>
                  <li>
                    <nz-input-group [nzSuffix]="urlSuffixButton">
                      <input
                        type="text"
                        nz-input
                        placeholder="Add an option..."
                        formControlName="addOptionText"
                        (keydown.enter)="
                          handleAddOptionToPrompt($event, control, i)
                        "
                        #optionInput
                      />
                    </nz-input-group>

                    <ng-template #urlSuffixButton>
                      <button
                        nz-button
                        nzBlock
                        nzSize="small"
                        nzType="text"
                        (click)="handleAddOptionToPrompt($event, control, i)"
                      >
                        <root-icon size="small" name="save"></root-icon>
                        Save
                      </button>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="item-placeholder-transition bg-mustard-200 rounded-sm"
              [style.height.px]="draggingPlaceholderHeight"
              *cdkDragPlaceholder
            ></div>
          </div>
        </div>

        <norby-custom-message-tooltip
          action="use custom fields"
          [trackProductAnalytics]="UPSELL_EVENT"
          [properties]="UPSELL_CUSTOM_FIELDS_PROPERTIES"
          (onMessageUpdated)="button.disabled = !!$event"
        >
          <button
            #button
            type="button"
            class="ant-btn-animation flex items-center w-full rounded-sm px-5 py-2 border border-neutral-900 bg-white focus:bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 mb-3 justify-center hover:bg-neutral-700 hover:text-white"
            (click)="handleAddPrompt()"
          >
            <norby-icon name="plus"></norby-icon>Add a custom field
          </button>
        </norby-custom-message-tooltip>
      </div>
    </div>

    <!-- NOT for v2  - keep it for later use
  <div class="mt-2">
    <norby-formcontrol-label
      label="Allow avatar upload?"
    ></norby-formcontrol-label>

    <p>
      Select if you would like to collect avatars from the people who register.
    </p>

    <norby-switch-button> </norby-switch-button>
  </div>-->

    <div class="mb-4">
      <norby-collapsible-group>
        <norby-collapsible title="Add tags">
          <p class="text-neutral-600">
            Automatically apply tags to everyone who registers.
          </p>

          <div class="flex items-center flex-wrap">
            <div *ngFor="let tag of tags" class="mr-2 mb-2">
              <root-tag
                [closeable]="true"
                (handleClose)="handleTagDelete(tag)"
                >{{ tag }}</root-tag
              >
            </div>
          </div>

          <div class="input-container mt-2" *ngIf="tagInputVisible">
            <div rootFormItem>
              <div rootNestedInputGroup>
                <norby-input
                  formControlName="tag"
                  placeholder="Tag name"
                  [isNestedButton]="true"
                  (keydown.enter)="handleTagInputConfirm()"
                ></norby-input>

                <root-button
                  text="Save"
                  formSubmit="button"
                  (handleClick)="handleTagInputConfirm()"
                >
                </root-button>
              </div>
            </div>
          </div>

          <button
            *ngIf="!tagInputVisible"
            type="button"
            class="mt-2 ant-btn-animation flex items-center w-full rounded-sm px-5 py-2 border border-neutral-900 bg-white focus:bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 justify-center hover:bg-neutral-700 hover:text-white"
            (click)="handleShowTagInput()"
          >
            <norby-icon name="plus"></norby-icon>Add a tag
          </button>
        </norby-collapsible>

        <norby-collapsible title="Set a capacity limit">
          <p>Stop accepting new registrations after a limit is reached.</p>
          <norby-switch-button
            [value]="showRegistrationCapacity"
            (valueChange)="handleShowRegistrationCapacityChange($event)"
          >
          </norby-switch-button>

          <div *ngIf="showRegistrationCapacity" class="mt-2">
            <norby-custom-message-tooltip
              action="add more attendees"
              [trackProductAnalytics]="UPSELL_EVENT"
              [properties]="UPSELL_EVENTS_CAPACITY_FIELDS_PROPERTIES"
              (onMessageUpdated)="capacityInput.disabled = !!$event"
            >
              <norby-number-input
                #capacityInput
                class="mb-2"
                formControlName="capacity"
                placeholder="Enter the maximum number of attendees"
              ></norby-number-input>
            </norby-custom-message-tooltip>
            <norby-switch-button
              label="Display capacity on page"
              formControlName="displayLimit"
              [isDisabled]="shouldDisableDisplayCapacityToggle"
            >
            </norby-switch-button>
          </div>
        </norby-collapsible>

        <norby-collapsible title="Set a registration deadline">
          <p>Close event registration at a specific date and time.</p>

          <norby-switch-button
            [value]="showRegistrationCloseDate"
            (valueChange)="handleShowRegistrationCloseDateChange($event)"
          >
          </norby-switch-button>

          <div *ngIf="showRegistrationCloseDate" class="mt-2">
            <norby-date-time-picker
              class="3xl:w-1/2 3xl:pr-2 w-full pr-0"
              formControlName="registrationCloseDate"
            >
            </norby-date-time-picker>
          </div>
        </norby-collapsible>

        <norby-collapsible title="Enable keyword signups">
          <p *ngIf="phoneNumber">
            Allow people to sign up by texting a keyword to your Norby number,
            {{ phoneNumber | phone : 'US' }}.
          </p>

          <p *ngIf="!phoneNumber">
            Allow people to sign up by texting a keyword to your Norby number.
          </p>

          <norby-custom-message-tooltip
            action="use keywords"
            [isNorbyNumberNeeded]="true"
            [trackProductAnalytics]="UPSELL_EVENT"
            [properties]="UPSELL_KEYWORD_PROPERTIES"
            (onMessageUpdated)="keywordInput.isDisabled = !!$event"
          >
            <norby-input
              #keywordInput
              placeholder="Keyword"
              formControlName="eventKeyword"
            ></norby-input>
          </norby-custom-message-tooltip>
        </norby-collapsible>

        <norby-collapsible title="Set a post-signup redirect">
          <p>
            Redirect attendees to another URL after they finish registering.
          </p>
          <norby-input
            formControlName="postRegistrationRedirect"
            placeholder="https://www.your-website.com"
            infoTooltip="Send users to another URL after they register."
            [isUrl]="true"
          ></norby-input>
        </norby-collapsible>
      </norby-collapsible-group>
    </div>
  </form>
</div>
