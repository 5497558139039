import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// 3rd party
import { plainToInstance } from 'class-transformer';

// Libs
import {
  IPSQLContactResults,
  ApiSurfaces,
  ENDPOINTS,
  IInboxMedium,
  PaginatedQueryFilters,
  CloneContactListDto,
  ContactListContactResults,
  ContactListListSummaryResponse,
  ContactListResults,
  ContactView,
  CreateContactListDto,
  GetContactListContactsDto,
  IContactListListSummaryResponse,
  IContactView,
  SetContactListSettingsDto,
  UpdateContactListDto
} from 'models';
import { ErrorService, MessageService, MessageType } from 'uikit';
import { IContactsService } from './contacts.service.interface';
import { ApiService } from '../api/api.service';
import { ContactCsvType } from '../content-management';

@Injectable({
  providedIn: 'root'
})
export class ContactsService implements IContactsService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _api: ApiService,
    private _error: ErrorService,
    private _message: MessageService
  ) {}

  async getContacts(input: {
    offset?: number;
    after?: string;
    limit?: number;
    lite?: boolean;
    requireMedium?: boolean;
    medium?: IInboxMedium;
  }): Promise<IPSQLContactResults> {
    try {
      const { offset, after, limit, lite, requireMedium, medium } = input;
      const pagination = offset ? { offset } : after ? { after } : {};
      const ret = await this._api.get<IPSQLContactResults>(
        ApiSurfaces.API,
        `v2/contact`,
        {
          ...pagination,
          limit: limit ?? 10,
          lite: !!lite,
          requireMedium: !!requireMedium,
          ...(medium && { medium })
        }
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getContactsCsvUrl() {
    try {
      const endpoint = `v2/contact/csv`;
      const ret = (
        await this._api.get<ContactCsvType>(ApiSurfaces.API, endpoint)
      )?.url;
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async postContactsCsvV2(
    currentSlug: string,
    uploadedFileName: string,
    newsletterOptIn: boolean,
    captchaResponse: string
  ): Promise<any> {
    try {
      const ret = await this._api.post(
        ApiSurfaces.API,
        ENDPOINTS.contacts.csvImportV2,
        {
          slug: currentSlug,
          source: uploadedFileName,
          sourceType: 'firebaseStorage',
          optAllIntoNewsletter: newsletterOptIn
        },
        null,
        captchaResponse
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async downloadContactsCsv(): Promise<void> {
    return this.getContactsCsvUrl().then((uri: string) => {
      const link = this._document?.createElement('a');
      link.setAttribute('download', 'rsvps.csv');
      link.href = uri;
      this._document?.body?.appendChild(link);
      link.click();
      link.remove();
    });
  }

  getContactListSummary = async (): Promise<ContactListListSummaryResponse> => {
    try {
      const response = await this._api.get<IContactListListSummaryResponse>(
        ApiSurfaces.API,
        `contact_list/summary`
      );
      return plainToInstance(ContactListListSummaryResponse, response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  updateContactListSettings = async (
    input: SetContactListSettingsDto
  ): Promise<ContactListListSummaryResponse> => {
    try {
      const response = await this._api.put<ContactView>(
        ApiSurfaces.API,
        `contact_list/settings`,
        input
      );
      return plainToInstance(ContactListListSummaryResponse, response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  createContactList = async (
    input: CreateContactListDto,
    showConfirmation = true
  ): Promise<ContactView> => {
    try {
      const response = await this._api.post<IContactView>(
        ApiSurfaces.API,
        `contact_list`,
        input
      );

      if (showConfirmation) {
        this._message.show({
          text: response ? 'Saved' : 'Error',
          type: response ? MessageType.SUCCESS : MessageType.ERROR
        });
      }
      return ContactView.fromObject(response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  updateContactList = async (
    listId: string,
    input: UpdateContactListDto,
    showConfirmation = true
  ): Promise<ContactView> => {
    try {
      const response = await this._api.patch<IContactView>(
        ApiSurfaces.API,
        `contact_list/${listId}`,
        input
      );

      if (showConfirmation) {
        this._message.show({
          text: response ? 'Saved' : 'Error',
          type: response ? MessageType.SUCCESS : MessageType.ERROR
        });
      }
      return ContactView.fromObject(response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  cloneContactList = async (
    listId: string,
    input: CloneContactListDto,
    showConfirmation = true
  ): Promise<ContactView> => {
    try {
      const response = await this._api.post<IContactView>(
        ApiSurfaces.API,
        `contact_list/${listId}/clone`,
        input
      );

      if (showConfirmation) {
        this._message.show({
          text: response ? 'Saved' : 'Error',
          type: response ? MessageType.SUCCESS : MessageType.ERROR
        });
      }
      return ContactView.fromObject(response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  deleteContactList = async ({
    listId
  }: {
    listId: string;
  }): Promise<boolean> => {
    try {
      const wasDeleted = await this._api.delete<boolean>(
        ApiSurfaces.API,
        `contact_list/${listId}`
      );

      this._message.show({
        text: wasDeleted ? 'Deleted' : 'Error',
        type: wasDeleted ? MessageType.SUCCESS : MessageType.ERROR
      });
      return wasDeleted;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getContactLists = async (
    input: PaginatedQueryFilters
  ): Promise<ContactListResults> => {
    try {
      const response = await this._api.get<any>(
        ApiSurfaces.API,
        `contact_list`,
        input
      );
      return plainToInstance(ContactListResults, response);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getContactList = async ({
    listId
  }: {
    listId: string;
  }): Promise<ContactView> => {
    try {
      const resp = await this._api.get<IContactView>(
        ApiSurfaces.API,
        `contact_list/${listId}`
      );

      return ContactView.fromObject(resp);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getContactsInList = async (
    listId: string,
    input: GetContactListContactsDto
  ): Promise<ContactListContactResults> => {
    try {
      return await this._api.get<ContactListContactResults>(
        ApiSurfaces.API,
        `contact_list/${listId}/contacts`,
        input
      );
    } catch (e) {
      this._error.displayError(e);
    }
  };

  exportCsv = async (
    listId: string,
    showConfirmation = true,
    captchaResponse: string
  ): Promise<void> => {
    try {
      const response = await this._api.post<IContactView>(
        ApiSurfaces.API,
        `contact_list/${listId}/export`,
        {},
        null,
        captchaResponse
      );

      if (showConfirmation) {
        this._message.show({
          text: response
            ? `Your export is being prepared. You'll receive an email with a link to download when it's ready.`
            : 'Error',
          type: response ? MessageType.SUCCESS : MessageType.ERROR
        });
      }
    } catch (e) {
      this._error.displayError(e);
    }
  };
}
