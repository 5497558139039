import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbySwitchButtonModule } from 'uikit';
import { NorbyEditProfileBlockComponent } from '../components/templates/content-block-editors/norby-edit-profile-block';

@NgModule({
  declarations: [NorbyEditProfileBlockComponent],
  imports: [CommonModule, NorbySwitchButtonModule, ReactiveFormsModule],
  exports: [NorbyEditProfileBlockComponent]
})
export class NorbyEditProfileBlockModule {}
