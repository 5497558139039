import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'norby-ant-color-picker',
  templateUrl: './norby-ant-color-picker.component.html',
  styleUrls: ['./norby-ant-color-picker.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyAntColorPickerComponent),
      multi: true
    }
  ]
})
export class NorbyAntColorPickerComponent implements ControlValueAccessor {
  @Input() label: string = 'Hex';
  @Input() placeholder: string = '';
  @Input() helperText: string = '';
  @Input() infoTooltip: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;

  isButtonDisabled = true;
  val: string = '';

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;

  constructor() {}

  writeValue(value: string) {
    if (value !== null) {
      this.isButtonDisabled = false;
    }
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  handleColorPickerChange(event) {
    this.isButtonDisabled = false;
    this.val = event.color.toHexString();
    this._markAsTouched();
    this._onChanged(this.val);
  }

  handleResetValueClick() {
    this.isButtonDisabled = true;
    this.val = null;
    this._markAsTouched();
    this._onChanged(this.val);
  }
}
