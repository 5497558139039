import { DEFAULT_ACCOUNT_STATUS, IAccountStoreService } from 'models';
import { BehaviorSubject } from 'rxjs';

export class MockAccountStoreService implements IAccountStoreService {
  private _addOns$ = new BehaviorSubject(null);
  private _accountStatus$ = new BehaviorSubject(DEFAULT_ACCOUNT_STATUS);

  accountStatus$ = this._accountStatus$.asObservable();
  addOns$ = this._addOns$.asObservable();
}
