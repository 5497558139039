import { Injectable, TemplateRef } from '@angular/core';

// 3rd party
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

// Libs
import { UiService } from '../ui';
import {
  ContactListColumn,
  ContactList,
  ContactView,
  ContactViewFromModal,
  IPSQLContact
} from 'models';

// App
import { IContactModalService } from './contact-modal.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactModalService implements IContactModalService {
  editColumnsComponent;
  addListComponent;
  viewAllListsComponent;
  buildContactListFiltersComponent;
  contactDetailsDrawerComponent;

  constructor(
    protected _dialog: NzModalService,
    protected _drawer: NzDrawerService,
    protected _ui: UiService
  ) {}

  async launchContactDetailsDrawer(
    contact: IPSQLContact,
    showDelete = true,
    actionButtonsTpl?: TemplateRef<any>,
    bodyTpl?: TemplateRef<any>
  ): Promise<any> {
    if (!this.contactDetailsDrawerComponent) {
      this._ui.setLoading(true);
      const { ContactDetailsDrawerComponent } = await import(
        '../../entry-points/contact-details-drawer'
      );
      this.contactDetailsDrawerComponent = ContactDetailsDrawerComponent;
      this._ui.setLoading(false);
    }
    const component = this.contactDetailsDrawerComponent;
    const drawerRef = this._drawer.create<
      typeof component,
      {
        contact: IPSQLContact;
        shouldDelete: boolean;
      }
    >({
      nzPlacement: 'right',
      nzContent: component,
      nzClosable: false,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
      nzContentParams: {
        contact,
        actionButtonsTpl,
        bodyTpl,
        showDelete
      }
    });
    return await firstValueFrom(drawerRef.afterClose);
  }

  async launchEditColumnsModal(
    columns: ContactListColumn[]
  ): Promise<ContactListColumn[]> {
    if (!this.editColumnsComponent) {
      this._ui.setLoading(true);
      const { EditContactColumnsComponent } = await import(
        '../../entry-points/edit-contact-columns'
      );

      this.editColumnsComponent = EditContactColumnsComponent;
      this._ui.setLoading(false);
    }

    const component = this.editColumnsComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzTitle: 'Edit columns',
      nzContent: component,
      nzMaskClosable: true,
      nzOkText: 'Confirm',
      nzCloseIcon: 'feather/x',
      nzData: { columns }
    } as ModalOptions);

    return firstValueFrom<ContactListColumn[]>(dialogRef.afterClose);
  }

  async launchAddOrEditListModal(
    editList?: ContactView,
    saveAsNew = false
  ): Promise<ContactViewFromModal> {
    if (!this.addListComponent) {
      this._ui.setLoading(true);
      const { AddContactListComponent } = await import(
        '../../entry-points/add-contact-list'
      );

      this.addListComponent = AddContactListComponent;
      this._ui.setLoading(false);
    }

    const isEditing = !!editList?.id;
    const isDefault = !!editList?.isDefault;
    const component = this.addListComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzTitle: saveAsNew
        ? 'Save a new list'
        : isEditing
          ? 'Manage list'
          : 'Add a list',
      nzContent: component,
      nzMaskClosable: true,
      nzOkText:
        isEditing && !isDefault ? 'Save' : isDefault ? 'Okay' : 'Create',
      nzCancelText:
        isEditing && !isDefault ? 'Cancel' : isDefault ? null : 'Cancel',
      nzCloseIcon: 'feather/x',
      nzData: { editList, saveAsNew }
    } as ModalOptions);

    return firstValueFrom<ContactViewFromModal>(dialogRef.afterClose);
  }

  async launchViewAllListsModal(): Promise<ContactView> {
    if (!this.viewAllListsComponent) {
      this._ui.setLoading(true);
      const { ViewContactListsComponent } = await import(
        '../../entry-points/view-contact-lists'
      );

      this.viewAllListsComponent = ViewContactListsComponent;
      this._ui.setLoading(false);
    }

    const component = this.viewAllListsComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzTitle: 'All lists',
      nzContent: component,
      nzMaskClosable: true,
      nzOkText: 'Okay',
      nzCancelText: null,
      nzWidth: '880px',
      nzCloseIcon: 'feather/x'
    } as ModalOptions);

    return firstValueFrom<ContactView>(dialogRef.afterClose);
  }

  async launchBuildListFilters(list: ContactList): Promise<ContactList> {
    if (!this.buildContactListFiltersComponent) {
      this._ui.setLoading(true);
      const { BuildContactListFiltersComponent } = await import(
        '../../entry-points/build-contact-list-filters'
      );

      this.buildContactListFiltersComponent = BuildContactListFiltersComponent;
      this._ui.setLoading(false);
    }

    const component = this.buildContactListFiltersComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzTitle: 'Edit filters',
      nzContent: component,
      nzMaskClosable: true,
      nzOkText: 'Confirm',
      nzWidth: '700px',
      nzCloseIcon: 'feather/x',
      nzData: { list }
    } as ModalOptions);

    return firstValueFrom<ContactList>(dialogRef.afterClose);
  }
}
