import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { NorbyLayoutSelectorComponent } from '../components/atoms/norby-layout-selector';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';

@NgModule({
  declarations: [NorbyLayoutSelectorComponent],
  imports: [CommonModule, NorbyConfigurableSelectorModule],
  exports: [NorbyLayoutSelectorComponent]
})
export class NorbyLayoutSelectorModule {}
