import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyStepsMenuComponent } from '../components/molecules/norby-steps-menu';
import { NorbyCollapsibleModule } from './norby-collapsible.module';
import { NorbyStepsMenuItemModule } from './norby-steps-menu-item.module';

@NgModule({
  declarations: [NorbyStepsMenuComponent],
  imports: [CommonModule, NorbyCollapsibleModule, NorbyStepsMenuItemModule],
  exports: [NorbyStepsMenuComponent]
})
export class NorbyStepsMenuModule {}
