<div class="relative">
  <div
    *ngIf="shouldShowPopup"
    [@zoomBigMotion]="'active'"
    class="rounded-sm absolute p-4 flex flex-row bottom-9 bg-white z-50 shadow-ant-default w-72"
    [ngClass]="{
      'left-0': popupDisplayDirection === 'left',
      'right-0': popupDisplayDirection !== 'left'
    }"
  >
    <div class="xl:hidden">
      <norby-icon
        class="pr-2 justify-start items-start"
        name="norby-ai"
      ></norby-icon>
    </div>
    <div>
      <p class="text-neutral-900 font-medium mb-0.5 text-xs">
        Shorten your message?
      </p>
      <p class="text-neutral-700 text-xs">
        Use AI to shorten your message and use fewer segments.
      </p>
      <div class="flex flex-row">
        <button
          nz-button
          [nzLoading]="isLoading"
          nzSize="small"
          class="mr-2"
          nzType="primary"
          (click)="handleClickShortenMessage()"
        >
          Shorten
        </button>
        <a
          nz-button
          buttonType="secondary"
          nzSize="small"
          href="https://www.twilio.com/blog/2017/03/what-the-heck-is-a-segment.html"
          target="_blank"
          rel="noreferrer"
          [trackProductAnalytics]="TRACK_CLICK_EVENT"
          [properties]="KNOWLEDGE_BASE"
        >
          <div class="xl:hidden md:block">Learn more</div>
          <div class="hidden xl:block md:hidden">More</div>
        </a>
      </div>
    </div>
    <div>
      <button (click)="handleClickTogglePopup()" class="hover:cursor-pointer">
        <norby-icon
          class="pl-2 justify-start items-start"
          name="x"
        ></norby-icon>
      </button>
    </div>
  </div>

  <div
    class="segment-counter"
    [ngClass]="{
      'text-left': popupDisplayDirection === 'left',
      'text-right': popupDisplayDirection !== 'left'
    }"
  >
    <span
      *ngIf="segmentCount"
      (click)="handleClickTogglePopup()"
      class="text-mustard align-middle mr-2 text-xs hover:cursor-pointer"
    >
      {{ segmentCount }}
      <span class="sm:hidden">segment{{ segmentCount !== 1 ? 's' : '' }}</span>
    </span>
    <svg
      class="inline-block align-middle fill-transparent"
      width="26px"
      height="26px"
      [ngClass]="segmentCount ? 'active' : 'inactive'"
    >
      <circle class="stroke-2" r="12px" cx="50%" cy="50%" stroke="#d0d0d0" />
      <circle
        class="progress-ring stroke-2 fill-transparent"
        r="12px"
        cx="50%"
        cy="50%"
        [attr.stroke-dasharray]="(characterCount / 160) * 75.4 + ' 75.4'"
      />
      <text
        class="progress-text text-xxs stroke-mustard"
        x="50%"
        y="50%"
        dy="3px"
      >
        {{ characterCount }}
      </text>
    </svg>
  </div>
</div>
