<form [formGroup]="formGroup">
  <norby-slider
    class="mb-4"
    [floor]="35"
    [ceil]="400"
    label="Height"
    formControlName="height"
  ></norby-slider>
  <norby-input
    label="Button Text"
    type="text"
    placeholder="Insert text here..."
    formControlName="label"
  ></norby-input>
  <norby-slider
    formControlName="borderRadius"
    [floor]="0"
    [ceil]="100"
    [allowNull]="true"
    label="Border radius"
  ></norby-slider>

  <norby-slider
    formControlName="borderWidth"
    [floor]="0"
    [ceil]="100"
    [allowNull]="true"
    label="Border width"
  ></norby-slider>

  <norby-drop-shadow-picker
    label="Drop shadow"
    formControlName="dropShadow"
    placeholder="6px 6px 0px #000"
    formControlName="dropShadow"
  ></norby-drop-shadow-picker>
  <norby-input
    class="mb-4"
    label="Link URL"
    formControlName="clickThroughUrl"
    [placeholder]="clickThroughUrlDefault"
    [isUrl]="true"
  ></norby-input>
  <norby-icon-radio-button-group
    label="Alignment"
    formControlName="alignment"
    size="default"
    class="flex-row"
  >
    <norby-icon-radio-button
      iconName="align_left"
      buttonValue="left"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_center"
      buttonValue="center"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_right"
      buttonValue="right"
    ></norby-icon-radio-button>
  </norby-icon-radio-button-group>

  <norby-ant-color-picker
    label="Background Color"
    formControlName="backgroundColor"
  >
  </norby-ant-color-picker>
  <norby-ant-color-picker label="Text Color" formControlName="textColor">
  </norby-ant-color-picker>
  <norby-ant-color-picker label="Border Color" formControlName="borderColor">
  </norby-ant-color-picker>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
