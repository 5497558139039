import { SafeUrl } from '@angular/platform-browser';

import { hashString, transformTimestampToDate } from '../tools';
import { IQueryResult, MmsAttachment, PageInfo } from './general';
import { IFormattedMmsAttachment } from './MmsAttachments';
import { EntityType } from './content';
import {
  InstagramAttachment,
  InboxInstagramAttachment,
  InboxInstagramReaction,
  InboxInstagramStoryType,
  InboxInstagramStory,
  InboxInstagramShare,
  IFormattedInstagramImageAttachment,
  ISendContactBulkInstagramMessage
} from './instagram';

import { Transform } from 'class-transformer';
import { IPSQLContact } from './IPSQLContact';
import { PaginatedQueryFilters } from './paginatedQueryFilters';

export type IInboxMedium =
  | 'sms'
  | 'email'
  | 'interaction'
  | 'twitter'
  | 'instagram';

export const MEDIUM_LABEL_MAP: { [key in IInboxMedium]: string } = {
  sms: 'SMS',
  email: 'Email',
  interaction: 'Interaction',
  twitter: 'Twitter',
  instagram: 'Instagram'
};

export enum InboxDeliveryStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  SENT = 'sent',
  UNDELIVERED = 'undelivered',
  RECEIVED = 'received',
  FAILED = 'failed',
  PROCESSED = 'processed',
  BOUNCED = 'bounced',
  DROPPED = 'dropped',
  DEFERRED = 'deferred',
  BLOCKED = 'blocked'
}

export type IInboxSmsDeliveryStatus =
  | InboxDeliveryStatus.PENDING
  | InboxDeliveryStatus.SENT
  | InboxDeliveryStatus.DELIVERED
  | InboxDeliveryStatus.UNDELIVERED
  | InboxDeliveryStatus.FAILED
  | InboxDeliveryStatus.RECEIVED;

export type IInboxEmailDeliveryStatus =
  | InboxDeliveryStatus.PENDING
  | InboxDeliveryStatus.PROCESSED
  | InboxDeliveryStatus.DELIVERED
  | InboxDeliveryStatus.BOUNCED
  | InboxDeliveryStatus.DROPPED
  | InboxDeliveryStatus.DEFERRED
  | InboxDeliveryStatus.BLOCKED
  | InboxDeliveryStatus.RECEIVED;

export type IInboxDeliveryStatus =
  | InboxDeliveryStatus.PENDING
  | IInboxSmsDeliveryStatus
  | IInboxEmailDeliveryStatus;

export type IInboxMetadataType = 'email' | 'sms' | 'interaction' | 'instagram';
export const IInboxMetadataTypes: IInboxMetadataType[] = [
  'email',
  'sms',
  'interaction'
];

export class IInboxMetadataBase {
  type!: IInboxMetadataType;
}

export type InboxMmsAttachment = MmsAttachment & {
  status: 'uploading' | 'success' | 'error';
  url: SafeUrl;
};

export class IInboxAttachments extends IInboxMetadataBase {
  attachments?: string[] | null;
  formatted_attachments?: IFormattedMmsAttachment[];
  error_message?: string;
}

export class IInboxEmailMetadata extends IInboxAttachments {
  type!: 'email';
  subject!: string;
  email_attachments?: MmsAttachment[];
}

export class IInboxSmsMetadata extends IInboxAttachments {
  type!: 'sms';
  mms_attachments?: MmsAttachment[];
}

export class IInboxInteractionMetadata extends IInboxMetadataBase {
  type!: 'interaction';
  interaction_id!: number;
  interaction_type!: string;
  interaction_value?: string;
  interaction_metadata!: any;
  entity_id!: string;
  entity_type!: EntityType;
}

export class IInboxInstagramMetadata extends IInboxMetadataBase {
  type!: 'instagram';
  thread_id?: string;
  message_id!: string;
  external_username!: string;
  account_integration_username!: string;
  account_integration_id!: string;
  is_unsupported?: boolean;
  attachments?: string[] | null = [];
  instagram_attachments?: InstagramAttachment[] = [];
  instagram_attachments_raw?: InboxInstagramAttachment[];
  reactions?: InboxInstagramReaction[] | null;
  story?: {
    [key in InboxInstagramStoryType]: InboxInstagramStory;
  };
  shares?: InboxInstagramShare[];
  formatted_attachments?: IFormattedInstagramImageAttachment[] = [];
}

export type IInboxMetadata =
  | IInboxEmailMetadata
  | IInboxSmsMetadata
  | IInboxInteractionMetadata
  | IInboxInstagramMetadata;

export type IInboxItemObject = IInboxItem | IInboxItemLite;

export class IInboxItemLite {
  inboxItemId!: string;
  contact_id!: string;
  medium!: IInboxMedium;
  medium_identifier?: string;
  inbound = false;
  body!: string;
  subject?: string;
  delivery_status?: IInboxDeliveryStatus | null;
  created_at: Date;
  created_at_cursor: string;
  seen = false;
  attachments?: MmsAttachment[];
  metadata?: IInboxMetadata;

  get hash(): number {
    return hashString(`
      ${this.body}
      ${this.delivery_status}
      ${this.attachments}
      ${this.subject}
    `);
  }
}

export class IInboxItem {
  id!: string;
  parent_inbox_item_id?: string;
  slug!: string;
  contact_id!: string;
  lookup_key!: string;
  deduplication_key?: string | null;
  medium!: IInboxMedium;
  medium_identifier?: string;
  inbound!: boolean;
  body!: string;
  plainTextBody!: string;
  delivery_status?: IInboxDeliveryStatus | null;
  metadata!: IInboxMetadata;
  seen?: boolean | null;
  replyTo?: string | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  thread_last_seen_at?: Date;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  thread_last_updated_at?: Date;
  thread_last_updated_at_cursor?: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modified_at!: Date;
  modified_at_cursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  created_at!: Date;
  created_at_cursor!: string;

  get hash(): number {
    return hashString(`
      ${this.body}
      ${this.metadata}
      ${this.delivery_status}
    `);
  }
}

export class IInboxItemResponseDto {
  id!: string;
  parent_inbox_item_id?: string | null;
  slug!: string;
  contact_id!: string;
  deduplication_key?: string | null;
  medium!: IInboxMedium;
  inbound!: boolean;
  body!: string;
  plainTextBody!: string;
  delivery_status?: string | null;
  metadata!: IInboxMetadata;
  seen?: boolean | null;
  medium_identifier?: string | null;
  replyTo?: string | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  thread_last_seen_at?: Date | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  thread_last_updated_at?: Date | null;
  thread_last_updated_at_cursor?: string | null;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  modified_at!: Date;
  modified_at_cursor!: string;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  created_at!: string;
  created_at_cursor!: string;
}

export class IInboxItemResultEdge {
  cursor?: string;
  node!: IInboxItemResponseDto;
}

export class IInboxItemResult extends IQueryResult {
  pageInfo!: PageInfo;
  edges!: IInboxItemResultEdge[];
}

export class IInboxSendSmsMessage {
  inboxItemId!: string;
  medium: IInboxMedium = 'sms';
  mediumIdentifier!: number;
  body!: string;
  attachments?: MmsAttachment[];
}

export class IInboxSendEmailMessage {
  inboxItemId!: string;
  medium: IInboxMedium = 'email';
  mediumIdentifier!: string;
  body!: string;
  subject!: string;
  replyTo?: string;
}

export class IInboxSendMessage {
  data!: IInboxSendMediumMessage;
}

export class ISendContactBulkMessages {
  medium!: 'instagram';
  mediumIdentifier!: string;
  data!: ISendContactBulkInstagramMessage[];
}

export type IInboxSendMediumMessage =
  | IInboxSendSmsMessage
  | IInboxSendEmailMessage;

export type InboxOverlayType = 'contact' | 'thread' | 'interaction';

export type InboxOverlayData = {
  contact: IPSQLContact;
  inboxItemId?: string; // for threads
  interactions?: IInboxItem[];
};

export type ContactsFilterArgs = {
  unseen?: boolean;
  requireMedium?: boolean;
  lite?: string;
} & PaginatedQueryFilters;
