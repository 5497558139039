import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardModule } from './card.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { NotificationDetailViewComponent } from '../components/organisms/notification-detail-view';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';

@NgModule({
  declarations: [NotificationDetailViewComponent],
  imports: [
    CommonModule,
    CardModule,
    SkeletonModule,
    SoftImageViewModule,
    SafeHtmlPipeModule
  ],
  exports: [NotificationDetailViewComponent]
})
export class NotificationDetailViewModule {}
