import {
  ChangeDetectorRef,
  Component,
  Output,
  OnInit,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';

// Libs
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-tag-selector',
  templateUrl: './norby-tag-selector.component.html',
  styleUrls: ['./norby-tag-selector.component.less']
})
export class NorbyTagSelectorComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  @Input() label?: string = 'Tags';
  @Input() infoTooltip?: string = '';
  @Input() tags: Array<string> = [];

  @Output() onUpdatedTags: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  tagInputVisible: boolean = false;
  formGroup: FormGroup;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({ tag: [''] });
  }

  handleTagDelete(removedTag: string): void {
    this.onUpdatedTags.emit(this.tags?.filter((tag) => tag !== removedTag));
  }

  showTagInput(): void {
    this.tagInputVisible = true;
  }

  handleTagInputConfirm(): void {
    const newTag = this.formGroup?.controls?.tag?.value;
    if (!newTag || this.tags?.find((tag) => tag === newTag)) {
      return;
    }

    this.formGroup.controls.tag.setValue('');
    this.tagInputVisible = false;
    this._cdr.detectChanges();
    this.onUpdatedTags.emit([...this.tags, newTag]);
  }
}
