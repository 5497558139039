<!-- Show share button if the link is published -->
<button
  aria-label="Share"
  *ngIf="link?.published && textLabel"
  nz-button
  [nzSize]="size"
  class="share-button"
  nz-dropdown
  [nzDropdownMenu]="shareMenu"
  [nzType]="type"
  (nzVisibleChange)="handleMenuStateChanged($event)"
>
  <root-icon name="share"></root-icon>
  {{ textLabel }}
</button>

<button
  aria-label="Share"
  *ngIf="link?.published && !textLabel"
  nz-button
  [nzSize]="size"
  class="share-button icon-circle"
  nz-dropdown
  [nzDropdownMenu]="shareMenu"
  [nzType]="type"
  (nzVisibleChange)="handleMenuStateChanged($event)"
>
  <root-icon name="share"></root-icon>
</button>

<!-- SHARE MENU -->
<nz-dropdown-menu #shareMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngIf="isLoading" nzDisabled>
      <root-icon name="loader"></root-icon>
      Loading...
    </li>
    <li
      nz-menu-item
      *ngIf="!isLoading && canUseSystemShare"
      [nzDisabled]="isDisabled"
      (click)="doShare()"
      [logEvent]="userTappedShare"
      [cubeData]="{ entityType: 'link', entityId: link?.contentId }"
    >
      <root-icon name="share"></root-icon>
      Share...
    </li>
    <li
      nz-menu-item
      (click)="handleCopy()"
      [nzDisabled]="isDisabled"
      *ngIf="!isLoading"
      [cdkCopyToClipboard]="
        userContent?.shortLinks?.clickThroughShortLink || link?.shareUrl
      "
    >
      <root-icon name="link"></root-icon>
      Copy link
    </li>
    <li
      *ngIf="userContent?.shortLinks?.clickThroughShortLink || link?.shareUrl"
      nz-menu-item
      (click)="downloadQRCode()"
    >
      <root-icon name="grid"></root-icon>
      Download QR Code
    </li>
  </ul>
</nz-dropdown-menu>

<div #qrCanvas class="hidden">
  <nz-qrcode
    [nzValue]="userContent?.shortLinks?.clickThroughShortLink || link?.shareUrl"
    nzLevel="M"
  ></nz-qrcode>
  <a #download></a>
</div>
