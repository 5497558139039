<lib-content-row-view
  avatarIcon="user"
  [avatarSize]="avatarSize"
  [avatarPosition]="avatarPosition"
  [title]="actualName"
  [avatarColor]="color"
  [imageUrl]="photoURL"
  [subtitle]="subtitle"
  avatarIconColor="white"
  avatarShape="circle"
  [invert]="invert"
>
  <ng-content></ng-content>
</lib-content-row-view>
