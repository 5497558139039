import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentRegisterable, ITicket, IUserPublicMetadata } from 'models';

@Component({
  selector: 'lib-content-registration-purchase',
  templateUrl: './content-registration-purchase.component.html',
  styleUrls: ['./content-registration-purchase.component.less']
})
export class ContentRegistrationPurchaseComponent implements OnInit {
  @Input() userHasPaid: boolean;
  @Input() selectedTicket: ITicket;
  @Input() content: ContentRegisterable;
  @Input() userProfile: IUserPublicMetadata;
  @Input() isOnFinalScreen: boolean;
  @Input() hasLoadedProfile: boolean;
  @Input() themeClasses: string[];
  @Input() promoCode: string;

  @Output() handleNext = new EventEmitter<void>();
  @Output() handleSetError = new EventEmitter<any>();
  @Output() handleSkipPayment = new EventEmitter<void>();

  isLoading: boolean;

  constructor() {}

  ngOnInit(): void {}

  paymentCallback() {
    this.isLoading = true;
    const component = this;
    return (success: boolean, error?: any) => {
      this.isLoading = false;
      if (success) return component.handleNext.emit();
      this.handleSetError.emit(error);
    };
  }
}
