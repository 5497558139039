<norby-file-upload
  [images]="currentImages"
  [allowMultiple]="true"
  acceptedFileTypes=".jpeg, .jpg, .png"
  (onUpdatedImages)="handleUpdatedImages($event)"
></norby-file-upload>

<div class="mt-4 flex flex-row items-start">
  <norby-switch-button
    [value]="hideCard"
    rightLabel="Use transparent background"
    (valueChange)="handleHideCardChange($event)"
  ></norby-switch-button>
</div>
