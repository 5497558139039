import { Injectable } from '@angular/core';

// Libs
import {
  IFeatureFlag,
  IQueryResult,
  PaginatedQueryFilters,
  PaginatedQuerySummary
} from 'models';

// App
import {
  FEATURE_FLAG_CREATED,
  FEATURE_FLAG_DELETED,
  FEATURE_FLAG_UPDATED,
  FEATURE_FLAG_DELETED_WITH_ID,
  FEATURE_FLAG_DELETED_WITH_NAME,
  FEATURE_FLAG_UPDATED_WITH_ID,
  FEATURE_FLAG_UPDATED_WITH_NAME,
  GLOBAL_TOPIC_IDENTIFIER,
  RealtimeServerSocketMessage
} from '../socket';
import { FeatureFlagsService } from '../feature-flags';
import {
  ObservableDataService,
  QuerySummary,
  RealtimeSocketEventHandler
} from '../observable-data';

// 3rd party
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsStoreService {
  constructor(
    private _featureFlags: FeatureFlagsService,
    private _obs: ObservableDataService
  ) {}

  getFeatureFlags$(
    args?: PaginatedQueryFilters
  ): Observable<PaginatedQuerySummary<IFeatureFlag>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: FEATURE_FLAG_CREATED,
        payload: {
          resourceId: GLOBAL_TOPIC_IDENTIFIER
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IFeatureFlag[]
        ) => {
          const integration = plainToClass(IFeatureFlag, event?.data);
          return [...currentValue, integration];
        }
      },
      {
        event: FEATURE_FLAG_UPDATED,
        payload: {
          resourceId: GLOBAL_TOPIC_IDENTIFIER
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IFeatureFlag[]
        ) => {
          const updatedFlag = plainToClass(IFeatureFlag, event?.data);
          const existingFlagIndex = currentValue.findIndex(
            (flag) => flag.id === updatedFlag.id
          );

          if (existingFlagIndex !== -1) {
            currentValue?.splice(existingFlagIndex, 1, updatedFlag);
            return [...currentValue];
          }

          return currentValue;
        }
      },
      {
        event: FEATURE_FLAG_DELETED,
        payload: {
          resourceId: GLOBAL_TOPIC_IDENTIFIER
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IFeatureFlag[]
        ) => {
          const updatedFlag = plainToClass(IFeatureFlag, event?.data);
          const existingFlagIndex = currentValue.findIndex(
            (integration) => integration.id === updatedFlag.id
          );

          if (existingFlagIndex !== -1) {
            currentValue?.splice(existingFlagIndex, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    // Function used to transform lookup results and
    // incorporate them into the stream
    const transformer = (res: IQueryResult, currentValue: IFeatureFlag[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const featureFlags =
        res?.edges?.map((edge) => {
          return plainToClass(IFeatureFlag, edge?.node);
        }) ?? [];

      return {
        items: [...(currentValue ?? []), ...featureFlags],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    return this._obs.query$<IFeatureFlag>({
      handlers,
      lookup: this._featureFlags.getFeatureFlags,
      transformer,
      args
    });
  }

  getFeatureFlagById$(id: string): Observable<QuerySummary<IFeatureFlag>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: FEATURE_FLAG_UPDATED_WITH_ID,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(IFeatureFlag, event?.data)
      },
      {
        event: FEATURE_FLAG_DELETED_WITH_ID,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(IFeatureFlag, event?.data)
      }
    ];

    return this._obs.document$<IFeatureFlag>({
      handlers,
      lookup: this._featureFlags.getFeatureById,
      args: { id }
    });
  }

  getFeatureFlagByName$(name: string): Observable<QuerySummary<IFeatureFlag>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: FEATURE_FLAG_UPDATED_WITH_NAME,
        payload: {
          resourceId: name
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(IFeatureFlag, event?.data)
      },
      {
        event: FEATURE_FLAG_DELETED_WITH_NAME,
        payload: {
          resourceId: name
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(IFeatureFlag, event?.data)
      }
    ];

    return this._obs.document$<IFeatureFlag>({
      handlers,
      lookup: this._featureFlags.getFeatureByName,
      args: { name }
    });
  }
}
