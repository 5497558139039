import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyShapeSelectorItemModule } from './norby-shape-selector-item.module';
import { NorbyFormcontrolLabelModule } from 'uikit';
import { NorbyShapeSelectorComponent } from '../components/atoms/norby-shape-selector';

@NgModule({
  declarations: [NorbyShapeSelectorComponent],
  imports: [
    CommonModule,
    NorbyShapeSelectorItemModule,
    NorbyFormcontrolLabelModule
  ],
  exports: [NorbyShapeSelectorComponent]
})
export class NorbyShapeSelectorModule {}
