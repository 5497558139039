import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

// 3rd party
import { plainToClass } from 'class-transformer';

// Lib
import { IImage, ImagePageBlock } from 'models';

@Component({
  selector: 'norby-edit-image-block',
  templateUrl: './norby-edit-image-block.component.html',
  styleUrls: ['./norby-edit-image-block.component.less']
})
export class NorbyEditImageBlockComponent implements OnChanges {
  @Input() selectedBlock: ImagePageBlock;

  @Output() onUpdatedBlock: EventEmitter<ImagePageBlock> =
    new EventEmitter<ImagePageBlock>();

  currentImages: IImage[];
  hideCard: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.currentImages = this.selectedBlock?.images ?? [];
    this.hideCard = this.selectedBlock?.hideCard;
  }

  get hasImages(): boolean {
    return this.currentImages?.length > 0;
  }

  handleUpdatedImages(updatedImages: IImage[]): void {
    this.currentImages = updatedImages;
    this._emitChanges();
  }

  handleHideCardChange(hideCard: boolean): void {
    this.hideCard = hideCard;
    this._emitChanges();
  }

  private _emitChanges(): void {
    const updatedBlock = plainToClass(ImagePageBlock, {
      ...this.selectedBlock,
      images: this.currentImages,
      hideCard: this.hideCard
    });

    this.onUpdatedBlock.emit(updatedBlock);
  }
}
