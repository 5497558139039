import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

// 3rd party
import { NzIconModule } from 'ng-zorro-antd/icon';

// App
import { EditContactColumnsComponent } from './edit-contact-columns.component';

@NgModule({
  declarations: [EditContactColumnsComponent],
  imports: [CommonModule, DragDropModule, NzIconModule]
})
export class EditContactColumnsModule {}
