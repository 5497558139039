import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
  ViewChildren,
  QueryList,
  Inject,
  ChangeDetectorRef
} from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray
} from '@angular/cdk/drag-drop';

import { DOCUMENT } from '@angular/common';

import {
  ClickedUpsellProductAnalyticsLocations,
  ClickedUpsellProductAnalyticsTypes,
  ContentSignup,
  ProductAnalyticsEventTypes,
  PromptControlType,
  ContentSignupMetadata,
  uuidv4,
  SignupChanges,
  SignupMetadataChanges,
  compareSignups,
  compareSignupsMetadata,
  UrlValidator
} from 'models';
import { BaseComponent, IconService, rootMoreVertical } from 'uikit';
import { THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS } from '../../../organisms/norby-theme-customizer';

@Component({
  selector: 'lib-signup-registration-step',
  templateUrl: './signup-registration-step.component.html',
  styleUrls: ['./signup-registration-step.component.less']
})
export class SignupRegistrationStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() signup: ContentSignup;
  @Input() signupMetadata: ContentSignupMetadata;
  @Input() phoneNumber: string = '';
  @Input() disableKeyword: boolean = false;

  @Output() onUpdatedSignup: EventEmitter<SignupChanges> =
    new EventEmitter<SignupChanges>();
  @Output() onUpdatedSignupMetadata: EventEmitter<SignupMetadataChanges> =
    new EventEmitter<SignupMetadataChanges>();

  @ViewChildren('optionInput', { read: ElementRef })
  optionInput!: QueryList<ElementRef>;

  formGroup: UntypedFormGroup;
  promptsForm: UntypedFormGroup;
  promptsControls: Array<PromptControlType> = [];

  // UI toggles
  showRegistrationCloseDate: boolean = false;
  showRegistrationCapacity: boolean = false;

  // Drag and drop
  draggingPlaceholderHeight: number;
  readonly DEFAULT_PLACEHOLDER_HEIGHT: number = 160;

  // Tags
  tagInputVisible = false;
  tags: string[] = [];

  // Upsell analytics
  readonly UPSELL_SIGNUP = ProductAnalyticsEventTypes.CLICKED_UPSELL;
  readonly UPSELL_CUSTOM_FIELDS_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_CUSTOM_FIELDS,
    cta: 'Upgrade your plan to use custom fields.'
  };
  readonly UPSELL_KEYWORD_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_KEYWORDS,
    cta: 'Upgrade your plan to use keywords.'
  };
  readonly UPSELL_NOTIFICATION_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SIGNUP_NOTIFICATIONS,
    cta: 'Upgrade your plan to use custom notifications.'
  };

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _iconService: IconService,
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    this._iconService.registerIcons([rootMoreVertical]);
  }

  get rsvpConfirmationMedium(): string {
    return (
      this.signupMetadata?.notifications
        ?.find((x) => x.type === 'rsvpConfirmation')
        ?.deliveryType.toString() || ''
    );
  }

  get shouldDisableDisplayCapacityToggle() {
    return !(this.formGroup?.controls?.capacity?.value >= 0);
  }

  ngOnInit(): void {
    this._updateUiToggles();
    this._updateTags();
    this._initForms();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        collectEmail:
          !!this.signup?.privateUserInfoRequirements?.email?.required,
        collectPhone:
          !!this.signup?.privateUserInfoRequirements?.phoneNumber?.required,
        collectName: !!this.signup?.userInfoRequirements?.displayName?.required,
        signupKeyword: this.signupMetadata?.keywords?.[0]?.keyword,
        postRegistrationRedirect: this.signup?.urls?.postRegistrationRedirect,
        registrationCloseDate: this.signup?.registrationCloseDate,
        capacity: this.signupMetadata?.rsvpRestrictions?.limit,
        displayLimit: this.signupMetadata?.rsvpRestrictions?.displayLimit
      },
      { emitEvent: false }
    );

    this._updateUiToggles();
    this._updateTags();
  }

  private _updateTags() {
    this.tags = this.signup?.tags || [];
  }

  private _initForms() {
    const promptsArray = this.signup?.prompts ?? [];

    this.promptsControls = promptsArray.map<PromptControlType>(
      (prompt, idx) => {
        const id = `p${idx}`;
        return {
          id,
          controlInstance: `prompt${id}`,
          isNew: false,
          options: prompt?.options
        };
      }
    );

    this.promptsForm = this._formBuilder.group(
      promptsArray.reduce((prev, curr, index) => {
        return {
          ...prev,
          [this.promptsControls[index].controlInstance]:
            this._formBuilder.group({
              prompt: [curr?.prompt, Validators.required],
              type: [curr?.type ?? 'text', Validators.required],
              required: [curr?.required ?? false, Validators.required],
              options: [curr?.options ?? []],
              addOptionText: ['']
            })
        };
      }, {})
    );

    this.formGroup = this._formBuilder.group({
      collectEmail: [
        !!this.signup?.privateUserInfoRequirements?.email?.required
      ],
      collectPhone: [
        !!this.signup?.privateUserInfoRequirements?.phoneNumber?.required
      ],
      collectName: [!!this.signup?.userInfoRequirements?.displayName?.required],
      signupKeyword: [this.signupMetadata?.keywords?.[0]?.keyword],
      postRegistrationRedirect: [
        this.signup?.urls?.postRegistrationRedirect,
        [UrlValidator]
      ],
      registrationCloseDate: [this.signup?.registrationCloseDate],
      capacity: [
        this.signupMetadata?.rsvpRestrictions?.limit,
        [Validators.min(0), Validators.max(10000)]
      ],
      displayLimit: [this.signupMetadata?.rsvpRestrictions?.displayLimit],
      prompts: this.promptsForm,
      tag: ['']
    });

    this.formGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe((value) => {
        const signup = ContentSignup.fromObject({
          ...this.signup,
          registrationCloseDate: this.showRegistrationCloseDate
            ? value.registrationCloseDate
            : null,
          registrationCloseDateCursor: null,
          urls: {
            ...this.signup?.urls,
            postRegistrationRedirect: value?.postRegistrationRedirect
          },
          privateUserInfoRequirements: {
            ...this.signup?.privateUserInfoRequirements,
            email: { required: value?.collectEmail || false },
            phoneNumber: { required: value?.collectPhone || false }
          },
          userInfoRequirements: {
            ...this.signup?.userInfoRequirements,
            displayName: { required: value?.collectName || false }
          },
          prompts:
            Object.keys(value?.prompts || {}).length > 0
              ? this.promptsControls.map(
                  (ctrl) => value?.prompts[ctrl.controlInstance]
                )
              : [],
          rsvpRestrictions: this.showRegistrationCapacity
            ? {
                remaining: this._calculateNewRemaining(
                  value?.capacity,
                  this.signupMetadata?.rsvpRestrictions?.limit,
                  this.signup?.rsvpRestrictions?.remaining
                ),
                displayLimit: value?.displayLimit,
                ...(value?.displayLimit && { limit: value?.capacity ?? 0 })
              }
            : null,
          tags: this.tags
        });

        const signupMetadata = ContentSignupMetadata.fromObject({
          ...this.signupMetadata,
          keywords: value?.signupKeyword
            ? [{ keyword: value?.signupKeyword, action: 'rsvp' }]
            : [],
          rsvpRestrictions: this.showRegistrationCapacity
            ? {
                limit: value?.capacity ?? 0,
                displayLimit: value?.displayLimit
              }
            : null
        });

        if (
          (!value.collectEmail && this.rsvpConfirmationMedium === 'email') ||
          (!value.collectPhone && this.rsvpConfirmationMedium === 'sms')
        ) {
          if (
            (!value.collectEmail && this.rsvpConfirmationMedium === 'email') ||
            (!value.collectPhone && this.rsvpConfirmationMedium === 'sms')
          ) {
            signupMetadata.notifications = signupMetadata.notifications.filter(
              (x) => x.type !== 'rsvpConfirmation'
            );
          }
        }

        if (!compareSignups(this.signup, signup)) {
          this.onUpdatedSignup.emit({
            isDirty: this.formGroup.dirty,
            isRegistrationStepValid: this.formGroup.valid,
            signup
          });
        }

        if (!compareSignupsMetadata(this.signupMetadata, signupMetadata)) {
          this.onUpdatedSignupMetadata.emit({
            isDirty: this.formGroup.dirty,
            isRegistrationStepValid: this.formGroup.valid,
            signupMetadata
          });
        }
      });
  }

  private _calculateNewRemaining(
    newLimit: number,
    prevLimit: number,
    prevRemaining: number
  ) {
    const numberUsed = (prevLimit ?? 0) - (prevRemaining ?? 0);
    return (newLimit ?? 0) - numberUsed;
  }

  private _updateUiToggles() {
    this.showRegistrationCloseDate = !!this.signup?.registrationCloseDate;
    this.showRegistrationCapacity =
      this.signupMetadata?.rsvpRestrictions?.limit >= 0;
  }

  trackBy(idx: number, item: PromptControlType) {
    return item.controlInstance;
  }

  getPromptType(control: PromptControlType): string {
    return this.promptsForm?.get(control?.controlInstance)?.get('type')?.value;
  }

  handleTagDelete(removedTag: {}): void {
    this.tags = this.tags?.filter((tag) => tag !== removedTag);
    this.formGroup.markAsDirty();
    this.formGroup.updateValueAndValidity();
  }

  handleShowTagInput(): void {
    this.tagInputVisible = true;
  }

  handleTagInputConfirm(): void {
    const value = this.formGroup?.controls?.tag?.value;

    if (value && this.tags?.indexOf(value) === -1) {
      this.tags = [...this.tags, value];
      this.tagInputVisible = false;
      this.formGroup.controls.tag.setValue('');
    }
  }

  // This function handles the drop event when an item is moved in a list
  handleDrop(event: CdkDragDrop<PromptControlType[]>) {
    // Get the current and previous index of the dragged item
    const currIdx = event.currentIndex;
    const prevIdx = event.previousIndex;

    // If the item was dropped in the same place it started, do nothing
    if (currIdx === prevIdx) {
      return;
    }

    // Move the item in the copied array from its old position to its new position
    moveItemInArray(this.promptsControls, prevIdx, currIdx);
    this.promptsForm.markAsDirty();
    this.formGroup.updateValueAndValidity();
  }

  handleDragStarted(event: CdkDragStart) {
    const preview =
      this._document.getElementsByClassName('cdk-drag-preview')?.[0];
    this.draggingPlaceholderHeight =
      preview?.getBoundingClientRect()?.height ||
      this.DEFAULT_PLACEHOLDER_HEIGHT;
    this._cdr.detectChanges();
  }

  handleAddPrompt(): void {
    const id = uuidv4();
    const control: PromptControlType = {
      id,
      controlInstance: `prompt${id}`,
      isNew: true,
      options: []
    };

    const index = this.promptsControls?.push(control);
    this.promptsForm.addControl(
      this.promptsControls[index - 1]?.controlInstance,
      this._formBuilder.group({
        prompt: ['', Validators.required],
        type: ['text', Validators.required],
        options: [[]],
        required: [false, Validators.required],
        addOptionText: ['']
      })
    );

    const instance = this.promptsForm.get(control.controlInstance);
    instance
      .get('type')
      .valueChanges.pipe(this.takeUntilDestroy)
      .subscribe(() => instance.get('options')?.updateValueAndValidity());
  }

  handleRemovePrompt(control: PromptControlType): void {
    if (!(this.promptsControls?.length > 0)) {
      return;
    }

    const index =
      this.promptsControls?.length > 1
        ? this.promptsControls?.indexOf(control)
        : 0;
    this.promptsControls?.splice(index, 1);
    this.promptsForm?.removeControl(control?.controlInstance);
  }

  handleAddOptionToPrompt(
    event: Event,
    control: PromptControlType,
    index: number
  ) {
    event.preventDefault();

    const option = this.promptsForm
      .get(control.controlInstance)
      ?.get('addOptionText')?.value;

    if (option?.length > 0) {
      control?.options?.push(option);
      this.promptsForm
        .get(control.controlInstance)
        .patchValue({ options: control.options, addOptionText: '' });
    }

    this.optionInput.toArray()[index].nativeElement.focus();
  }

  handleRemoveOptionFromPrompt(
    option: string,
    control: PromptControlType
  ): void {
    const idx = control?.options?.indexOf(option);
    if (idx === -1) return;
    control?.options?.splice(idx, 1);
    this.promptsForm
      .get(control.controlInstance)
      .patchValue({ options: control.options });
  }

  getCustomFieldTitle(control) {
    return this.promptsForm?.get(control)?.get('prompt')?.value || 'New field';
  }

  getCustomFieldType(control) {
    return this.promptsForm?.get(control)?.get('type')?.value
      ? ' (' + this.promptsForm?.get(control)?.get('type')?.value + ')'
      : '';
  }

  handleShowRegistrationCloseDateChange(value: boolean) {
    this.showRegistrationCloseDate = value;
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({ registrationCloseDate: null });
  }

  handleShowRegistrationCapacityChange(value: boolean) {
    this.showRegistrationCapacity = value;
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({ capacity: null, displayLimit: true });
  }
}
