import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NorbyAlertModule,
  NorbyCollapsibleModule,
  NorbySwitchButtonModule
} from 'uikit';
import { NorbyEditCalendlyBlockAppearanceComponent } from '../components/templates/content-block-editors/norby-edit-calendly-block-appearance';

@NgModule({
  declarations: [NorbyEditCalendlyBlockAppearanceComponent],
  imports: [
    CommonModule,
    NorbySwitchButtonModule,
    NorbyCollapsibleModule,
    ReactiveFormsModule,
    NorbyAlertModule
  ],
  exports: [NorbyEditCalendlyBlockAppearanceComponent]
})
export class NorbyEditCalendlyBlockAppearanceModule {}
