<div
  class="content-container-wrapper"
  [class.mobile]="platform === 'mobile'"
  [class.desktop]="platform === 'desktop'"
>
  <div
    class="content-container"
    [class.has-image]="!content || content?.imageUrl"
  >
    <div class="space-wrapper">
      <div
        class="meta-wrapper"
        [ngClass]="platform == 'mobile' ? 'flex-col' : 'flex-row'"
      >
        <div *ngIf="content && metadataTemplate" class="meta card-text-color">
          <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
        </div>
        <root-skeleton
          *ngIf="!content"
          [title]="false"
          [paragraph]="{ rows: 3, width: 150 }"
        >
        </root-skeleton>
        <div class="main-actions-container" (click)="$event.stopPropagation()">
          <div class="row">
            <div class="wide">
              <lib-event-drop-share-button-view
                [content]="content"
                [userContent]="userContent"
              >
              </lib-event-drop-share-button-view>
            </div>
          </div>
          <div class="row">
            <div class="wide" *ngIf="mainButtonTemplate">
              <ng-container
                *ngTemplateOutlet="mainButtonTemplate"
              ></ng-container>
            </div>
          </div>
        </div>
      </div>
      <ng-container
        [ngTemplateOutlet]="
          !content?.images?.length
            ? null
            : content?.images?.length == 1
            ? singleImageTemplate
            : carouselTemplate
        "
      ></ng-container>

      <root-card
        *ngIf="userContent?.rsvpEnabled && content?.published"
        [bodyStyle]="{ padding: 0 }"
      >
        <lib-rsvp-confirmation-view
          [content]="content"
          [userContent]="userContent"
          [isLoadingUserContent]="isLoading"
          (onSubmitAnother)="handleSubmitAnother()"
        >
        </lib-rsvp-confirmation-view>
      </root-card>

      <root-card *ngIf="content?.body" [bodyStyle]="{ padding: 0 }">
        <div class="content-body ql-readonly-body card-text-color">
          <div
            *ngIf="content.body"
            [innerHTML]="content?.signedBody || content.body | safeHtml"
          ></div>
        </div>
      </root-card>
    </div>
  </div>
</div>

<!-- TPL FOR SINGLE IMAGE BLOCK -->
<ng-template #singleImageTemplate>
  <div
    *ngIf="!content || content?.imageUrl"
    [style.backgroundColor]="
      content?.image?.palettes?.length > 0 ? content.image?.palettes[0] : ''
    "
  >
    <lib-soft-image-view
      [imageUrl]="content?.imageUrl"
      [image]="content?.image"
      [initialHeight]="'100px'"
      [imgixImage]="content?.imgixImage"
      [alt]="content?.title"
    >
    </lib-soft-image-view>
  </div>
</ng-template>

<!-- TPL FOR MULTIPLE IMAGE CAROUSEL -->
<ng-template #carouselTemplate>
  <root-carousel [autoPlay]="true" [autoPlaySpeed]="6000">
    <div carousel-content *ngFor="let image of content?.images; index as i">
      <lib-soft-image-view
        [imageUrl]="image.url"
        [imgixImage]="content?.imgixImages?.[i]"
      >
      </lib-soft-image-view>
    </div>
  </root-carousel>
</ng-template>
