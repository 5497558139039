import { Injectable } from '@angular/core';

// 3rd party
import { Observable, map } from 'rxjs';
import { plainToClass } from 'class-transformer';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

// Libs
import {
  IQueryResult,
  PaginatedQueryFilters,
  PaginatedQuerySummary,
  Conversation,
  PaginatedMessageFilters,
  ConversationMessage,
  ConversationMessageGroup
} from 'models';

// App
import { AiService } from '../ai/ai.service';
import { DeviceService } from '../device';
import {
  ObservableDataService,
  QuerySummary,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  RealtimeServerSocketMessage,
  CONVERSATION_CREATED_IN_SLUG_TOPIC,
  CONVERSATION_DELETED_IN_SLUG_TOPIC,
  CONVERSATION_UPDATED_IN_SLUG_TOPIC,
  AI_CONVERSATION_MESSAGE_TOPIC,
  CONVERSATION_UPDATED_TOPIC,
  CONVERSATION_DELETED_TOPIC
} from '../socket';
import { IAiStoreService } from './ai-store.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AiStoreService implements IAiStoreService {
  constructor(
    private _device: DeviceService,
    private _obs: ObservableDataService,
    private _ai: AiService
  ) {}

  getConversations$(
    args: PaginatedQueryFilters
  ): Observable<PaginatedQuerySummary<Conversation>> {
    const lookup = this._ai.getConversations;

    const transformer = (res: IQueryResult, currentValue: Conversation[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const conversations =
        res?.edges?.map((edge) => {
          return plainToClass(Conversation, edge?.node);
        }) ?? [];

      return {
        items: [...(currentValue ?? []), ...conversations],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONVERSATION_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: Conversation[]
        ) => {
          const addOn = plainToClass(Conversation, event?.data);
          currentValue.unshift(addOn);
          return [...currentValue];
        }
      },
      {
        event: CONVERSATION_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: Conversation[]
        ) => {
          const addOn = plainToClass(Conversation, event?.data);
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );
          if (currentIdx > -1) {
            currentValue[currentIdx] = addOn;
          } else {
            currentValue?.unshift(addOn);
          }
          return [...currentValue];
        }
      },
      {
        event: CONVERSATION_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: Conversation[]
        ) => {
          const addOn = plainToClass(Conversation, event?.data);
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._obs.query$<Conversation>({
      args,
      lookup,
      transformer,
      handlers
    });
  }

  getConversationMessagesById$(
    args: PaginatedMessageFilters
  ): Observable<PaginatedQuerySummary<ConversationMessageGroup>> {
    const transformer = (
      res: IQueryResult,
      currentValue: ConversationMessageGroup[]
    ) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const messages =
        res?.edges?.map((edge) => {
          return plainToClass(ConversationMessage, edge?.node);
        }) ?? [];
      const messageGroups = groupMessages(messages);

      const cursor = hasNextPage ? res?.pageInfo?.maxCursor : null;
      const items = [...messageGroups, ...(currentValue ?? [])];
      return { items, cursor };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: AI_CONVERSATION_MESSAGE_TOPIC,
        payload: {
          resourceId: args.id
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: ConversationMessageGroup[]
        ) => {
          // If the payload is null, just return the currentValue
          if (!event?.data) {
            return currentValue;
          }

          const message = plainToClass(ConversationMessage, event.data);

          // If we have no current list of message groups, initiate one with the
          // newly received message
          if (!currentValue?.length) {
            return [ConversationMessageGroup.fromMessages([message])];
          }

          // Check if the last message group is the optimistic one, and if so,
          // temporarily remove it from currentValue and save a reference to it
          const optimisticMessageGroup = currentValue[currentValue.length - 1]
            .isOptimistic
            ? currentValue.pop()
            : null;

          // Now get a reference to the most recent non-optimistic group
          // and check if the new messages matches the direction of that
          // existing group
          const mostRecentGroup = currentValue[currentValue.length - 1];
          const matchesDirection =
            (mostRecentGroup.isInbound && message.isInbound) ||
            (mostRecentGroup.isOutbound && message.isOutbound);

          // If it matches the direction, just add it to that group
          if (matchesDirection) {
            mostRecentGroup.addNewerMessage(message);
          }

          // If it doesn't match the direction, create a new group and append it
          else {
            currentValue.push(ConversationMessageGroup.fromMessages([message]));
          }

          // Update and restore the optimistic message group
          // If the newly received message is inbound, we just need to
          // restore the optimistic message group to the end of the array
          // If the newly received message is outbound, we need to find
          // the corresponding optimistic message in the optimistic
          // message group and remove it
          if (optimisticMessageGroup) {
            if (message.isOutbound) {
              const existingIndex = optimisticMessageGroup.messages.findIndex(
                (oM) => oM?.content === message.content
              );

              if (existingIndex > -1) {
                optimisticMessageGroup.removeMessageAtIndex(existingIndex);
              }
            }

            // Only restore the optimistic group if it still has elements in it
            if (optimisticMessageGroup.length) {
              currentValue.push(optimisticMessageGroup);
            }
          }

          return [...currentValue];
        }
      }
    ];

    return this._obs
      .query$<ConversationMessageGroup>({
        handlers,
        lookup: this._ai.getConversationMessagesById,
        transformer,
        args
      })
      .pipe(
        map((summary) =>
          summary
            ? {
                ...summary,
                next: (message: ConversationMessage) => {
                  // If there's already a message group for optimistic messages,
                  // just add the new optimistic message to it, otherwise add
                  // a new group for optimistic messages to the end of the list
                  const newestMessageGroup =
                    summary.items[summary.items.length - 1];
                  if (newestMessageGroup.isOptimistic) {
                    newestMessageGroup.addNewerMessage(message);
                  } else {
                    summary.items.push(
                      ConversationMessageGroup.fromMessages([message])
                    );
                  }

                  summary.next([...summary.items]);
                }
              }
            : null
        )
      );
  }

  getConversationById$({
    id
  }: {
    id: string;
  }): Observable<QuerySummary<Conversation>> {
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: CONVERSATION_UPDATED_TOPIC,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(Conversation, event?.data)
      },
      {
        event: CONVERSATION_DELETED_TOPIC,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) =>
          plainToClass(Conversation, event?.data)
      }
    ];

    return this._obs.document$<Conversation>({
      handlers,
      lookup: this._ai.getConversationById,
      args: { id }
    });
  }
}

// Max messages that should be grouped together
const MAX_MESSAGES_PER_GROUP = 6;

// Assumes messages are ordered newest at the top
function groupMessages(
  messages: ConversationMessage[]
): ConversationMessageGroup[] {
  const messageGroups = [];

  if (!messages.length) {
    return messageGroups;
  }

  let currentItem: ConversationMessage = null,
    group: ConversationMessageGroup = new ConversationMessageGroup();

  for (let index = 0; index < messages.length; index++) {
    const previousItem = currentItem;
    currentItem = messages[index];

    const hasSameRole = currentItem?.role === previousItem?.role;
    const canCompareTimestamps =
      currentItem?.timestamp && previousItem?.timestamp;
    const isLessThan2HoursApart =
      canCompareTimestamps &&
      dayjs
        .duration(currentItem.timestamp.diff(previousItem.timestamp))
        .asHours() <= 2;
    const isSameDay =
      canCompareTimestamps &&
      previousItem.timestamp.isSame(currentItem.timestamp, 'day');
    const isNewGroup = !group.length;
    const messagesShouldBeGrouped =
      hasSameRole &&
      isLessThan2HoursApart &&
      isSameDay &&
      group.length < MAX_MESSAGES_PER_GROUP;

    if (isNewGroup || messagesShouldBeGrouped) {
      group.addOlderMessage(currentItem);
    } else {
      messageGroups.unshift(group);
      group = ConversationMessageGroup.fromMessages([currentItem]);
    }
  }

  if (group.length) {
    messageGroups.unshift(group);
  }

  return messageGroups;
}
