import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { filter } from 'rxjs';

// Libs
import { AVAILABLE_GRADIENTS } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-gradient-selector',
  templateUrl: './norby-gradient-selector.component.html',
  styleUrls: [
    '../../../../../../../node_modules/grapick/dist/grapick.min.css',
    './norby-gradient-selector.component.less'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyGradientSelectorComponent),
      multi: true
    }
  ]
})
export class NorbyGradientSelectorComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() helperText?: string;
  @Input() infoTooltip?: string;
  @Input() disabled?: boolean = false;
  @Input() required?: boolean = false;

  val: string;
  formGroup: UntypedFormGroup;

  private _disableOnChangedEmission = false;

  readonly GRADIENTS: string[] = AVAILABLE_GRADIENTS;

  @ViewChild('picker', { static: true }) picker: ElementRef;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;
  private _grapick;
  private _gp: any;
  private _lastEmittedValue: string;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef
  ) {
    super();
    if (isPlatformBrowser(this._platform)) {
      import('grapick').then((Grapick) => {
        this._grapick = Grapick.default;
        this._initGrapick();
        this._cdr.detectChanges();
      });
    }
  }

  get isLinear(): boolean {
    return this.type === 'linear';
  }

  get angle(): number {
    return +(this._getDirection(this._gp)?.replace('deg', '') ?? 0);
  }

  get type(): string {
    return this._gp?.getType() ?? 'linear';
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      angle: [0],
      type: ['linear']
    });

    this.formGroup.valueChanges
      .pipe(
        filter(
          ({ angle, type }) =>
            !!this._gp && (angle !== this.angle || type !== this.type)
        ),
        this.takeUntilDestroy
      )
      .subscribe(({ angle, type }) => {
        this._gp.setDirection(`${angle || 0}deg`, { silent: true });
        this._gp.setType(type);
      });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platform)) {
      this._initGrapick();
    }
  }

  ngOnDestroy(): void {
    this._disableOnChangedEmission = true;
    this._gp?.destroy();
  }

  private _initGrapick() {
    if (!this._grapick || !!this._gp) {
      return;
    }

    this._gp = new this._grapick({
      el: this.picker.nativeElement
    });

    this._gp.setValue(this.val, { silent: true });
    this._syncReactiveForm();
    this._gp.on('change', (_) => this._emitChangesIfNeeded());
  }

  writeValue(value: string) {
    this._disableOnChangedEmission = true;
    this.val = value || 'linear-gradient(0deg, #ffffff 0%, #000000 100%)';

    const safeVal = this._getCurrentSafeValue();
    if (this._gp && this.val !== safeVal) {
      this._gp.setValue(this.val, { silent: true });
      this._syncReactiveForm();
    }

    this._disableOnChangedEmission = false;
  }

  handleChoseGradientFromLibrary(value: string) {
    this.writeValue(value);
    this._emitChangesIfNeeded();
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _emitChangesIfNeeded() {
    if (this._disableOnChangedEmission) {
      return;
    }

    const updatedVal = this._getCurrentSafeValue();
    if (updatedVal !== this._lastEmittedValue) {
      this._lastEmittedValue = updatedVal;
      this._onChanged(updatedVal);
      this._markAsTouched();
    }
  }

  private _getCurrentSafeValue() {
    const safeVal = this._gp?.getSafeValue(
      this.type,
      this.type === 'radial' ? 'center' : `${this.angle}deg`
    );

    return safeVal?.replace(/-webkit-|-moz-|-o-|-ms-/, '');
  }

  private _syncReactiveForm() {
    this.formGroup.setValue(
      { angle: this.angle, type: this.type },
      { emitEvent: false }
    );
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  private _getDirection(gp) {
    return gp
      ?.getDirection()
      ?.replace('to top', '0deg')
      ?.replace('to bottom', '180deg')
      ?.replace('to left', '270deg')
      ?.replace('to right', '90deg');
  }
}
