import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import {
  RegisterableDetailClassicLeftViewComponent,
  RegisterableDetailClassicRightViewComponent,
  RegisterableDetailClassicVerticalComponent,
  RegisterableDetailLargeImageViewComponent
} from '../views/registerable-detail';
import { RsvpConfirmationViewModule } from './rsvp-confirmation-view.module';
import { AlertModule } from './alert.module';
import { CardModule } from './card.module';
import { CarouselModule } from './carousel.module';
import { EventDropShareButtonViewModule } from './event-drop-share-button-view.module';
import { EventMainButtonViewModule } from './event-main-button-view.module';
import { IconModule } from './icon.module';
import { SafeHtmlPipeModule } from './safe-html-pipe.module';
import { SkeletonModule } from './skeleton.module';
import { SoftImageViewModule } from './soft-image-view.module';
import { TagModule } from './tag.module';
import { DropMainButtonViewModule } from './drop-main-button-view.module';

@NgModule({
  declarations: [
    RegisterableDetailClassicLeftViewComponent,
    RegisterableDetailClassicRightViewComponent,
    RegisterableDetailClassicVerticalComponent,
    RegisterableDetailLargeImageViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    EventMainButtonViewModule,
    DropMainButtonViewModule,
    EventDropShareButtonViewModule,
    SoftImageViewModule,
    RsvpConfirmationViewModule,
    SafeHtmlPipeModule,
    TagModule,
    IconModule,
    CardModule,
    AlertModule,
    SkeletonModule,
    CarouselModule
  ],
  exports: [
    RegisterableDetailClassicLeftViewComponent,
    RegisterableDetailClassicRightViewComponent,
    RegisterableDetailClassicVerticalComponent,
    RegisterableDetailLargeImageViewComponent
  ]
})
export class RegisterableDetailViewModule {}
