import { Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';

const momentTransformer = ({ value }) => (value ? dayjs(value) : null);

export class BillingEstimateSummary {
  total: number;
  subtotal: number;
  amountDue: number;
}

export class BillingEstimateLineItemPeriod {
  @Transform(momentTransformer, { toClassOnly: true })
  start: dayjs.Dayjs;

  @Transform(momentTransformer, { toClassOnly: true })
  end: dayjs.Dayjs;
}

export class BillingEstimateLineItem {
  description: string;
  quantity: number;
  unitAmountDecimal: string;
  amount: number;

  @Type(() => BillingEstimateLineItemPeriod)
  period: BillingEstimateLineItemPeriod;

  get periodSummary(): string {
    const start = this.period?.start;
    const end = this.period?.end;
    return start && end
      ? `${start?.format('MMMM D, YYYY')} to ${end?.format('MMMM D, YYYY')}`
      : null;
  }
}

export class BillingEstimate {
  summary: BillingEstimateSummary;

  @Type(() => BillingEstimateLineItem)
  lineItems: Array<BillingEstimateLineItem>;
}

export class SlugReferralSummary {
  amountPending!: number;
  amountAwarded!: number;
  amountRedeemed!: number;
  amountRemaining!: number;
}
