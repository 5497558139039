import { Component, Input } from '@angular/core';

@Component({
  selector: 'norby-shape-selector-item',
  templateUrl: './norby-shape-selector-item.component.html',
  styleUrls: ['./norby-shape-selector-item.component.less']
})
export class NorbyShapeSelectorItemComponent {
  @Input() url: string;
  @Input() label: string;
  @Input() selected: boolean;

  constructor() {}
}
