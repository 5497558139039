<label
  *ngIf="label"
  class="form-label inline-block mb-1 text-lg text-neutral-800"
>
  {{ label }}
</label>

<norby-configurable-selector
  key="key"
  label="label"
  [selectorType]="NORBY_SELECTOR_TYPES_ENUM.SOCIAL_LINKS_SELECTOR"
  [placeholder]="placeholder"
  [items]="links"
  [value]="value"
  [isDisabled]="isDisabled"
  [optionHeightPx]="65"
  [optionOverflowSize]="4"
  [optionTemplate]="optionTemplate"
  (onValueChange)="handleChange($event)"
></norby-configurable-selector>

<ng-template #optionTemplate let-item>
  <div class="my-2">
    <div class="flex flex-row">
      <norby-icon
        [name]="item.icon"
        size="small"
        class="flex-none px-0.5 text-neutral-500"
      ></norby-icon>
      <div class="flex flex-col flex-auto px-2 justify-center overflow-hidden">
        <span class="text-xs truncate text-neutral-800">{{ item.label }}</span>
        <span
          *ngIf="item.url != ''"
          class="text-xs truncate text-neutral-600"
          >{{ item.url }}</span
        >
      </div>
    </div>
  </div>
</ng-template>
