<form [formGroup]="formGroup">
  <div
    class="border rounded-none border-mustard"
    [style.paddingTop.px]="block?.paddingTop ?? DEFAULT_BLOCK_PADDING"
    [style.paddingRight.px]="block?.paddingRight ?? DEFAULT_BLOCK_PADDING"
    [style.paddingBottom.px]="block?.paddingBottom ?? DEFAULT_BLOCK_PADDING"
    [style.paddingLeft.px]="block?.paddingLeft ?? DEFAULT_BLOCK_PADDING"
  >
    <norby-rich-text-editor-view
      formControlName="body"
      [useVariables]="true"
      [initWithFocus]="true"
      [isSendField]="true"
      [isBorderless]="true"
      [hasImageSelection]="false"
    ></norby-rich-text-editor-view>
  </div>
</form>
