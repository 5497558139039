import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SendThumbnailPreviewViewComponent } from '../views/send-thumbnail-preview-view';
import { SafeHtmlPipeModule } from 'uikit';

@NgModule({
  declarations: [SendThumbnailPreviewViewComponent],
  imports: [CommonModule, SafeHtmlPipeModule],
  exports: [SendThumbnailPreviewViewComponent]
})
export class SendThumbnailPreviewViewModule {}
