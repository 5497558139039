import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SingleSend } from 'models';

@Component({
  selector: 'norby-sends-schedule-confirmation-view',
  templateUrl: './norby-sends-schedule-confirmation-view.component.html',
  styleUrls: ['./norby-sends-schedule-confirmation-view.component.less']
})
export class NorbySendsScheduleConfirmationViewComponent implements OnInit {
  @Input() send: SingleSend;
  @Input() scheduleDisplay: string;
  @Output() onReturnToDashboard: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  get deliveryType() {
    return (
      this.send?.deliveryType.charAt(0).toUpperCase() +
      this.send?.deliveryType.slice(1)
    );
  }

  ngOnInit(): void {}

  handleReturnToDashboard(): void {
    this.onReturnToDashboard.emit();
  }
}
