<div
  #viewportWrapper
  class="viewport-wrapper"
  [class.mask]="overlayMask"
  (click)="handleClickBackground()"
>
  <div
    class="bottom-sheet-container"
    [class.is-expanded]="isExpanded"
    [class.is-scrollable]="isScrollable"
    [class.is-dragging]="isDragging"
    [class.is-collapsed]="isCollapsed"
    [class.shadow]="showDropShadow && !isExpanded"
    #bottomSheetContainer
    [style.transform]="bottomSheetTransform"
    [style.background-color]="backgroundColor"
    [style.padding]="bottomSheetPadding"
    (click)="$event.stopPropagation()"
  >
    <nz-spin [nzSpinning]="loading">
      <div
        class="bottom-sheet-inner"
        #bottomSheetInner
        [style.height]="bottomSheetInnerHeight"
      >
        <div
          class="bottom-sheet-handle"
          [style.background-color]="backgroundColor"
        >
          <div></div>
        </div>
        <div class="bottom-sheet-content" [class.flush]="flush">
          <ng-content *ngIf="!loading"></ng-content>
        </div>
        <div
          class="collapsed-text"
          *ngIf="collapsible && !loading && collapsedText && isCollapsed"
        >
          <h4>{{ collapsedText }}</h4>
        </div>
      </div>
    </nz-spin>
  </div>
</div>
