import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// 3rd party
import { filter, tap } from 'rxjs';
import { plainToClass } from 'class-transformer';

// App
import { IProductAnalytics } from './product-analytics.service.interface';
import { ApiService, SocketService } from 'shared';
import {
  ProductAnalyticsEventType,
  ProductAnalyticsProperties,
  ENDPOINTS,
  TrackProductAnalytics,
  ApiSurfaces
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class ProductAnalyticsService implements IProductAnalytics {
  private _previousRoute: string;

  constructor(
    @Inject(DOCUMENT) private _document,
    private _api: ApiService,
    private _router: Router,
    private _socket: SocketService
  ) {
    this._router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd && event.url !== this._previousRoute // workaround for angular bug that fires multiple NavigationEnd events
        ),
        tap((event: NavigationEnd) => (this._previousRoute = event.url))
      )
      .subscribe((event: NavigationEnd) => {
        const path = event.url;
        this.dashboardPageViewStart({ path });
      });
  }

  /**
   * Track a dashboard page view
   * @param path path to start tracking on
   */
  dashboardPageViewStart({ path }: { path: string }) {
    this._socket.sendMessage({
      message: 'interactions/dashboard_page_view',
      payload: {
        path,
        action: 'start',
        domReferrer: this._document?.referrer
      }
    });
  }

  /**
   * Used to tell the socket to stop tracking a dashboard page view. Not generally needed as
   * each PageStart event closes out the tracking for the previous page, and starts
   * it for the newely specified page. Since we call pageViewStart on every route change
   * we do not need to issue stops.
   * @param path path to stop tracking on
   */
  dashboardPageViewStop({ path }: { path: string }) {
    this._socket.sendMessage({
      message: 'interactions/dashboard_page_view',
      payload: {
        path,
        action: 'stop',
        domReferrer: this._document?.referrer
      }
    });
  }

  async trackProductAnalytics(
    event: ProductAnalyticsEventType,
    properties?: ProductAnalyticsProperties
  ): Promise<void> {
    const data = plainToClass(TrackProductAnalytics, {
      data: {
        event,
        properties,
        timestamp: new Date()
      }
    });

    await this._api.post<any>(
      ApiSurfaces.API,
      ENDPOINTS.analytics.trackProductAnalytics,
      data
    );
  }
}
