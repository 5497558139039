<root-spin [spinning]="!isDataReady">
  <div *ngIf="isDataReady">
    <div *ngIf="isAll" class="rounded bg-azure-800 px-6 py-4 mb-5">
      <h3>All contacts</h3>
      <p class="m-0">
        This is where you can find, manage, and filter all of your contacts.
        Every identifiable contact you collect will appear here.
      </p>
    </div>
    <div *ngIf="isEmail" class="rounded bg-azure-800 px-6 py-4 mb-5">
      <h3>Email contacts</h3>
      <p class="m-0">
        This is where you can find, manage, and filter all of your email
        contacts. Every contact you collect that has an email address will
        appear here.
      </p>
    </div>
    <div *ngIf="isSms" class="rounded bg-azure-800 px-6 py-4 mb-5">
      <h3>SMS contacts</h3>
      <p class="m-0">
        This is where you can find, manage, and filter all of your SMS contacts.
        Every contact you collect that has provided a phone number for SMS
        messaging will appear here.
      </p>
    </div>
    <form [formGroup]="form" nz-form nzLayout="vertical">
      <nz-form-item [rootTooltip]="tooltipMessage">
        <nz-form-label nzRequired>Name</nz-form-label>
        <nz-form-control>
          <input formControlName="name" nz-input placeholder="My New List" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item
        *ngIf="canSaveCurrentList && (editList?.isCustom || !editList)"
        [rootTooltip]="tooltipMessage"
      >
        <nz-form-label>Visibility</nz-form-label>
        <p class="text-neutral-600">
          Private lists will only be visible to you. Shared lists will be
          visible and editable by anyone on your team.
        </p>
        <nz-form-control>
          <norby-radio-button-group
            formControlName="visibility"
            [block]="true"
            [rootTooltip]="referenceTooltipMessage"
            [isDisabled]="!canSaveCurrentList || hasReference"
          >
            <norby-radio-button
              class="flex flex-auto"
              buttonLabel="Private"
              buttonValue="private"
            ></norby-radio-button
            ><norby-radio-button
              class="flex flex-auto"
              buttonLabel="Shared"
              buttonValue="public"
            ></norby-radio-button>
          </norby-radio-button-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="!canSaveCurrentList && editList?.isCustom">
        <nz-form-label>Owner</nz-form-label>
        <div rootTooltip="Owns this list">
          <lib-user-row
            size="small"
            [userId]="editList?.createdBy"
          ></lib-user-row>
        </div>
      </nz-form-item>

      <ng-container *ngIf="editList && !saveAsNew">
        <nz-divider></nz-divider>

        <button
          nz-button
          nzBlock
          aria-label="Save a copy"
          [nzLoading]="isSaveCopyLoading"
          (click)="handleSaveCopy()"
        >
          <i nz-icon nzType="feather/copy"></i>
          Save a copy
        </button>

        <button
          nz-button
          nzBlock
          aria-label="Export list as CSV"
          [nzLoading]="isExportLoading"
          (click)="handleExportClick()"
          class="mt-3"
        >
          <i nz-icon nzType="feather/download"></i>
          Export as a CSV
        </button>

        <button
          nz-button
          nzBlock
          nzDanger
          aria-label="Delete list"
          *ngIf="canSaveCurrentList && editList.isCustom"
          [rootTooltip]="referenceDeleteMessage"
          [nzLoading]="isDeleteLoading"
          [disabled]="hasReference"
          (nzOnConfirm)="handleDeleteClick()"
          nz-popconfirm
          nzPopconfirmTitle="Are you sure?"
          class="mt-3"
        >
          <i nz-icon nzType="feather/trash"></i>
          Delete list
        </button>
      </ng-container>
    </form>
  </div>
</root-spin>
