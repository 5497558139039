export enum DowngradeStagesEnum {
  REASON = 'reason',
  PAIN_POINTS = 'painPoints',
  LACKING_FEATURES = 'lackingFeatures',
  CHANGE_IN_NEEDS = 'changeInNeeds',
  RETENTION_PROMO = 'retentionPromo',
  CONFIRMATION = 'confirmation'
}

export type DowngradeStages = `${DowngradeStagesEnum}`;

export enum DowngradeReason {
  PAIN_POINTS = 'painPoints',
  BUDGET = 'budget',
  STEEP_LEARNING_CURVE = 'steepLearningCurve',
  LACKING_FEATURES = 'lackingFeatures',
  CHANGE_IN_NEEDS = 'changeInNeeds',
  DIFFERENT_SOLUTION = 'differentSolution',
  OTHER = 'other'
}

export type DowngradeReasonType = `${DowngradeReason}`;

export type PainPointType =
  | 'createLandingPage'
  | 'createEventOrSignup'
  | 'addContacts'
  | 'scheduleSmsOrSend'
  | 'messagingAndInbox'
  | 'integrations'
  | 'customizingPage'
  | 'analytics'
  | 'navigatingDashboard'
  | 'other';

export type OtherSolutions =
  | 'automations'
  | 'appointmentBooking'
  | 'moreAnalytics'
  | 'landingPageTemplates'
  | 'betterCustomerSupport'
  | 'flexiblePricingOptions'
  | 'improvedExperience'
  | 'other';

export type DowngradePromoCopy = {
  title: string;
  subtitle: string;
  durationInMonths: number;
  reminders: string[];
  promoCode: string;
  promoCodeId: string;
  ctaLabel: string;
};
