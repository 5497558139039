import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// App
import { UserRowModule } from '../../modules/user-row.module';
import { InboxContactDetailsModule } from '../../modules/inbox-contact-details.module';
// Libs
import { IconModule } from 'uikit';
import { NorbyNavigationBarModule } from 'customer-uikit';

// 3rd party
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { ContactDetailsDrawerComponent } from './contact-details-drawer.component';

@NgModule({
  declarations: [ContactDetailsDrawerComponent],
  imports: [
    CommonModule,
    RouterModule,
    InboxContactDetailsModule,
    IconModule,
    UserRowModule,
    NorbyNavigationBarModule,

    NzButtonModule,
    NzTagModule,
    NzDrawerModule,
    NzAvatarModule,
    NzToolTipModule,
    NzPopconfirmModule
  ],
  exports: [ContactDetailsDrawerComponent]
})
export class ContactDetailsDrawerModule {}
