import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IContact,
  SegmentContactPreview,
  SegmentPreviewResponseDTO,
  SingleSend,
  generateRandomName
} from 'models';

@Component({
  selector: 'norby-segment-preview-list',
  templateUrl: './norby-segment-preview-list.component.html',
  styleUrls: ['./norby-segment-preview-list.component.less']
})
export class NorbySegmentPreviewListComponent implements OnInit {
  @Input() send: SingleSend;
  @Input() segmentPreviewResponse: SegmentPreviewResponseDTO;
  @Output() onClose = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  get segmentPreviewCountDisplay(): string {
    return `${
      this.segmentPreviewResponse?.segmentExceedsPreviewLimit
        ? '5000+'
        : this.segmentPreviewResponse?.segmentPreviewCount.toString()
    } people`;
  }

  getNameForContact(contact: IContact) {
    return contact?.name ?? generateRandomName(contact?.id).label;
  }

  contactListInfoDisplay(contact: SegmentContactPreview) {
    return this.send.deliveryType === 'email'
      ? (contact.primaryEmail ?? '')
      : (contact.primaryPhoneNumber ?? '');
  }
  handleClose() {
    this.onClose.emit();
  }
}
