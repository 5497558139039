<lib-device-preview-view
  [platform]="platform"
  [isInteractive]="isInteractive"
  [isScrollable]="isScrollable"
  [showAsAbstract]="showAsAbstract"
  [backgroundCss]="backgroundCss"
  *ngIf="isBrowser"
>
  <lib-landing-page-view
    live-themed
    [page]="page"
    [accountInfo]="accountInfo"
    [theme]="theme"
    [showAsAbstract]="showAsAbstract"
    (onContentBlocksUpdate)="handleContentBlocksUpdated($event)"
  ></lib-landing-page-view>
</lib-device-preview-view>
