import { Directive, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';

// 3rd party
import { Observable, BehaviorSubject, Subject, fromEvent } from 'rxjs';
import {
  map,
  tap,
  scan,
  mergeMap,
  throttleTime,
  takeUntil,
  debounceTime,
  filter
} from 'rxjs/operators';

// App
import { isBrowser } from 'models';
import { BaseComponent } from 'uikit';

const THROTTLE_TIME = 10;
const DEBOUNCE_TIME = 150;
const FETCH_TRIGGER_DISTANCE = 300; // Distance from scroll bottom to trigger fetch

// A map of cursors to pages (arrays of document changes)
type PageMapType = { [pageKey: string]: QueryDocumentSnapshot<any>[] };

// A map of document ids to document snapshots
// Used in deduping process for generating items array
type DocMapType = { [id: string]: QueryDocumentSnapshot<any> };

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class InfiniteScrollComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() scrollTrackingEnabled = true;

  private _reset$ = new Subject<void>();
  private _offset$ = new BehaviorSubject<string>(null);
  private _cursor: string;

  isFinished = false;
  isLoadingFirstBatch = true;
  isLoadingNextBatch = false;
  items = Array(this.placeholderCount).fill(null);

  ngOnInit() {
    this._initScrollListener();
  }

  ngOnChanges() {
    super.ngOnChanges();
    this._initScrollListener();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._reset$?.next();
    this._reset$?.complete();
  }

  private _initScrollListener() {
    if (this.scrollTrackingEnabled && isBrowser)
      fromEvent(window, 'scroll')
        .pipe(this.takeUntilChanges)
        .subscribe((event: Event) => {
          const doc = event.target as Document;
          const scrollingEl = doc.scrollingElement;
          const defaultView = doc.defaultView;
          const distance =
            scrollingEl.scrollHeight -
            scrollingEl.scrollTop -
            defaultView.innerHeight;
          if (distance < FETCH_TRIGGER_DISTANCE) this._fetchMore();
        });
  }

  // Query to use for infinite scroll
  abstract documentQuery(
    startAfter?: string
  ): Observable<QueryDocumentSnapshot<any>[]>;

  // Will store the value of this field on the last item in items
  // as the saved cursor for the next page
  abstract get cursorField(): string;

  // Number of placeholder items to show before first batch loads
  get placeholderCount(): number {
    return 6;
  }

  // Transformer to use for document data returned by query
  // Used primarily to map raw content objects to classes
  // Default provided, override for arbitrary transforms
  itemMapper(item: any) {
    return item;
  }

  // Used with *ngFor in templates with infinite scroll lists
  // Prevents previously loaded chunks from reloading
  trackByIdx(idx: number) {
    return idx;
  }

  // Clear state and load first chunk
  protected resetInfiniteScroll() {
    if (!isBrowser) return;

    this._reset$.next(null);
    this.isFinished = false;
    this.isLoadingNextBatch = false;
    this.isLoadingFirstBatch = true;
    this.items = this.items.fill(null);
    this._offset$.next(null);

    this._offset$
      .pipe(
        // Filter duplicates
        filter((c) => !this.isLoadingNextBatch),

        // Set a ceiling on how fast requests can be fired off
        throttleTime(THROTTLE_TIME),

        // Set loading new page state if a cursor is coming through the pipe
        tap((c) => (this.isLoadingNextBatch = !!c)),

        // Subscribe to the query for the given cursor
        mergeMap((startAfter) => this._queryForBatch(startAfter)),

        // Merge in the new page
        scan((acc, cur) => ({ ...acc, ...cur }), {} as PageMapType),

        // If a bunch of pages just updated in quick succession, we
        // want to make sure we don't do a lot of wasted work – only
        // bother to flatten and generate a new items snapshot when
        // things have settled down
        debounceTime(DEBOUNCE_TIME),

        // Remember to unsubscribe :)
        this._takeUntilReset
      )
      .subscribe((pageMap) => {
        // Flatten pages into a single deduped map
        const docMap = Object.values(pageMap).reduce(
          (acc, docList) => ({
            ...acc,
            ...docList.reduce(
              (innerDocMap, snapshot) => ({
                ...innerDocMap,
                [snapshot.id]: snapshot
              }),
              {} as DocMapType
            )
          }),
          {} as DocMapType
        );
        const snapshots = Object.values(docMap);
        this.isLoadingFirstBatch = false;
        this.isLoadingNextBatch = false;
        this.items = snapshots.map((snapshot) =>
          this.itemMapper(snapshot.data())
        );
        this._cursor = this.items[this.items.length - 1]?.[this.cursorField];
      });
  }

  private _fetchMore() {
    if (this.isFinished || this.isLoadingNextBatch || this.isLoadingFirstBatch)
      return;

    this._offset$.next(this._cursor);
  }

  private _takeUntilReset = <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(takeUntil(this._reset$));
  };

  private _queryForBatch(startAfter: string = ''): Observable<PageMapType> {
    return this.documentQuery(startAfter).pipe(
      tap((arr) => (arr?.length ? null : (this.isFinished = true))),
      map((arr) => ({ [startAfter]: arr })),
      this._takeUntilReset
    );
  }
}
