<div class="flex justify-start items-center mt-3 mb-3">
  <norby-icon name="users"></norby-icon>
  <h3 class="pl-2 m-0">{{ segmentPreviewCountDisplay }}</h3>
</div>

<div
  *ngFor="let contact of segmentPreviewResponse.previewContacts; index as i"
  class="flex h-8"
>
  <div class="flex flex-1 justify-between items-center">
    <div class="flex flex-1 recipient-list-item">
      <span>{{ i + 1 }}.</span>
      <div class="flex flex-1 justify-between">
        <span [ngClass]="{ 'text-neutral-600': !contact.name }">{{
          getNameForContact(contact)
        }}</span>
        <span class="text-neutral-500">
          {{ contactListInfoDisplay(contact) }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="flex mt-10">
  <norby-button
    text="Close list"
    (buttonClicked)="handleClose()"
    class="flex flex-1"
  ></norby-button>
</div>
