import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import {
  DEFAULT_BLOCK_PADDING,
  ISlug,
  SignupSingleSendBlock,
  Content,
  SingleSend,
  populateButtonOrCardTheme
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'edit-email-signup-block',
  templateUrl: './edit-email-signup-block.component.html',
  styleUrls: ['./edit-email-signup-block.component.less']
})
export class EditEmailSignupBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() slug: ISlug;
  @Input() send: SingleSend;
  @Input() block: SignupSingleSendBlock;
  @Input() contentLabel: string;

  @Output() onCreateClick = new EventEmitter();
  @Output() onEditDetailsClick = new EventEmitter<Content>();
  @Output() onUpdatedBlock = new EventEmitter<{
    block: SignupSingleSendBlock;
    content: Content;
  }>();

  formGroup: UntypedFormGroup;

  private _selectedSignup: Content;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        signup: this.blockSignup,
        ...(!!this.block?.theme?.card?.borderRadius && {
          borderRadius: Number(this.block?.theme?.card?.borderRadius)
        }),
        ...(!!this.block?.theme?.card?.borderWidth && {
          borderWidth: Number(this.block?.theme?.card?.borderWidth)
        }),
        dropShadow: this.block?.theme?.card?.dropShadow,
        backgroundColor: this.block?.theme?.card?.backgroundColor,
        textColor: this.block?.theme?.card?.textColor,
        borderColor: this.block?.theme?.card?.borderColor,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  get blockSignup(): Content {
    return this.send?.contentBlockMap?.[this.block.contentId] ?? null;
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      signup: [this.blockSignup],
      borderRadius: [
        this.block?.theme?.card?.borderRadius,
        [Validators.min(0), Validators.max(100)]
      ],
      borderWidth: [
        this.block?.theme?.card?.borderWidth,
        [Validators.min(0), Validators.max(100)]
      ],
      dropShadow: [this.block.theme?.card?.dropShadow],
      backgroundColor: [this.block.theme?.card?.backgroundColor],
      textColor: [this.block.theme?.card?.textColor],
      borderColor: [this.block.theme?.card?.borderColor],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) => {
          if (value.signup) {
            this._selectedSignup = value.signup;
          }

          const cardTheme = {
            card: populateButtonOrCardTheme(value)
          };
          return plainToClass(SignupSingleSendBlock, {
            ...this.block,
            contentId: value?.signup?.contentId ?? null,
            ...value,
            theme: cardTheme
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((block) =>
        this.onUpdatedBlock.emit({ block, content: this._selectedSignup })
      );
  }

  handleCreateClick() {
    this.onCreateClick.emit();
  }

  handleEditDetails() {
    this.onEditDetailsClick.emit(this.blockSignup);
  }
}
