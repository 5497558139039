import { Injectable } from '@angular/core';
import { ISearchResults, ISearchService } from 'models';

export type SearchToken = { token: string };

@Injectable({
  providedIn: 'root'
})
export class MockSearchService implements ISearchService {
  async searchContacts(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
  }): Promise<ISearchResults> {
    return Promise.resolve(null);
  }

  async searchContent(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    excludeContent?: string[];
    incluceContent?: string[];
  }): Promise<ISearchResults> {
    return Promise.resolve(null);
  }

  async searchContactLists(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    digest?: boolean;
  }): Promise<ISearchResults> {
    return Promise.resolve(null);
  }

  async searchSends(queryFilters: {
    query: string;
    limit?: number;
    offset?: number;
    includeSentOnly?: boolean;
  }): Promise<ISearchResults> {
    return Promise.resolve(null);
  }
}
