<div class="signup-container">
  <form [formGroup]="formGroup" class="signup-wrapper">
    <h1>Confirmation</h1>

    <p class="text-neutral-600">
      Customize the confirmation message sent to users after they complete your
      signup.
    </p>

    <norby-select
      label="When someone registers..."
      formControlName="confirmationMedium"
      (onHandleChange)="handleConfirmationMediumChange($event)"
    >
      <option value="none">Don't send a confirmation</option>
      <option value="sms" *ngIf="smsEnabledForSignup">
        Send a SMS confirmation
      </option>
      <option value="email" *ngIf="emailEnabledForSignup">
        Send an email confirmation
      </option>
    </norby-select>

    <div *ngIf="sendSmsConfirmation">
      <div class="mt-2 mb-6">
        <norby-custom-message-tooltip
          action="customize SMS notifications"
          [isNorbyNumberNeeded]="true"
          [trackProductAnalytics]="UPSELL_SIGNUP"
          [properties]="UPSELL_NOTIFICATION_PROPERTIES"
          (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
        >
          <norby-rich-text-editor-view
            formControlName="confirmationBodySms"
            helperText="Tip: Type '@' to insert a variable."
            placeholder="You're confirmed for..."
            label="Message"
            [hasToolbar]="false"
            [useVariables]="true"
            [isDisabled]="shouldDisableSmsFields"
          ></norby-rich-text-editor-view>
        </norby-custom-message-tooltip>
        <lib-link-shortener-popover-view
          [message]="confirmationBodySms"
          (onUpdatedShowingPopup)="handleShowingPopup($event)"
        >
        </lib-link-shortener-popover-view>
        <norby-segment-counter
          [message]="confirmationBodySms"
          [shouldHidePopup]="hideAiSMSPopup || showingPopup"
          (onTogglePopup)="handleTogglePopup()"
          (onShortenedMessage)="handleShortenedMessage($event)"
        ></norby-segment-counter>
      </div>

      <div *ngIf="sendSmsConfirmation" class="mb-5">
        <norby-formcontrol-label
          label="Attachments"
          infoTooltip="Attach images or video to your SMS notification"
        >
        </norby-formcontrol-label>

        <norby-custom-message-tooltip
          action="send attachments"
          [isNorbyNumberNeeded]="true"
          [trackProductAnalytics]="UPSELL_SIGNUP"
          [properties]="UPSELL_ATTACHMENTS_PROPERTIES"
          (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
        >
          <root-spin [spinning]="isUploading">
            <nz-upload
              [nzLimit]="ATTACHMENT_LIMIT"
              [(nzFileList)]="attachmentFileList"
              nzListType="picture"
              [nzMultiple]="false"
              [nzBeforeUpload]="uploadFile"
              (nzChange)="handleAttachmentUploadChange($event)"
              [nzAccept]="ATTACHMENT_MIME_TYPES"
              class="flex flex-1 flex-col"
            >
              <button
                nz-button
                nzBlock
                nzType="secondary"
                [disabled]="shouldDisableSmsFields"
              >
                <root-icon name="plus"></root-icon>Add an attachment
              </button>
            </nz-upload>
          </root-spin>
        </norby-custom-message-tooltip>
      </div>

      <div class="mb-4 flex">
        <norby-button
          text="Send a test..."
          buttonType="secondary"
          (click)="sendTest('sms', 'rsvpConfirmation')"
        ></norby-button>
      </div>
    </div>

    <div *ngIf="sendEmailConfirmation">
      <div class="mt-2 mb-5">
        <norby-rich-text-editor-view
          label="Subject"
          formControlName="confirmationSubject"
          placeholder="You're confirmed!"
          helperText="Tip: Type '@' to insert a variable."
          [isSingleLine]="true"
          [hasToolbar]="false"
          [useVariables]="true"
        ></norby-rich-text-editor-view>
      </div>

      <div class="mb-5">
        <norby-rich-text-editor-view
          formControlName="confirmationBodyEmail"
          placeholder="You're confirmed for..."
          helperText="Tip: Type '@' to insert a variable."
          [useVariables]="true"
        ></norby-rich-text-editor-view>
      </div>

      <div class="mb-4 flex">
        <norby-button
          text="Send a test..."
          buttonType="secondary"
          (click)="sendTest('email', 'rsvpConfirmation')"
          [isDisabled]="disableSendTest"
        ></norby-button>
      </div>
    </div>
  </form>
</div>
