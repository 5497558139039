<div
  class="flex flex-row items-center min-w-0 font-normal hover:text-neutral-800"
  [ngClass]="{
    'text-neutral-800': isActive,
    'text-neutral-700': !isActive
  }"
>
  <norby-icon [name]="avatarIcon" (click)="handleItemClick()"></norby-icon>

  <label
    class="flex-auto ml-1 truncate cursor-pointer"
    [ngClass]="{ 'font-medium': isActive }"
    (click)="handleItemClick()"
  >
    {{ title }}
  </label>

  <norby-icon
    name="zap_fill"
    class="text-lilac-base fill-lilac-base ml-1"
    *ngIf="blockChanged"
  ></norby-icon>
</div>
