import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalLoadingIndicatorViewComponent } from '../views/global-loading-indicator-view';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [GlobalLoadingIndicatorViewComponent],
  imports: [CommonModule, IconModule],
  exports: [GlobalLoadingIndicatorViewComponent]
})
export class GlobalLoadingIndicatorViewModule {}
