import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardViewComponent } from '../views';

@NgModule({
  declarations: [SummaryCardViewComponent],
  imports: [CommonModule],
  exports: [SummaryCardViewComponent]
})
export class SummaryCardViewModule {}
