import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { NorbyFormcontrolLabelModule, NorbyIconModule } from 'uikit';
import { NorbyContentSelectorComponent } from '../components/atoms/norby-content-selector';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';

@NgModule({
  declarations: [NorbyContentSelectorComponent],
  imports: [
    CommonModule,
    NorbyFormcontrolLabelModule,
    NorbyConfigurableSelectorModule,
    NorbyIconModule
  ],
  exports: [NorbyContentSelectorComponent]
})
export class NorbyContentSelectorModule {}
