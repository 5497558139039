import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { NorbyContentSelectorByIdComponent } from '../components/atoms/norby-content-selector-by-id';
import { NorbyIconModule, NorbyFormcontrolLabelModule } from 'uikit';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';

@NgModule({
  declarations: [NorbyContentSelectorByIdComponent],
  imports: [
    CommonModule,
    NorbyFormcontrolLabelModule,
    NorbyConfigurableSelectorModule,
    NorbyIconModule
  ],
  exports: [NorbyContentSelectorByIdComponent]
})
export class NorbyContentSelectorByIdModule {}
