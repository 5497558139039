import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyDropShadowSlidersComponent } from '../components/molecules/norby-drop-shadow-sliders';
import { NorbySliderModule } from 'uikit';

@NgModule({
  declarations: [NorbyDropShadowSlidersComponent],
  exports: [NorbyDropShadowSlidersComponent],
  imports: [CommonModule, ReactiveFormsModule, NorbySliderModule]
})
export class NorbyDropShadowSlidersModule {}
