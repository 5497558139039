import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytes
} from '@angular/fire/storage';

// Libs
import {
  ACCEPTED_IMAGE_MIME_TYPES_STR,
  IContactNote,
  IPSQLContact,
  uuidv4
} from 'models';
import {
  BaseComponent,
  ErrorService,
  IconService,
  MessageService,
  MessageType,
  rootCheckCircle,
  rootMail,
  rootMessageCircle,
  rootPlus,
  rootRefreshCw,
  rootSlash,
  rootStar,
  rootTrash,
  rootUpload
} from 'uikit';

// 3rd party
import dayjs from 'dayjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { plainToClass } from 'class-transformer';

// App
import { DeviceService, InboxService, LoggerService } from '../../services';

@Component({
  selector: 'app-inbox-contact-details',
  templateUrl: './inbox-contact-details.component.html',
  styleUrls: ['./inbox-contact-details.component.less']
})
export class InboxContactDetailsComponent extends BaseComponent {
  @Input() actionButtonsTpl?: TemplateRef<any>;
  @Input() contact: IPSQLContact;
  @Input() showDelete: boolean = true;

  @Output() onDelete = new EventEmitter<void>();
  @Output() onViewInbox = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<IPSQLContact>();

  @ViewChild('tagInputElement', { static: false })
  tagInputElement?: ElementRef;

  slug: string;
  formGroup: UntypedFormGroup;
  isLoading: boolean = false;
  isLoadingContact: boolean = true;
  isTagInputVisible: boolean = false;
  isNameInputVisible: boolean = false;
  isUploadingAvatar: boolean = false;
  contactNotes: IContactNote[] = [];

  // emails
  isEmailInputVisible: boolean = false;
  emailInputVisibleAtIndex = null;
  emails = [];
  emailsToAdd = [];
  emailsToRemove = [];

  readonly IMAGE_MIME_TYPES = ACCEPTED_IMAGE_MIME_TYPES_STR;

  constructor(
    private _device: DeviceService,
    private _formBuilder: UntypedFormBuilder,
    private _inbox: InboxService,
    private _logger: LoggerService,
    private _message: MessageService,
    private _storage: Storage,
    private _iconService: IconService,
    private _error: ErrorService
  ) {
    super();
    this._iconService.registerIcons([
      rootRefreshCw,
      rootUpload,
      rootTrash,
      rootMessageCircle,
      rootMail,
      rootStar,
      rootPlus,
      rootCheckCircle,
      rootSlash
    ]);
  }

  get nickname(): string {
    return this.formGroup?.get('nickname').value;
  }

  get primaryEmailAddress(): string {
    return this.formGroup?.get('primaryEmailAddress').value;
  }

  get newEmail(): string {
    return this.formGroup?.get('newEmail').value;
  }

  get editEmail(): string {
    return this.formGroup?.get('editEmail').value;
  }

  get avatarImageUrl(): string {
    return this.formGroup?.get('avatarUrl').value;
  }

  get contactCreatedDate(): string {
    return dayjs(this.contact?.created_at).format('MMMM YYYY');
  }

  get tags(): string[] {
    return this.formGroup?.get('tags').value;
  }

  ngOnInit(): void {
    this._initForm();
    this._initContactIfNeeded();
    this._device.currentSlug$
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => (this.slug = slug));
  }

  private async _fetchNotes(contactId: string) {
    const results = await this._inbox.listContactNote(contactId);
    this.contactNotes = results.edges.map((edge) => edge.node);
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      nickname: [this.contact?.nickname ?? '', Validators.required],
      primaryEmailAddress: [
        this.contact?.primary_email ?? null,
        [Validators.email]
      ],
      newEmail: [null, Validators.email],
      editEmail: [null, Validators.email],
      avatarUrl: [this.contact?.avatarUrl],
      tags: [this.contact?.tags ?? []]
    });
  }

  private async _initContactIfNeeded() {
    if (this.contact) {
      const contact = await this._inbox.getContactById({ id: this.contact.id });
      this.contact = contact;
      this.emails = contact?.emails ?? [];
      this.formGroup.patchValue({
        nickname: contact?.nickname ?? '',
        primaryEmailAddress: contact?.primary_email ?? null,
        avatarUrl: contact?.avatarUrl,
        tags: contact?.tags ?? []
      });
      this._fetchNotes(this.contact.id);
    }

    this.isLoadingContact = false;
  }

  toggleNameInput(isVisible: boolean): void {
    this.isNameInputVisible = isVisible;

    if (isVisible) {
      this.formGroup.patchValue({
        nickname: this.contact?.name
      });
    }
  }

  updateName(): void {
    // optimistically update name
    this.contact.nickname = this.nickname;
    this.submit();
    this.toggleNameInput(false);
  }

  addEmail(): void {
    this.emails.push(this.newEmail);
    this.emailsToAdd.push(this.newEmail);
    this.formGroup.patchValue({
      newEmail: ''
    });

    this.submit();
    this.isEmailInputVisible = false;
  }

  deleteEmail(index: number): void {
    const deleted = this.emails.splice(index, 1)[0];
    if (deleted === this.primaryEmailAddress) {
      this.formGroup.patchValue({
        primaryEmailAddress: undefined
      });
    }
    this.emailsToRemove.push(deleted);
    this.submit();
  }

  toggleEmailInput(isVisible: boolean): void {
    this.isEmailInputVisible = isVisible;

    // clear out input if cancel
    if (!isVisible) {
      this.formGroup.patchValue({
        newEmail: null
      });
    }
  }

  setEmailInputAtIndex(index: number): void {
    this.emailInputVisibleAtIndex = index;
    this.formGroup.patchValue({
      editEmail: this.emails[index]
    });
  }

  hideEditEmailInput(): void {
    this.emailInputVisibleAtIndex = null;
    this.formGroup.patchValue({
      editEmail: null
    });
  }

  updateEmail(): void {
    this.emailsToAdd.push(this.editEmail);

    const prevEmail = this.emails[this.emailInputVisibleAtIndex];

    if (this.primaryEmailAddress === prevEmail) {
      this.formGroup.patchValue({
        primaryEmailAddress: this.editEmail
      });
    }
    this.emailsToRemove.push(prevEmail);
    this.emails.splice(this.emailInputVisibleAtIndex, 1, this.editEmail);
    this.submit();
    this.hideEditEmailInput();
  }

  setPrimaryEmail(email: string): void {
    // noop if email selected is already the primary email
    if (email === this.primaryEmailAddress) {
      return;
    }

    this.formGroup.patchValue({
      primaryEmailAddress: email
    });
    this.submit();
  }

  // Called by file picker event emitter in UI
  uploadAvatarFile = (file: NzUploadFile): boolean => {
    if (!file) return false;
    const extension = file.name?.split('.')?.pop();
    const fileName = `${uuidv4()}.${extension}`;
    const storageRef = ref(this._storage, `images/${fileName}`);
    this.isUploadingAvatar = true;
    uploadBytes(storageRef, file as any)
      .then(async (snapshot) => {
        const avatarUrl = await getDownloadURL(snapshot.ref);
        this.isUploadingAvatar = false;
        this.formGroup.patchValue({ avatarUrl });
        this.submit();
      })
      .catch((e) => {
        this.isUploadingAvatar = false;
        this._logger.logError(e, {
          tags: {
            feature: 'inbox'
          }
        });
      });
    return false;
  };

  clearAvatarImg(): void {
    this.formGroup.patchValue({
      avatarUrl: null
    });
    this.submit();
  }

  showTagInput(): void {
    this.isTagInputVisible = true;

    setTimeout(() => {
      this.tagInputElement?.nativeElement.focus();
    }, 10);
  }

  // TODO move launchAddContactNoteDialog to shared lib
  // async showNoteModal(
  //   existingNote?: IContactNote,
  //   index?: number
  // ): Promise<void> {
  //   return;
  // const note = await this._inboxModal.launchAddContactNoteDialog(
  //   this.contact.id,
  //   existingNote
  // );

  // if (!note) {
  //   return;
  // }

  // if updating a note
  // if (existingNote && typeof index === 'number') {
  //   this.contactNotes[index] = note;
  //   return;
  // }

  // if created a new note
  // this.contactNotes = [note, ...this.contactNotes];
  // }

  handleTagInputConfirm(): void {
    const element = this.tagInputElement?.nativeElement;
    const value = element?.value?.trim();

    if (!value) {
      this.isTagInputVisible = false;
      return;
    }

    if (value && this.tags.indexOf(value) === -1) {
      this.formGroup.controls['tags'].setValue([...this.tags, value]);
      this.formGroup.markAsDirty();
    }

    element.value = '';
    this.isTagInputVisible = false;
    this.submit();
  }

  handleTagClose(removedTag: {}): void {
    const updatedTagList = this.tags.filter((tag) => tag !== removedTag);
    this.formGroup.controls['tags'].setValue(updatedTagList);
    this.submit();
    this.formGroup.markAsDirty();
  }

  async handleNoteClose(id: string): Promise<void> {
    await this._inbox.deleteContactNote(this.contact.id, id);
    this.contactNotes = this.contactNotes.filter((note) => note.id !== id);
  }

  async submit(): Promise<void> {
    try {
      this.isLoading = true;

      const updatedContact = {
        ...(this.nickname && { nickname: this.nickname }),
        primaryEmailAddress: this.primaryEmailAddress?.length
          ? this.primaryEmailAddress
          : null,
        emailAddressesToAdd: this.emailsToAdd,
        emailAddressesToRemove: this.emailsToRemove,
        altPhotoUrl: this.avatarImageUrl,
        tags: this.tags
      };

      const ret = await this._inbox.updateContact(
        this.contact?.id,
        updatedContact
      );
      const contact = plainToClass(IPSQLContact, ret);

      if (contact) {
        this._message.show({
          text: 'Contact updated',
          type: MessageType.SUCCESS
        });
      }

      this.onSave.next(contact);
      this.isLoading = false;
    } catch (e) {
      this._error.displayError(e);
      this.isLoading = false;
      this._logger.logError(e, {
        tags: {
          feature: 'inbox'
        }
      });
    }
  }

  async deleteContact(): Promise<void> {
    try {
      await this._inbox.deleteContact(this.contact?.id);
      this.onDelete.next();
    } catch (e) {
      this._error.displayError(e);
      this._logger.logError(e, {
        tags: {
          feature: 'inbox'
        }
      });
    }
  }

  viewInInbox() {
    this.onViewInbox.next();
  }
}
