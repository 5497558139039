import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { map } from 'rxjs';

// Lib
import {
  ContentSignup,
  IImage,
  Theme,
  ThemeChange,
  SignupChanges,
  compareSignups,
  RegisterableLayout,
  RegisterableLayoutTypeEnum
} from 'models';
import { BaseComponent } from 'uikit';
import { THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS } from '../../../organisms/norby-theme-customizer';

@Component({
  selector: 'lib-signup-build-step',
  templateUrl: './signup-build-step.component.html',
  styleUrls: ['./signup-build-step.component.less']
})
export class SignupBuildStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() signup: ContentSignup;
  @Input() themes: Theme[];
  @Input() layouts: RegisterableLayout;

  @Output() onUpdatedSignup: EventEmitter<SignupChanges> =
    new EventEmitter<SignupChanges>();

  formGroup: UntypedFormGroup;

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_SIGNUP_DETAIL_SECTIONS;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      subtitle: [this.signup?.subtitle],
      body: [this.signup?.body],
      images: [this.signup?.images],
      rsvpLabel: [this.signup?.buttonLabels?.pre],
      rsvpConfirmedLabel: [this.signup?.buttonLabels?.preConfirmed],
      layout: [this.signup?.layout || RegisterableLayoutTypeEnum.CLASSIC_LEFT]
    });

    this.formGroup.valueChanges
      .pipe(
        map((value) =>
          ContentSignup.fromObject({
            ...this.signup,
            subtitle: value?.subtitle,
            body: value?.body,
            signedBody: value?.body,
            images: value?.images,
            imgixImages: [],
            buttonLabels: {
              ...this.signup?.buttonLabels,
              pre: value?.rsvpLabel,
              preConfirmed: value?.rsvpConfirmedLabel
            },
            layout: value.layout
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((signup) => this._emitDataIfChanged(signup));
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        subtitle: this.signup?.subtitle,
        body: this.signup?.body,
        images: this.signup?.images,
        rsvpLabel: this.signup?.buttonLabels?.pre,
        rsvpConfirmedLabel: this.signup?.buttonLabels?.preConfirmed,
        layout: this.signup?.layout || RegisterableLayoutTypeEnum.CLASSIC_LEFT
      },
      { emitEvent: false }
    );
  }

  handleSignupThemeUpdated({ theme }: ThemeChange) {
    this.formGroup.markAsDirty();
    const signup = ContentSignup.fromObject({
      ...this.signup,
      theme
    });
    this._emitDataIfChanged(signup);
  }

  handleNewThemeSelected(themeIdentifier: string): void {
    this.formGroup.markAsDirty();
    const theme = this.themes?.find((t) => t.id === themeIdentifier);
    const signup = ContentSignup.fromObject({
      ...this.signup,
      theme
    });
    this._emitDataIfChanged(signup);
  }

  onUpdatedImages(images: IImage[]) {
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({ images });
  }

  private _emitDataIfChanged(signup) {
    if (!compareSignups(this.signup, signup)) {
      this.onUpdatedSignup.emit({
        isDirty: this.formGroup.dirty,
        isBuildStepValid: this.formGroup.valid,
        signup
      });
    }
  }
}
