import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackProductAnalyticsDirectiveModule } from './track-product-analytics-directive.module';
import { NorbyCustomMessageTooltipComponent } from '../components/atoms/norby-custom-message-tooltip';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'uikit';

@NgModule({
  declarations: [NorbyCustomMessageTooltipComponent],
  imports: [
    CommonModule,
    TrackProductAnalyticsDirectiveModule,
    TooltipModule,
    RouterModule
  ],
  exports: [NorbyCustomMessageTooltipComponent]
})
export class NorbyCustomMessageTooltipModule {}
