import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { map } from 'rxjs';

// Lib
import {
  ContentEvent,
  IImage,
  Theme,
  ThemeChange,
  EventChanges,
  compareEvents,
  RegisterableLayout,
  RegisterableLayoutTypeEnum
} from 'models';
import { BaseComponent } from 'uikit';
import { THEME_CUSTOMIZER_EVENT_DETAIL_SECTIONS } from '../../../organisms/norby-theme-customizer';

@Component({
  selector: 'lib-event-build-step',
  templateUrl: './event-build-step.component.html',
  styleUrls: ['./event-build-step.component.less']
})
export class EventBuildStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() event: ContentEvent;
  @Input() themes: Theme[];
  @Input() layouts: RegisterableLayout;

  @Output() onUpdatedEvent: EventEmitter<EventChanges> =
    new EventEmitter<EventChanges>();

  formGroup: UntypedFormGroup;

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_EVENT_DETAIL_SECTIONS;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      subtitle: [this.event?.subtitle],
      body: [this.event?.body],
      images: [this.event?.images],
      rsvpLabel: [this.event?.buttonLabels?.pre],
      rsvpConfirmedLabel: [this.event?.buttonLabels?.preConfirmed],
      joinLabel: [this.event?.buttonLabels?.mid],
      newsletterLabel: [this.event?.buttonLabels?.newsletter],
      layout: [this.event?.layout || RegisterableLayoutTypeEnum.CLASSIC_LEFT]
    });

    this.formGroup.valueChanges
      .pipe(
        map((value) =>
          ContentEvent.fromObject({
            ...this.event,
            subtitle: value?.subtitle,
            body: value?.body,
            signedBody: value?.body,
            images: value?.images,
            imgixImages: [],
            buttonLabels: {
              ...this.event?.buttonLabels,
              pre: value?.rsvpLabel,
              preConfirmed: value?.rsvpConfirmedLabel,
              mid: value?.joinLabel,
              newsletter: value?.newsletterLabel
            },
            layout: value.layout
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((event) => this._emitDataIfChanged(event));
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        subtitle: this.event?.subtitle,
        body: this.event?.body,
        images: this.event?.images,
        rsvpLabel: this.event?.buttonLabels?.pre,
        rsvpConfirmedLabel: this.event?.buttonLabels?.preConfirmed,
        joinLabel: this.event?.buttonLabels?.mid,
        newsletterLabel: this.event?.buttonLabels?.newsletter,
        layout: this.event?.layout || RegisterableLayoutTypeEnum.CLASSIC_LEFT
      },
      { emitEvent: false }
    );
  }

  handleEventThemeUpdated({ theme }: ThemeChange) {
    this.formGroup.markAsDirty();
    const event = ContentEvent.fromObject({
      ...this.event,
      theme
    });
    this._emitDataIfChanged(event);
  }

  handleNewThemeSelected(themeIdentifier: string): void {
    this.formGroup.markAsDirty();
    const theme = this.themes?.find((t) => t.id === themeIdentifier);
    const event = ContentEvent.fromObject({
      ...this.event,
      theme
    });
    this._emitDataIfChanged(event);
  }

  onUpdatedImages(images: IImage[]) {
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({ images });
  }

  private _emitDataIfChanged(event) {
    if (!compareEvents(this.event, event)) {
      this.onUpdatedEvent.emit({
        isDirty: this.formGroup.dirty,
        isBuildStepValid: this.formGroup.valid,
        event
      });
    }
  }
}
