import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyShapeSelectorItemComponent } from '../components/atoms/norby-shape-selector-item';

@NgModule({
  declarations: [NorbyShapeSelectorItemComponent],
  imports: [CommonModule],
  exports: [NorbyShapeSelectorItemComponent]
})
export class NorbyShapeSelectorItemModule {}
