import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  DropdownModule,
  FormModule,
  CheckboxModule,
  NorbyIconModule,
  NorbySelectModule,
  NorbyInputModule,
  NorbyButtonModule,
  NorbyCustomFilterDropdownModule,
  NorbyDateInputModule
} from 'uikit';
import { NorbyCustomEngagementFilterComponent } from '../components/atoms/norby-custom-engagement-filter';

@NgModule({
  declarations: [NorbyCustomEngagementFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    CheckboxModule,
    DropdownModule,

    NorbyIconModule,
    NorbyDateInputModule,
    NorbySelectModule,
    NorbyInputModule,
    NorbyButtonModule,
    NorbyCustomFilterDropdownModule
  ],
  exports: [NorbyCustomEngagementFilterComponent]
})
export class NorbyCustomEngagementFilterModule {}
