import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyInputModule } from 'uikit';
import { NorbyEditCalendlyBlockComponent } from '../components/templates/content-block-editors/norby-edit-calendly-block';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';

@NgModule({
  declarations: [NorbyEditCalendlyBlockComponent],
  imports: [
    CommonModule,
    NorbyInputModule,
    ReactiveFormsModule,
    NorbyCustomMessageTooltipModule
  ],
  exports: [NorbyEditCalendlyBlockComponent]
})
export class NorbyEditCalendlyBlockModule {}
