<form [formGroup]="formGroup">
  <div class="w-full mb-3" formGroupName="filterGroups">
    <div *ngFor="let control of filterGroupsControls; index as i">
      <div [formGroupName]="control?.controlInstance" class="flex items-center">
        <!-- <div *ngIf="i > 0" class="flex basis-20 pr-2"> -->
        <!-- <norby-select
            class="flex flex-1"
            formControlName="filterOperator"
            (onHandleChange)="handleUpdateFilterOperator($event, i, control)"
          >
            <option value="AND">And</option>
            <option value="OR">Or</option>
          </norby-select> -->
        <!-- </div> -->
        <p *ngIf="i > 0" class="text-neutral-600 p-1">Or</p>

        <div
          class="flex-1 w-full rounded-sm border border-neutral-200 bg-mustard-200 p-2 mb-3"
        >
          <div
            *ngFor="let filter of control.filters; index as x"
            class="flex items-center"
          >
            <p *ngIf="x > 0" class="text-neutral-600 p-1">And</p>

            <div
              class="flex flex-1 items-center flex-wrap mb-3 h-12 border border-neutral-300 rounded-sm bg-white"
            >
              <norby-icon class="px-3" name="users"></norby-icon>

              <div class="flex-1 min-w-0">
                <div class="line-clamp">{{ filter.displayTitle }}</div>
              </div>
              <div class="px-3">
                <norby-icon-button
                  (onButtonClicked)="handleDeleteFilter(filter, i, control)"
                  [isDisabled]="isDisabled"
                  iconName="trash"
                ></norby-icon-button>
              </div>
            </div>
          </div>
          <div class="flex items-center" *ngIf="editingGroupIndex === i">
            <p
              *ngIf="control.filters?.length > 0"
              class="text-neutral-600 p-1 mb-8"
            >
              And
            </p>

            <norby-input
              class="pb-2"
              placeholder="Start typing..."
              [customFilter]="filterListTpl"
              [isVisible]="isFilterListVisible"
              [mouseOnChild]="mouseOnChild"
              [tagText]="tagText"
              [isDisabled]="isDisabled"
              (onFocus)="handleInputFocused()"
              (onOverlayClosed)="handleFilterListClosed()"
              formControlName="searchInput"
              [helperText]="primarySearchHelperText"
            ></norby-input>
          </div>

          <norby-icon-button-with-label
            *ngIf="editingGroupIndex !== i"
            [isDisabled]="isDisabled"
            iconName="plus"
            size="small"
            buttonLabel="Add filter"
            (onButtonClicked)="handleAddFilterRule(i, control)"
          ></norby-icon-button-with-label>
        </div>
      </div>
    </div>
    <norby-icon-button-with-label
      [isDisabled]="isDisabled"
      iconName="plus"
      size="small"
      buttonLabel="Add filter group"
      (onButtonClicked)="handleAddFilterGroup()"
    ></norby-icon-button-with-label>
  </div>

  <div
    *ngIf="
      !(
        segment?.filterGroups?.length > 0 ||
        segment?.includedContacts?.length > 0
      ) && !isEventUpdates
    "
    class="flex items-center flex-wrap mb-3 h-12 border border-neutral-300 rounded-sm"
  >
    <norby-icon class="px-3" name="users"></norby-icon>

    <div class="flex-1 min-w-0">
      <div class="truncate">
        All contacts <span class="text-neutral-600">(default)</span>
      </div>
    </div>
  </div>

  <div class="rounded-sm border border-neutral-200 mb-3 p-2">
    <norby-collapsible title="Include specific contacts">
      <norby-input
        placeholder="Start typing..."
        [label]="sendToLabel"
        formControlName="includedContactInput"
        [searchResults]="includedContactResultsTpl"
        [results]="includedContactOptions"
        [isDisabled]="isDisabled"
        [helperText]="includedContactHelperText"
      ></norby-input>

      <div
        *ngFor="let contact of segment?.includedContacts"
        class="flex items-center flex-wrap mb-3 h-12 border border-neutral-300 text-neutral-900 rounded-sm"
      >
        <root-avatar class="px-3">
          <norby-icon name="user"></norby-icon>
        </root-avatar>
        <div class="flex-1 min-w-0">
          <div
            class="truncate"
            [ngClass]="{
              'text-neutral-600': !contact.name
            }"
          >
            {{ getNameForContact(contact) }}
          </div>
        </div>
        <div class="flex-1 min-w-0">
          <div class="truncate">
            {{ contactListInfoDisplay(contact) }}
          </div>
        </div>
        <div class="px-3">
          <norby-icon-button
            (onButtonClicked)="handleRemoveIncludedContact(contact.contactId)"
            [isDisabled]="isDisabled"
            iconName="trash"
          ></norby-icon-button>
        </div>
      </div>
    </norby-collapsible>
  </div>

  <div class="rounded-sm border border-neutral-200 p-2">
    <norby-collapsible title="Exclude specific contacts">
      <norby-input
        placeholder="Start typing..."
        [label]="dontSendToLabel"
        formControlName="excludedContactInput"
        [searchResults]="excludedContactResultsTpl"
        [results]="excludedContactOptions"
        [isDisabled]="isDisabled"
        [helperText]="excludedContactHelperText"
      ></norby-input>

      <div
        *ngFor="let contact of segment?.excludedContacts"
        class="flex items-center flex-wrap mb-3 h-12 border border-neutral-300 rounded-sm"
      >
        <root-avatar class="px-3">
          <norby-icon name="user"></norby-icon>
        </root-avatar>
        <div
          class="flex-1 min-w-0"
          [ngClass]="{
            'text-neutral-600': !contact.name
          }"
        >
          <div class="truncate">
            {{ getNameForContact(contact) }}
          </div>
        </div>
        <div class="flex-1 min-w-0">
          <div class="truncate">
            {{ contactListInfoDisplay(contact) }}
          </div>
        </div>
        <div class="px-3">
          <norby-icon-button
            (onButtonClicked)="handleRemoveExcludedContact(contact.contactId)"
            [isDisabled]="isDisabled"
            iconName="trash"
          ></norby-icon-button>
        </div>
      </div>
    </norby-collapsible>
  </div>
</form>

<root-dropdown-menu #filterListTpl>
  <norby-filter-list
    [contentFilterOptions]="contentFilterOptions"
    [contactListFilterOptions]="contactListFilterOptions"
    [engagementFilterOptions]="engagementFilterOptions"
    [landingPageFilterOptions]="landingPageFilterOptions"
    [tagText]="tagText"
    [deliveryType]="deliveryType"
    [isSearchingRecipient]="isSearchingFilter"
    [isEventUpdates]="isEventUpdates"
    (mouseOnDropdown)="handleChildDropdownMouseEvent($event)"
    (onAddFilter)="handleAddFilter($event)"
  ></norby-filter-list>
</root-dropdown-menu>

<root-dropdown-menu #includedContactResultsTpl>
  <root-spin [spinning]="isSearchingIncludeContact">
    <div *ngIf="includedContactOptions">
      <div *ngFor="let option of includedContactOptions">
        <div
          class="flex items-center p-2 rounded-sm"
          (click)="handleAddContact(option)"
          [ngClass]="{
            'text-neutral-600 cursor-not-allowed': !isContactOptedIn(option),
            'hover:bg-neutral-300 cursor-pointer': isContactOptedIn(option)
          }"
          [rootTooltip]="
            !isContactOptedIn(option)
              ? 'Sends can only be sent to contacts that are opted in to receive marketing messages.'
              : ''
          "
        >
          <norby-icon class="flex-none" name="user"></norby-icon>
          <div class="flex flex-auto items-center min-w-0">
            <div class="flex flex-1 mr-2 min-w-0">
              <div
                class="mr-2 truncate flex-initial"
                [ngClass]="{
                  'text-neutral-600': !option.contact.name
                }"
                [innerHtml]="option.highlightedName"
              ></div>
              <div
                class="truncate flex-auto"
                [innerHtml]="contactSearchResultInfoDisplay(option)"
              ></div>
            </div>
            <div
              *ngIf="!isContactOptedIn(option)"
              class="text-terracota text-xs flex-none mr-1"
            >
              Not opted in
            </div>
          </div>
        </div>
      </div>
    </div>
  </root-spin>
</root-dropdown-menu>

<root-dropdown-menu #excludedContactResultsTpl>
  <root-spin [spinning]="isSearchingExcludeContact">
    <div *ngIf="excludedContactOptions">
      <div *ngFor="let option of excludedContactOptions">
        <div
          class="flex items-center p-2 rounded-sm cursor-pointer hover:bg-neutral-300"
          (click)="handleExcludeContact(option.contact)"
        >
          <norby-icon class="flex-none" name="user"></norby-icon>
          <div class="flex flex-auto items-center min-w-0">
            <div class="flex flex-1 mr-2 min-w-0">
              <div
                class="mr-2 truncate flex-initial"
                [ngClass]="{
                  'text-neutral-600': !option.contact.name
                }"
                [innerHtml]="option.highlightedName"
              ></div>
              <div
                class="truncate flex-auto"
                [innerHtml]="contactSearchResultInfoDisplay(option)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </root-spin>
</root-dropdown-menu>
