import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyFilterListModule } from './norby-filter-list.module';
import {
  NorbyIconModule,
  NorbyInputModule,
  NorbyIconButtonModule,
  AvatarModule,
  DropdownModule,
  NorbyCollapsibleModule,
  NorbyCustomFilterDropdownModule,
  SearchResultsModule,
  SpinModule,
  TooltipModule,
  NorbyIconButtonWithLabelModule,
  NorbySelectModule
} from 'uikit';
import { NorbyFiltersBuildViewComponent } from '../components/templates/segmenting/norby-filters-build-view';

@NgModule({
  declarations: [NorbyFiltersBuildViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    AvatarModule,
    SpinModule,
    NorbyInputModule,
    NorbyCollapsibleModule,
    NorbyFilterListModule,
    NorbyCustomFilterDropdownModule,
    NorbyIconModule,
    NorbyIconButtonModule,
    SearchResultsModule,
    TooltipModule,
    NorbyIconButtonWithLabelModule,
    NorbySelectModule
  ],
  exports: [NorbyFiltersBuildViewComponent]
})
export class NorbyFiltersBuildViewModule {}
