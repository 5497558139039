<root-spin [spinning]="!message">
  <div class="event-container min-h-[150px]">
    <form
      *ngIf="message"
      @slideIn
      [formGroup]="formGroup"
      class="event-wrapper"
      (ngSubmit)="handleSubmitMessage()"
    >
      <a
        href="https://help.withnorby.com/ai-beta"
        target="_blank"
        rel="noreferrer"
      >
        <img src="assets/svg/ai_beta.svg" width="60" height="30" class="mb-3" />
      </a>

      <h1 class="mb-2">Create your event with AI</h1>

      <p class="text-neutral-600 text-lg">
        {{ message }}
      </p>

      <div rootInputGroup>
        <norby-input
          class="grouped use-ai"
          formControlName="reply"
          placeholder="Say something..."
          [isJoinedRight]="true"
          [isRequired]="true"
          [isLoading]="isLoadingResponse"
        ></norby-input>

        <norby-icon-button-with-label
          class="grouped"
          iconName="zap"
          buttonLabel="Next"
          buttonType="primary"
          [isDisabled]="!formGroup.valid || isLoadingResponse"
          [isJoinedRight]="false"
          [isJoinedLeft]="true"
          (onButtonClicked)="handleSubmitMessage()"
        ></norby-icon-button-with-label>
      </div>

      <div class="mt-4" *ngIf="showSkipButton" @slideIn>
        <button
          type="button"
          (click)="handleSkip()"
          class="text-neutral-600 p-0 hover:text-neutral-800"
        >
          Or skip and build manually
        </button>
      </div>

      <div class="mt-4" *ngIf="showAskDifferentQuestionButton" @slideIn>
        <button
          type="button"
          (click)="handleAskDifferentQuestion()"
          class="text-neutral-600 p-0 hover:text-neutral-800"
        >
          Ask me a different question
        </button>
      </div>
    </form>
  </div>
</root-spin>
