import { Component, OnInit } from '@angular/core';

// 3rd party
import { NzModalRef } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs';

// Lib
import { BaseComponent } from 'uikit';
import { ContactList } from 'models';

// App
import { ContactsListStoreService } from '../../services/contacts-list-store';

@Component({
  selector: 'app-view-contact-lists',
  templateUrl: './view-contact-lists.component.html',
  styleUrls: ['./view-contact-lists.component.less']
})
export class ViewContactListsComponent extends BaseComponent implements OnInit {
  isLoading = true;
  lists: ContactList[] = [];
  fetchMoreListsFn: () => Promise<void>;

  constructor(
    private _contactsListStore: ContactsListStoreService,
    private _modalRef: NzModalRef<ViewContactListsComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this._contactsListStore
      .getContactLists$()
      .pipe(
        filter((data) => !!data?.items),
        this.takeUntilDestroy
      )
      .subscribe(({ items, fetch }) => {
        this.lists = items;
        this.fetchMoreListsFn = fetch;
        this.isLoading = false;
      });
  }

  async handleLoadNext() {
    this.isLoading = true;
    await this.fetchMoreListsFn?.();
    this.isLoading = false;
  }

  handleClickList(list: ContactList) {
    this._modalRef.close(list);
  }
}
