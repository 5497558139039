import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'shared';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'magic',
    loadChildren: () =>
      import('./routes/magic-login/magic-login.module').then(
        (m) => m.MagicLoginModule
      )
  },
  {
    path: 'debug-branding',
    loadChildren: () =>
      import('./routes/debug-branding/debug-branding.module').then(
        (m) => m.DebugBrandingModule
      )
  },
  {
    path: ':slug',
    loadChildren: () =>
      import('./routes/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
