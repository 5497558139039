import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NorbyButtonModule,
  NorbyFileUploadModule,
  NorbyFormcontrolLabelModule,
  NorbyIconRadioButtonGroupModule,
  NorbyIconRadioButtonModule,
  NorbyInputModule,
  NorbySliderModule,
  NorbySwitchButtonModule,
  NorbyTextAreaModule
} from 'uikit';

import {
  EditEmailButtonBlockComponent,
  EditEmailDividerBlockComponent,
  EditEmailHeaderBlockComponent,
  EditEmailImageBlockComponent,
  EditEmailSpacerBlockComponent,
  EditEmailTextBlockComponent,
  EditEmailFooterBlockComponent,
  EditEmailYoutubeBlockComponent,
  EditEmailEventBlockComponent,
  EditEmailQuoteBlockComponent,
  EditEmailSignupBlockComponent,
  EditEmailUpcomingEventsBlockComponent
} from '../components/templates/email-block-editors';
import { NorbyDropShadowPickerModule } from './norby-drop-shadow-picker.module';
import { NorbyEditSocialIconsModule } from './norby-edit-social-icons.module';
import { NorbyContentSelectorModule } from './norby-content-selector.module';
import { NorbyAntColorPickerModule } from './norby-ant-color-picker.module';

@NgModule({
  declarations: [
    EditEmailHeaderBlockComponent,
    EditEmailImageBlockComponent,
    EditEmailTextBlockComponent,
    EditEmailSpacerBlockComponent,
    EditEmailDividerBlockComponent,
    EditEmailButtonBlockComponent,
    EditEmailFooterBlockComponent,
    EditEmailYoutubeBlockComponent,
    EditEmailEventBlockComponent,
    EditEmailQuoteBlockComponent,
    EditEmailSignupBlockComponent,
    EditEmailUpcomingEventsBlockComponent
  ],
  imports: [
    CommonModule,
    NorbyInputModule,
    ReactiveFormsModule,
    NorbyFileUploadModule,
    NorbyIconRadioButtonGroupModule,
    NorbyIconRadioButtonModule,
    NorbySliderModule,
    NorbyDropShadowPickerModule,
    NorbyEditSocialIconsModule,
    NorbyContentSelectorModule,
    NorbyButtonModule,
    NorbyFormcontrolLabelModule,
    NorbyTextAreaModule,
    NorbySwitchButtonModule,
    NorbyAntColorPickerModule
  ],
  exports: [
    EditEmailHeaderBlockComponent,
    EditEmailImageBlockComponent,
    EditEmailTextBlockComponent,
    EditEmailSpacerBlockComponent,
    EditEmailDividerBlockComponent,
    EditEmailButtonBlockComponent,
    EditEmailFooterBlockComponent,
    EditEmailYoutubeBlockComponent,
    EditEmailEventBlockComponent,
    EditEmailQuoteBlockComponent,
    EditEmailSignupBlockComponent,
    EditEmailUpcomingEventsBlockComponent
  ]
})
export class EmailBlockEditorsModule {}
