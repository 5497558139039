import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { filter, map } from 'rxjs';

// Lib
import { CalendlyPageBlock, PageBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-calendly-block-appearance',
  templateUrl: './norby-edit-calendly-block-appearance.component.html',
  styleUrls: ['./norby-edit-calendly-block-appearance.component.less']
})
export class NorbyEditCalendlyBlockAppearanceComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: CalendlyPageBlock;

  @Output() onUpdatedBlock: EventEmitter<CalendlyPageBlock> =
    new EventEmitter<CalendlyPageBlock>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        bookingPageBackgroundColor:
          this.selectedBlock?.bookingPageBackgroundColor,
        bookingPageTextColor: this.selectedBlock?.bookingPageTextColor,
        bookingPageButtonLinkColor:
          this.selectedBlock?.bookingPageButtonLinkColor,
        hidePageDetails: !!this.selectedBlock?.hidePageDetails,
        hideCookieBanner: !!this.selectedBlock?.hideCookieBanner
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      bookingPageBackgroundColor: [
        this.selectedBlock?.bookingPageBackgroundColor || '#ffffff'
      ],
      bookingPageTextColor: [
        this.selectedBlock?.bookingPageTextColor || '#1A1A1A'
      ],
      bookingPageButtonLinkColor: [
        this.selectedBlock?.bookingPageButtonLinkColor || '#0069FF'
      ],
      hidePageDetails: [!!this.selectedBlock?.hidePageDetails],
      hideCookieBanner: [!!this.selectedBlock?.hideCookieBanner]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map(
          (value) =>
            PageBlock.fromObject({
              ...this.selectedBlock,
              bookingPageBackgroundColor: value.bookingPageBackgroundColor,
              bookingPageTextColor: value.bookingPageTextColor,
              bookingPageButtonLinkColor: value.bookingPageButtonLinkColor,
              hidePageDetails: value.hidePageDetails,
              hideCookieBanner: value.hideCookieBanner,
              embedStyle: {
                ...this.selectedBlock.embedStyle,
                type: 'inline'
              }
            }) as CalendlyPageBlock
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
