<form [formGroup]="formGroup">
  <div class="mb-4">
    <norby-file-upload
      label="Image"
      [images]="blockImageArray"
      acceptedFileTypes=".jpeg, .jpg, .png, .gif"
      (onUpdatedImages)="handleBackgroundImageUpdated($event)"
    ></norby-file-upload>
  </div>
  <norby-icon-radio-button-group
    label="Alignment"
    formControlName="alignment"
    size="default"
    class="flex-row"
  >
    <norby-icon-radio-button
      iconName="align_left"
      buttonValue="left"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_center"
      buttonValue="center"
    ></norby-icon-radio-button>

    <norby-icon-radio-button
      iconName="align_right"
      buttonValue="right"
    ></norby-icon-radio-button>
  </norby-icon-radio-button-group>
  <norby-slider
    class="mb-4"
    [floor]="32"
    [ceil]="400"
    label="Height"
    formControlName="height"
  ></norby-slider>
  <norby-input
    class="mb-4"
    label="Link URL"
    formControlName="clickThroughUrl"
    [placeholder]="clickThroughUrlDefault"
    [isUrl]="true"
  ></norby-input>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
