import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionHeaderViewComponent } from '../views/action-header-view';
import { ButtonModule } from './button.module';
import { IconModule } from './icon.module';

@NgModule({
  declarations: [ActionHeaderViewComponent],
  imports: [CommonModule, ButtonModule, IconModule],
  exports: [ActionHeaderViewComponent]
})
export class ActionHeaderViewModule {}
