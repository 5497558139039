import { Injectable } from '@angular/core';
import { IDrawerService, IUserPublicMetadata } from 'models';
import { NzDrawerOptions } from 'ng-zorro-antd/drawer';

@Injectable({
  providedIn: 'root'
})
export class MockDrawerService implements IDrawerService {
  constructor() {}

  closeDrawer(): Promise<any> {
    return Promise.resolve(null);
  }

  createDrawer(
    component: any,
    options: NzDrawerOptions<any, any>
  ): Promise<any> {
    return Promise.resolve(null);
  }

  launchEditProfile(userProfile?: IUserPublicMetadata): Promise<any> {
    return Promise.resolve(null);
  }

  launchAddUserPhoneNumberDialog(): Promise<any> {
    return Promise.resolve(null);
  }

  logoutAndGoHome(): Promise<void> {
    return Promise.resolve(null);
  }

  launchPhoneCollectionDialog(): Promise<boolean> {
    return Promise.resolve(null);
  }
}
