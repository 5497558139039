import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Lib
import { IImage, NewsletterSignupPageBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-newsletter-signup-block',
  templateUrl: './norby-edit-newsletter-signup-block.component.html',
  styleUrls: ['./norby-edit-newsletter-signup-block.component.less']
})
export class NorbyEditNewsletterSignupBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: NewsletterSignupPageBlock;

  @Output() onUpdatedBlock: EventEmitter<NewsletterSignupPageBlock> =
    new EventEmitter<NewsletterSignupPageBlock>();

  formGroup: UntypedFormGroup;
  images: IImage[] = [];
  tags: string[] = [];

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const initialImages = this.selectedBlock?.images || [];

    this.images = initialImages.map((img) => ({
      url: img.url,
      uid: img.url
    }));

    this.tags = this.selectedBlock?.tags?.slice() || [];

    this.formGroup?.patchValue(
      {
        title: this.selectedBlock?.title,
        subtitle: this.selectedBlock?.subtitle,
        postSubmitTitle: this.selectedBlock?.postSubmitTitle,
        postSubmitMessage: this.selectedBlock?.postSubmitMessage,
        buttonLabel: this.selectedBlock?.buttonLabel
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      title: [this.selectedBlock?.title],
      subtitle: [this.selectedBlock?.subtitle],
      postSubmitTitle: [this.selectedBlock?.postSubmitTitle],
      postSubmitMessage: [this.selectedBlock?.postSubmitMessage],
      buttonLabel: [this.selectedBlock?.buttonLabel]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map(() => this._generateUpdatedBlock()),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }

  handleUpdatedImages(images: IImage[]) {
    this.images = images;
    if (this.formGroup.valid) {
      const block = this._generateUpdatedBlock();
      this.onUpdatedBlock.emit(block);
    }
  }

  handleUpdateTags(tags: string[]) {
    this.tags = tags;
    if (this.formGroup.valid) {
      const block = this._generateUpdatedBlock();
      this.onUpdatedBlock.emit(block);
    }
  }

  private _generateUpdatedBlock() {
    return plainToClass(NewsletterSignupPageBlock, {
      ...this.selectedBlock,
      ...this.formGroup.value,
      images: this.images,
      tags: this.tags
    });
  }
}
