<div
  class="flex flex-col align-items-center w-16 p-3 cursor-pointer hover:bg-green-light hover:rounded-sm"
  [ngClass]="{ 'bg-green-light rounded-sm': selected }"
>
  <img [src]="url" [alt]="label" class="w-10 h-10" />
  <label
    class="text-sm text-neutral-800 font-normal text-center cursor-pointer"
  >
    {{ label }}
  </label>
</div>
