<form nz-form [formGroup]="appearanceForm" *ngIf="isBrowser">
  <norby-collapsible-group>
    <norby-collapsible
      [title]="sections.background?.title"
      formGroupName="layout"
      *ngIf="sections?.background"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.background?.description">
        {{ sections.background.description }}
      </div>
      <norby-select
        *ngIf="allowComplexBackgrounds"
        label="Mode"
        infoTooltip="Choose a background fill mode"
        (onHandleChange)="handleBackgroundModeChange($event)"
        [value]="backgroundMode"
      >
        <option value="image">Image</option>
        <option value="color">Color</option>
        <option value="gradient">Gradient</option>
      </norby-select>

      <norby-file-upload
        [images]="backgroundImage"
        label="Image"
        *ngIf="backgroundMode === 'image'"
        (onUpdatedImages)="handleBackgroundImageChange($event)"
      ></norby-file-upload>

      <norby-ant-color-picker
        *ngIf="backgroundMode === 'color'"
        label="Color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>

      <norby-gradient-selector
        *ngIf="backgroundMode === 'gradient'"
        label="Gradient"
        formControlName="backgroundGradient"
      ></norby-gradient-selector>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.fonts?.title"
      formGroupName="fonts"
      *ngIf="sections?.fonts"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.fonts?.description">
        {{ sections.fonts.description }}
      </div>
      <ng-container *ngFor="let item of TYPE_LEVELS; trackBy: trackBy">
        <norby-formcontrol-label [label]="item.label"></norby-formcontrol-label>

        <div rootFormItem>
          <div rootInputGroup>
            <norby-number-input
              *ngIf="item.showSize"
              class="grouped input"
              [value]="appearanceForm?.value?.fonts[item.identifier]?.fontSize"
              [isJoinedRight]="true"
              (onHandleChange)="handleFontSizeChange($event, item.identifier)"
            ></norby-number-input>

            <norby-font-selector
              [ngClass]="
                item.showSize ? 'grouped select' : 'grouped default-select'
              "
              placeholder="Choose a font"
              [fonts]="availableFonts"
              [isJoinedLeft]="item.showSize"
              [formControlName]="item.identifier"
              (onImportClick)="onImportButtonClick(item?.identifier)"
            ></norby-font-selector>
          </div>
        </div>
      </ng-container>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.text?.title"
      formGroupName="text"
      *ngIf="sections?.text"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.text?.description">
        {{ sections.text.description }}
      </div>
      <norby-ant-color-picker label="Text color" formControlName="color">
      </norby-ant-color-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.links?.title"
      formGroupName="link"
      *ngIf="sections?.links"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.links?.description">
        {{ sections.links.description }}
      </div>
      <norby-ant-color-picker
        label="Link text color"
        formControlName="textColor"
        helperText="Text color used for links"
      >
      </norby-ant-color-picker>

      <norby-ant-color-picker
        label="Link text color (hover)"
        formControlName="hoverTextColor"
        helperText="Color used when hovering over a link"
      >
      </norby-ant-color-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.cards?.title"
      *ngIf="sections?.cards"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.cards?.description">
        {{ sections.cards.description }}
      </div>
      <!-- TODO: Enable this after determining updates to customer theme modifications -->
      <!-- 
      <norby-formcontrol-label label="Style"></norby-formcontrol-label>

      <div rootFormItem>
        <div rootInputGroup>
          <norby-select
            [value]="selectedCardType"
            class="grouped select-cards"
            placeholder="Choose a style"
            [isJoinedLeft]="false"
            [isJoinedRight]="true"
            (onHandleChange)="handleCardTypeChange($event)"
          >
            <option value="default">Default</option>
            <option
              *ngFor="
                let ctrl of customCardsStylesFormArray.controls;
                index as i
              "
              [value]="i"
            >
              {{ ctrl?.value?.name }}
            </option>
          </norby-select>

          <norby-icon-button-with-label
            class="grouped button-cards"
            iconName="plus"
            buttonLabel="Add new"
            [isJoinedRight]="false"
            [isJoinedLeft]="true"
            (onButtonClicked)="onAddCardButtonClick()"
          ></norby-icon-button-with-label>
        </div>
      </div> -->

      <ng-container *ngIf="selectedCardType === 'default'" formGroupName="card">
        <div *ngIf="sections.cards?.contentType === 'link'">
          <norby-select label="Image Position" formControlName="imagePosition">
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="top">Top</option>
            <option value="bottom">Bottom</option>
          </norby-select>

          <norby-select label="Text Position" formControlName="textPosition">
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="center">Center</option>
          </norby-select>
        </div>

        <norby-slider
          formControlName="borderRadius"
          [floor]="0"
          [ceil]="100"
          [allowNull]="true"
          label="Border radius"
        ></norby-slider>

        <norby-slider
          formControlName="borderWidth"
          [floor]="0"
          [ceil]="100"
          [allowNull]="true"
          label="Border width"
        ></norby-slider>

        <norby-drop-shadow-picker
          label="Drop shadow"
          formControlName="dropShadow"
          placeholder="6px 6px 0px #000"
          formControlName="dropShadow"
          [helperText]="
            allowComplexBackgrounds
              ? ''
              : 'Drop shadows are not supported by all email clients.'
          "
        ></norby-drop-shadow-picker>

        <norby-ant-color-picker
          label="Background color"
          formControlName="backgroundColor"
        >
        </norby-ant-color-picker>
        <norby-ant-color-picker label="Text color" formControlName="textColor">
        </norby-ant-color-picker>
        <norby-ant-color-picker
          label="Border color"
          formControlName="borderColor"
        >
        </norby-ant-color-picker>
      </ng-container>

      <ng-container
        *ngIf="selectedCardType !== 'default'"
        formArrayName="customCards"
      >
        <ng-container
          *ngFor="let ctrl of customCardsStylesFormArray.controls; index as i"
          [formArrayName]="i"
          [formGroup]="ctrl"
        >
          <div [style.display]="getCustomCardDisplayStyle(i)">
            <norby-slider
              formControlName="borderRadius"
              [floor]="0"
              [ceil]="100"
              [allowNull]="true"
              label="Border radius"
            ></norby-slider>

            <norby-slider
              formControlName="borderWidth"
              [floor]="0"
              [ceil]="100"
              [allowNull]="true"
              label="Border width"
            ></norby-slider>

            <norby-drop-shadow-picker
              label="Drop shadow"
              formControlName="dropShadow"
              placeholder="6px 6px 0px #000"
              formControlName="dropShadow"
            ></norby-drop-shadow-picker>

            <norby-ant-color-picker
              label="Background color"
              formControlName="backgroundColor"
            >
            </norby-ant-color-picker>
            <norby-ant-color-picker
              label="Text color"
              formControlName="textColor"
            >
            </norby-ant-color-picker>
            <norby-ant-color-picker
              label="Border color"
              formControlName="borderColor"
            >
            </norby-ant-color-picker>
          </div>
        </ng-container>
      </ng-container>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.buttons?.title"
      *ngIf="sections?.buttons"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.buttons?.description">
        {{ sections.buttons.description }}
      </div>
      <norby-select
        [value]="buttonTypes[0].type"
        placeholder="Choose a style"
        (onHandleChange)="handleButtonTypeChange($event)"
      >
        <option
          *ngFor="let buttonType of buttonTypes"
          [value]="buttonType.type"
        >
          {{ buttonType.name }}
        </option>
      </norby-select>

      <ng-container
        *ngFor="let buttonType of buttonTypes"
        [formGroupName]="buttonType.type"
      >
        <ng-container *ngIf="buttonType.type === selectedButtonType">
          <norby-slider
            formControlName="borderRadius"
            [floor]="0"
            [ceil]="100"
            [allowNull]="true"
            label="Border radius"
          ></norby-slider>

          <norby-drop-shadow-picker
            label="Drop shadow"
            placeholder="6px 6px 0px #000"
            formControlName="dropShadow"
          ></norby-drop-shadow-picker>

          <norby-ant-color-picker
            label="Background color"
            formControlName="backgroundColor"
          >
          </norby-ant-color-picker>
          <norby-ant-color-picker
            label="Text color"
            formControlName="textColor"
          >
          </norby-ant-color-picker>
          <norby-ant-color-picker
            label="Border color"
            formControlName="borderColor"
          >
          </norby-ant-color-picker>
          <norby-ant-color-picker
            label="Background color (hover)"
            formControlName="hoverBackgroundColor"
          >
          </norby-ant-color-picker>
          <norby-ant-color-picker
            label="Text color (hover)"
            formControlName="hoverTextColor"
          >
          </norby-ant-color-picker>
          <norby-ant-color-picker
            label="Border color (hover)"
            formControlName="hoverBorderColor"
          >
          </norby-ant-color-picker>
        </ng-container>
      </ng-container>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.tooltips?.title"
      formGroupName="tooltip"
      *ngIf="sections?.tooltips"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.tooltips?.description">
        {{ sections.tooltips.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.dropdowns?.title"
      formGroupName="dropdown"
      *ngIf="sections?.dropdowns"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.dropdowns?.description">
        {{ sections.dropdowns.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.snackbars?.title"
      formGroupName="snackbar"
      *ngIf="sections?.snackbars"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.snackbars?.description">
        {{ sections.snackbars.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.textinputs?.title"
      formGroupName="textInput"
      *ngIf="sections?.textinputs"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.textinputs?.description">
        {{ sections.textinputs.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.selects?.title"
      formGroupName="select"
      *ngIf="sections?.selects"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.selects?.description">
        {{ sections.selects.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.checkboxes?.title"
      formGroupName="checkbox"
      *ngIf="sections?.checkboxes"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections.checkboxes?.description">
        {{ sections.checkboxes.description }}
      </div>
      <norby-ant-color-picker
        label="Background color"
        formControlName="backgroundColor"
      >
      </norby-ant-color-picker>
      <norby-ant-color-picker label="Text color" formControlName="textColor">
      </norby-ant-color-picker>
      <norby-ant-color-picker
        label="Border color"
        formControlName="borderColor"
      >
      </norby-ant-color-picker>

      <norby-slider
        [floor]="0"
        [ceil]="100"
        [allowNull]="true"
        label="Border radius"
        formControlName="borderRadius"
      ></norby-slider>

      <norby-drop-shadow-picker
        label="Drop shadow"
        formControlName="dropShadow"
        placeholder="6px 6px 0px #000"
      ></norby-drop-shadow-picker>
    </norby-collapsible>

    <norby-collapsible
      [title]="sections.metadata?.title"
      formGroupName="metadata"
      *ngIf="sections?.metadata"
      [isDisabled]="isDisabled"
    >
      <div class="collapsible-copy" *ngIf="sections?.metadata?.description">
        {{ sections?.metadata.description }}
      </div>

      <norby-custom-message-tooltip
        action="change your favicon"
        (onMessageUpdated)="child.isDisabled = !!$event"
      >
        <norby-file-upload
          #child
          [images]="favIcon"
          label="Favicon"
          infoTooltip="Your custom browser icon"
          (onUpdatedImages)="handleFavIconChange($event)"
        ></norby-file-upload>
      </norby-custom-message-tooltip>
    </norby-collapsible>
  </norby-collapsible-group>
</form>
