import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';

import { LinkShareButtonViewComponent } from '../views/link-share-button-view';
import { IconModule, LogEventDirectiveModule } from 'uikit';

@NgModule({
  declarations: [LinkShareButtonViewComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    LogEventDirectiveModule,
    IconModule,

    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
    NzQRCodeModule
  ],
  exports: [LinkShareButtonViewComponent]
})
export class LinkShareButtonViewModule {}
