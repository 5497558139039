import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';

// App
import { ContactListTableViewComponent } from '../components/contact-list-table-view';
import { UserRowModule } from './user-row.module';

@NgModule({
  declarations: [ContactListTableViewComponent],
  exports: [ContactListTableViewComponent],
  imports: [CommonModule, NzTableModule, NzButtonModule, UserRowModule]
})
export class ContactListTableViewModule {}
