<nz-table
  [nzData]="lists"
  [nzFrontPagination]="false"
  [nzLoading]="isLoading"
  [nzScroll]="{ x: '600px' }"
>
  <thead>
    <tr>
      <th nzLeft>Title</th>
      <th nzWidth="150px">Last updated</th>
      <th nzEllipsis>Created by</th>
      <th nzWidth="72px">Size</th>
    </tr>
  </thead>

  <tbody>
    <tr
      *ngFor="let list of lists; trackBy: trackBy; let i = index"
      class="cursor-pointer"
      (click)="handleClickList(i)"
    >
      <td nzLeft>{{ list.title }}</td>
      <td nzEllipsis>{{ list.modifiedAt | date }}</td>
      <td nzEllipsis>
        <lib-user-row [userId]="list.createdBy" size="small"></lib-user-row>
      </td>
      <td>{{ list.size }}</td>
    </tr>
  </tbody>
</nz-table>

<button
  *ngIf="hasNextPage"
  nz-button
  nzBlock
  [nzLoading]="isLoading"
  (click)="handleLoadNext()"
>
  Load more
</button>
