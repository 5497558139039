<div class="flex flex-col">
  <nz-progress
    [nzPercent]="usagePercentage"
    [nzShowInfo]="false"
    nzStatus="exception"
  ></nz-progress>

  <div class="font-bold text-terracota">
    {{ usage }} text message{{ usage == 1 ? '' : 's' }} used
  </div>

  <div class="text-neutral-600">
    You have {{ remainingSmsCount }} included message{{
      remainingSmsCount == 1 ? '' : 's'
    }}
    remaining.
    <a
      [routerLink]="['/', slugStr, 'account']"
      class="underline"
      [trackProductAnalytics]="UPSELL_EVENT"
      [properties]="UPSELL_PROPERTIES"
      >Upgrade</a
    >
    for more.
  </div>
</div>
