import { instanceToPlain, plainToInstance } from 'class-transformer';
import { IContact, IPSQLContact } from '../types';

// These are unsafe / shallow conversions intended specifically for
// translating contacts for use in the contact detail modal
//
// TO DO: deprecate IPSQLContact

const _camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (l) => `_${l.toLowerCase()}`);

const _snakeToCamelCase = (str: string) =>
  str.replace(/[-_][a-z]/g, (g) => g.slice(-1).toUpperCase());

export const convertIContactToIPSQLContact = (
  contact: IContact
): IPSQLContact =>
  plainToInstance(
    IPSQLContact,
    Object.keys(instanceToPlain(contact)).reduce(
      (acc, key) => ({ ...acc, [_camelToSnakeCase(key)]: contact[key] }),
      {}
    )
  );

export const convertIPSQLContactToIContact = (
  contact: IPSQLContact
): IContact =>
  plainToInstance(
    IContact,
    Object.keys(instanceToPlain(contact)).reduce(
      (acc, key) => ({ ...acc, [_snakeToCamelCase(key)]: contact[key] }),
      {}
    )
  );
