import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAlertModule } from 'ng-zorro-antd/alert';

// App
import { FloatingActionButtonsViewComponent } from '../views/floating-action-buttons-view';
import { IconModule } from 'uikit';

@NgModule({
  declarations: [FloatingActionButtonsViewComponent],
  imports: [
    CommonModule,
    IconModule,

    NzIconModule,
    NzAlertModule,
    NzButtonModule
  ],
  exports: [FloatingActionButtonsViewComponent]
})
export class FloatingActionButtonsViewModule {}
