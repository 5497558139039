<div class="flex justify-between items-center">
  <nz-color-picker
    [nzFlipFlop]="flipFlop"
    nzFormat="hex"
    [nzValue]="val"
    nzDisabledAlpha
    (nzOnChange)="handleColorPickerChange($event)"
  ></nz-color-picker>

  <ng-template #flipFlop>
    <div class="flex flex-col w-full mb-3">
      <div class="flex flex-row h-20 min-h-20">
        <div class="w-20 min-w-20 h-20 min-h-20 mx-0 p-0 cursor-pointer">
          <div
            class="w-full h-0 shadow-ant-default pb-full rounded-sm"
            [ngStyle]="{ 'background-color': val }"
          ></div>
        </div>

        <div class="w-full pl-2 flex flex-col justify-between">
          <norby-formcontrol-label
            [label]="label"
            [infoTooltip]="infoTooltip"
            [isRequired]="required"
          ></norby-formcontrol-label>

          <norby-input-base
            class="w-28"
            type="text"
            [value]="val"
            name="text"
            [isRequired]="required"
            isReadOnly="true"
            maxLength="9"
            [placeholder]="placeholder"
          ></norby-input-base>
        </div>
      </div>
      <norby-helpertext [helperText]="helperText"></norby-helpertext>
    </div>
  </ng-template>
  <norby-button
    text="Reset"
    buttonType="secondary"
    [isDisabled]="isButtonDisabled"
    (buttonClicked)="handleResetValueClick()"
  ></norby-button>
</div>
