import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialIconsComponent } from '../views/social-icons';
import { ButtonModule } from './button.module';
import { IconModule } from './icon.module';
import { TooltipModule } from './tooltip.module';

@NgModule({
  declarations: [SocialIconsComponent],
  imports: [CommonModule, ButtonModule, TooltipModule, IconModule],
  exports: [SocialIconsComponent]
})
export class SocialIconsModule {}
