<div [formGroup]="formGroup" class="norby-tag-selector-container">
  <norby-formcontrol-label
    [label]="label"
    [infoTooltip]="infoTooltip"
  ></norby-formcontrol-label>
  <div class="tags-container">
    <root-tag
      [tagStyle]="'border-radius: 1px;'"
      *ngFor="let tag of tags"
      [closeable]="true"
      (handleClose)="handleTagDelete(tag)"
      >{{ tag }}</root-tag
    >
  </div>

  <div class="input-container" *ngIf="tagInputVisible">
    <div rootFormItem>
      <div rootNestedInputGroup>
        <norby-input
          formControlName="tag"
          placeholder="Tag name"
          [isNestedButton]="true"
          (keydown.enter)="handleTagInputConfirm()"
        ></norby-input>

        <root-button
          text="Save"
          formSubmit="button"
          (handleClick)="handleTagInputConfirm()"
        >
        </root-button>
      </div>
    </div>
  </div>

  <norby-button
    *ngIf="!tagInputVisible"
    buttonType="secondary"
    text="Add a new tag"
    (buttonClicked)="showTagInput()"
  ></norby-button>
</div>
