import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyButtonModule } from 'uikit';
import { BuildContactListFiltersComponent } from './build-contact-list-filters.component';
import { BuildFiltersControllerModule } from '../../modules/build-filters-controller.module';

@NgModule({
  declarations: [BuildContactListFiltersComponent],
  imports: [CommonModule, BuildFiltersControllerModule, NorbyButtonModule],
  exports: [BuildContactListFiltersComponent]
})
export class BuildContactListModule {}
