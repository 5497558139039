<div class="flex flex-col items-center">
  <img src="assets/svg/up-arrow-1.svg" class="arrow-svg mb-1" />
  <h1 class="mb-1">Ready to send?</h1>

  <div class="flex">
    <norby-button
      class="p-2"
      text="Send now"
      [isLoading]="isSending"
      (buttonClicked)="handleSubmitSend()"
    ></norby-button>

    <norby-button
      class="p-2"
      buttonType="secondary"
      text="Cancel"
      (buttonClicked)="handleCancelSend()"
    ></norby-button>
  </div>
</div>
