import { Injectable } from '@angular/core';

// 3rd party
import { map, switchMap, from, filter, pairwise, startWith } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import { AdminQueriesService } from '../admin-queries';
import { BillingService } from '../billing';
import { DeviceService } from '../device';
import { ObservableDataService } from '../observable-data';
import { PermissionsService } from '../permissions';
import {
  ACCOUNT_ADDON_CREATED_IN_SLUG_TOPIC,
  RealtimeServerSocketMessage,
  ACCOUNT_ADDON_UPDATED_IN_SLUG_TOPIC,
  ACCOUNT_ADDON_DELETED_IN_SLUG_TOPIC
} from '../socket';
import {
  IAddOnResultNode,
  IQueryResult,
  AccountStatus,
  IAccountStoreService
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class AccountStoreService implements IAccountStoreService {
  constructor(
    private _adminQueries: AdminQueriesService,
    private _billing: BillingService,
    private _ods: ObservableDataService,
    private _permissions: PermissionsService,
    private _device: DeviceService
  ) {}

  accountStatus$ = this._permissions.userCanEditSite$().pipe(
    startWith(null), // Necessary because pairwise emits on second emission
    switchMap((canEdit) =>
      canEdit ? this._adminQueries.getCurrentSlugMetadata$() : from([null])
    ),
    map((metadata) => AccountStatus.fromMetadata(metadata)),
    pairwise(),
    filter(([prev, next]) => !prev.isEqualTo(next)),
    map(([, next]) => next)
  );

  addOns$ = this._ods.query$<IAddOnResultNode>({
    lookup: this._billing.fetchAddons,
    transformer: (res: IQueryResult, currentValue: IAddOnResultNode[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const addOns =
        res?.edges?.map((p) => plainToClass(IAddOnResultNode, p?.node)) ?? [];
      return {
        items: [...(currentValue ?? []), ...addOns],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    },
    handlers: [
      {
        event: ACCOUNT_ADDON_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAddOnResultNode[]
        ) => {
          const addOn = plainToClass(IAddOnResultNode, event?.data);
          currentValue?.push(addOn);
          return [...currentValue];
        }
      },
      {
        event: ACCOUNT_ADDON_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAddOnResultNode[]
        ) => {
          const addOn = plainToClass(IAddOnResultNode, event?.data);
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );
          if (currentIdx > -1) {
            currentValue[currentIdx] = addOn;
          } else {
            currentValue?.push(addOn);
          }
          return [...currentValue];
        }
      },
      {
        event: ACCOUNT_ADDON_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: IAddOnResultNode[]
        ) => {
          const addOn = plainToClass(IAddOnResultNode, event?.data);
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ]
  });
}
