<div class="note-container">
  <p>{{ note?.body }}</p>
  <div (click)="$event.stopPropagation()">
    <root-icon
      name="trash"
      class="delete-icon"
      (nzOnConfirm)="handleDelete.emit(note.id)"
      nz-popconfirm
      nzOkText="Delete"
      nzPopconfirmTitle="Are you sure? This is permanent!"
    ></root-icon>
  </div>
</div>
