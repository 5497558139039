import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

// 3rd Party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Libs
import {
  ISlug,
  DEFAULT_IMAGE_ALIGNMENT,
  YoutubeSingleSendBlock,
  YoutubeUrlValidator,
  DEFAULT_BLOCK_PADDING
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'edit-email-youtube-block',
  templateUrl: './edit-email-youtube-block.component.html',
  styleUrls: ['./edit-email-youtube-block.component.less']
})
export class EditEmailYoutubeBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: YoutubeSingleSendBlock;
  @Input() slug: ISlug;

  @Output() onUpdatedBlock: EventEmitter<YoutubeSingleSendBlock> =
    new EventEmitter<YoutubeSingleSendBlock>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        url: this.block?.url,
        height: this.block?.height,
        alignment: this.block?.alignment,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      url: [
        this.block?.url ?? null,
        [Validators.required, YoutubeUrlValidator]
      ],
      height: [this.block?.height ?? null],
      alignment: [this.block?.alignment ?? DEFAULT_IMAGE_ALIGNMENT],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(YoutubeSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
