import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';

import { ContentEvent, ContentSignup, IUserContent, Platform } from 'models';

@Component({
  selector: 'lib-registerable-detail-classic-right-view',
  templateUrl: './classic-right-view.component.html',
  styleUrls: ['./classic-right-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterableDetailClassicRightViewComponent {
  @Input() content: ContentEvent | ContentSignup;
  @Input() userContent: IUserContent;
  @Input() isLoading = false;
  @Input() platform: Platform;
  @Input() metadataTemplate: TemplateRef<any>;
  @Input() mainButtonTemplate: TemplateRef<any>;

  @Output() onSubmitAnother = new EventEmitter<void>();

  constructor() {}

  handleSubmitAnother() {
    this.onSubmitAnother.emit();
  }
}
