<nz-card
  [nzCover]="coverTemplate"
  [class.active]="isActive"
  (click)="handleOnClick()"
>
  <nz-card-meta
    *ngIf="theme"
    [nzTitle]="theme?.name"
    [nzDescription]="description"
    class="theme-meta-container"
  >
  </nz-card-meta>
  <nz-skeleton
    *ngIf="!theme"
    [nzTitle]="false"
    [nzAvatar]="false"
    [nzParagraph]="{ rows: 3, width: 150 }"
  >
  </nz-skeleton>
  <div class="actions-container" (click)="$event.stopPropagation()">
    <ng-content></ng-content>
  </div>
</nz-card>

<ng-template #coverTemplate>
  <div class="theme-image" [style.background-image]="themeImage"></div>
</ng-template>

<ng-template #description>
  <div
    *ngIf="theme?.description"
    [innerHTML]="theme?.description | safeHtml"
    class="theme-meta"
  ></div>
</ng-template>
