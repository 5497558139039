<div class="invite-user-container">
  <p class="text-neutral-600">
    Invite a teammate to join {{ slug?.accountInfo?.title }}.
  </p>

  <p class="text-neutral-600" *ngIf="!showRoles">
    Teammates will be able to manage connected integrations and start their own
    conversations with Norby Chat.
  </p>

  <form [formGroup]="formGroup" nz-form nzLayout="vertical">
    <nz-form-item>
      <nz-form-label nzFor="banner">Email</nz-form-label>
      <input
        type="email"
        nz-input
        placeholder="Email"
        formControlName="email"
        nzSize="large"
        required
      />
    </nz-form-item>

    <nz-form-item *ngIf="showRoles">
      <nz-form-label nzFor="banner">Role</nz-form-label>
      <nz-form-control [nzExtra]="rolesTpl">
        <nz-select nzSize="large" formControlName="role">
          <nz-option
            [nzLabel]="option"
            *ngFor="let option of ROLE_OPTIONS"
            [nzValue]="option"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
      <ng-template #rolesTpl
        >See a full breakdown of roles and permissions in our
        <a
          href="https://help.withnorby.com/add-manage-team-permissions"
          target="_blank"
          >Knowledge Base</a
        >.</ng-template
      >
    </nz-form-item>

    <nz-form-item *ngIf="userIsGlobalAdmin">
      <nz-form-label nzFor="onboarding">Onboarding</nz-form-label>
      <nz-form-text>
        <p>Send an onboarding invitation to a new team.</p>
      </nz-form-text>
      <nz-select formControlName="onboarding">
        <nz-option
          [nzLabel]="'No onboarding flow'"
          [nzValue]="false"
        ></nz-option>
        <nz-option
          [nzLabel]="'Show onboarding flow'"
          [nzValue]="true"
        ></nz-option>
      </nz-select>
    </nz-form-item>
  </form>
</div>
