import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FormModule,
  NorbyButtonModule,
  NorbyCollapsibleModule,
  NorbyFileUploadModule,
  NorbyFormcontrolLabelModule,
  NorbyInputModule,
  NorbyTabModule,
  NorbyTabsModule
} from 'uikit';
import { NorbyRichTextEditorViewModule } from './norby-rich-text-editor-view.module';
import { NorbyThemeCustomizerModule } from './norby-theme-customizer.module';
import { NorbyThemeSelectorModule } from './norby-theme-selector.module';
import { SignupBuildStepComponent } from '../components/templates/signups/signup-build-step';
import { NorbyLayoutSelectorModule } from './norby-layout-selector.module';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';

@NgModule({
  declarations: [SignupBuildStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyThemeCustomizerModule,
    NorbyThemeSelectorModule,
    NorbyRichTextEditorViewModule,
    NorbyFileUploadModule,
    NorbyFormcontrolLabelModule,
    NorbyCollapsibleModule,
    NorbyTabsModule,
    NorbyTabModule,
    NorbyInputModule,
    NorbyLayoutSelectorModule,
    NorbyCustomMessageTooltipModule
  ],
  exports: [SignupBuildStepComponent]
})
export class SignupBuildStepModule {}
