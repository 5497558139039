import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ButtonModule,
  FormModule,
  NorbyButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyInputModule,
  TagModule
} from 'uikit';
import { NorbyTagSelectorComponent } from '../components/atoms/norby-tag-selector';

@NgModule({
  declarations: [NorbyTagSelectorComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    TagModule,
    NorbyFormcontrolLabelModule,
    NorbyInputModule,
    NorbyButtonModule,
    ButtonModule
  ],
  exports: [NorbyTagSelectorComponent]
})
export class NorbyTagSelectorModule {}
