import { APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';

// 3rd party
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { QuillModule } from 'ngx-quill';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Libs
import {
  DeviceService,
  getSharedProviders,
  getAntProviders,
  LoginModule,
  FirestoreFileUploadService,
  ApiService,
  ShareService,
  ContentInteractionsService,
  AnalyticsService,
  ContentService,
  ContentManagementService,
  CustomerContentService,
  AccountStoreService,
  ErrorAlerter,
  SearchService,
  AiService,
  CustomerDeviceService,
  DrawerService,
  ContactModalService
} from 'shared';
import {
  MessageModule,
  MockDeviceService,
  MockFileUploadService,
  ThemeCustomizerService,
  MockShareService,
  MockApiService,
  MockContentInteractionsService,
  GlobalLoadingIndicatorViewModule,
  MockAnalyticsService,
  MockSearchService,
  MockAiService,
  MockContentManagementService,
  MockContentModalService,
  MockContentService
} from 'uikit';
import {
  BILLING_PORTAL_URL_TOKEN,
  CHAMELEON_API_KEY,
  CHAMELEON_FAST_URL,
  CLOUD_ANALYTICS_URL_TOKEN,
  GTM_ID,
  PROFITWELL_API_KEY
} from 'models';
import { MockAccountStoreService, MockDrawerService } from 'customer-uikit';
import { ContentModalService } from './services/content-modal';
import { CustomerThemeCustomizerService } from './services/customer-theme-customizer';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CustomerContactModalService } from './services/customer-contact-modal';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModule,
    GlobalLoadingIndicatorViewModule,
    MessageModule.forRoot(),

    // Third party
    QuillModule.forRoot(),
    NzModalModule,
    NzDrawerModule,
    NzNotificationModule
  ],
  providers: [
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions()),

    // App
    ...getSharedProviders(environment),
    ...getAntProviders(environment),
    { provide: APP_ID, useValue: 'customer' },
    { provide: BILLING_PORTAL_URL_TOKEN, useValue: environment.billingBaseUrl },
    {
      provide: CHAMELEON_API_KEY,
      useValue: environment?.chameleonConfig?.apiKey
    },
    {
      provide: CHAMELEON_FAST_URL,
      useValue: environment?.chameleonConfig?.fastUrl
    },
    {
      provide: PROFITWELL_API_KEY,
      useValue: environment?.profitWellConfig?.apiKey
    },
    {
      provide: GTM_ID,
      useValue: environment?.gtmConfig?.id
    },
    {
      provide: CLOUD_ANALYTICS_URL_TOKEN,
      useValue: environment.cloudAnalyticsUrl
    },
    {
      provide: DeviceService,
      useClass: CustomerDeviceService
    },
    {
      provide: MockDeviceService,
      useClass: CustomerDeviceService
    },
    {
      provide: MockShareService,
      useExisting: ShareService
    },
    {
      provide: MockContentInteractionsService,
      useExisting: ContentInteractionsService
    },
    {
      provide: MockSearchService,
      useExisting: SearchService
    },
    {
      provide: ThemeCustomizerService,
      useClass: CustomerThemeCustomizerService
    },
    {
      provide: MockFileUploadService,
      useClass: FirestoreFileUploadService
    },
    {
      provide: MockApiService,
      useExisting: ApiService
    },
    {
      provide: MockAnalyticsService,
      useClass: AnalyticsService
    },
    {
      provide: MockContentModalService,
      useClass: ContentModalService
    },
    {
      provide: MockDrawerService,
      useClass: DrawerService
    },
    {
      provide: MockAiService,
      useExisting: AiService
    },
    {
      provide: MockContentService,
      useClass: CustomerContentService
    },
    {
      provide: MockAccountStoreService,
      useClass: AccountStoreService
    },
    {
      provide: MockContentManagementService,
      useClass: ContentManagementService
    },
    {
      provide: ContentService,
      useClass: CustomerContentService
    },
    {
      provide: ContactModalService,
      useClass: CustomerContactModalService
    },
    provideEnvironmentNgxMask(),
    ...(!environment.production
      ? [
          {
            provide: ErrorHandler,
            useClass: ErrorAlerter
          }
        ]
      : [])
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
