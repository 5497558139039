import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// 3rd party
import { Observable, map, tap } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  IQueryResult,
  SingleSend,
  ApiSurfaces,
  IPSQLSendResults,
  SendsFilterArgs,
  ENDPOINTS,
  PaginatedQuerySummary
} from 'models';
import { ErrorService } from 'uikit';

// App
import { ISendsStoreService } from './sends-store.service.interface';
import { ApiService } from '../api';
import { DeviceService } from '../device';
import {
  ObservableDataService,
  RealtimeSocketEventHandler,
  QuerySummary
} from '../observable-data';
import {
  SINGLE_SEND_CREATED_IN_SLUG_TOPIC,
  RealtimeServerSocketMessage,
  SINGLE_SEND_UPDATED_IN_SLUG_TOPIC,
  SINGLE_SEND_DELETED_IN_SLUG_TOPIC,
  SINGLE_SEND_UPDATED_TOPIC,
  SINGLE_SEND_DELETED_TOPIC
} from '../socket';
import { SendsService } from '../sends/sends.service';
import { ContentService } from '../content';

@Injectable({
  providedIn: 'root'
})
export class SendsStoreService implements ISendsStoreService {
  constructor(
    private _obs: ObservableDataService,
    private _api: ApiService,
    private _error: ErrorService,
    private _device: DeviceService,
    private _sends: SendsService,
    private _content: ContentService
  ) {}

  getSends$(
    args: SendsFilterArgs
  ): Observable<PaginatedQuerySummary<SingleSend>> {
    // Function used to fetch pages
    const lookup = this.getSends;

    // Function used to transform lookup results and
    // incorporate them into the stream
    const transformer = (res: IQueryResult, currentValue: SingleSend[]) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const sends =
        res?.edges?.map((edge) => {
          return plainToClass(SingleSend, edge?.node);
        }) ?? [];

      return {
        items: [...(currentValue ?? []), ...sends],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: SINGLE_SEND_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: SingleSend[]
        ) => {
          const addOn = plainToClass(SingleSend, event?.data);
          if (!this._matchesSendFilters(args, addOn)) {
            return currentValue;
          }
          currentValue?.unshift(addOn);
          return [...currentValue];
        }
      },
      {
        event: SINGLE_SEND_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: SingleSend[]
        ) => {
          const addOn = plainToClass(SingleSend, event?.data);
          if (!this._matchesSendFilters(args, addOn)) {
            const currentIdx = currentValue?.findIndex(
              (node) => node?.id === addOn?.id
            );
            if (currentIdx > -1) {
              currentValue.splice(currentIdx, 1);
            }

            return currentValue;
          }
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );
          if (currentIdx > -1) {
            currentValue[currentIdx] = addOn;
          } else {
            currentValue?.unshift(addOn);
          }
          return [...currentValue];
        }
      },
      {
        event: SINGLE_SEND_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: SingleSend[]
        ) => {
          const addOn = plainToClass(SingleSend, event?.data);
          if (!this._matchesSendFilters(args, addOn)) {
            return currentValue;
          }
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._obs.query$<SingleSend>({
      args,
      lookup,
      transformer,
      handlers
    });
  }

  getSendById$(
    id: string,
    addContent = false
  ): Observable<QuerySummary<SingleSend>> {
    const lookup = this._sends.getSendById;
    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: SINGLE_SEND_UPDATED_TOPIC,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) => {
          const ret = SingleSend.fromObject(event?.data);

          if (addContent) {
            this._content.addContentToSend(ret);
          }

          return ret;
        }
      },
      {
        event: SINGLE_SEND_DELETED_TOPIC,
        payload: {
          resourceId: id
        },
        transformer: (event: RealtimeServerSocketMessage) => null
      }
    ];

    let cachedSummary: QuerySummary<SingleSend>;

    const baseObservable$ = this._obs.document$<SingleSend>({
      handlers,
      lookup,
      args: { id }
    });

    return addContent
      ? this._content
          .addContentToSend$(
            baseObservable$.pipe(
              tap((summary) => (cachedSummary = summary)),
              map((summary) => summary?.data)
            )
          )
          .pipe(map((data) => ({ ...cachedSummary, data })))
      : baseObservable$;
  }

  getSends = async (filters: SendsFilterArgs): Promise<IPSQLSendResults> => {
    try {
      const ret = await this._api.get<IPSQLSendResults>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2,
        filters
      );
      return ret;
    } catch (e) {
      const is404 = e instanceof HttpErrorResponse && e.status === 404;
      if (!is404) {
        this._error.displayError(e);
      }
    }
  };

  private _matchesSendFilters(args: SendsFilterArgs, send: SingleSend) {
    if (args?.filter && send?.draft && args.filter !== 'draft') {
      return false;
    }

    if (
      args?.filter &&
      !send?.draft &&
      send?.sendAt &&
      !send?.hasBeenSent &&
      args.filter !== 'upcoming'
    ) {
      return false;
    }

    if (args?.filter && send?.hasBeenSent && args.filter !== 'sent') {
      return false;
    }

    return true;
  }
}
