import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventDropCardComponent } from '../components/event-drop-card';
import { DropCardViewModule, EventCardViewModule } from 'uikit';

@NgModule({
  declarations: [EventDropCardComponent],
  imports: [CommonModule, EventCardViewModule, DropCardViewModule],
  exports: [EventDropCardComponent]
})
export class EventDropCardModule {}
