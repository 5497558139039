import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// 3rd party
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subject,
  switchMap,
  tap
} from 'rxjs';

// Lib
import {
  BaseComponent,
  ErrorService,
  MockContentManagementService,
  MockContentService,
  MockSearchService
} from 'uikit';
import { Content, NorbySelectorTypesEnum, SEARCH_DEBOUNCE_TIME } from 'models';

@Component({
  selector: 'norby-content-selector-by-id',
  templateUrl: './norby-content-selector-by-id.component.html',
  styleUrls: ['./norby-content-selector-by-id.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyContentSelectorByIdComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NorbyContentSelectorByIdComponent
  extends BaseComponent
  implements ControlValueAccessor, OnChanges, OnInit
{
  private _search$ = new Subject<string>();
  private _searchResults: Content[];
  private _defaultContent: Content[];
  private _currentQuery: string;

  @Input() label: string;
  @Input() buttonText: string;
  @Input() placeholder: string;
  @Input() value?: string;
  @Input() infoTooltip?: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() contentType: 'link' | 'drop' | 'event' = 'event';

  @Output() onContentChange = new EventEmitter<string>();
  @Output() onCreateClick = new EventEmitter();

  readonly NORBY_SELECTOR_TYPES_ENUM = NorbySelectorTypesEnum;

  val: string;
  isSearching = false;

  private _onChanged: any = (_: any) => {};
  private _onTouched: any = () => {};
  private _touched = false;

  constructor(
    private _search: MockSearchService,
    private _content: MockContentService,
    private _contentManagement: MockContentManagementService,
    private _error: ErrorService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  get iconName() {
    const identifier = this.options?.[0]?.contentType ?? this.contentType;
    return identifier === 'drop'
      ? 'edit'
      : identifier === 'event'
        ? 'calendar'
        : 'link';
  }

  get options(): Content[] {
    return (
      (this._currentQuery?.length
        ? this._searchResults
        : this._defaultContent) || []
    );
  }

  ngOnInit() {
    this._initSearchSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.value) {
      this.val = this.value;
    }

    this._initDefaultContent();
  }

  private _initDefaultContent() {
    let defaultStream$: Observable<Content[]>;

    if (this.contentType === 'event') {
      defaultStream$ = this._content
        .getEventsForCurrentSlug$({
          orderBy: 'modifiedAt',
          sort: 'desc',
          published: true,
          limit: 10
        })
        .pipe(
          filter((summary) => !!summary),
          map((summary) => summary.items)
        );
    } else if (this.contentType === 'drop') {
      defaultStream$ = this._contentManagement.getDropsForCurrentSlug$({
        published: true,
        limit: 10
      });
    } else if (this.contentType === 'link') {
      defaultStream$ = this._contentManagement.getLinksForCurrentSlug$({
        published: true,
        limit: 10,
        descending: true
      });
    }

    defaultStream$?.pipe(this.takeUntilChanges)?.subscribe((content) => {
      this._defaultContent = content;
      this._cdr.detectChanges();
    });
  }

  private _handleSearchError(e: Error) {
    this._error.displayError(e);
    this._search$ = new Subject<string>();
    this._initSearchSubscription();
    return Promise.resolve(null);
  }

  private _initSearchSubscription() {
    this._search$
      .pipe(
        tap((query) => {
          this._currentQuery = query;
          this._cdr.detectChanges();
        }),
        filter((query) => !!query?.length),
        tap(() => {
          this.isSearching = true;
          this._cdr.detectChanges();
        }),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        switchMap((query) =>
          this._search.searchContent({
            query,
            offset: 0,
            excludeContent: ['event', 'drop', 'link'].filter(
              (type) => type !== this.contentType
            ) // Only searching appropriate type
          })
        ),
        catchError((e) => this._handleSearchError(e)),
        this.takeUntilDestroy
      )
      .subscribe((rawResults) => {
        this._searchResults =
          rawResults?.edges?.map((edge) => Content.fromObject(edge?.node)) ||
          [];
        this.isSearching = false;
        this._cdr.detectChanges();
      });
  }

  handleSearch(query: string) {
    this._search$.next(query);
  }

  handleChange(content: Content) {
    const contentId = content?.contentId || '';
    this.val = contentId;
    this._onChanged(contentId);
    this._markAsTouched();
    this.onContentChange.emit(contentId);
  }

  writeValue(value: string) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
