import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToWords'
})
export class CamelCaseToWordsPipe implements PipeTransform {
  transform(value: string): any {
    return value?.length > 0 ? value.replace(/([A-Z])/g, ' $1') : value;
  }
}
