import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FormModule,
  NorbyInputModule,
  NorbySelectModule,
  NorbyRadioButtonGroupModule,
  NorbyRadioButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyButtonModule,
  IconModule,
  NorbyIconButtonWithLabelModule,
  NorbyAlertModule,
  NorbySwitchButtonModule,
  TooltipModule,
  NorbyDateTimePickerModule
} from 'uikit';
import { EventDetailsStepComponent } from '../components/templates/events/event-details-step';

@NgModule({
  declarations: [EventDetailsStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,

    NorbyInputModule,
    NorbyButtonModule,
    NorbySwitchButtonModule,
    NorbySelectModule,
    NorbyFormcontrolLabelModule,
    NorbyDateTimePickerModule,
    NorbyRadioButtonGroupModule,
    NorbyRadioButtonModule,
    NorbyIconButtonWithLabelModule,
    NorbyAlertModule,
    IconModule,
    TooltipModule
  ],
  exports: [EventDetailsStepComponent]
})
export class EventDetailsStepModule {}
