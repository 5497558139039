<label
  *ngIf="label"
  class="form-label inline-block mb-1 text-lg text-neutral-800"
>
  {{ label }}
</label>

<norby-configurable-selector
  key="type"
  label="display"
  [selectorType]="NORBY_SELECTOR_TYPES_ENUM.LAYOUT_SELECTOR"
  [placeholder]="placeholder"
  [items]="layouts"
  [value]="val"
  [isDisabled]="isDisabled"
  [isSearchable]="false"
  [optionHeightPx]="85"
  [optionOverflowSize]="4"
  [optionTemplate]="optionTemplate"
  (onValueChange)="handleChange($event)"
></norby-configurable-selector>
<ng-template #optionTemplate let-item>
  <div class="flex flex-row py-2">
    <div>
      <img class="object-cover h-15 rounded-xs" [src]="item.imgPath" />
    </div>
    <div class="flex flex-col px-2 justify-center overflow-hidden">
      <span class="text-sm truncate">{{ item.display }}</span>
    </div>
    <div class="flex-grow"></div>
  </div>
</ng-template>
