import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BaseComponent } from 'uikit';

@Injectable({
  providedIn: 'root'
})
export class MetaService extends BaseComponent {
  private _hasAddedTags = false;

  constructor(
    private _title: Title,
    private _meta: Meta
  ) {
    super();
  }

  setMeta(
    title: string,
    description?: string,
    image?: string,
    keywords?: string
  ) {
    this._title.setTitle(title);

    if (this._hasAddedTags) {
      this._meta.updateTag({ name: 'og:title', content: title });
      this._meta.updateTag({
        name: 'og:description',
        content: description ?? ''
      });
      this._meta.updateTag({ name: 'og:image', content: image ?? '' });
      this._meta.updateTag({ name: 'keywords', content: keywords ?? '' });
      this._meta.updateTag({ name: 'description', content: description ?? '' });
    } else {
      this._hasAddedTags = true;
      this._meta.addTags([
        { name: 'twitter:card', content: 'summary' }, // added once
        { name: 'og:title', content: title },
        { name: 'og:description', content: description ?? '' },
        { name: 'og:image', content: image ?? '' },
        { name: 'keywords', content: keywords ?? '' },
        { name: 'description', content: description ?? '' }
      ]);
    }
  }
}
