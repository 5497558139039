<div class="flex justify-between items-center">
  <nz-color-picker
    class="flex-1 pr-2"
    [nzFlipFlop]="flipFlop"
    [nzTitle]="dropShadowSliders"
    nzFormat="hex"
    [nzValue]="colorVal"
    nzDisabledAlpha
    (nzOnChange)="handleColorPickerChange($event)"
  ></nz-color-picker>

  <ng-template #flipFlop>
    <div class="flex flex-col w-full mb-3">
      <div class="flex flex-row">
        <div class="w-full">
          <norby-formcontrol-label
            [label]="label"
            [infoTooltip]="infoTooltip"
            [isRequired]="isRequired"
          ></norby-formcontrol-label>

          <norby-input-base
            type="text"
            [isReadOnly]="true"
            [value]="val || ''"
            name="text"
            [isDisabled]="disabled"
            [isRequired]="isRequired"
            isReadOnly="true"
            maxLength="25"
            [placeholder]="placeholder"
          >
          </norby-input-base>
        </div>
      </div>
      <norby-helpertext [helperText]="helperText"></norby-helpertext>
    </div>
  </ng-template>

  <ng-template #dropShadowSliders>
    <div class="drop-shadow-sliders">
      <norby-drop-shadow-sliders
        (onShadowChange)="handleShadowChange($event)"
        [values]="dropShadowValues"
      ></norby-drop-shadow-sliders>
    </div>
  </ng-template>
  <norby-button
    text="Reset"
    buttonType="secondary"
    [isDisabled]="isButtonDisabled"
    (buttonClicked)="handleResetValueClick()"
  ></norby-button>
</div>
