import { Directive, HostListener, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';

import {
  ApiSurfaces,
  ProductAnalyticsEventType,
  ProductAnalyticsProperties,
  TrackProductAnalytics,
  ENDPOINTS
} from 'models';
import { MockApiService } from 'uikit';

/*
  Easy analytics tracking in template files. Send product analytics to Mixpanel

  Usage:

  .ts
  import { ProductAnalyticsEventTypes, ClickedUpsellProductAnalyticsLocations } from 'models';
  ...
  eventType = ProductAnalyticsEventTypes.eventType;
  properties = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.SEARCH_RESULTS,
    cta: 'Upgrade'
  };

  .html
  <a [trackProductAnalytics]="eventType" [properties]="properties">Upgrade</a>
*/

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[trackProductAnalytics]'
})
export class TrackProductAnalyticsDirective {
  constructor(private _api: MockApiService) {}
  @Input('trackProductAnalytics') eventType: ProductAnalyticsEventType;
  @Input() properties: ProductAnalyticsProperties;

  @HostListener('click') onClick() {
    const data = plainToClass(TrackProductAnalytics, {
      data: {
        event: this.eventType,
        properties: this.properties,
        timestamp: new Date()
      }
    });

    this._api.post<any>(
      ApiSurfaces.API,
      ENDPOINTS.analytics.trackProductAnalytics,
      data
    );
  }
}
