import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';

// App

// Libs
import { IconModule } from 'uikit';
import { PhonePipeModule } from 'customer-uikit';
import { ContactsTableViewComponent } from '../views/contacts-table-view';
import { UserRowViewModule } from './user-row-view.module';

@NgModule({
  declarations: [ContactsTableViewComponent],
  exports: [ContactsTableViewComponent],
  imports: [
    CommonModule,

    NzPaginationModule,
    NzTableModule,
    NzTagModule,
    NzToolTipModule,
    NzButtonModule,

    UserRowViewModule,
    PhonePipeModule,
    IconModule
  ]
})
export class ContactsTableViewModule {}
