<div class="p-2">
  <div class="pb-2">
    <span> {{ content?.title }}</span>
  </div>
  <form [formGroup]="formGroup">
    <div class="flex">
      <button
        type="button"
        (click)="handleOpenFilterDropdown()"
        class="flex items-center text-base justify-between rounded-sm mb-3 px-3 pb-4 pt-3.5 h-12 border border-neutral-300 hover:border-neutral-500 flex-1 bg-white leading-5 whitespace-nowrap w-80"
        [ngClass]="filterDisplay ? 'text-neutral-800' : 'text-neutral-600'"
        [customFilter]="customFilterTpl"
        [closeOnClick]="true"
        position="center"
      >
        <span>{{ filterDisplay || 'Select filter' }}</span
        ><norby-icon name="chevron_down"></norby-icon>
      </button>
    </div>

    <div *ngIf="isDateFilter">
      <norby-date-input formControlName="date"></norby-date-input>
    </div>
    <div *ngFor="let prompt of content?.prompts">
      <div rootFormItem *ngIf="selectedPrompt === prompt">
        <norby-input
          *ngIf="!prompt?.type || prompt?.type === 'text'"
          class="prompt-input"
          [formControlName]="prompt.prompt"
          placeholder="Your answer..."
        ></norby-input>

        <norby-select
          *ngIf="prompt?.type == 'select'"
          prompt="Select an option"
          [formControlName]="prompt.prompt"
        >
          <option
            *ngFor="let option of prompt?.options | keyvalue"
            [ngValue]="option.value"
          >
            {{ option.value }}
          </option>
        </norby-select>
        <root-checkbox-wrapper
          *ngIf="prompt?.type == 'checkbox'"
          (onHandleChange)="handleDidCheckCheckbox($event, prompt.prompt)"
        >
          <div *ngFor="let option of prompt?.options">
            <label root-checkbox [value]="option">{{ option }}</label>
          </div>
        </root-checkbox-wrapper>
      </div>
    </div>
  </form>
  <norby-button
    class="flex add-filter-button"
    text="Add Filter"
    [isDisabled]="isButtonDisabled"
    (buttonClicked)="handleAddFilter()"
  ></norby-button>
</div>
<root-dropdown-menu #customFilterTpl>
  <div class="p-2 custom-filter">
    <div class="py-2">Registered</div>
    <div class="ml-3 border-l">
      <div
        (click)="handleDateClick('before')"
        class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
      >
        Before
      </div>
      <div
        (click)="handleDateClick('on')"
        class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
      >
        On
      </div>
      <div
        (click)="handleDateClick('after')"
        class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
      >
        After
      </div>
    </div>
    <div *ngIf="content?.prompts?.length > 0">
      <div class="py-2">Responded</div>
      <div class="px-2 ml-3 border-l" *ngFor="let prompt of content.prompts">
        <div
          *ngIf="prompt?.type !== 'datePicker'"
          (click)="handlePromptClick(prompt)"
          class="cursor-pointer"
        >
          {{ prompt?.prompt }}
        </div>
        <div *ngIf="prompt?.type === 'datePicker'" class="cursor-pointer">
          {{ prompt?.prompt }}
          <div class="ml-3 border-l">
            <div
              (click)="handleDatePickerPromptClick('before', prompt)"
              class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            >
              Is before
            </div>
            <div
              (click)="handleDatePickerPromptClick('on', prompt)"
              class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            >
              Is
            </div>
            <div
              (click)="handleDatePickerPromptClick('after', prompt)"
              class="cursor-pointer px-2 hover:bg-neutral-300 rounded-sm"
            >
              Is after
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</root-dropdown-menu>
