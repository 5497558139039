import { Injectable } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';

import { Observable } from 'rxjs';

import { FIREBASE_COLLECTIONS, IFirestoreDocumentService } from 'models';

@Injectable({
  providedIn: 'root'
})
export class FirestoreDocumentService implements IFirestoreDocumentService {
  constructor(private _firestore: Firestore) {}

  getStaticDocument$<T>(document: string): Observable<T> {
    const ref = doc(this._firestore, FIREBASE_COLLECTIONS.static, document);
    return docData(ref) as Observable<T>;
  }
}
