<form [formGroup]="formGroup">
  <norby-input
    [label]="LABEL"
    formControlName="url"
    [placeholder]="PLACEHOLDER"
    [infoTooltip]="TOOLTIP"
    [isUrl]="true"
  ></norby-input>

  <norby-switch-button
    formControlName="hideColor"
    label="Disable album theme"
    helperText="Use the default Spotify theme instead of colors sampled from the album art."
  ></norby-switch-button>
</form>
