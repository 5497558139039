import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { DevicePreviewViewComponent } from '../views/device-preview-view';

@NgModule({
  declarations: [DevicePreviewViewComponent],
  imports: [CommonModule, ScrollingModule],
  exports: [DevicePreviewViewComponent]
})
export class DevicePreviewViewModule {}
