import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// 3rd party
import { map } from 'rxjs';

// Lib
import { ContentPageBlock, PageBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-content-block',
  templateUrl: './norby-edit-content-block.component.html',
  styleUrls: ['./norby-edit-content-block.component.less']
})
export class NorbyEditContentBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: ContentPageBlock;
  @Input() contentLabel: string;
  @Input() contentType: 'link' | 'drop' | 'event' = 'event';

  @Output() onCreateClick = new EventEmitter();
  @Output() onEditDetailsClick = new EventEmitter();
  @Output() onUpdatedBlock: EventEmitter<ContentPageBlock> =
    new EventEmitter<ContentPageBlock>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  get summary(): string {
    return this.selectedBlock?.content?.summary ?? '';
  }

  get inputPlaceholder(): string {
    return (
      this.selectedBlock?.content?.title ??
      'My amazing workshop, panel, class, conference, or meetup'
    );
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        title: this.selectedBlock?.title ?? '',
        content: this.selectedBlock?.content
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      title: [this.selectedBlock?.title ?? ''],
      content: [this.selectedBlock?.content]
    });

    this.formGroup.valueChanges
      .pipe(
        map(
          (value) =>
            PageBlock.fromObject({
              ...this.selectedBlock,
              contentId: value?.content?.contentId ?? null,
              ...value
            }) as ContentPageBlock
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }

  handleCreateClick() {
    this.onCreateClick.emit();
  }

  handleEditDetails() {
    this.onEditDetailsClick.emit();
  }
}
