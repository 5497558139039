<div class="event-container">
  <form [formGroup]="formGroup" class="event-wrapper">
    <div>
      <h1>Details</h1>
      <p class="text-neutral-600">
        Let's start with the basic details about your event.
      </p>
    </div>

    <norby-formcontrol-label
      label="Title"
      [isRequired]="true"
    ></norby-formcontrol-label>
    <div class="mb-4">
      <norby-input
        formControlName="title"
        placeholder="Your next workshop, launch, or drop..."
      ></norby-input>
    </div>

    <div class="mb-2">
      <norby-formcontrol-label
        label="Location"
        infoTooltip="Are you hosting a virtual event or meeting in person?"
      >
      </norby-formcontrol-label>

      <norby-radio-button-group
        [block]="true"
        formControlName="contentLocationType"
      >
        <norby-radio-button
          class="block-radio-button"
          buttonLabel="Online"
          buttonValue="online"
        ></norby-radio-button>

        <norby-radio-button
          class="block-radio-button"
          buttonLabel="In real life"
          buttonValue="irl"
        ></norby-radio-button>
      </norby-radio-button-group>
    </div>

    <norby-input
      class="mb-2"
      *ngIf="isIrl"
      label="Address"
      formControlName="contentLocation"
      infoTooltip="Add the address or location for this event."
    ></norby-input>

    <div class="flex flex-row justify-between mb-2" *ngIf="isIrl">
      <p class="text-neutral-600">Display the location on the event page</p>
      <norby-switch-button formControlName="contentLocationPublic">
      </norby-switch-button>
    </div>

    <norby-alert
      *ngIf="isOnline && zoomAccountIsMismatched"
      class="mb-2"
      type="alert"
      >{{ ZOOM_MISMATCH_MESSAGE }}
    </norby-alert>

    <norby-alert
      *ngIf="isOnline && isZoomAccountUnlinked"
      class="mb-2"
      type="alert"
      >{{ ZOOM_UNLINKED_MESSAGE }}
      <button (click)="handleReconnectZoom()">
        Reconnect Zoom
      </button></norby-alert
    >

    <div class="mb-5" *ngIf="isOnline">
      <norby-formcontrol-label
        label="URL"
        infoTooltip="Enter the URL where your event will take place. We'll include it by default in event notifications and automatically track joins."
      ></norby-formcontrol-label>
      <div rootInputGroup>
        <norby-input
          class="grouped"
          [ngClass]="{
            'zoom-input-add': !shouldGenerateZoomLink && zoomIsConnected,
            'zoom-input-remove': shouldGenerateZoomLink,
            'no-zoom': !zoomIsConnected
          }"
          formControlName="contentUrl"
          [placeholder]="
            shouldGenerateZoomLink ? 'Connected to Zoom Meeting' : 'https://'
          "
          [isJoinedRight]="zoomIsConnected"
          [isTextHidden]="shouldGenerateZoomLink"
          [isDisabled]="shouldGenerateZoomLink"
          [isUrl]="true"
        ></norby-input>
        <norby-icon-button-with-label
          *ngIf="zoomIsConnected"
          class="grouped"
          iconName="video"
          [buttonLabel]="shouldGenerateZoomLink ? 'Remove Zoom' : 'Add Zoom'"
          [isJoinedRight]="false"
          [isJoinedLeft]="true"
          (onButtonClicked)="handleToggleZoom(!shouldGenerateZoomLink)"
        ></norby-icon-button-with-label>
      </div>
    </div>

    <div class="flex flex-row flex-wrap">
      <norby-date-time-picker
        class="3xl:w-1/2 3xl:pr-2 w-full pr-0 mb-2"
        label="Starting at"
        [infoTooltip]="timeZoneTooltip"
        formControlName="startDate"
        [errorText]="
          formGroup.errors?.daterangeerror
            ? 'Your event must have an end date after its start date.'
            : ''
        "
        [isDisabled]="event.published && !event?.isFuture"
      >
      </norby-date-time-picker>

      <norby-date-time-picker
        class="3xl:w-1/2 3xl:pl-2 w-full pl-0 mb-2 mt-2"
        label="Ending at"
        formControlName="endDate"
        [isDisabled]="event.published && !event?.isFuture"
      >
      </norby-date-time-picker>
    </div>

    <norby-select
      class="mb-1"
      label="Category"
      formControlName="eventType"
      infoTooltip="What kind of event are you hosting?"
    >
      <option [value]="type.key" *ngFor="let type of EVENT_TYPES | keyvalue">
        {{ type.value }}
      </option>
    </norby-select>
  </form>
</div>
