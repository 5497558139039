import { ValidatorFn, FormGroup } from '@angular/forms';

export function RequireCheckboxesToBeCheckedValidator(
  minRequired = 1
): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    const numChecked = Object.keys(formGroup.controls).reduce(
      (sum, key) => (formGroup.controls[key].value ? ++sum : sum),
      0
    );

    if (numChecked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true
      };
    }

    return null;
  };
}
