import dayjs from 'dayjs';

export function messageDate(date: Date): string {
  if (!date) return;

  const today = dayjs();
  const messageMoment = dayjs(date);

  // show time stamp if message is from today
  if (today.isSame(messageMoment, 'day')) {
    return messageMoment.format('h:mm A');
  }

  // show 'Yesterday' if message is from yesterday
  if (today.subtract(1, 'day').isSame(messageMoment, 'day')) {
    return 'Yesterday';
  }

  // show day of the week if message is from the week
  if (today.isSame(messageMoment, 'week')) {
    return messageMoment.format('dddd');
  }

  // return full date if older
  return dayjs(date).format('M/DD/YY');
}
