import { Injectable } from '@angular/core';

// 3rd party
import { BehaviorSubject } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Lib
import { ErrorService } from 'uikit';
import {
  PaginatedQueryFilters,
  IInboxItemResult,
  IInboxSendMessage,
  IContactSummaryResponseDto,
  IPSQLContactCreate,
  IPSQLContactResponseDto,
  IPSQLContactResults,
  IPSQLContactUpdate,
  IPSQLContact,
  IContactNoteResults,
  ISendContactBulkMessages,
  ApiSurfaces,
  ContactsFilterArgs
} from 'models';

// App
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  chatViewExpanded = new BehaviorSubject<boolean>(false);

  constructor(
    private _api: ApiService,
    private _error: ErrorService
  ) {}

  getContacts = async (
    filters: ContactsFilterArgs
  ): Promise<IPSQLContactResults> => {
    try {
      const ret = await this._api.get<IPSQLContactResults>(
        ApiSurfaces.API,
        `v2/contact`,
        filters
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getContactListings = async (
    filters?: PaginatedQueryFilters
  ): Promise<IPSQLContactResults> => {
    try {
      const ret = await this._api.get<IPSQLContactResults>(
        ApiSurfaces.API,
        `v2/contact/inbox_listing`,
        filters
      );

      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getContactById = async ({ id }: { id: string }): Promise<IPSQLContact> => {
    const contact = await this._api.get<IPSQLContactResponseDto>(
      ApiSurfaces.API,
      `v2/contact/${id}`
    );
    return plainToClass(IPSQLContact, contact);
  };

  async createContact(
    contact: IPSQLContactCreate
  ): Promise<IPSQLContactResponseDto> {
    return this._api.post<IPSQLContactResponseDto>(
      ApiSurfaces.API,
      `v2/contact`,
      contact
    );
  }

  async updateContact(
    id: string,
    contact: IPSQLContactUpdate
  ): Promise<IPSQLContactResponseDto> {
    return this._api.patch<IPSQLContactResponseDto>(
      ApiSurfaces.API,
      `v2/contact/${id}`,
      contact
    );
  }

  async deleteContact(id: string) {
    return this._api.delete(ApiSurfaces.API, `v2/contact/${id}`);
  }

  async mergeContacts(mergeContacts: {
    sourceContactId: string;
    targetContactId: string;
  }) {
    try {
      await this._api.post(
        ApiSurfaces.API,
        `v2/contact/merge_contacts`,
        mergeContacts
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async deleteContacts(ids: string[]) {
    try {
      await this._api.post(ApiSurfaces.API, `v2/contact/bulk_delete`, {
        ids
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async markInboxSeen(contactId: string) {
    await this._api.post(ApiSurfaces.API, `v2/contact/${contactId}/inbox/seen`);
  }

  async markInboxItemSeen(ids: string[]) {
    await this._api.post(ApiSurfaces.API, `v2/contact/inbox/inbox_items_seen`, {
      ids
    });
  }

  getContactInbox = async (
    input: PaginatedQueryFilters & { contactId: string }
  ): Promise<IInboxItemResult> => {
    const { contactId, ...filters } = input;
    return this._api.get(
      ApiSurfaces.API,
      `v2/contact/${contactId}/inbox`,
      filters
    );
  };

  async sendMessage(contactId: string, message: IInboxSendMessage) {
    return this._api.post(
      ApiSurfaces.API,
      `v2/contact/${contactId}/send_message`,
      message
    );
  }

  async sendBulkMessages(
    contactId: string,
    messages: ISendContactBulkMessages
  ) {
    return this._api.post(
      ApiSurfaces.API,
      `v2/contact/${contactId}/send_bulk_messages`,
      messages
    );
  }

  getContactSummary = async (): Promise<IContactSummaryResponseDto> => {
    return this._api.get(ApiSurfaces.API, `v2/contact/summary`);
  };

  async retryMessage(contactId: string, inboxItemId: string) {
    return this._api.post(
      ApiSurfaces.API,
      `v2/contact/${contactId}/send_message_retry`,
      {
        inboxItemId
      }
    );
  }

  async listContactNote(contactId: string, limit = 3) {
    return this._api.get<IContactNoteResults>(
      ApiSurfaces.API,
      `v2/contact/${contactId}/note?limit=${limit}`
    );
  }

  async addContactNote(contactId: string, body: string) {
    return this._api.post(ApiSurfaces.API, `v2/contact/${contactId}/note`, {
      body
    });
  }

  async editContactNote(contactId: string, noteId: string, body: string) {
    return this._api.patch(
      ApiSurfaces.API,
      `v2/contact/${contactId}/note/${noteId}`,
      {
        body
      }
    );
  }

  async deleteContactNote(contactId: string, noteId: string) {
    return this._api.delete<boolean>(
      ApiSurfaces.API,
      `v2/contact/${contactId}/note/${noteId}`
    );
  }
}
