<div class="event-container">
  <form [formGroup]="formGroup" class="event-wrapper">
    <div>
      <h1>Design</h1>

      <p class="text-neutral-600">Build and customize your event page.</p>
    </div>

    <norby-tabs [isContentFullWidth]="true" [isHeaderFullWidth]="true">
      <norby-tab title="Content">
        <div class="mb-5">
          <norby-input
            formControlName="subtitle"
            label="Subtitle"
            placeholder="Join us for a group demo"
          >
          </norby-input>
        </div>

        <div class="mb-5">
          <norby-formcontrol-label label="Body"></norby-formcontrol-label>

          <norby-rich-text-editor-view
            formControlName="body"
            [isBorderless]="false"
          ></norby-rich-text-editor-view>
        </div>

        <norby-file-upload
          label="Images"
          [isRequired]="false"
          [isDisabled]="false"
          [images]="formGroup.get('images').value || []"
          [allowMultiple]="true"
          acceptedFileTypes=".jpeg, .jpg, .png"
          (onUpdatedImages)="onUpdatedImages($event)"
        ></norby-file-upload>

        <div class="mt-2 mb-5">
          <norby-collapsible title="More customization">
            <div
              class="mb-4"
              *ngIf="event?.privateUserInfoRequirements?.email?.required"
            >
              <norby-input
                formControlName="newsletterLabel"
                label="Email opt in label"
                placeholder="e.g. Sign up for my weekly newsletter to stay connected."
                helperText="Customize the email marketing opt in label shown next to the checkbox when collecting emails."
              >
              </norby-input>
            </div>

            <div class="mb-4">
              <norby-input
                formControlName="rsvpLabel"
                label="Signup button"
                placeholder="Sign up"
                helperText="Customize the text shown on the signup button."
              >
              </norby-input>
            </div>

            <div class="mb-4">
              <norby-input
                formControlName="rsvpConfirmedLabel"
                label="Manage registration menu"
                placeholder="You're confirmed"
                helperText="Customize the text shown on the registration management menu after a successful signup."
              >
              </norby-input>
            </div>

            <norby-input
              formControlName="joinLabel"
              label="Join button"
              placeholder="Join"
              helperText="Customize the text shown on the button while the event is happening."
            >
            </norby-input>
          </norby-collapsible>
        </div>
      </norby-tab>
      <norby-tab title="Appearance">
        <norby-formcontrol-label label="Layout"></norby-formcontrol-label>
        <norby-custom-message-tooltip
          action="customize your event page layout"
          (onMessageUpdated)="layoutSelector.isDisabled = !!$event"
        >
          <norby-layout-selector
            #layoutSelector
            placeholder="Select a layout..."
            formControlName="layout"
            [isDisabled]="true"
            [layouts]="layouts"
          >
          </norby-layout-selector>
        </norby-custom-message-tooltip>
        <div class="theme-selector-container mt-6">
          <norby-formcontrol-label label="Theme"></norby-formcontrol-label>
          <norby-theme-selector
            placeholder="Select a theme"
            [themes]="themes"
            (onValueChange)="handleNewThemeSelected($event)"
          >
          </norby-theme-selector>
        </div>
        <div class="theme-customizer-container">
          <norby-theme-customizer
            [theme]="event?.theme"
            (onThemeChange)="handleEventThemeUpdated($event)"
            [sections]="THEME_CUSTOMIZER_SECTIONS"
          ></norby-theme-customizer>
        </div>
      </norby-tab>
    </norby-tabs>
  </form>
</div>
