import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Lib
import { CalendlyPageBlock, UrlValidator } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-calendly-block',
  templateUrl: './norby-edit-calendly-block.component.html',
  styleUrls: ['./norby-edit-calendly-block.component.less']
})
export class NorbyEditCalendlyBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: CalendlyPageBlock;

  @Output() onUpdatedBlock: EventEmitter<CalendlyPageBlock> =
    new EventEmitter<CalendlyPageBlock>();

  formGroup: UntypedFormGroup;

  readonly LABEL: string = 'URL';
  readonly PLACEHOLDER: string = 'https://calendly.com/xxxx-xxx';
  readonly HELPER_TEXT: string = 'Publish to view the rendered Calendly embed.';
  readonly TOOLTIP: string = 'The link to your Calendly';

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        url: this.selectedBlock?.url
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      url: [
        this.selectedBlock?.url,
        [
          Validators.required,
          UrlValidator,
          Validators.minLength(29),
          Validators.pattern('https://calendly.com/.*')
        ]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(CalendlyPageBlock, {
            ...this.selectedBlock,
            url: value.url,
            embedStyle: {
              ...this.selectedBlock.embedStyle,
              type: 'inline'
            }
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
