import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { ViewContactListsComponent } from './view-contact-lists.component';
import { ContactListTableViewModule } from '../../modules/contact-list-table-view.module';

@NgModule({
  declarations: [ViewContactListsComponent],
  imports: [CommonModule, ContactListTableViewModule]
})
export class ViewContactListsModule {}
