import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DropdownModule,
  NorbyButtonModule,
  NorbyCustomFilterDropdownModule,
  NorbyIconModule,
  SpinModule,
  TooltipModule
} from 'uikit';

import { NorbyCustomContentFilterModule } from './norby-custom-content-filter.module';
import { NorbyCustomEngagementFilterModule } from './norby-custom-engagement-filter.module';
import { NorbyFilterListComponent } from '../components/organisms/norby-filter-list';

@NgModule({
  declarations: [NorbyFilterListComponent],
  imports: [
    CommonModule,
    DropdownModule,
    SpinModule,
    TooltipModule,
    NorbyIconModule,
    NorbyButtonModule,
    NorbyCustomContentFilterModule,
    NorbyCustomEngagementFilterModule,
    NorbyCustomFilterDropdownModule
  ],
  exports: [NorbyFilterListComponent]
})
export class NorbyFilterListModule {}
