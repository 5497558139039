<div
  rootTooltip
  [tooltip]="
    shouldDisplayTooltip ? (isLinkTooltip ? linkTooltip : tooltip) : ''
  "
  [delayClose]="isLinkTooltip"
>
  <ng-content> </ng-content>
</div>

<ng-template #linkTooltip>
  <a
    [routerLink]="tooltipMessage.url"
    class="text-white hover:text-white hover:underline"
    [trackProductAnalytics]="trackProductAnalytics"
    [properties]="properties"
  >
    {{ tooltipMessage.message }}
  </a>
</ng-template>

<ng-template #tooltip>
  {{ tooltipMessage.message }}
</ng-template>
