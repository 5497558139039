import { colorFromHash, generateRandomName, hashString } from '../tools';
import { IInboxMedium } from './IInboxItem';

// The camel-cased form of IPSQLContact, used for all new API responses
export class IContactMetadata {
  isFaux!: boolean | undefined;
  contactSourceId!: string;
  createdVia!: 'import' | 'undefined' | undefined;
  lastImportedAt!: Date | undefined;
}

export class IContact {
  private _randomName: string;
  private _randomColor: string;

  id!: string;
  slug!: string;
  displayName?: string;
  nickname?: string;
  photoUrl?: string;
  altPhotoUrl?: string;
  primaryPhoneNumber?: string;
  phoneNumbers?: string[];
  visiblePhoneNumbers!: string[];
  primaryEmail?: string;
  primaryEmailVerified?: boolean;
  emails?: string[];
  verifiedEmails?: string[];
  hasOptedIntoEmailMarketing!: boolean;
  hasOptedIntoSmsMarketing!: boolean;
  deleted!: boolean;
  deletedAt?: Date;
  deletedAtCursor?: string;
  lastSentContactCardAt?: Date;
  lastInformedAboutKeywordsAt?: Date;
  inboxSeen!: boolean;
  inboxLastSeenAt?: Date;
  inboxLastSeenAtCursor?: string;
  inboxLastUpdatedByContactAt?: Date;
  inboxLastUpdatedByContactAtCursor?: string;
  inboxMessageActivityLastUpdatedAt?: Date;
  inboxMessageActivityLastUpdatedAtCursor?: string;
  inboxEmailMessageActivityLastUpdatedAt?: Date;
  inboxEmailMessageActivityLastUpdatedAtCursor?: string;
  inboxSmsMessageActivityLastUpdatedAt?: Date;
  inboxSmsMessageActivityLastUpdatedAtCursor?: string;
  inboxInstagramMessageActivityLastUpdatedAt?: Date;
  inboxInstagramMessageActivityLastUpdatedAtCursor?: string;
  inboxLastUpdatedAt?: Date;
  inboxLastUpdatedAtCursor?: string;
  mostRecentInboxItemId?: string;
  mostRecentSmsInboxItemId?: string;
  mostRecentEmailInboxItemId?: string;
  mostRecentInstagramInboxItemId?: string;
  isPhoneContact?: boolean;
  isEmailContact?: boolean;
  mediums?: IInboxMedium[];
  tags?: string[];
  deduplicationKey?: string;
  indexCursor?: string;
  metadata?: IContactMetadata;
  modifiedAt!: Date;
  modifiedAtCursor!: string;
  modifiedByCustomer!: boolean;
  createdAt!: Date;
  createdAtCursor!: string;
  hasPhoneNumber!: boolean;

  get name(): string {
    let name = this.nickname ?? this.displayName ?? this._randomName;

    if (!name) {
      const { label, color } = generateRandomName(this.id);
      this._randomColor = color;
      name = this._randomName = label;
    }

    return name;
  }

  get avatarUrl(): string {
    return this.altPhotoUrl ?? this.photoUrl;
  }

  get avatarColor(): string {
    if (!this._randomColor) {
      const { label, color } = generateRandomName(this.id);
      this._randomName = label;
      this._randomColor = color;
    }

    return this._randomColor;
  }
}
