import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FormModule,
  NorbyAlertModule,
  NorbyButtonModule,
  NorbyStepsMenuItemModule
} from 'uikit';
import { EventSummaryStepComponent } from '../components/templates/events/event-summary-step';
import { SummaryCardViewModule } from './summary-card-view.module';

@NgModule({
  declarations: [EventSummaryStepComponent],
  imports: [
    CommonModule,
    NorbyButtonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    NorbyStepsMenuItemModule,
    NorbyButtonModule,
    NorbyAlertModule,
    SummaryCardViewModule
  ],
  exports: [EventSummaryStepComponent]
})
export class EventSummaryStepModule {}
