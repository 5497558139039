import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// 3rd party
import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';

// Lib
import {
  NorbyButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyHelpertextModule,
  NorbyInputBaseModule
} from 'uikit';

import { NorbyAntColorPickerComponent } from '../components/atoms/norby-ant-color-picker';

@NgModule({
  declarations: [NorbyAntColorPickerComponent],
  imports: [
    CommonModule,
    NzColorPickerModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyHelpertextModule,
    NorbyInputBaseModule,
    NorbyButtonModule
  ],
  exports: [NorbyAntColorPickerComponent]
})
export class NorbyAntColorPickerModule {}
