<div class="flex flex-col items-center">
  <img src="assets/svg/up-arrow-1.svg" class="arrow-svg mb-1" />

  <div class="flex flex-col items-center" *ngIf="!send?.draft">
    <h1 class="mb-1">{{ deliveryType }} Queued</h1>
    <p class="mb-2">Your message has been queued and will be sent shortly.</p>
  </div>

  <div class="flex flex-col items-center" *ngIf="send?.draft">
    <h1 class="mb-1">{{ deliveryType }} Saved</h1>
    <p class="mb-2">Your message has been saved to drafts.</p>
  </div>

  <norby-button
    text="Back to dashboard"
    (buttonClicked)="handleReturnToDashboard()"
  ></norby-button>
</div>
