export class IContactNote {
  id!: string;
  body!: string;
  slug!: string;
  entity_type!: string;
  created_by_user_display_name!: string;
  last_modified_by_user_display_name!: string;
  modified_at!: Date;
  created_at!: Date;
  modified_at_cursor!: string;
  created_at_cursor!: string;
}
