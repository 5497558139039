<div class="steps-content user-theme-purchase-complete">
  <lib-payment-form
    *ngIf="!userHasPaid && selectedTicket"
    [ticket]="selectedTicket"
    [content]="content"
    [paymentCallback]="paymentCallback()"
    [name]="userProfile?.displayName"
    [isFinalScreen]="isOnFinalScreen"
    [themeClasses]="themeClasses"
    [promoCode]="promoCode"
  >
  </lib-payment-form>
  <div *ngIf="userHasPaid">
    <h2 rootFormItem>Payment complete</h2>

    <p rootFormItem>You've already paid.</p>

    <div rootFormItem>
      <root-button
        type="primary"
        size="large"
        [block]="true"
        [disabled]="!hasLoadedProfile"
        [loading]="isLoading"
        [text]="isOnFinalScreen ? 'Submit' : 'Next'"
        (handleClick)="handleSkipPayment.emit()"
      >
      </root-button>
    </div>
  </div>
</div>
