import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzUploadModule } from 'ng-zorro-antd/upload';

// Lib
import {
  FormModule,
  IconModule,
  NorbyButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyRadioButtonGroupModule,
  NorbyRadioButtonModule,
  NorbySelectModule,
  SpinModule,
  TooltipModule
} from 'uikit';

import { SignupNotificationsStepComponent } from '../components/templates/signups/signup-notifications-step';
import { NorbyRichTextEditorViewModule } from './norby-rich-text-editor-view.module';
import { NorbySegmentCounterModule } from './norby-segment-counter.module';
import { LinkShortenerPopoverViewModule } from './link-shortener-popover-view.module';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';

@NgModule({
  declarations: [SignupNotificationsStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyButtonModule,
    NorbyFormcontrolLabelModule,
    NorbyRadioButtonGroupModule,
    NorbyRadioButtonModule,
    NorbyRichTextEditorViewModule,
    NorbySelectModule,
    NorbySegmentCounterModule,
    NorbyCustomMessageTooltipModule,
    TooltipModule,
    SpinModule,
    NzButtonModule,
    NzUploadModule,
    NzCardModule,
    NzDividerModule,
    NzListModule,
    NzFormModule,
    IconModule,
    LinkShortenerPopoverViewModule
  ],
  exports: [SignupNotificationsStepComponent]
})
export class SignupNotificationsStepModule {}
