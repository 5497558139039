import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

export type ScheduledSendPayload = {
  date: string;
  time: string;
};

@Component({
  selector: 'norby-sends-schedule-view',
  templateUrl: './norby-sends-schedule-view.component.html',
  styleUrls: ['./norby-sends-schedule-view.component.less']
})
export class NorbySendsScheduleViewComponent implements OnInit {
  @Input() isScheduling: boolean = false;
  @Output() onSubmitScheduledSend: EventEmitter<Date> =
    new EventEmitter<Date>();
  @Output() onCancelSend: EventEmitter<void> = new EventEmitter<void>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this._initForm();
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      datetime: ['', Validators.required]
    });
  }

  handleSubmitScheduledSend(): void {
    this.onSubmitScheduledSend.emit(this.formGroup.get('datetime').value);
  }

  handleCancelSend(): void {
    this.onCancelSend.emit();
  }
}
