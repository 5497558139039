<root-spin [spinning]="isLoading">
  <form (ngSubmit)="completeCardPayment()" [formGroup]="stripePaymentForm">
    <h2>Enter your billing details</h2>
    <p>
      You'll see <em>{{ ticket?.statementDescription }}</em> on your statement.
    </p>

    <div rootFormItem>
      <norby-input
        label="Name"
        formControlName="name"
        placeholder="Jane Doe"
      ></norby-input>
    </div>

    <div class="form-row">
      <div rootFormItem>
        <norby-input
          label="Address Line 1"
          formControlName="line1"
          placeholder="620 Atlantic Avenue"
        ></norby-input>
      </div>

      <div rootFormItem>
        <norby-input
          label="Address Line 2"
          formControlName="line2"
          placeholder="Apt 5"
        ></norby-input>
      </div>
    </div>

    <div class="form-row">
      <div rootFormItem>
        <norby-input
          label="City"
          formControlName="city"
          placeholder="Brooklyn"
        ></norby-input>
      </div>

      <div rootFormItem>
        <norby-input
          label="State"
          formControlName="state"
          placeholder="NY"
        ></norby-input>
      </div>
    </div>

    <div class="form-row">
      <div rootFormItem>
        <norby-input
          label="Postal Code"
          formControlName="postalCode"
          placeholder="12345"
        ></norby-input>
      </div>

      <div rootFormItem class="country-select">
        <label class="form-label inline-block pb-2 text-xs" attr.for="country"
          >Country</label
        >
        <norby-select formControlName="country">
          <option
            *ngFor="let country of billingCountries"
            [value]="country?.code"
          >
            {{ country?.name }}
          </option>
        </norby-select>
      </div>
    </div>

    <div id="card-element" class="card-input-field"></div>
    <div id="card-errors"></div>

    <div class="submit">
      <root-button
        (click)="completeCardPayment()"
        [disabled]="!stripePaymentForm.valid || !cardInputComplete || isLoading"
        [loading]="isSubmitting"
        [block]="true"
        size="large"
        type="primary"
        text="Purchase"
      >
      </root-button>
    </div>
  </form>
</root-spin>
