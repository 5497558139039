import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyFiltersBuildViewModule } from 'customer-uikit';
import { BuildFiltersController } from '../components/build-filters-controller';

@NgModule({
  declarations: [BuildFiltersController],
  imports: [CommonModule, NorbyFiltersBuildViewModule],
  exports: [BuildFiltersController]
})
export class BuildFiltersControllerModule {}
