import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Lib
import {
  ClickedUpsellProductAnalyticsLocations,
  ClickedUpsellProductAnalyticsTypes,
  EmbedPageBlock,
  ProductAnalyticsEventTypes
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-html-block',
  templateUrl: './norby-edit-html-block.component.html',
  styleUrls: ['./norby-edit-html-block.component.less']
})
export class NorbyEditHtmlBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: EmbedPageBlock;
  @Input() maxLength?: number = 10000;
  @Input() placeholder?: string = 'Your HTML code...';
  @Input() rows?: number = 4;

  @Output() onUpdatedBlock: EventEmitter<EmbedPageBlock> =
    new EventEmitter<EmbedPageBlock>();

  formGroup: UntypedFormGroup;

  readonly UPSELL_EVENT = ProductAnalyticsEventTypes.CLICKED_UPSELL;
  readonly UPSELL_CUSTOM_HTML_BLOCK = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.HTML_BLOCK,
    cta: 'Upgrade your plan to use custom HTML blocks.'
  };

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        embedCode: this.selectedBlock?.embedCode
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      embedCode: [this.selectedBlock?.embedCode, Validators.required]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(EmbedPageBlock, {
            ...this.selectedBlock,
            embedCode: value.embedCode
              .replace(/&lt;/g, '<')
              .replace(/&gt;/g, '>')
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
