import { plainToClass, Type } from 'class-transformer';
import { TimeZone } from './timezone';

export type ZoomAccountType = 'none' | 'basic' | 'licensed' | 'onPrem';

export enum IAccountIntegrationMetadataTypes {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  SHOPIFY = 'shopify',
  ZOOM = 'zoom',
  STRIPE = 'stripe',
  EVENTBRITE = 'eventbrite',
  CONVERTKIT = 'convertkit',
  FLODESK = 'flodesk',
  HUBSPOT = 'hubspot',
  MAILCHIMP = 'mailchimp',
  KLAVIYO = 'klaviyo',
  GOOGLE = 'google',
  META = 'meta',
  BEEHIIV = 'beehiiv',
  GOOGLE_ADS = 'googleAds',
  SLACK = 'norbyChatSlackBot'
}
export type IAccountIntegrationMetadataType =
  `${IAccountIntegrationMetadataTypes}`;

export type IAccountIntegrationsInstagramFeature =
  `${IAccountIntegrationsInstagramFeatures}`;

export enum IAccountIntegrationsInstagramFeatures {
  INSTAGRAM_CONTACTS = 'instagram_contacts',
  INSTAGRAM_MESSAGES = 'instagram_messages'
}

export enum IAccountIntegrationsTwitterFeatures {
  TWITTER_CONTACTS = 'twitter_contacts',
  TWITTER_MESSAGES = 'twitter_messages'
}

export type IAccountIntegrationsFeature =
  | `${IAccountIntegrationsInstagramFeatures}`
  | `${IAccountIntegrationsTwitterFeatures}`;

export type IAccountIntegrationsSyncStatus =
  | 'pending'
  | 'processing'
  | 'completed'
  | 'failed';

export class IAccountIntegrationMetadataBase {
  type!: IAccountIntegrationMetadataType;
  missing_scopes?: string[] | null;
  linked_by_user!: IAccountIntegrationLinkedByUserMetadata;
  sync_error?: string;
}

export class IAccountIntegrationLinkedByUserMetadata {
  id?: string;
  name?: string;
}

export class ITwitterAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'twitter';
  profile_id!: string;
}

export class IFacebookLongLivedAccessToken {
  access_token!: string;
  expires_at?: Date | null;
}

export class IInstagramAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'instagram';
  account_id!: string;
  instagram_username!: string;
  page_id!: string;
  instagram_business_account_id?: string;
}

export class IFacebookAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'facebook';
  account_id!: string;
  instagram_business_account_ids?: string[];
}

export class IShopifyAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'shopify';
  shop_domain!: string;
  shop_id!: string;
}

export class IZoomAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'zoom';
  name?: string;
  timezone!: TimeZone;
  account_type!: ZoomAccountType;
}

export class IStripeAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'stripe';
  stripe_seller_id!: string;
}

export class IMailchimpAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'mailchimp';
  account_id!: string;
}

export class IKlaviyoAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'klaviyo';
  account_id!: string;
}

export class IEventbriteAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'eventbrite';
  account_id!: string;
  organizations: IEventbriteAccountIntegrationMetadataOrganization[];
  selected_organizations: IEventbriteAccountIntegrationMetadataOrganization[];
}

export class IGoogleAnalyticsAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'google';
  account_id!: string;
  properties: IGoogleAnalyticsAccountIntegrationMetadataProperty[];
  selected_properties: IGoogleAnalyticsAccountIntegrationMetadataProperty[];
}

export class IMetaAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'meta';
  account_id!: string;
  business_account_name: string;
  ad_accounts: IMetaAccountIntegrationMetadataAdAccount[];
  selected_ad_accounts: IMetaAccountIntegrationMetadataAdAccount[];
}

export class IGoogleAdsAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'googleAds';
  account_id!: string;
  ad_accounts: IGoogleAdsAccountIntegrationMetadataAdAccount[];
  selected_ad_accounts: IGoogleAdsAccountIntegrationMetadataAdAccount[];
}

export class IBeehiivAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'beehiiv';
  account_id!: string;
}

export class ISlackAccountIntegrationMetadata extends IAccountIntegrationMetadataBase {
  type!: 'norbyChatSlackBot';
  installation: any;
  permitted_slack_user_emails?: string[];
}
export class IGoogleAnalyticsAccountIntegrationMetadataProperty {
  display_name: string;
  ga_version: string;
  parent_account_id: string;
  property: string;
  property_type: string;
}

export class IEventbriteAccountIntegrationMetadataOrganization {
  created: string;
  id: string;
  image_id: string;
  locale: string;
  name: string;
  parent_id: string;
  vertical: string;
  _type: string;
}

export class IMetaAccountIntegrationMetadataAdAccount {
  account_id: string;
  id: string;
}

export class IGoogleAdsAccountIntegrationMetadataAdAccount {
  id: string;
  resource_name: string;
  client_customer: string;
  manager: boolean;
  descriptive_name: string;
}

export type IAccountIntegrationMetadata =
  | ITwitterAccountIntegrationMetadata
  | IFacebookAccountIntegrationMetadata
  | IInstagramAccountIntegrationMetadata
  | IShopifyAccountIntegrationMetadata
  | IZoomAccountIntegrationMetadata
  | IStripeAccountIntegrationMetadata
  | IMailchimpAccountIntegrationMetadata
  | IKlaviyoAccountIntegrationMetadata
  | IGoogleAnalyticsAccountIntegrationMetadata
  | IEventbriteAccountIntegrationMetadata
  | IMetaAccountIntegrationMetadata
  | IGoogleAdsAccountIntegrationMetadata
  | IBeehiivAccountIntegrationMetadata
  | ISlackAccountIntegrationMetadata;

export abstract class IAccountIntegration {
  id!: string;
  slug!: string;
  account_id!: string;
  account_type!: IAccountIntegrationMetadataType;
  scopes!: string[];
  enabled_features?: IAccountIntegrationsFeature[];
  sync_status?: IAccountIntegrationsSyncStatus;
  renew_at?: Date | null;
  renew_at_cursor?: string;
  modified_at!: Date;
  modified_at_cursor!: string;
  created_at!: Date;
  created_at_cursor!: string;
  is_linked!: boolean;
  display_name?: string;

  access_token!: string | null;
  refresh_token!: string | null;

  mostRecentAccountIntegrationDeleteDataJob?: IAccountIntegrationJob | null;
  mostRecentAccountIntegrationSyncDataJob?: IAccountIntegrationJob | null;

  @Type(() => IAccountIntegrationMetadataBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: [
        { value: IFacebookAccountIntegrationMetadata, name: 'facebook' },
        { value: IInstagramAccountIntegrationMetadata, name: 'instagram' },
        { value: ITwitterAccountIntegrationMetadata, name: 'twitter' },
        { value: IShopifyAccountIntegrationMetadata, name: 'shopify' },
        { value: IZoomAccountIntegrationMetadata, name: 'zoom' },
        { value: IStripeAccountIntegrationMetadata, name: 'stripe' },
        { value: IMailchimpAccountIntegrationMetadata, name: 'mailchimp' },
        { value: IKlaviyoAccountIntegrationMetadata, name: 'klaviyo' },
        { value: IGoogleAnalyticsAccountIntegrationMetadata, name: 'google' },
        { value: IEventbriteAccountIntegrationMetadata, name: 'eventbrite' },
        { value: IMetaAccountIntegrationMetadata, name: 'meta' },
        { value: IGoogleAdsAccountIntegrationMetadata, name: 'googleAds' },
        { value: IBeehiivAccountIntegrationMetadata, name: 'beehiiv' },
        { value: ISlackAccountIntegrationMetadata, name: 'norbyChatSlackBot' }
      ]
    }
  })
  metadata?: IAccountIntegrationMetadata | null;

  static fromObject(object: IAccountIntegration) {
    switch (object?.account_type) {
      case 'instagram':
        return plainToClass(IInstagramAccountIntegration, {
          ...object,
          display_name: 'Instagram'
        });
      case 'facebook':
        return plainToClass(IFacebookAccountIntegration, {
          ...object,
          display_name: 'Facebook'
        });
      case 'twitter':
        return plainToClass(ITwitterAccountIntegration, {
          ...object,
          display_name: 'Twitter'
        });
      case 'shopify':
        return plainToClass(IShopifyAccountIntegration, {
          ...object,
          display_name: 'Shopify'
        });
      case 'zoom':
        return plainToClass(IZoomAccountIntegration, {
          ...object,
          display_name: 'Zoom'
        });
      case 'stripe':
        return plainToClass(IStripeAccountIntegration, {
          ...object,
          display_name: 'Stripe'
        });
      case 'mailchimp':
        return plainToClass(IMailchimpAccountIntegration, {
          ...object,
          display_name: 'Mailchimp'
        });
      case 'klaviyo':
        return plainToClass(IKlaviyoAccountIntegration, {
          ...object,
          display_name: 'Klaviyo'
        });
      case 'google':
        return plainToClass(IGoogleAnalyticsIntegration, {
          ...object,
          display_name: 'Google Analytics'
        });
      case 'eventbrite':
        return plainToClass(IEventbriteAccountIntegration, {
          ...object,
          display_name: 'Eventbrite'
        });
      case 'meta':
        return plainToClass(IMetaAccountIntegration, {
          ...object,
          display_name: 'Meta Ads'
        });
      case 'googleAds':
        return plainToClass(IGoogleAdsAccountIntegration, {
          ...object,
          display_name: 'Google Ads'
        });
      case 'beehiiv':
        return plainToClass(IBeehiivAccountIntegration, {
          ...object,
          display_name: 'Beehiiv'
        });
      case 'norbyChatSlackBot':
        return plainToClass(ISlackAccountIntegration, {
          ...object,
          display_name: 'Slack'
        });
      default:
        return null;
    }
  }
}

export class IInstagramAccountIntegration extends IAccountIntegration {
  get hasMessagePermissions(): boolean {
    return this.enabled_features?.includes(
      IAccountIntegrationsInstagramFeatures.INSTAGRAM_MESSAGES
    );
  }

  get hasContactPermissions(): boolean {
    return this.enabled_features?.includes(
      IAccountIntegrationsInstagramFeatures.INSTAGRAM_CONTACTS
    );
  }
}

export class IFacebookAccountIntegration extends IAccountIntegration {}

export class ITwitterAccountIntegration extends IAccountIntegration {}

export class IShopifyAccountIntegration extends IAccountIntegration {}

export class IStripeAccountIntegration extends IAccountIntegration {}

export class IZoomAccountIntegration extends IAccountIntegration {}

export class IMailchimpAccountIntegration extends IAccountIntegration {}

export class IKlaviyoAccountIntegration extends IAccountIntegration {}

export class IGoogleAnalyticsIntegration extends IAccountIntegration {}

export class IEventbriteAccountIntegration extends IAccountIntegration {}

export class IMetaAccountIntegration extends IAccountIntegration {}

export class IGoogleAdsAccountIntegration extends IAccountIntegration {}

export class IBeehiivAccountIntegration extends IAccountIntegration {}

export class ISlackAccountIntegration extends IAccountIntegration {}

export class IUpdateIntegrationFeaturesDTO {
  instagram_contacts?: boolean;
  instagram_messages?: boolean;
  twitter_contacts?: boolean;
  twitter_messages?: boolean;
}

export class IAccountIntegrationResponse {
  integrations!: IAccountIntegration[];
}

// Account Integration Jobs

export enum IAccountIntegrationJobStatusEnum {
  PROCESSING = 'processing',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export type IAccountIntegrationJobStatus =
  `${IAccountIntegrationJobStatusEnum}`;

export enum IAccountIntegrationJobTypeEnum {
  DELETE = 'delete',
  SYNC = 'sync'
}

export type IAccountIntegrationJobType = `${IAccountIntegrationJobTypeEnum}`;

export class IAccountIntegrationJob {
  id!: string;
  job_type!: IAccountIntegrationJobType;
  slug!: string;
  account_integration_id?: string | null;
  account_integration_type!: IAccountIntegrationMetadataType;
  account_id!: string;
  job_id!: string;
  status!: IAccountIntegrationJobStatus;
  error?: string | null;
  metadata?: IAccountIntegrationJobMetadata | null;
  created_at!: Date;
  created_at_cursor!: string;
  finished_at?: Date | null;
  finished_at_cursor?: string;
  attempts!: number;
}

export class IAccountIntegrationJobMetadataBase {
  integration_type!: IAccountIntegrationMetadataType;
}

export class IAccountIntegrationJobInstagramMetadata extends IAccountIntegrationJobMetadataBase {
  integration_type!: IAccountIntegrationMetadataTypes.INSTAGRAM;
  sync_features!: IAccountIntegrationsInstagramFeature[];
  facebook_error?: FacebookError;
}

export class FacebookError {
  type?: string | null;
  details?: string | null;
  status_code?: string | null;
  user_disabled_message_access?: boolean;
}

export type IAccountIntegrationJobMetadata =
  IAccountIntegrationJobInstagramMetadata;

// utils
export function isFacebookIntegration(
  integration: IAccountIntegration
): integration is IFacebookAccountIntegration {
  return integration?.account_type === 'facebook';
}

export function isInstagramIntegration(
  integration: IAccountIntegration
): integration is IInstagramAccountIntegration {
  return integration?.account_type === 'instagram';
}

export function isTwitterIntegration(
  integration: IAccountIntegration
): integration is ITwitterAccountIntegration {
  return integration?.account_type === 'twitter';
}

export function isShopifyIntegration(
  integration: IAccountIntegration
): integration is IShopifyAccountIntegration {
  return integration?.account_type === 'shopify';
}

export function isZoomIntegration(
  integration: IAccountIntegration
): integration is IZoomAccountIntegration {
  return integration?.account_type === 'zoom';
}

export function isStripeIntegration(
  integration: IAccountIntegration
): integration is IStripeAccountIntegration {
  return integration?.account_type === 'stripe';
}

export function isMailchimpIntegration(
  integration: IAccountIntegration
): integration is IMailchimpAccountIntegration {
  return integration?.account_type === 'mailchimp';
}

export function isKlaviyoIntegration(
  integration: IAccountIntegration
): integration is IKlaviyoAccountIntegration {
  return integration?.account_type === 'klaviyo';
}

export function isGoogleAnalyticsIntegration(
  integration: IAccountIntegration
): integration is IGoogleAnalyticsIntegration {
  return integration?.account_type === 'google';
}

export function isEventbriteIntegration(
  integration: IAccountIntegration
): integration is IEventbriteAccountIntegration {
  return integration?.account_type === 'eventbrite';
}

export function isFacebookIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IFacebookAccountIntegrationMetadata {
  return integration?.type === 'facebook';
}

export function isInstagramIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IInstagramAccountIntegrationMetadata {
  return integration?.type === 'instagram';
}

export function isTwitterIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is ITwitterAccountIntegrationMetadata {
  return integration?.type === 'twitter';
}

export function isShopifyIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IShopifyAccountIntegrationMetadata {
  return integration?.type === 'shopify';
}

export function isZoomIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IZoomAccountIntegrationMetadata {
  return integration?.type === 'zoom';
}

export function isStripeIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IStripeAccountIntegrationMetadata {
  return integration?.type === 'stripe';
}

export function isMailchimpIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IMailchimpAccountIntegrationMetadata {
  return integration?.type === 'mailchimp';
}

export function isKlaviyoIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IKlaviyoAccountIntegrationMetadata {
  return integration?.type === 'klaviyo';
}

export function isEventbriteIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IEventbriteAccountIntegrationMetadata {
  return integration?.type === 'eventbrite';
}

export function isGoogleAdsIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IGoogleAdsAccountIntegrationMetadata {
  return integration?.type === 'googleAds';
}

export function isBeehiivIntegrationMetadata(
  integration: IAccountIntegrationMetadata
): integration is IBeehiivAccountIntegrationMetadata {
  return integration?.type === 'beehiiv';
}
