<form [formGroup]="formGroup">
  <norby-ant-color-picker label="Background Color" formControlName="color">
  </norby-ant-color-picker>
  <norby-slider
    class="mb-4"
    [floor]="1"
    [ceil]="400"
    label="Height"
    formControlName="height"
  ></norby-slider>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
