import { Expose, Type } from 'class-transformer';
import { CountryCode } from '../constants';

export enum StandardRegistrationJobPositionEnum {
  DIRECTOR = 'Director',
  GM = 'GM',
  VP = 'VP',
  CEO = 'CEO',
  CFO = 'CFO',
  GENERAL_COUNSEL = 'General Counsel',
  OTHER = 'Other'
}

export type StandardRegistrationJobPosition =
  `${StandardRegistrationJobPositionEnum}`;

export enum StandardRegistrationBusinessTypeEnum {
  // SOLE_PROPRIETORSHIP = 'Sole Proprietorship',
  PARTNERSHIP = 'Partnership',
  LLC = `Limited Liability Corporation`,
  CO_OP = `Co-operative`,
  // NON_PROFIT = 'Non-profit Corporation',
  GENERAL_COUNSEL = 'General Counsel',
  CORPORATION = 'Corporation'
}

export type StandardRegistrationBusinessType =
  `${StandardRegistrationBusinessTypeEnum}`;

export enum StandardRegistrationBusinessIndustryEnum {
  AUTOMOTIVE = 'AUTOMOTIVE',
  AGRICULTURE = 'AGRICULTURE',
  BANKING = 'BANKING',
  CONSUMER = 'CONSUMER',
  EDUCATION = 'EDUCATION',
  ENGINEERING = 'ENGINEERING',
  ENERGY = 'ENERGY',
  OIL_AND_GAS = 'OIL_AND_GAS',
  FAST_MOVING_CONSUMER_GOODS = 'FAST_MOVING_CONSUMER_GOODS',
  FINANCIAL = 'FINANCIAL',
  FINTECH = 'FINTECH',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  MEDIA = 'MEDIA',
  ONLINE = 'ONLINE',
  RAW_MATERIALS = 'RAW_MATERIALS',
  REAL_ESTATE = 'REAL_ESTATE',
  RELIGION = 'RELIGION',
  RETAIL = 'RETAIL',
  JEWELRY = 'JEWELRY',
  TECHNOLOGY = 'TECHNOLOGY',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL = 'TRAVEL',
  ELECTRONICS = 'ELECTRONICS',
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT'
}

export type StandardRegistrationBusinessIndustry =
  `${StandardRegistrationBusinessIndustryEnum}`;

export const StandardRegistrationBusinessIndustryLabels: {
  [key in StandardRegistrationBusinessIndustry]: string;
} = {
  [StandardRegistrationBusinessIndustryEnum.AUTOMOTIVE]: 'Automotive',
  [StandardRegistrationBusinessIndustryEnum.AGRICULTURE]: 'Agriculture',
  [StandardRegistrationBusinessIndustryEnum.BANKING]: 'Banking',
  [StandardRegistrationBusinessIndustryEnum.CONSUMER]: 'Consumer',
  [StandardRegistrationBusinessIndustryEnum.EDUCATION]: 'Education',
  [StandardRegistrationBusinessIndustryEnum.ENGINEERING]: 'Engineering',
  [StandardRegistrationBusinessIndustryEnum.ENERGY]: 'Energy',
  [StandardRegistrationBusinessIndustryEnum.OIL_AND_GAS]: 'Oil and Gas',
  [StandardRegistrationBusinessIndustryEnum.FAST_MOVING_CONSUMER_GOODS]:
    'Fast Moving Consumer Gas',
  [StandardRegistrationBusinessIndustryEnum.FINANCIAL]: 'Financial',
  [StandardRegistrationBusinessIndustryEnum.FINTECH]: 'Fintech',
  [StandardRegistrationBusinessIndustryEnum.FOOD_AND_BEVERAGE]:
    'Food and Beverage',
  [StandardRegistrationBusinessIndustryEnum.GOVERNMENT]: 'Government',
  [StandardRegistrationBusinessIndustryEnum.HEALTHCARE]: 'Healthcare',
  [StandardRegistrationBusinessIndustryEnum.HOSPITALITY]: 'Hospitality',
  [StandardRegistrationBusinessIndustryEnum.INSURANCE]: 'Insurance',
  [StandardRegistrationBusinessIndustryEnum.LEGAL]: 'Legal',
  [StandardRegistrationBusinessIndustryEnum.MANUFACTURING]: 'Manufacturing',
  [StandardRegistrationBusinessIndustryEnum.MEDIA]: 'Media',
  [StandardRegistrationBusinessIndustryEnum.ONLINE]: 'Online',
  [StandardRegistrationBusinessIndustryEnum.RAW_MATERIALS]: 'Raw Materials',
  [StandardRegistrationBusinessIndustryEnum.REAL_ESTATE]: 'Real Estate',
  [StandardRegistrationBusinessIndustryEnum.RELIGION]: 'Religion',
  [StandardRegistrationBusinessIndustryEnum.RETAIL]: 'Retail',
  [StandardRegistrationBusinessIndustryEnum.JEWELRY]: 'Jewelry',
  [StandardRegistrationBusinessIndustryEnum.TECHNOLOGY]: 'Technology',
  [StandardRegistrationBusinessIndustryEnum.TELECOMMUNICATIONS]:
    'Telecommunications',
  [StandardRegistrationBusinessIndustryEnum.TRANSPORTATION]: 'Transportation',
  [StandardRegistrationBusinessIndustryEnum.TRAVEL]: 'Travel',
  [StandardRegistrationBusinessIndustryEnum.ELECTRONICS]: 'Electronics',
  [StandardRegistrationBusinessIndustryEnum.NOT_FOR_PROFIT]: 'Not-for-Profit'
};

export enum StandardRegistrationBusinessRegistrationIdentifierEnum {
  EIN = 'EIN',
  DUNS = 'DUNS',
  CCN = 'CCN',
  CN = 'CN',
  ACN = 'ACN',
  CIN = 'CIN',
  VAT = 'VAT',
  VATRN = 'VATRN',
  RN = 'RN',
  OTHER = 'Other'
}

export type StandardRegistrationBusinessRegistrationIdentifier =
  `${StandardRegistrationBusinessRegistrationIdentifierEnum}`;

export enum StandardRegistrationCompanyTypeEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
  // NON_PROFIT = 'non-profit',
  GOVERNMENT = 'government'
}

export type StandardRegistrationCompanyType =
  `${StandardRegistrationCompanyTypeEnum}`;

export enum StandardRegistrationRegionsOfOperationEnum {
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  LATIN_AMERICA = 'LATIN_AMERICA',
  USA_AND_CANADA = 'USA_AND_CANADA'
}

export type StandardRegistrationRegionsOfOperationType =
  `${StandardRegistrationRegionsOfOperationEnum}`;

export const StandardRegistrationRegionsOfOperationLabels: {
  [key in StandardRegistrationRegionsOfOperationType]: string;
} = {
  [StandardRegistrationRegionsOfOperationEnum.AFRICA]: 'Africa',
  [StandardRegistrationRegionsOfOperationEnum.ASIA]: 'Asia',
  [StandardRegistrationRegionsOfOperationEnum.EUROPE]: 'Europe',
  [StandardRegistrationRegionsOfOperationEnum.LATIN_AMERICA]: 'Latin America',
  [StandardRegistrationRegionsOfOperationEnum.USA_AND_CANADA]:
    'United States & Canada'
};

export enum StandardRegistrationStockExchangeEnum {
  NONE = 'NONE',
  NASDAQ = 'NASDAQ',
  NYSE = 'NYSE',
  AMEX = 'AMEX',
  ASX = 'ASX',
  B3 = 'B3',
  BME = 'BME',
  BSE = 'BSE',
  FRA = 'FRA',
  ICEX = 'ICEX',
  JPX = 'JPX',
  JSE = 'JSE',
  KRX = 'KRX',
  LON = 'LON',
  NSE = 'NSE',
  OMX = 'OMX',
  SEHK = 'SEHK',
  SGX = 'SGX',
  SSE = 'SSE',
  STO = 'STO',
  SWX = 'SWX',
  SZSE = 'SZSE',
  TSX = 'TSX',
  TWSE = 'TWSE',
  VSE = 'VSE',
  OTHER = 'OTHER'
}

export type StandardRegistrationStockExchange =
  `${StandardRegistrationStockExchangeEnum}`;

export class A2P10DLCLowVolumeStandardRegistration {
  @Expose({ name: 'failure_message' })
  failureMessage: string;

  @Type(() => CompleteA2P10DLCLowVolumeStandardRegistrationInput)
  @Expose({ name: 'registration_information' })
  registrationInformation: CompleteA2P10DLCLowVolumeStandardRegistrationInput;
}

export class RegistrationBusinessAddress {
  street: string;
  city: string;
  region: string;

  @Expose({ name: 'postal_code' })
  postalCode: string;

  @Expose({ name: 'iso_country' })
  isoCountry: CountryCode;
}

export class CompleteA2P10DLCLowVolumeStandardRegistrationInput {
  @Expose({ name: 'representative_email' })
  representativeEmail: string;

  @Expose({ name: 'representative_phone_number' })
  representativePhoneNumber: string;

  @Expose({ name: 'representative_first_name' })
  representativeFirstName: string;

  @Expose({ name: 'representative_last_name' })
  representativeLastName: string;

  @Expose({ name: 'representative_job_position' })
  representativeJobPosition: StandardRegistrationJobPosition;

  @Expose({ name: 'representative_job_title' })
  representativeJobTitle: string;

  @Expose({ name: 'business_address' })
  @Type(() => RegistrationBusinessAddress)
  businessAddress: RegistrationBusinessAddress;

  @Expose({ name: 'business_name' })
  businessName: string;

  @Expose({ name: 'business_website_url' })
  businessWebsiteUrl: string;

  @Expose({ name: 'business_registration_identifier' })
  businessRegistrationIdentifier: StandardRegistrationBusinessRegistrationIdentifier;

  @Expose({ name: 'business_registration_number' })
  businessRegistrationNumber: string;

  @Expose({ name: 'business_industry' })
  businessIndustry: StandardRegistrationBusinessIndustry;

  @Expose({ name: 'business_type' })
  businessType: StandardRegistrationBusinessType;

  @Expose({ name: 'business_regions_of_operation' })
  businessRegionsOfOperation: StandardRegistrationRegionsOfOperationType;

  @Expose({ name: 'company_type' })
  companyType: StandardRegistrationCompanyType;

  @Expose({ name: 'stock_exchange' })
  stockExchange?: StandardRegistrationStockExchange;

  @Expose({ name: 'sample_messages' })
  sampleMessages?: string[];

  @Expose({ name: 'use_case_description' })
  useCaseDescription: string;

  @Expose({ name: 'area_codes' })
  areaCodes?: number[];
}

export class TenDlcOtpDTO {
  phoneNumber!: string;
  vertical!: SoleProprietorBrandVertical;
  brandName!: string;
}

export enum SoleProprietorBrandVerticals {
  AGRICULTURE = 'AGRICULTURE',
  COMMUNICATION = 'COMMUNICATION',
  CONSTRUCTION = 'CONSTRUCTION',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FINANCIAL = 'FINANCIAL',
  GAMBLING = 'GAMBLING',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INSURANCE = 'INSURANCE',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  NGO = 'NGO',
  POLITICAL = 'POLITICAL',
  POSTAL = 'POSTAL',
  PROFESSIONAL = 'PROFESSIONAL',
  REAL_ESTATE = 'REAL_ESTATE',
  RETAIL = 'RETAIL',
  TECHNOLOGY = 'TECHNOLOGY',
  TRANSPORTATION = 'TRANSPORTATION'
}

export type SoleProprietorBrandVertical = `${SoleProprietorBrandVerticals}`;
