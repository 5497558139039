import { Injectable } from '@angular/core';

// 3rd party
import { User } from 'firebase/auth';
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

// App
import {
  LandingPage,
  ContentSignup,
  ISignupCreateDTO,
  ContentEvent,
  IEventCreateDTO,
  ContentRegisterable,
  ContentLink,
  ISendTestMessageContentData,
  FULL_SCREEN_MODAL_SETTINGS,
  ISlug,
  CONTENT_EVENTS,
  SingleSend,
  INotificationDTO,
  Content,
  EventDropAnalyticsTabsType,
  NotificationMediums
} from 'models';
import { UiService, AnalyticsService, DrawerService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class ContentModalService {
  // Analytics
  eventDropAnalyticsComponent;
  linkAnalyticsComponent;
  campaignAnalyticsComponent;
  newsletterLinkClicksDetailComponent;
  pageAnalyticsComponent;

  // Edit / create
  editSignupComponent;
  editEventComponent;
  editLinkComponent;
  editLandingPageComponent;
  campaignTestFormComponent;
  eventCancelMessageBoxComponent;
  contentTestFormComponent;
  editSendComponent;
  chooseSendTypeComponent;
  sendTestFormComponent;

  // Preview
  dropPreviewComponent;
  collectionPreviewComponent;
  eventPreviewComponent;
  sendFullscreenPreviewComponent;
  notificationFullscreenPreviewComponent;

  eventPublishedComponent;
  signupPublishedComponent;
  eventEditTicketComponent;

  zoomIntegrationModalComponent;
  sendsListModalComponent;

  constructor(
    private _drawer: DrawerService,
    private _dialog: NzModalService,
    private _analytics: AnalyticsService,
    private _ui: UiService
  ) {}

  // Launch the analytics viewer for the given event or drop
  // Allows the host to view rsvps, conversion, shares, usage, etc
  async launchEventDropAnalyticsViewer(
    content: ContentRegisterable,
    initialTab?: EventDropAnalyticsTabsType
  ): Promise<string> {
    if (!this.eventDropAnalyticsComponent) {
      this._ui.setLoading(true);
      const { EventDropAnalyticsComponent } = await import(
        '../../entry-points/event-drop-analytics'
      );

      this.eventDropAnalyticsComponent = EventDropAnalyticsComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventDropAnalyticsComponent;

    return this._drawer.createDrawer(component, {
      nzContentParams: { content, initialTab }
    });
  }

  // Launch the analytics viewer for the given single send
  async launchCampaignAnalytics(sendId: string): Promise<string> {
    if (!this.campaignAnalyticsComponent) {
      this._ui.setLoading(true);
      const { CampaignAnalyticsComponent } = await import(
        '../../entry-points/campaign-analytics'
      );

      this.campaignAnalyticsComponent = CampaignAnalyticsComponent;
      this._ui.setLoading(false);
    }

    const component = this.campaignAnalyticsComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { sendId }
    });
  }

  async launchLinkClicksDetail(
    entityId: string,
    interactionValue: string,
    contentNotificationTypes?: string[]
  ) {
    if (!this.newsletterLinkClicksDetailComponent) {
      this._ui.setLoading(true);
      const { LinkClicksDetailComponent } = await import(
        '../../entry-points/link-clicks-detail'
      );

      this.newsletterLinkClicksDetailComponent = LinkClicksDetailComponent;
      this._ui.setLoading(false);
    }

    const component = this.newsletterLinkClicksDetailComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { entityId, interactionValue, contentNotificationTypes }
    });
  }

  // Launch the link analytics viewer for the given link
  // Allows the host to view clicks, etc
  async launchLinkAnalyticsViewer(link: ContentLink): Promise<string> {
    if (!this.linkAnalyticsComponent) {
      this._ui.setLoading(true);
      const { LinkAnalyticsComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/link-analytics'
      );

      this.linkAnalyticsComponent = LinkAnalyticsComponent;
      this._ui.setLoading(false);
    }

    const component = this.linkAnalyticsComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { link }
    });
  }

  async launchPageAnalyticsViewer(page: LandingPage): Promise<string> {
    if (!this.pageAnalyticsComponent) {
      this._ui.setLoading(true);
      const { PageAnalyticsComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/page-analytics'
      );

      this.pageAnalyticsComponent = PageAnalyticsComponent;
      this._ui.setLoading(false);
    }

    const component = this.pageAnalyticsComponent;
    return this._drawer.createDrawer(component, {
      nzContentParams: { page }
    });
  }

  async launchSendTestFormDialog(send: SingleSend): Promise<void> {
    if (!this.sendTestFormComponent) {
      this._ui.setLoading(true);
      const { SendTestFormComponent } = await import(
        '../../entry-points/send-test-form'
      );

      this.sendTestFormComponent = SendTestFormComponent;
      this._ui.setLoading(false);
    }

    const component = this.sendTestFormComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Send',
      nzData: {
        send
      }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchContentTestFormDialog(
    content: ISendTestMessageContentData
  ): Promise<void> {
    if (!this.contentTestFormComponent) {
      this._ui.setLoading(true);
      const { ContentNotificationsTestComponent } = await import(
        '../../entry-points/content-notifications-test'
      );

      this.contentTestFormComponent = ContentNotificationsTestComponent;
      this._ui.setLoading(false);
    }

    const component = this.contentTestFormComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Send',
      nzTitle: 'Send a test',
      nzData: {
        content
      }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  // Create a new drop or edit the one passed in
  async launchCreateOrEditSignupFlow({
    signup,
    duplicateScaffold,
    showEditButtons = true,
    fromExternalFlow = false
  }: {
    signup?: ContentSignup;
    duplicateScaffold?: ISignupCreateDTO;
    showEditButtons?: boolean;
    fromExternalFlow?: boolean;
  }): Promise<{ contentId: string; content: Content }> {
    let startEvent = CONTENT_EVENTS.userBeganCreateDropFlow;
    let endEvent = CONTENT_EVENTS.userFinishedCreateDropFlow;

    if (signup) {
      startEvent = CONTENT_EVENTS.userBeganEditDropFlow;
      endEvent = CONTENT_EVENTS.userFinishedEditDropFlow;
    } else if (duplicateScaffold) {
      startEvent = CONTENT_EVENTS.userBeganDuplicateDropFlow;
      endEvent = CONTENT_EVENTS.userFinishedDuplicateDropFlow;
    }

    this._analytics.track(startEvent);

    if (!this.editSignupComponent) {
      this._ui.setLoading(true);
      const { EditSignupComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/edit-signup'
      );

      this.editSignupComponent = EditSignupComponent;
      this._ui.setLoading(false);
    }

    const component = this.editSignupComponent;
    const ret = await this._drawer.createDrawer(component, {
      nzContentParams: {
        signup,
        duplicateScaffold,
        showEditButtons,
        fromExternalFlow
      }
    });

    this._analytics.track(endEvent);

    return ret;
  }

  // Create a new send
  async launchEditSendFlow({
    send,
    isDuplicate
  }: {
    send?: SingleSend;
    isDuplicate?: boolean;
  }): Promise<string> {
    this._analytics.track(CONTENT_EVENTS.userBeganEditSingleSendFlow);

    const nzContentParams = {
      send,
      ...(isDuplicate && { isDuplicate })
    };

    if (!send) {
      if (!this.chooseSendTypeComponent) {
        this._ui.setLoading(true);
        const { ChooseSendTypeModalComponent } = await import(
          '../../entry-points/choose-send-type'
        );

        this.chooseSendTypeComponent = ChooseSendTypeModalComponent;
        this._ui.setLoading(false);
      }

      const component = this.chooseSendTypeComponent;
      const dialogRef = this._dialog.create<typeof component>({
        nzContent: component,
        nzCloseIcon: 'feather/x',
        nzOkDisabled: true,
        nzFooter: null,
        nzTitle: 'Create a send',
        nzWidth: '652px'
      });

      const chosenType = await firstValueFrom<NotificationMediums>(
        dialogRef.afterClose
      );

      if (chosenType) {
        nzContentParams['initialSendType'] = chosenType;
      } else {
        return;
      }
    }

    if (!this.editSendComponent) {
      this._ui.setLoading(true);
      const { EditSendComponent } = await import(
        '../../entry-points/edit-send'
      );

      this.editSendComponent = EditSendComponent;
      this._ui.setLoading(false);
    }

    const component = this.editSendComponent;
    const ret = await this._drawer.createDrawer(component, {
      nzContentParams
    });

    return ret;
  }

  // Create a new event or edit the one passed in
  async launchCreateOrEditEventFlow({
    event,
    duplicateScaffold,
    showEditButtons = true,
    fromExternalFlow = false
  }: {
    event?: ContentEvent;
    duplicateScaffold?: IEventCreateDTO;
    showEditButtons?: boolean;
    fromExternalFlow?: boolean;
  }): Promise<{ contentId: string; content: Content }> {
    let startEvent = CONTENT_EVENTS.userBeganCreateEventFlow;
    let endEvent = CONTENT_EVENTS.userFinishedCreateEventFlow;

    if (event) {
      startEvent = CONTENT_EVENTS.userBeganEditEventFlow;
      endEvent = CONTENT_EVENTS.userFinishedEditEventFlow;
    } else if (duplicateScaffold) {
      startEvent = CONTENT_EVENTS.userBeganDuplicateEventFlow;
      endEvent = CONTENT_EVENTS.userFinishedDuplicateEventFlow;
    }

    this._analytics.track(startEvent);

    if (!this.editEventComponent) {
      this._ui.setLoading(true);
      const { EditEventComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/edit-event'
      );

      this.editEventComponent = EditEventComponent;
      this._ui.setLoading(false);
    }

    const component = this.editEventComponent;
    const ret = await this._drawer.createDrawer(component, {
      nzContentParams: {
        event,
        duplicateScaffold,
        showEditButtons,
        fromExternalFlow
      }
    });

    this._analytics.track(endEvent);

    return ret;
  }

  // Create a new link or edit the one passed in
  async launchCreateOrEditLinkFlow({
    link,
    showEditButtons = true,
    fromExternalFlow = false
  }: {
    link?: ContentLink;
    showEditButtons?: boolean;
    fromExternalFlow?: boolean;
  }): Promise<{ contentId: string; content: Content }> {
    let startEvent = CONTENT_EVENTS.userBeganCreateLinkFlow;
    let endEvent = CONTENT_EVENTS.userFinishedCreateLinkFlow;

    if (link?.contentId) {
      startEvent = CONTENT_EVENTS.userBeganEditLinkFlow;
      endEvent = CONTENT_EVENTS.userFinishedEditLinkFlow;
    } else if (link) {
      startEvent = CONTENT_EVENTS.userBeganDuplicateLinkFlow;
      endEvent = CONTENT_EVENTS.userFinishedDuplicateLinkFlow;
    }

    this._analytics.track(startEvent);

    if (!this.editLinkComponent) {
      this._ui.setLoading(true);
      const { EditLinkComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/edit-link'
      );

      this.editLinkComponent = EditLinkComponent;
      this._ui.setLoading(false);
    }

    const component = this.editLinkComponent;
    const ret = await this._drawer.createDrawer(component, {
      nzContentParams: {
        link,
        showEditButtons,
        fromExternalFlow
      }
    });

    this._analytics.track(endEvent);

    return ret;
  }

  // Create a new landing page or edit the one passed in
  async launchCreateOrEditLandingPageFlow({
    page,
    slug
  }: {
    page?: LandingPage;
    slug?: ISlug;
  }): Promise<string> {
    let startEvent = CONTENT_EVENTS.userBeganCreateCollectionFlow;
    let endEvent = CONTENT_EVENTS.userFinishedCreateCollectionFlow;

    if (page?.id) {
      startEvent = CONTENT_EVENTS.userBeganEditCollectionFlow;
      endEvent = CONTENT_EVENTS.userFinishedEditCollectionFlow;
    } else if (page) {
      startEvent = CONTENT_EVENTS.userBeganDuplicateCollectionFlow;
      endEvent = CONTENT_EVENTS.userFinishedDuplicateCollectionFlow;
    }

    this._analytics.track(startEvent);

    if (!this.editLandingPageComponent) {
      this._ui.setLoading(true);
      const { EditLandingPageComponent } = await import(
        /* webpackPrefetch: true */
        '../../entry-points/edit-landing-page'
      );

      this.editLandingPageComponent = EditLandingPageComponent;
      this._ui.setLoading(false);
    }

    const component = this.editLandingPageComponent;

    const ret = await this._drawer.createDrawer(component, {
      nzContentParams: { page, slug },
      nzBodyStyle: { padding: '0' }
    });

    this._analytics.track(endEvent);

    return ret;
  }

  async launchEventCancelMessageBoxDialog(input: { event: ContentEvent }) {
    if (!this.eventCancelMessageBoxComponent) {
      this._ui.setLoading(true);
      const { EventCancelMessageBoxComponent } = await import(
        '../../entry-points/event-cancel-message-box'
      );

      this.eventCancelMessageBoxComponent = EventCancelMessageBoxComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventCancelMessageBoxComponent;
    this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Cancel your event',
      nzOkDisabled:
        input?.event?.privateUserInfoRequirements?.email?.required ||
        input?.event?.privateUserInfoRequirements?.phoneNumber?.required,
      nzCancelText: 'Go back',
      nzOkText: "I'm sure",
      nzData: {
        ...input
      }
    } as ModalOptions);
  }

  async launchDropPreview(drop: ContentSignup) {
    if (!this.dropPreviewComponent) {
      this._ui.setLoading(true);
      const { DropPreviewComponent } = await import(
        '../../entry-points/drop-preview'
      );

      this.dropPreviewComponent = DropPreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.dropPreviewComponent;
    this._dialog.create<typeof component, boolean>({
      ...FULL_SCREEN_MODAL_SETTINGS,
      nzContent: component,
      nzData: { drop }
    } as ModalOptions);
  }

  async launchCollectionPreview(page: LandingPage) {
    if (!this.collectionPreviewComponent) {
      this._ui.setLoading(true);
      const { CollectionPreviewComponent } = await import(
        '../../entry-points/collection-preview'
      );

      this.collectionPreviewComponent = CollectionPreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.collectionPreviewComponent;
    this._dialog.create<typeof component, boolean>({
      ...FULL_SCREEN_MODAL_SETTINGS,
      nzContent: component,
      nzData: { page }
    } as ModalOptions);
  }

  async launchSendPreview({ send, slug }: { send?: SingleSend; slug?: ISlug }) {
    if (!this.sendFullscreenPreviewComponent) {
      this._ui.setLoading(true);
      const { SendFullscreenPreviewComponent } = await import(
        '../../entry-points/send-fullscreen-preview'
      );

      this.sendFullscreenPreviewComponent = SendFullscreenPreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.sendFullscreenPreviewComponent;
    this._dialog.create<typeof component, boolean>({
      ...FULL_SCREEN_MODAL_SETTINGS,
      nzContent: component,
      nzData: { send, slug }
    } as ModalOptions);
  }

  async launchNotificationPreview({
    notification,
    isEmail
  }: {
    notification?: INotificationDTO;
    isEmail?: boolean;
  }) {
    if (!this.notificationFullscreenPreviewComponent) {
      this._ui.setLoading(true);
      const { NotificationFullscreenPreviewComponent } = await import(
        '../../entry-points/notification-fullscreen-preview'
      );

      this.notificationFullscreenPreviewComponent =
        NotificationFullscreenPreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.notificationFullscreenPreviewComponent;
    this._dialog.create<typeof component, boolean>({
      ...FULL_SCREEN_MODAL_SETTINGS,
      nzContent: component,
      nzData: { notification, isEmail }
    } as ModalOptions);
  }

  async launchEventPreview(event: ContentEvent) {
    if (!this.eventPreviewComponent) {
      this._ui.setLoading(true);
      const { EventPreviewComponent } = await import(
        '../../entry-points/event-preview'
      );

      this.eventPreviewComponent = EventPreviewComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventPreviewComponent;
    this._dialog.create<typeof component, boolean>({
      ...FULL_SCREEN_MODAL_SETTINGS,
      nzContent: component,
      nzData: { event }
    } as ModalOptions);
  }

  async launchEventPublishedDialog(
    eventUrl: string,
    fromExternalFlow: boolean
  ) {
    if (!this.eventPublishedComponent) {
      this._ui.setLoading(true);
      const { EventPublishedComponent } = await import(
        '../../entry-points/event-published'
      );

      this.eventPublishedComponent = EventPublishedComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventPublishedComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzData: { eventUrl, fromExternalFlow },
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Add',
      nzFooter: null
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchSignupPublishedDialog(
    signupUrl: string,
    fromExternalFlow: boolean
  ) {
    if (!this.signupPublishedComponent) {
      this._ui.setLoading(true);
      const { SignupPublishedComponent } = await import(
        '../../entry-points/signup-published'
      );

      this.signupPublishedComponent = SignupPublishedComponent;
      this._ui.setLoading(false);
    }

    const component = this.signupPublishedComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzData: { signupUrl, fromExternalFlow },
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Add',
      nzFooter: null
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchEventAddTicketDialog(event: ContentEvent) {
    if (!this.eventEditTicketComponent) {
      this._ui.setLoading(true);
      const { EventEditTicketComponent } = await import(
        '../../entry-points/event-edit-ticket'
      );

      this.eventEditTicketComponent = EventEditTicketComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventEditTicketComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Add',
      nzFooter: null
    });

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchZoomIntegrationModal() {
    if (!this.zoomIntegrationModalComponent) {
      this._ui.setLoading(true);
      const { ZoomIntegrationModalComponent } = await import(
        '../../entry-points/zoom-integration-modal'
      );

      this.zoomIntegrationModalComponent = ZoomIntegrationModalComponent;
      this._ui.setLoading(false);
    }

    const component = this.zoomIntegrationModalComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzFooter: null
    });

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchUpcomingSendsListDialog(eventUrl: string) {
    if (!this.eventPublishedComponent) {
      this._ui.setLoading(true);
      const { EventPublishedComponent } = await import(
        '../../entry-points/event-published'
      );

      this.eventPublishedComponent = EventPublishedComponent;
      this._ui.setLoading(false);
    }

    const component = this.eventPublishedComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzData: { eventUrl },
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzOkText: 'Add',
      nzFooter: null
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }

  async launchSendsListModal(
    title: string,
    query: string,
    userCanEdit: boolean,
    authUser: User,
    limit?: number
  ) {
    if (!this.sendsListModalComponent) {
      this._ui.setLoading(true);
      const { SendsListModalComponent } = await import(
        '../../entry-points/sends-list-modal'
      );

      this.sendsListModalComponent = SendsListModalComponent;
      this._ui.setLoading(false);
    }

    const component = this.sendsListModalComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzWidth: '652px',
      nzContent: component,
      nzTitle: title,
      nzData: {
        query,
        userCanEdit,
        authUser,
        ...(limit && { limit })
      },
      nzCloseIcon: 'feather/x',
      nzFooter: null
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }
}
