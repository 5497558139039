import { Injectable } from '@angular/core';

// 3rd party
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';

// App
import { IInboxModalService } from './inbox-modal.service.interface';

// Libs
import {
  IContactNote,
  IPSQLContact,
  InboxOverlayData,
  InboxOverlayType
} from 'models';
import { UiService, DrawerService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class InboxModalService implements IInboxModalService {
  contactDetailsModalComponent;
  createContactComponent;
  importContactsComponent;
  inboxOverlayComponent;
  mergeContactsComponent;
  replyToComponent;
  addContactNoteComponent;

  constructor(
    private _dialog: NzModalService,
    private _drawer: DrawerService,
    private _ui: UiService
  ) {}

  async launchCreateContactDialog(args?: {
    requireContactMethod: boolean;
  }): Promise<IPSQLContact> {
    if (!this.createContactComponent) {
      this._ui.setLoading(true);
      const { CreateContactComponent } = await import(
        '../../entry-points/create-contact'
      );

      this.createContactComponent = CreateContactComponent;
      this._ui.setLoading(false);
    }

    const component = this.createContactComponent;
    const dialogRef = this._dialog.create<typeof component, IPSQLContact>({
      nzContent: component,
      nzTitle: 'Add a contact',
      nzCloseIcon: 'feather/x',
      nzOkText: 'Add',
      nzData: args
    } as ModalOptions);

    return await dialogRef.afterClose.toPromise();
  }

  async launchImportContactDialog(): Promise<void> {
    if (!this.importContactsComponent) {
      this._ui.setLoading(true);
      const { ImportContactsComponent } = await import(
        '../../entry-points/import-contacts'
      );

      this.importContactsComponent = ImportContactsComponent;
      this._ui.setLoading(false);
    }

    const component = this.importContactsComponent;
    this._dialog.create<typeof component, IPSQLContact>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Import contacts',
      nzOkText: 'Import'
    });
  }

  // This one is the problem
  async launchInboxDrawer(
    data: InboxOverlayData,
    contentType: InboxOverlayType
  ): Promise<void> {
    if (!this.inboxOverlayComponent) {
      this._ui.setLoading(true);
      const { InboxOverlayComponent } = await import(
        '../../entry-points/inbox-overlay'
      );

      this.inboxOverlayComponent = InboxOverlayComponent;
      this._ui.setLoading(false);
    }

    const component = this.inboxOverlayComponent;
    this._drawer.createDrawer(component, {
      nzContentParams: { data, contentType }
    });
  }

  async launchMergeContactsDialog(
    contactId1: string,
    contactId2: string
  ): Promise<void> {
    if (!this.mergeContactsComponent) {
      this._ui.setLoading(true);
      const { MergeContactsComponent } = await import(
        '../../entry-points/merge-contacts'
      );

      this.mergeContactsComponent = MergeContactsComponent;
      this._ui.setLoading(false);
    }

    const component = this.mergeContactsComponent;
    this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Merge contacts',
      nzFooter: null,
      nzData: {
        contactId1,
        contactId2
      }
    } as ModalOptions);
  }

  async launchReplyToDialog(slugReplyTo?: string): Promise<string> {
    if (!this.replyToComponent) {
      this._ui.setLoading(true);
      const { InboxReplyToComponent } = await import(
        '../../entry-points/inbox-reply-to'
      );

      this.replyToComponent = InboxReplyToComponent;
      this._ui.setLoading(false);
    }

    const component = this.replyToComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzTitle: 'Configure reply-to email',
      nzCloseIcon: 'feather/x',
      nzOkText: 'Save',
      nzData: {
        slugReplyTo
      }
    } as ModalOptions);

    return await dialogRef.afterClose.toPromise();
  }

  async launchAddContactNoteDialog(contactId: string, note?: IContactNote) {
    if (!this.addContactNoteComponent) {
      this._ui.setLoading(true);
      const { AddContactNoteModalComponent } = await import(
        '../../entry-points/add-contact-note-modal'
      );

      this.addContactNoteComponent = AddContactNoteModalComponent;
      this._ui.setLoading(false);
    }

    const component = this.addContactNoteComponent;
    const dialogRef = this._dialog.create<typeof component>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzOkDisabled: true,
      nzTitle: note ? 'Update note' : 'Add a note',
      nzOkText: note ? 'Update' : 'Add',
      nzData: {
        contactId,
        note
      }
    } as ModalOptions);

    return await firstValueFrom(dialogRef.afterClose);
  }
}
