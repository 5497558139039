<div class="contact-table-container">
  <nz-table
    (nzPageIndexChange)="handlePageChange($event)"
    (nzPageSizeChange)="handlePageSizeChange($event)"
    [(nzPageIndex)]="currentPage"
    [nzData]="contacts"
    [nzFrontPagination]="false"
    [nzLoading]="isLoading"
    [nzPageSizeOptions]="[10, 25, 50]"
    [nzPageSize]="pageSize"
    [nzShowSizeChanger]="true"
    [nzTotal]="totalItems"
    [nzScroll]="{ x: '900px' }"
    [nzFooter]="footer"
    [nzPaginationType]="paginationType"
  >
    <thead>
      <tr>
        <th
          *ngFor="let column of columns; index as i"
          [nzLeft]="i === 0"
          [nzEllipsis]="i !== 0"
        >
          {{ getNameForColumn(column) }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let contact of contacts; trackBy: trackBy; let i = index"
        class="cursor-pointer"
        (click)="handleShowContactDetails(i)"
      >
        <td
          *ngFor="let column of columns; index as i"
          [nzLeft]="i === 0"
          [nzEllipsis]="i !== 0"
        >
          <ng-container *ngIf="column === 'name'">
            <lib-user-row-view
              avatarSize="small"
              [userId]="contact?.id"
              [displayName]="contact?.name"
              [photoURL]="contact?.avatarUrl"
            >
            </lib-user-row-view>
          </ng-container>

          <ng-container *ngIf="column === 'primaryEmail'">
            {{ contact?.primaryEmail }}
          </ng-container>

          <ng-container *ngIf="column === 'primaryEmailVerified'">
            <root-icon
              *ngIf="contact?.primaryEmailVerified"
              name="check_circle"
              nz-tooltip
              nzTooltipTitle="This contact has verified their email address."
            ></root-icon>
          </ng-container>

          <ng-container *ngIf="column === 'hasOptedIntoSmsMarketing'">
            <div class="flex">
              <root-icon
                *ngIf="contact.hasOptedIntoSmsMarketing"
                name="check_circle"
                nz-tooltip
                nzTooltipTitle="Opted into SMS marketing"
              ></root-icon>
            </div>
          </ng-container>

          <ng-container *ngIf="column === 'hasOptedIntoEmailMarketing'">
            <div class="flex">
              <root-icon
                *ngIf="contact?.hasOptedIntoEmailMarketing"
                name="check_circle"
                nz-tooltip
                nzTooltipTitle="Opted into email marketing"
              ></root-icon>
            </div>
          </ng-container>

          <ng-container *ngIf="column === 'primaryPhoneNumber'">
            {{ contact?.primaryPhoneNumber | phone }}</ng-container
          >

          <ng-container *ngIf="column === 'tags'">
            <nz-tag *ngFor="let tag of contact?.tags">{{ tag }}</nz-tag>
          </ng-container>

          <ng-container *ngIf="column === 'mediums'">
            {{ contact?.mediums?.join(', ') }}
          </ng-container>

          <ng-container *ngIf="column === 'photoUrl'">
            {{ contact?.photoUrl }}
          </ng-container>

          <ng-container *ngIf="column === 'altPhotoUrl'">
            {{ contact?.altPhotoUrl }}
          </ng-container>

          <ng-container *ngIf="column === 'verifiedEmails'">
            {{ contact?.verifiedEmails?.join(', ') }}
          </ng-container>

          <ng-container *ngIf="column === 'emails'">
            {{ contact?.emails?.join(', ') }}
          </ng-container>

          <ng-container *ngIf="column === 'phoneNumbers'">
            {{ contact?.phoneNumbers?.join(', ') }}
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
