<div class="flex flex-col">
  <norby-navigation-bar
    titleText="Details"
    (onBackClicked)="handleClose()"
  ></norby-navigation-bar>

  <app-inbox-contact-details
    cdkFocusRegionStart
    [contact]="contact"
    [showDelete]="showDelete"
    [actionButtonsTpl]="actionButtonsTpl"
    (onDelete)="handleDelete()"
    (onSave)="handleSave($event)"
    (onViewInbox)="handleClose()"
  >
    <ng-container *ngTemplateOutlet="bodyTpl"></ng-container>
  </app-inbox-contact-details>
  <div cdkFocusRegionEnd></div>
</div>
