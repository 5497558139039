import { ActivatedRoute } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';

import { AdminModalService } from '../admin-modal';
import { InboxModalService } from '../inbox-modal';
import { NotificationsModalService } from '../notifications-modal';

import { IntegrationsService, LoggerService } from 'shared';
import { IAccountIntegrationMetadataTypes } from 'models';

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

type Trigger =
  | 'mergeContacts'
  | 'instagramPermissions'
  | 'instagramPermissionsError'
  | 'viewNotifications'
  | 'manageNotificationPreferences';

type ActionContext = MergeContactsContext;

type MergeContactsContext = {
  contactId1: string;
  contactId2: string;
};

/**
 * Action queries are query params applied to the url that can trigger an action such as opening a modal/drawer
 * Queries take the shape of `?action=xyz&actionContext=urlencodedJSON`
 * The `actionContext` param is an optional URL encoded JSON that contains necessary data to perform an action
 *
 * Ex. the `mergeContacts` action will require a context of contact ids {contactId1: '123', contactId2: '456'}
 * which encodes to `?action=mergeContacts&actionContext=%7B"contactId1":%20"123","contactId2":%20"456"%7D`
 */
@Injectable({
  providedIn: 'root'
})
export class ActionQueriesService implements OnDestroy {
  private _routeSubscription: Subscription;

  constructor(
    private _notificationsModal: NotificationsModalService,
    private _adminModal: AdminModalService,
    private _inboxModal: InboxModalService,
    private _integrations: IntegrationsService,
    private _logger: LoggerService,
    private _route: ActivatedRoute
  ) {
    this._routeSubscription = this._route.queryParamMap
      .pipe(filter((params) => !!params.get('action')))
      .subscribe((params) => {
        const action = params.get('action') as Trigger;
        const actionContext = params.get('actionContext')
          ? JSON.parse(params.get('actionContext'))
          : null;

        this.handleTrigger(action, actionContext);
      });
  }

  ngOnDestroy(): void {
    this._routeSubscription.unsubscribe();
  }

  handleTrigger(action: Trigger, actionContext: ActionContext): void {
    switch (action) {
      case 'mergeContacts':
        this.mergeContacts(actionContext);
        break;
      case 'instagramPermissions':
        this.openInstagramPermissions();
        break;
      case 'instagramPermissionsError':
        this.openInstagramPermissionsError();
        break;
      case 'viewNotifications':
        this.viewNotifications();
        break;
      case 'manageNotificationPreferences':
        this.manageNotificationPreferences();
        break;
    }
  }

  // ex. `?action=mergeContacts&actionContext=%7B"contactId1":%20"123","contactId2":%20"456"%7D`
  mergeContacts(mergeContacts: MergeContactsContext): void {
    // validate context
    if (
      !mergeContacts ||
      !mergeContacts.contactId1 ||
      !mergeContacts.contactId2
    ) {
      this._logger.logError('Insufficient data for merging contacts');
      return;
    }

    this._inboxModal.launchMergeContactsDialog(
      mergeContacts.contactId1,
      mergeContacts.contactId2
    );
  }

  // ex. `?action=instagramPermissions`
  async openInstagramPermissions(): Promise<void> {
    const integrations = await this._integrations.getAccountIntegrationsForType(
      {
        integrationType: IAccountIntegrationMetadataTypes.INSTAGRAM,
        isLinked: true
      }
    );
    this._adminModal.launchInstagramPermissions(integrations);
  }

  // ex. `?action=instagramPermissionsError`
  async openInstagramPermissionsError(): Promise<void> {
    this._adminModal.launchInstagramPermissionsError();
  }

  // ex. `?action=viewNotifications`
  async viewNotifications(): Promise<void> {
    this._notificationsModal.launchNotifications();
  }

  // ex. `?action=manageNotificationPreferences`
  async manageNotificationPreferences(): Promise<void> {
    this._notificationsModal.launchNotificationPreferences();
  }
}
