<i
  *ngIf="statIcon"
  nz-icon
  [nzType]="statIcon"
  class="border border-neutral-300 rounded-lg p-3 text-neutral-900"
></i>
<div class="flex my-1 text-neutral-600 text-xs items-center">
  {{ label }}
  <root-icon
    *ngIf="helperText"
    name="help_circle"
    nz-tooltip
    [nzTooltipTitle]="helperText"
  ></root-icon>
</div>
<div class="my-1">
  <span class="text-neutral-900 text-4xl font-serif inline-block font-light">
    {{ stat }}
  </span>
</div>
