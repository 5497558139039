<div class="event-container">
  <form [formGroup]="formGroup" class="event-wrapper">
    <h1>Notifications</h1>

    <p class="text-neutral-600">
      Customize your event confirmation, reminder, and start notifications.
    </p>

    <norby-tabs
      [isContentFullWidth]="true"
      [isHeaderFullWidth]="true"
      (onTabChange)="handleTabChange($event)"
    >
      <norby-tab title="Confirmation">
        <norby-select
          label="When someone registers..."
          formControlName="confirmationMedium"
          (onHandleChange)="handleConfirmationMediumChange($event)"
          [isDisabled]="!event?.isFuture"
        >
          <option value="none">Don't send a confirmation</option>
          <option value="sms" *ngIf="smsEnabledForEvent">
            Send a SMS confirmation
          </option>
          <option value="email" *ngIf="emailEnabledForEvent">
            Send an email confirmation
          </option>
        </norby-select>

        <div *ngIf="activeTabIndex === 0">
          <div *ngIf="sendSmsConfirmation">
            <div class="mt-2 mb-2">
              <norby-custom-message-tooltip
                action="customize SMS notifications"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_NOTIFICATION_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <norby-rich-text-editor-view
                  formControlName="confirmationBodySms"
                  placeholder="You're confirmed for..."
                  helperText="Tip: Type '@' to insert a variable."
                  label="Message"
                  [hasToolbar]="false"
                  [useVariables]="true"
                  [isDisabled]="shouldDisableSmsFields || !event?.isFuture"
                ></norby-rich-text-editor-view>
              </norby-custom-message-tooltip>
              <lib-link-shortener-popover-view
                [message]="confirmationBodySms"
                (onUpdatedShowingPopup)="
                  handleShowingPopup($event, 'confirmation')
                "
              >
              </lib-link-shortener-popover-view>
              <norby-segment-counter
                class="mt-2 block"
                [message]="confirmationBodySms"
                [shouldHidePopup]="
                  confirmationHideAiSMSPopup || showingPopup['confirmation']
                "
                (onTogglePopup)="handleTogglePopup('confirmation')"
                (onShortenedMessage)="
                  handleShortenedMessage($event, 'confirmation')
                "
              ></norby-segment-counter>
            </div>

            <div *ngIf="sendSmsConfirmation" class="mb-5">
              <norby-formcontrol-label
                label="Attachments"
                infoTooltip="Attach images or video to your SMS notification"
              >
              </norby-formcontrol-label>

              <norby-custom-message-tooltip
                action="send attachments"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_ATTACHMENTS_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <root-spin [spinning]="isUploading">
                  <nz-upload
                    [nzLimit]="ATTACHMENT_LIMIT"
                    [(nzFileList)]="attachmentFileListRsvpConfirmation"
                    nzListType="picture"
                    [nzMultiple]="false"
                    [nzBeforeUpload]="uploadFileRsvpConfirmation"
                    (nzChange)="
                      handleAttachmentUploadChange($event, 'rsvpConfirmation')
                    "
                    [nzAccept]="ATTACHMENT_MIME_TYPES"
                    class="flex flex-1 flex-col"
                  >
                    <button
                      nz-button
                      nzBlock
                      nzType="dashed"
                      [disabled]="shouldDisableSmsFields"
                    >
                      <root-icon name="plus"></root-icon>Add an attachment
                    </button>
                  </nz-upload>
                </root-spin>
              </norby-custom-message-tooltip>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('sms', 'rsvpConfirmation')"
              ></norby-button>
            </div>
          </div>

        <div *ngIf="sendEmailConfirmation">
          <div class="mb-5">
            <norby-rich-text-editor-view
              label="Subject"
              formControlName="confirmationSubject"
              helperText="Tip: Type '@' to insert a variable."
              placeholder="You're confirmed!"
              [isSingleLine]="true"
              [hasToolbar]="false"
              [useVariables]="true"
              [isDisabled]="!event?.isFuture"
            ></norby-rich-text-editor-view>
          </div>

            <div class="mb-5">
              <norby-rich-text-editor-view
                formControlName="confirmationBodyEmail"
                placeholder="You're confirmed for..."
                helperText="Tip: Type '@' to insert a variable."
                [useVariables]="true"
                [isDisabled]="!event?.isFuture"
              ></norby-rich-text-editor-view>
            </div>

            <div class="mb-5">
              <norby-switch-button
                label="Calendar invite"
                rightLabel="Attach a calendar invite to the confirmation email"
                infoTooltip="Gmail and other clients will allow the recipient to accept the invitation directly from their inbox"
                formControlName="attachCalendarInvite"
                [isDisabled]="!event?.isFuture"
              ></norby-switch-button>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('email', 'rsvpConfirmation')"
                [isDisabled]="!formGroup.valid || !event?.isFuture"
              ></norby-button>
            </div>
          </div>
        </div>
      </norby-tab>

      <norby-tab title="Reminder">
        <norby-select
          label="Before your event starts..."
          formControlName="eventReminderMedium"
          (onHandleChange)="handleEventReminderMediumChange($event)"
          [isDisabled]="
            eventReminderTriggered || eventStartTriggered || !event?.isFuture
          "
        >
          <option value="none">Don't send a reminder</option>
          <option value="sms" *ngIf="smsEnabledForEvent">
            Send a SMS reminder
          </option>
          <option value="email" *ngIf="emailEnabledForEvent">
            Send an email reminder
          </option>
        </norby-select>

        <div
          class="mt-2 mb-1"
          *ngIf="sendEventReminderSms || sendEventReminderEmail"
        >
          <norby-formcontrol-label
            label="How long before the event should the reminder be sent?"
          >
          </norby-formcontrol-label>

          <div class="flex flex-row gap-4">
            <norby-select
              class="w-full"
              formControlName="eventReminderOffsetQt"
              [isDisabled]="
                eventReminderTriggered ||
                eventStartTriggered ||
                !event?.isFuture
              "
            >
              <option [value]="item.value" *ngFor="let item of items">
                {{ item.label }}
              </option>
            </norby-select>

            <norby-select
              class="w-full"
              formControlName="eventReminderOffsetUnitsMultiplier"
              (onHandleChange)="handleOffsetUnitsChange($event)"
              [isDisabled]="
                eventReminderTriggered ||
                eventStartTriggered ||
                !event?.isFuture
              "
            >
              <option
                [value]="unit.multiplier"
                *ngFor="let unit of OFFSET_UNITS"
              >
                {{ unit.label }}
              </option>
            </norby-select>
          </div>
        </div>
        <div *ngIf="activeTabIndex === 1">
          <div *ngIf="sendEventReminderSms">
            <div class="mt-2 mb-2">
              <norby-custom-message-tooltip
                action="customize SMS notifications"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_NOTIFICATION_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <norby-rich-text-editor-view
                  formControlName="eventReminderBodySms"
                  placeholder="The event will start soon!"
                  helperText="Tip: Type '@' to insert a variable."
                  label="Message"
                  [hasToolbar]="false"
                  [useVariables]="true"
                  [isDisabled]="shouldDisableSmsFields || !event?.isFuture"
                ></norby-rich-text-editor-view>
              </norby-custom-message-tooltip>
              <lib-link-shortener-popover-view
                [message]="eventReminderBodySms"
                (onUpdatedShowingPopup)="handleShowingPopup($event, 'reminder')"
              >
              </lib-link-shortener-popover-view>
              <norby-segment-counter
                class="mt-2 block"
                [message]="eventReminderBodySms"
                [shouldHidePopup]="
                  eventReminderHideAiSMSPopup || showingPopup['reminder']
                "
                (onTogglePopup)="handleTogglePopup('eventReminder')"
                (onShortenedMessage)="
                  handleShortenedMessage($event, 'eventReminder')
                "
              ></norby-segment-counter>
            </div>

            <div *ngIf="sendEventReminderSms" class="mb-5">
              <norby-formcontrol-label
                label="Attachments"
                infoTooltip="Attach images or video to your SMS notification"
              >
              </norby-formcontrol-label>

              <norby-custom-message-tooltip
                action="send attachments"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_ATTACHMENTS_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <root-spin [spinning]="isUploading">
                  <nz-upload
                    [nzLimit]="ATTACHMENT_LIMIT"
                    [(nzFileList)]="attachmentFileListEventReminder"
                    nzListType="picture"
                    [nzMultiple]="false"
                    [nzBeforeUpload]="uploadFileEventReminder"
                    (nzChange)="
                      handleAttachmentUploadChange($event, 'eventReminder')
                    "
                    [nzAccept]="ATTACHMENT_MIME_TYPES"
                    class="flex flex-1 flex-col"
                  >
                    <button
                      nz-button
                      nzBlock
                      nzType="secondary"
                      [disabled]="shouldDisableSmsFields"
                    >
                      <root-icon name="plus"></root-icon>Add an attachment
                    </button>
                  </nz-upload>
                </root-spin>
              </norby-custom-message-tooltip>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('sms', 'eventReminder')"
                [isDisabled]="!formGroup.valid || !event?.isFuture"
              ></norby-button>
            </div>
          </div>

          <div *ngIf="sendEventReminderEmail">
            <div class="mt-2 mb-5">
              <norby-rich-text-editor-view
                label="Subject"
                formControlName="eventReminderSubject"
                placeholder="The event will start soon!"
                helperText="Tip: Type '@' to insert a variable."
                [isSingleLine]="true"
                [hasToolbar]="false"
                [isDisabled]="
                  eventReminderTriggered ||
                  eventStartTriggered ||
                  !event?.isFuture
                "
                [useVariables]="true"
              ></norby-rich-text-editor-view>
            </div>

            <div class="mb-5">
              <norby-rich-text-editor-view
                label="Body"
                formControlName="eventReminderBodyEmail"
                placeholder="The event is starting soon. Join here..."
                helperText="Tip: Type '@' to insert a variable."
                [isDisabled]="
                  eventReminderTriggered ||
                  eventStartTriggered ||
                  !event?.isFuture
                "
                [useVariables]="true"
              ></norby-rich-text-editor-view>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('email', 'eventReminder')"
                [isDisabled]="disableSendTest || !event?.isFuture"
              ></norby-button>
            </div>
          </div>
        </div>
      </norby-tab>

      <norby-tab title="Start">
        <norby-select
          label="When your event starts..."
          formControlName="eventStartMedium"
          (onHandleChange)="handleEventStartMediumChange($event)"
          [isDisabled]="eventStartTriggered || !event?.isFuture"
        >
          <option value="none">Don't send a notification</option>
          <option value="sms" *ngIf="smsEnabledForEvent">
            Send a SMS notification
          </option>
          <option value="email" *ngIf="emailEnabledForEvent">
            Send an email notification
          </option>
        </norby-select>

        <div *ngIf="activeTabIndex === 2">
          <div *ngIf="sendEventStartSms">
            <div class="mt-2 mb-2">
              <norby-custom-message-tooltip
                action="customize SMS notifications"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_NOTIFICATION_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <norby-rich-text-editor-view
                  formControlName="eventStartBodySms"
                  placeholder="The event is starting!"
                  helperText="Tip: Type '@' to insert a variable."
                  label="Message"
                  [hasToolbar]="false"
                  [useVariables]="true"
                  [isDisabled]="shouldDisableSmsFields || !event?.isFuture"
                ></norby-rich-text-editor-view>
              </norby-custom-message-tooltip>
              <lib-link-shortener-popover-view
                [message]="eventStartBodySms"
                (onUpdatedShowingPopup)="handleShowingPopup($event, 'start')"
              >
              </lib-link-shortener-popover-view>
              <norby-segment-counter
                class="mt-2 block"
                [message]="eventStartBodySms"
                [shouldHidePopup]="
                  eventStartHideAiSMSPopup || showingPopup['start']
                "
                (onTogglePopup)="handleTogglePopup('eventStart')"
                (onShortenedMessage)="
                  handleShortenedMessage($event, 'eventStart')
                "
              ></norby-segment-counter>
            </div>

            <div *ngIf="sendEventStartSms" class="mb-5">
              <norby-formcontrol-label
                label="Attachments"
                infoTooltip="Attach images or video to your SMS notification"
              >
              </norby-formcontrol-label>

              <norby-custom-message-tooltip
                action="send attachments"
                [isNorbyNumberNeeded]="true"
                [trackProductAnalytics]="UPSELL_EVENT"
                [properties]="UPSELL_ATTACHMENTS_PROPERTIES"
                (onMessageUpdated)="handleUpdatedSmsFieldStatus($event)"
              >
                <root-spin [spinning]="isUploading">
                  <nz-upload
                    [nzLimit]="ATTACHMENT_LIMIT"
                    [(nzFileList)]="attachmentFileListEventStart"
                    nzListType="picture"
                    [nzMultiple]="false"
                    [nzBeforeUpload]="uploadFileEventStart"
                    (nzChange)="
                      handleAttachmentUploadChange($event, 'eventStart')
                    "
                    [nzAccept]="ATTACHMENT_MIME_TYPES"
                    class="flex flex-1 flex-col"
                  >
                    <button
                      nz-button
                      nzBlock
                      nzType="secondary"
                      [disabled]="shouldDisableSmsFields"
                    >
                      <root-icon name="plus"></root-icon>Add an attachment
                    </button>
                  </nz-upload>
                </root-spin>
              </norby-custom-message-tooltip>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('sms', 'eventStart')"
                [isDisabled]="disableSendTest || !event?.isFuture"
              ></norby-button>
            </div>
          </div>

          <div *ngIf="sendEventStartEmail">
            <div class="mt-2 mb-5">
              <norby-rich-text-editor-view
                formControlName="eventStartSubject"
                helperText="Tip: Type '@' to insert a variable."
                label="Subject"
                [isDisabled]="eventStartTriggered || !event?.isFuture"
                [useVariables]="true"
                [isSingleLine]="true"
                [hasToolbar]="false"
              ></norby-rich-text-editor-view>
            </div>

            <div class="mb-5">
              <norby-rich-text-editor-view
                label="Body"
                formControlName="eventStartBodyEmail"
                placeholder="The event is starting now. Join here..."
                helperText="Tip: Type '@' to insert a variable."
                [isDisabled]="eventStartTriggered || !event?.isFuture"
                [useVariables]="true"
              ></norby-rich-text-editor-view>
            </div>

            <div class="mb-4 flex">
              <norby-button
                text="Send a test..."
                buttonType="secondary"
                (click)="sendTest('email', 'eventStart')"
                [isDisabled]="disableSendTest || !event?.isFuture"
              ></norby-button>
            </div>
          </div>
        </div>
      </norby-tab>
    </norby-tabs>
  </form>
</div>
