import { Component, EventEmitter, Input, Output } from '@angular/core';
import { rootX } from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'app-action-header-view',
  templateUrl: './action-header-view.component.html',
  styleUrls: ['./action-header-view.component.less']
})
export class ActionHeaderViewComponent {
  @Input() title: string;
  @Input() closeIcon: 'feather/x' | 'feather/arrow-left' | null = 'feather/x';
  @Output() closeClick = new EventEmitter<void>();

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([rootX]);
  }
}
