import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  NorbyFormcontrolLabelModule,
  NorbySelectModule,
  NorbySliderModule
} from 'uikit';
import { NorbyGradientSelectorComponent } from '../components/atoms/norby-gradient-selector';

@NgModule({
  declarations: [NorbyGradientSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbySelectModule,
    NorbySliderModule
  ],
  exports: [NorbyGradientSelectorComponent]
})
export class NorbyGradientSelectorModule {}
