import { Injectable } from '@angular/core';

// 3rd party
import { firstValueFrom } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

// Libs
import { SETTINGS_EVENTS } from 'models';
import { ITeamModalService } from './team-modal.service.interface';
import { AnalyticsService } from '../analytics';
import { UiService } from '../ui';

@Injectable({
  providedIn: 'root'
})
export class TeamModalService implements ITeamModalService {
  inviteUserComponent;

  constructor(
    private _dialog: NzModalService,
    private _analytics: AnalyticsService,
    private _ui: UiService
  ) {}

  // Launch a modal to invite a user to the current organization
  async launchInviteUser(): Promise<boolean> {
    this._analytics.track(SETTINGS_EVENTS.userBeganInviteTeamMemberFlow);

    if (!this.inviteUserComponent) {
      this._ui.setLoading(true);
      const { InviteUserComponent } = await import(
        '../../entry-points/invite-user/'
      );

      this.inviteUserComponent = InviteUserComponent;
      this._ui.setLoading(false);
    }

    const component = this.inviteUserComponent;
    const dialogRef = this._dialog.create<typeof component, boolean>({
      nzContent: component,
      nzCloseIcon: 'feather/x',
      nzTitle: 'Invite a teammate'
    });

    const result = await firstValueFrom(dialogRef.afterClose);

    this._analytics.track(SETTINGS_EVENTS.userFinishedInviteTeamMemberFlow);

    return result;
  }
}
