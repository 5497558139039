<div class="p-4">
  <div class="flex flex-row justify-between items-center">
    <label class="text-neutral-600 text-xs uppercase font-medium"
      >Content</label
    >
    <div
      class="inline-block relative cursor-pointer"
      (mouseover)="handleMouseOverFab(blocks?.length)"
      (mouseleave)="handleMouseLeaveFab()"
    >
      <norby-fab iconName="plus"></norby-fab>
      <div
        *ngIf="hoverIndex === blocks?.length"
        class="absolute top-0 right-0 bottom-0 left-0"
        nz-popover
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
        nzPopoverOverlayClassName="popover-flush"
        nzPopoverPlacement="bottomLeft"
      ></div>
    </div>
  </div>
  <div
    cdkDropList
    cdkDropListOrientation="vertical"
    [cdkDropListData]="blocks"
    (cdkDropListDropped)="handleDrop($event)"
  >
    <div
      *ngFor="let block of blocks; index as i; trackBy: trackBy"
      class="relative block-list-item"
      [class.active]="activeIndex === i"
    >
      <div
        class="block-list-item-drag flex flex-row items-center py-2 border rounded-sm relative"
        [ngClass]="{
          'border-mustard-400 bg-mustard-200': activeIndex === i,
          'border-transparent': activeIndex !== i
        }"
        cdkDrag
      >
        <div
          cdkDragHandle
          class="block-list-item-handle cursor-grab opacity-50 hover:opacity-100 flex flex-row items-center"
        >
          <root-icon name="more_vertical" class="first-handle"></root-icon>
          <root-icon name="more_vertical" class="second-handle"></root-icon>
        </div>
        <norby-content-item
          class="flex-1"
          [block]="block"
          [isActive]="activeIndex === i"
          (itemClick)="handleBlockClick($event, i)"
        ></norby-content-item>
        <button
          type="button"
          rootTooltip="Remove block"
          class="remove-block opacity-50 hover:opacity-100"
          (click)="handleRemoveBlock($event, i)"
        >
          <root-icon name="trash"></root-icon>
        </button>
        <div
          class="item-placeholder-transition bg-mustard-200 rounded-sm"
          *cdkDragPlaceholder
        ></div>
      </div>
      <div
        class="absolute left-0 -top-3 h-6 hide-when-dragging"
        [ngClass]="i === 0 ? 'right-15%' : 'right-0'"
        (mouseover)="handleMouseOverFab(i)"
        (mouseleave)="handleMouseLeaveFab()"
      >
        <div *ngIf="hoverIndex === i" class="relative">
          <norby-fab
            class="absolute top-0 z-10 scale-75 hover:scale-100 transition-all opacity-50 hover:opacity-100"
            [ngClass]="{
              'opacity-100': hoverIndex === i && popoverHover,
              'scale-100': hoverIndex === i && popoverHover,
              'right-41%': i === 0,
              'right-1/2': i !== 0
            }"
            iconName="plus"
            nz-popover
            nzPopoverTrigger="click"
            [nzPopoverContent]="contentTemplate"
            nzPopoverOverlayClassName="popover-flush"
            nzPopoverPlacement="right"
          ></norby-fab>
          <div
            class="absolute top-0 h-3 right-0 left-0 z-0 border-b border-border-color-split"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentTemplate>
  <norby-flyout-menu
    (mouseover)="handleMouseOverFlyout()"
    (mouseleave)="handleMouseLeaveFlyout()"
    [options]="BLOCK_OPTION_GROUPS"
    (onOptionClicked)="handleCreateOptionClicked($event)"
  ></norby-flyout-menu>
</ng-template>
