import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRowViewModule } from './user-row-view.module';
import { UserRowComponent } from '../components/user-row';

@NgModule({
  declarations: [UserRowComponent],
  imports: [CommonModule, UserRowViewModule],
  exports: [UserRowComponent]
})
export class UserRowModule {}
