import { Injectable, TemplateRef } from '@angular/core';

// 3rd party
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom } from 'rxjs';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

// Libs
import { IPSQLContact } from 'models';

// App
import { ContactModalService, UiService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactModalService extends ContactModalService {
  contactDetailsDrawerComponent;

  constructor(
    protected _dialog: NzModalService,
    protected _drawer: NzDrawerService,
    protected _ui: UiService
  ) {
    super(_dialog, _drawer, _ui);
  }

  async launchContactDetailsDrawer(
    contact: IPSQLContact,
    showDelete = true,
    actionButtonsTpl?: TemplateRef<any>,
    bodyTpl?: TemplateRef<any>
  ): Promise<any> {
    if (!this.contactDetailsDrawerComponent) {
      this._ui.setLoading(true);
      const { ContactDetailsDrawerComponent } = await import(
        '../../entry-points/contact-details-drawer'
      );

      this.contactDetailsDrawerComponent = ContactDetailsDrawerComponent;
      this._ui.setLoading(false);
    }

    const component = this.contactDetailsDrawerComponent;
    const drawerRef = this._drawer.create<
      typeof component,
      {
        contact: IPSQLContact;
        shouldDelete: boolean;
      }
    >({
      nzPlacement: 'right',
      nzContent: component,
      nzClosable: false,
      nzBodyStyle: { padding: '0px' },
      nzFooter: null,
      nzContentParams: {
        contact,
        actionButtonsTpl,
        bodyTpl,
        showDelete
      }
    });

    return await firstValueFrom(drawerRef.afterClose);
  }
}
