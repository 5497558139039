import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamelCaseToWordsPipe } from '../pipes/camel-case-to-words';

@NgModule({
  declarations: [CamelCaseToWordsPipe],
  imports: [CommonModule],
  exports: [CamelCaseToWordsPipe]
})
export class CamelCaseToWordsPipeModule {}
