<div class="my-norby">
  <div class="my-norby-header">
    <div class="site-preview-wrapper" *ngIf="slug && page" @slideIn>
      <lib-landing-page-preview
        [accountInfo]="slug?.accountInfo"
        [theme]="slug?.theme"
        [page]="page"
        [isInteractive]="false"
        [isScrollable]="false"
      ></lib-landing-page-preview>
    </div>
    <div class="absolute bottom-0 right-0 flex items-center p-1">
      <button
        nz-button
        nzShape="circle"
        class="ml-1"
        nz-tooltip
        nzTooltipTitle="View"
        (click)="handleViewClick()"
      >
        <i nz-icon nzType="feather/eye"></i>
      </button>
      <button
        nz-button
        nzShape="circle"
        class="ml-1"
        nz-tooltip
        nzTooltipTitle="Edit"
        (click)="handleEditClick()"
      >
        <i nz-icon nzType="feather/edit-2"></i>
      </button>
    </div>
  </div>

  <div class="my-norby-body">
    <nz-input-group
      nzSearch
      [nzSuffix]="urlSuffixButton"
      [nzPrefix]="urlPrefix"
    >
      <input
        type="text"
        nz-input
        nz-tooltip
        nzTooltipTitle="This is where your homepage appears – try using it as your link in bio!"
        readonly
        [disabled]="!rootUrl"
        [value]="rootUrl ? rootUrl : ''"
      />
    </nz-input-group>

    <nz-input-group
      nzSearch
      [nzSuffix]="allowPhoneNumberCopy ? phoneSuffixButton : null"
      [nzPrefix]="phonePrefix"
    >
      <input
        type="text"
        nz-input
        nz-tooltip
        [nzTooltipTitle]="phoneNumberTooltip"
        readonly
        [disabled]="!phoneNumber"
        [value]="phoneNumber | phone : 'US'"
        placeholder=""
      />
    </nz-input-group>
    <ng-template #urlSuffixButton>
      <button
        nz-button
        nzBlock
        nzSize="small"
        nzType="text"
        (click)="handleCopy()"
        [cdkCopyToClipboard]="'https://' + rootUrl"
        [trackProductAnalytics]="TRACK_CLICK_EVENT"
        [properties]="TRACK_COPY_LINK_PROPERTIES"
      >
        <root-icon size="small" name="copy"></root-icon>
        Copy
      </button>
    </ng-template>
    <ng-template #phoneSuffixButton>
      <button
        nz-button
        nzBlock
        nzSize="small"
        nzType="text"
        (click)="handleCopy()"
        [cdkCopyToClipboard]="phoneNumber"
        [trackProductAnalytics]="TRACK_CLICK_EVENT"
        [properties]="TRACK_COPY_NUMBER_PROPERTIES"
      >
        <root-icon size="small" name="copy"></root-icon>
        Copy
      </button>
    </ng-template>
    <ng-template #phonePrefix>
      <root-icon name="message_circle"></root-icon>
    </ng-template>
    <ng-template #urlPrefix>
      <root-icon name="link"></root-icon>
    </ng-template>
  </div>
</div>
