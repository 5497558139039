import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { NorbyThemeSelectorComponent } from '../components/atoms/norby-theme-selector';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';

@NgModule({
  declarations: [NorbyThemeSelectorComponent],
  imports: [CommonModule, NorbyConfigurableSelectorModule],
  exports: [NorbyThemeSelectorComponent]
})
export class NorbyThemeSelectorModule {}
