import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter
} from '@angular/core';

// Libs
import {
  ProductAnalyticsEventTypes,
  ClickedElementProductAnalyticsElementTypeEnum,
  ClickedElementProductAnalyticsElementCTAEnum
} from 'models';
import { BaseComponent, zoomBigMotion } from 'uikit';

@Component({
  selector: 'lib-link-shortener-popover-view',
  templateUrl: './link-shortener-popover-view.component.html',
  styleUrls: ['./link-shortener-popover-view.component.less'],
  animations: [zoomBigMotion]
})
export class LinkShortenerPopoverViewComponent
  extends BaseComponent
  implements OnChanges
{
  private _containsShortLink = false;

  @Input() message = '';
  @Input() popupDisplayDirection = 'right';
  @Input() shouldHidePopup = false;

  @Output() onUpdatedShowingPopup = new EventEmitter<boolean>();

  readonly TRACK_CLICK_EVENT = ProductAnalyticsEventTypes.CLICKED_ENTITY;
  readonly KNOWLEDGE_BASE = {
    elementType: ClickedElementProductAnalyticsElementTypeEnum.BUTTON,
    elementCTA: ClickedElementProductAnalyticsElementCTAEnum.KNOWLEDGE_BASE
  };

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes?.message) {
      this._containsShortLink = !!this.message?.includes('bit.ly');
      this.onUpdatedShowingPopup.emit(this._containsShortLink);
    }
  }

  handleClose(): void {
    this.shouldHidePopup = true;
    this.onUpdatedShowingPopup.emit(!this.shouldHidePopup);
  }

  get shouldShowPopup(): boolean {
    return this._containsShortLink && !this.shouldHidePopup;
  }
}
