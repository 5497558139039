import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { map } from 'rxjs';

// Lib
import {
  ContentEvent,
  ContentEventMetadata,
  EventChanges,
  EventMetadataChanges,
  compareEvents,
  UrlValidator,
  isRequiredValidator,
  ISlug,
  isAlphanumericValidator,
  uuidv4,
  PromoCodeControlType
} from 'models';
import { BaseComponent, MockContentModalService } from 'uikit';

@Component({
  selector: 'lib-event-monetization-step',
  templateUrl: './event-monetization-step.component.html',
  styleUrls: ['./event-monetization-step.component.less']
})
export class EventMonetizationStepComponent
  extends BaseComponent
  implements OnInit
{
  @Input() event: ContentEvent;
  @Input() eventMetadata: ContentEventMetadata;
  @Input() stripeIsConnected: boolean;
  @Input() slug: ISlug;

  @Output() onUpdatedEvent: EventEmitter<EventChanges> =
    new EventEmitter<EventChanges>();
  @Output() onUpdatedEventMetadata: EventEmitter<EventMetadataChanges> =
    new EventEmitter<EventMetadataChanges>();

  formGroup: UntypedFormGroup;
  promoCodesForm: UntypedFormGroup;
  promoCodesControls: Array<PromoCodeControlType> = [];
  isTicketingEnabled: boolean = false;
  isDonateUrlEnabled: boolean = false;

  readonly MAX_NUM_OF_TICKETS = new Array(10);

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _contentModal: MockContentModalService
  ) {
    super();
  }

  get integrationsLink() {
    return `/${this.slug?.slug}/integrations`;
  }

  private _ticketPriceUsdToCents(price: string | number) {
    const priceInCents = Number(price) * 100;
    return priceInCents;
  }

  private _ticketPriceCentsToUSD(price: string | number) {
    const priceInUSD = Number(price) / 100;
    return priceInUSD;
  }

  ngOnInit(): void {
    this._initPromoCodesForm();
    this._initFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        ticketPrice:
          this._ticketPriceCentsToUSD(this.event?.tickets?.[0]?.price) || 1,
        ticketStatementDescription:
          this.event?.tickets?.[0]?.statementDescription || undefined,
        refundPolicy: this.event?.tickets?.[0]?.refundPolicy || 'noRefunds',
        donateUrl: [this.event?.urls?.donateRaw || '', [UrlValidator]],
        promoCodes: this.promoCodesForm
      },
      { emitEvent: false }
    );

    this.isTicketingEnabled = this.event?.isPaid;
    this.isDonateUrlEnabled = !!this.event?.urls?.donateRaw;
  }

  private _initFormGroup() {
    this.formGroup = this._formBuilder.group({
      ticketPrice: [
        this._ticketPriceCentsToUSD(this.event?.tickets?.[0]?.price) || 1,
        [Validators.min(1), Validators.max(5000)]
      ],
      ticketStatementDescription: [
        this.event?.tickets?.[0]?.statementDescription || '',
        [
          isRequiredValidator(() => this.isTicketingEnabled),
          isAlphanumericValidator
        ]
      ],
      refundPolicy: [this.event?.tickets?.[0]?.refundPolicy || 'noRefunds'],
      donateUrl: [this.event?.urls?.donateRaw || '', [UrlValidator]],
      ticketMaxQty: [
        this.event?.tickets?.[0]?.maxQty || 1,
        [Validators.min(1), Validators.max(this.MAX_NUM_OF_TICKETS.length)]
      ],
      promoCodes: this.promoCodesForm
    });

    this.isTicketingEnabled = this.event?.isPaid;
    this.isDonateUrlEnabled = !!this.event?.urls?.donateRaw;

    this.formGroup?.valueChanges
      .pipe(
        map((value) => {
          if (
            value?.donateUrl === 'https://' ||
            !value?.donateUrl ||
            !this.isDonateUrlEnabled
          ) {
            value.donateUrl = '';
            this.formGroup.patchValue({ donateUrl: '' }, { emitEvent: false });
          }

          return ContentEvent.fromObject({
            ...this.event,
            urls: {
              ...this.event?.urls,
              donateRaw: this.isDonateUrlEnabled ? value?.donateUrl : ''
            },
            tickets: this.isTicketingEnabled
              ? [
                  {
                    ...this.event?.tickets?.[0],
                    label: value?.ticketStatementDescription,
                    price: this._ticketPriceUsdToCents(value?.ticketPrice),
                    statementDescription: value?.ticketStatementDescription,
                    refundPolicy: value?.refundPolicy,
                    maxQty: Number(value?.ticketMaxQty || 1)
                  }
                ]
              : [],
            promoCodes:
              Object.keys(value?.promoCodes || {}).length > 0
                ? this.promoCodesControls.map(
                    (ctrl) => value?.promoCodes[ctrl.controlInstance]
                  )
                : []
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((event) => {
        if (!compareEvents(this.event, event)) {
          this.onUpdatedEvent.emit({
            isDirty: this.formGroup.dirty,
            isMonetizationStepValid: this.isTicketingEnabled
              ? this.formGroup.valid
              : true,
            event
          });
        }
      });
  }

  private _initPromoCodesForm() {
    const promoCodesArray = this.event?.promoCodes?.slice() ?? [];

    this.promoCodesControls = promoCodesArray.map<PromoCodeControlType>(
      (code, idx) => {
        const id = `f${idx}`;
        return {
          id,
          controlInstance: `code${id}`,
          isNew: false
        };
      }
    );

    this.promoCodesForm = this._formBuilder.group(
      promoCodesArray.reduce((prev, curr, index) => {
        return {
          ...prev,
          [this.promoCodesControls[index]?.controlInstance]:
            this._formBuilder.group({
              id: [curr?.id],
              promoCode: [curr?.promoCode, [Validators.required]],
              discountPercentage: [
                curr?.discountPercentage,
                [Validators.required, Validators.min(1), Validators.max(100)]
              ],
              delete: [false]
            })
        };
      }, {})
    );
  }

  handleAddPromoCode() {
    const id = uuidv4();
    const control: PromoCodeControlType = {
      id,
      controlInstance: `code${id}`,
      isNew: true
    };

    const length = this.promoCodesControls?.push(control);

    this.promoCodesForm.addControl(
      this.promoCodesControls[length - 1]?.controlInstance,
      this._formBuilder.group({
        promoCode: ['', [Validators.required]],
        discountPercentage: [
          null,
          [Validators.required, Validators.min(1), Validators.max(100)]
        ],
        delete: [false]
      })
    );
  }

  handleMarkCodeDeleted(control: PromoCodeControlType) {
    if (!(this.promoCodesControls?.length > 0)) {
      return;
    }

    const value = this.promoCodesForm.controls[control.controlInstance].value;

    const index =
      this.promoCodesControls?.length > 1
        ? this.promoCodesControls?.indexOf(control)
        : 0;

    if (value.id) {
      this.promoCodesControls[index].delete = true;

      this.formGroup.markAsDirty();
      //add value to promo code fields in case they have been removed and form is invalid
      this.promoCodesForm
        .get(control.controlInstance)
        ?.patchValue({ delete: true, promoCode: ' ', discountPercentage: 1 });
    } else {
      this.promoCodesControls?.splice(index, 1);
      this.promoCodesForm?.removeControl(control?.controlInstance);
      this.promoCodesForm.markAsDirty();
    }
  }

  trackBy(idx: number, item: PromoCodeControlType) {
    return item.controlInstance;
  }

  handleTicketingChange(value: boolean) {
    this.isTicketingEnabled = value;
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({
      ticketPrice: 1,
      ticketStatementDescription: ''
    });
    this.formGroup.updateValueAndValidity();
  }

  handleDonationsChange(value: boolean) {
    this.isDonateUrlEnabled = value;
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({ donateUrl: '' });
  }

  handleAddTicketClicked() {
    // Save for V3
    this._contentModal.launchEventAddTicketDialog(this.event);
  }
}
