<form [formGroup]="formGroup">
  <div class="mb-3 w-full relative">
    <norby-select label="Type" formControlName="type">
      <option value="linear">Linear</option>
      <option value="radial">Radial</option>
    </norby-select>

    <norby-formcontrol-label
      label="Gradient"
      infoTooltip="Click to add color stops"
    ></norby-formcontrol-label>

    <div class="gradient-generator-container mt-4">
      <div class="gradient-picker-wrapper border rounded-sm">
        <div #picker></div>
      </div>
    </div>

    <div class="mt-8">
      <span
        class="w-4 h-4"
        style="
          background-image: linear-gradient(
            to right,
            rgb(8, 80, 120) 1%,
            rgb(133, 216, 206) 99%
          );
        "
      ></span>
    </div>
    <norby-slider
      *ngIf="isLinear"
      label="Angle"
      formControlName="angle"
      ceil="360"
    ></norby-slider>
  </div>

  <label
    class="form-label pb-2 text-xs flex flex-row items-center text-neutral-800"
    >Gallery</label
  >
  <div class="flex flex-row space-between">
    <div class="flex flex-row flex-wrap gap-4">
      <div
        *ngFor="let gradient of GRADIENTS"
        class="w-8 h-8 min-w-8 min-h-8 rounded-sm content-start"
        [style.background]="gradient"
        (click)="handleChoseGradientFromLibrary(gradient)"
      ></div>
    </div>
  </div>
</form>
