import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import { DEFAULT_BLOCK_PADDING, TextSingleSendBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'edit-email-text-block-view',
  templateUrl: './edit-email-text-block-view.component.html',
  styleUrls: ['./edit-email-text-block-view.component.less']
})
export class EditEmailTextBlockViewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() block: TextSingleSendBlock;

  @Output() onUpdatedBlock: EventEmitter<TextSingleSendBlock> =
    new EventEmitter<TextSingleSendBlock>();

  formGroup: UntypedFormGroup;
  readonly DEFAULT_BLOCK_PADDING = DEFAULT_BLOCK_PADDING;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        body: this.block?.body
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      body: [this.block?.body]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(TextSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
