import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

// App
import { NorbyIconModule, IconModule } from 'uikit';
import { StatisticViewComponent } from '../views';

@NgModule({
  declarations: [StatisticViewComponent],
  exports: [StatisticViewComponent],
  imports: [
    CommonModule,
    NorbyIconModule,
    IconModule,
    NzToolTipModule,
    NzIconModule
  ]
})
export class StatisticViewModule {}
