import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyInputModule, NorbySwitchButtonModule } from 'uikit';
import { NorbyEditSpotifyBlockComponent } from '../components/templates/content-block-editors/norby-edit-spotify-block';

@NgModule({
  declarations: [NorbyEditSpotifyBlockComponent],
  imports: [
    CommonModule,
    NorbyInputModule,
    NorbySwitchButtonModule,
    ReactiveFormsModule
  ],
  exports: [NorbyEditSpotifyBlockComponent]
})
export class NorbyEditSpotifyBlockModule {}
