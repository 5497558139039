<form [formGroup]="formGroup" class="drop-shadow-sliders-view-form">
  <label>Horizontal Offset</label>
  <norby-slider
    formControlName="h"
    [floor]="0"
    [ceil]="48"
    size="small"
  ></norby-slider>

  <label>Vertical Offset</label>
  <norby-slider
    formControlName="v"
    [floor]="0"
    [ceil]="48"
    size="small"
  ></norby-slider>

  <label>Blur</label>
  <norby-slider
    formControlName="blur"
    [floor]="0"
    [ceil]="48"
    size="small"
  ></norby-slider>
</form>
