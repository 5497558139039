import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// App
import { NorbyIconModule } from 'uikit';
import { NorbyConfigurableSelectorModule } from './norby-configurable-selector.module';
import { NorbySocialLinksSelectorComponent } from '../components/atoms/norby-social-links-selector';

@NgModule({
  declarations: [NorbySocialLinksSelectorComponent],
  imports: [CommonModule, NorbyConfigurableSelectorModule, NorbyIconModule],
  exports: [NorbySocialLinksSelectorComponent]
})
export class NorbySocialLinksSelectorModule {}
