import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyInputModule } from 'uikit';
import { NorbyEditYoutubeBlockComponent } from '../components/templates/content-block-editors/norby-edit-youtube-block';

@NgModule({
  declarations: [NorbyEditYoutubeBlockComponent],
  imports: [CommonModule, NorbyInputModule, ReactiveFormsModule],
  exports: [NorbyEditYoutubeBlockComponent]
})
export class NorbyEditYoutubeBlockModule {}
