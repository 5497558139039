import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NorbyContentItemComponent } from '../components/molecules/norby-content-item/norby-content-item.component';
import { NorbyIconModule } from './norby-icon.module';

@NgModule({
  declarations: [NorbyContentItemComponent],
  imports: [CommonModule, NorbyIconModule],
  exports: [NorbyContentItemComponent]
})
export class NorbyContentItemModule {}
