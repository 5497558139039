import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Lib
import { NorbySelectorTypesEnum } from 'models';

@Component({
  selector: 'norby-layout-selector',
  templateUrl: './norby-layout-selector.component.html',
  styleUrls: ['./norby-layout-selector.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyLayoutSelectorComponent),
      multi: true
    }
  ]
})
export class NorbyLayoutSelectorComponent
  implements ControlValueAccessor, OnInit
{
  @Input() label: string;
  @Input() placeholder: string;
  @Input() layouts: any[];
  @Input() value?: string;
  @Input() isDisabled: boolean = false;
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();

  readonly NORBY_SELECTOR_TYPES_ENUM = NorbySelectorTypesEnum;
  val: string;

  private _onChanged: any = (_: any) => {};
  private _onTouched: any = () => {};
  private _touched = false;

  constructor() {}

  ngOnInit(): void {
    this.val = this.value;
  }

  handleChange(item) {
    this._markAsTouched();
    this.onValueChange.emit(item.type);
    this._onChanged(item.type);
  }

  writeValue(value: string) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
