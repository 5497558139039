<lib-user-row-view
  [userId]="userId"
  [photoURL]="uiAvatarUrl"
  [subtitle]="subtitle"
  [displayName]="uiName"
  [avatarSize]="size"
  [invert]="invert"
>
  <ng-content></ng-content>
</lib-user-row-view>
