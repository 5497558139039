import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'uikit';

// App
import { ISlug, SingleSend } from 'models';

@Component({
  selector: 'app-send-thumbnail-preview-view',
  templateUrl: './send-thumbnail-preview-view.component.html',
  styleUrls: ['./send-thumbnail-preview-view.component.less']
})
export class SendThumbnailPreviewViewComponent extends BaseComponent {
  @Input() send: SingleSend;
  @Input() slug: ISlug;
  @Output() onViewPreview = new EventEmitter<void>();

  constructor() {
    super();
  }

  handleViewPreview() {
    this.onViewPreview.emit();
  }
}
