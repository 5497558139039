import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyContentSelectorModule } from './norby-content-selector.module';
import {
  NorbyIconModule,
  NorbyInputModule,
  NorbyImageModule,
  FormModule,
  NorbyButtonModule
} from 'uikit';
import { NorbyEditContentBlockComponent } from '../components/templates/content-block-editors/norby-edit-content-block';

@NgModule({
  declarations: [NorbyEditContentBlockComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyContentSelectorModule,
    NorbyIconModule,
    NorbyInputModule,
    NorbyButtonModule,
    NorbyImageModule,
    FormModule
  ],
  exports: [NorbyEditContentBlockComponent]
})
export class NorbyEditContentBlockModule {}
