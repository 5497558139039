<norby-image-radio-button-group
  [value]="signupType"
  (buttonClicked)="handleButtonClicked($event)"
>
  <norby-image-radio-button
    class="w-full"
    buttonLabel="Flow"
    buttonValue="flow"
    imageUrl="assets/svg/signup-flow.svg"
  ></norby-image-radio-button>

  <norby-image-radio-button
    class="w-full"
    buttonLabel="Form"
    buttonValue="form"
    imageUrl="assets/svg/signup-form.svg"
  ></norby-image-radio-button>
</norby-image-radio-button-group>
