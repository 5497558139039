import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { filter, map, tap } from 'rxjs';

// Libs
import {
  AVAILABLE_MASKS,
  ContentLink,
  HeaderPageBlock,
  IImage,
  ISlug,
  LandingPage,
  MenuItemControlType,
  uuidv4,
  FALLBACK_HEADER_IMAGE_URL
} from 'models';
import { isUrlValidator } from 'models';
import { BaseComponent, MockContentService } from 'uikit';

@Component({
  selector: 'norby-edit-header-block',
  templateUrl: './norby-edit-header-block.component.html',
  styleUrls: ['./norby-edit-header-block.component.less']
})
export class NorbyEditHeaderBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: HeaderPageBlock;
  @Input() slug: ISlug;
  @Input() availablePages: LandingPage[] = [];

  @Output() onUpdatedBlock: EventEmitter<HeaderPageBlock> =
    new EventEmitter<HeaderPageBlock>();
  @Output() onCreateLinkClick: EventEmitter<void> = new EventEmitter();

  formGroup: UntypedFormGroup;
  backgroundMode: 'image' | 'color' | 'gradient' | 'transparent' =
    'transparent';
  backgroundImage: IImage[] = [];
  avatarImage: IImage[] = [];

  menuItemsForm: UntypedFormGroup;
  menuItemsControls: Array<MenuItemControlType> = [];
  menuItemsArray: ContentLink[];

  readonly AVAILABLE_MASKS = AVAILABLE_MASKS;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _content: MockContentService
  ) {
    super();
  }

  private _initDefaultAvatar() {
    const defaultAvatar: IImage = new IImage();
    defaultAvatar.url =
      this.slug?.accountInfo?.avatarUrl ?? FALLBACK_HEADER_IMAGE_URL;

    this.formGroup?.patchValue({
      avatarImage: defaultAvatar
    });
  }

  ngOnInit(): void {
    this._initForm();
    if (!this.selectedBlock?.avatarImage && !this.selectedBlock?.headerType) {
      this._initDefaultAvatar();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this._initBackgroundMode();
    this._initImages();
    this._initUrlsAndPlaceholders();

    this.formGroup?.patchValue(
      {
        style: this.selectedBlock?.style ?? 'creator',
        avatarImage: this.selectedBlock?.avatarImage,
        height: this.selectedBlock?.height ?? 120,
        backgroundColor: this.selectedBlock?.backgroundColor ?? 'transparent',
        backgroundImage: this.selectedBlock?.backgroundImage,
        backgroundGradient: this.selectedBlock?.backgroundGradient,
        maskUrl: this.selectedBlock?.maskUrl,
        avatarClickThroughUrlType:
          this.selectedBlock?.avatarClickThroughUrlType ?? 'absolute',
        avatarClickThroughUrl: this.selectedBlock?.avatarClickThroughUrl,
        headerType: this.selectedBlock?.headerType || 'imageOnly',
        imageSize: this.selectedBlock?.imageSize || 100,
        menuAlignment: this.selectedBlock.menuAlignment || 'right',
        hamburgerMenuColor: this.selectedBlock.hamburgerMenuColor || '#000000'
      },
      { emitEvent: false }
    );

    if (!this.selectedBlock?.avatarImage && !this.selectedBlock?.headerType) {
      this._initDefaultAvatar();
    }
  }

  private async _initUrlsAndPlaceholders() {
    const menuItems = this.selectedBlock?.menuOptions ?? [];
    this.menuItemsArray = await Promise.all(
      menuItems.map(async (item) => {
        const link: ContentLink = await this._content.getContent(
          item.contentId
        );
        return link;
      })
    );
  }

  private _initImages() {
    this.backgroundImage = this.selectedBlock?.backgroundImage
      ? [this.selectedBlock.backgroundImage]
      : [];

    this.avatarImage = this.selectedBlock?.avatarImage
      ? [this.selectedBlock.avatarImage]
      : [];
  }

  private _initBackgroundMode() {
    const { backgroundImage, backgroundGradient, backgroundColor } =
      this.selectedBlock ?? {};

    if (!!backgroundImage?.url) {
      this.backgroundMode = 'image';
    } else if (!!backgroundGradient) {
      this.backgroundMode = 'gradient';
    } else if (backgroundColor === 'transparent') {
      this.backgroundMode = 'transparent';
    } else if (!!backgroundColor) {
      this.backgroundMode = 'color';
    } else {
      this.backgroundMode = 'transparent';
    }
  }

  private _initForm(): void {
    const menuItemsArray = this.selectedBlock?.menuOptions ?? [];

    this.menuItemsControls = menuItemsArray.map<MenuItemControlType>(
      (option, idx) => {
        const id = `o${idx}`;
        return {
          id,
          controlInstance: `menuItem${id}`,
          isNew: false
        };
      }
    );

    this.menuItemsForm = this._formBuilder.group(
      menuItemsArray.reduce((prev, curr, index) => {
        return {
          ...prev,
          [this.menuItemsControls[index].controlInstance]:
            this._formBuilder.group({
              contentId: [curr?.contentId, Validators.required],
              title: [curr?.title],
              disabled: [curr?.disabled ?? false]
            })
        };
      }, {})
    );

    this.formGroup = this._formBuilder.group({
      style: [this.selectedBlock?.style ?? 'creator', Validators.required],
      avatarImage: [this.selectedBlock?.avatarImage],
      height: [this.selectedBlock?.height ?? 120, Validators.required],
      backgroundColor: [this.selectedBlock?.backgroundColor ?? 'transparent'],
      backgroundImage: [this.selectedBlock?.backgroundImage],
      backgroundGradient: [this.selectedBlock?.backgroundGradient],
      maskUrl: [this.selectedBlock?.maskUrl],
      avatarClickThroughUrlType: [
        this.selectedBlock?.avatarClickThroughUrlType ?? 'absolute'
      ],
      avatarClickThroughUrl: [
        this.selectedBlock?.avatarClickThroughUrl ?? '',
        isUrlValidator(
          () => this.selectedBlock?.avatarClickThroughUrlType == 'absolute'
        )
      ],
      headerType: [this.selectedBlock?.headerType || 'imageOnly'],
      imageSize: [this.selectedBlock.imageSize || 100],
      menuAlignment: [this.selectedBlock.menuAlignment || 'right'],
      menuOptions: this.menuItemsForm,
      hamburgerMenuColor: [this.selectedBlock.hamburgerMenuColor || '#000000']
    });

    this.formGroup.valueChanges
      .pipe(
        tap((updatedBlock) => {
          const updatedBlockType = updatedBlock?.avatarClickThroughUrlType;
          const oldBlockType = this.selectedBlock?.avatarClickThroughUrlType;
          if (updatedBlockType !== oldBlockType) {
            const urlControl = this.formGroup.get('avatarClickThroughUrl');
            urlControl.setValue('', { emitEvent: false });
            updatedBlock.avatarClickThroughUrl = '';
          }
        }),
        filter(() => this.formGroup.valid),
        map((value) => {
          const menuOptionsArray = Object.values(value.menuOptions).map(
            ({ contentId, title, disabled }) => ({
              contentId,
              title,
              disabled
            })
          );

          return HeaderPageBlock.fromObject({
            ...this.selectedBlock,
            avatarClickThroughUrl: value.avatarClickThroughUrl || '',
            avatarClickThroughUrlType: value.avatarClickThroughUrlType,
            avatarImage: value.avatarImage,
            backgroundColor: value.backgroundColor,
            backgroundImage: value.backgroundImage,
            backgroundGradient: value.backgroundGradient,
            height: value.height,
            maskUrl: value.maskUrl,
            menuAlignment: value.menuAlignment,
            hamburgerMenuColor: value.hamburgerMenuColor,
            style: value.style,
            headerType: value.headerType,
            imageSize: value.imageSize,
            menuOptions:
              Object.keys(value.menuOptions || {}).length > 0
                ? menuOptionsArray
                : []
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((block: HeaderPageBlock) => {
        this.onUpdatedBlock.emit(block);
      });
  }

  handleBackgroundModeChange(value: string) {
    this.backgroundImage = [];

    let backgroundImage = null;
    let backgroundColor = null;
    let backgroundGradient = null;

    if (value === 'image') {
      this.backgroundMode = 'image';
      backgroundImage = this.selectedBlock?.backgroundImage ?? null;
    } else if (value === 'color') {
      this.backgroundMode = 'color';
      backgroundColor = this.selectedBlock?.backgroundColor ?? '';
    } else if (value === 'gradient') {
      this.backgroundMode = 'gradient';
      backgroundGradient = this.selectedBlock?.backgroundGradient ?? '';
    } else if (value === 'transparent') {
      this.backgroundMode = 'transparent';
      backgroundColor = 'transparent';
    }

    this.formGroup.patchValue(
      {
        backgroundImage,
        backgroundColor,
        backgroundGradient
      },
      { emitEvent: this.backgroundMode === 'transparent' }
    );

    this.formGroup.markAsDirty();
  }

  get isDirty(): boolean {
    return this.formGroup.dirty;
  }

  get currentHeaderStyle(): string {
    return this.formGroup?.get('style').value;
  }

  get pageTitle(): string {
    return `${this.selectedBlock ? 'Edit' : 'New'} header block`;
  }

  get messageState(): string {
    return this.formGroup.dirty && !this.formGroup.valid
      ? 'error'
      : this.formGroup.dirty
        ? 'warning'
        : 'none';
  }

  get message(): string {
    return !this.formGroup.valid && this.formGroup.dirty
      ? 'Please correct errors before saving'
      : this.formGroup.dirty
        ? 'You have unsaved changes'
        : null;
  }

  get isPointingToPage(): boolean {
    return (
      this.formGroup?.get('avatarClickThroughUrlType')?.value === 'collection'
    );
  }

  get isPointingToUrl(): boolean {
    return (
      this.formGroup?.get('avatarClickThroughUrlType')?.value === 'absolute'
    );
  }

  get headerType(): string {
    return this.formGroup?.get('headerType')?.value || 'imageOnly';
  }

  handleAvatarUpdated(updatedAvatarImages: IImage[]) {
    this.avatarImage = updatedAvatarImages;
    const avatarImage = updatedAvatarImages?.length
      ? updatedAvatarImages[0]
      : null;
    this.formGroup?.get('avatarImage').patchValue(avatarImage);
    this.formGroup.markAsDirty();
  }

  handleBackgroundImageUpdated(updatedBackgroundImage: IImage[]) {
    this.backgroundImage = updatedBackgroundImage;
    const backgroundImage = updatedBackgroundImage?.length
      ? updatedBackgroundImage[0]
      : null;
    this.formGroup?.get('backgroundImage').patchValue(backgroundImage);
    this.formGroup.markAsDirty();
  }

  handleMaskUrlChanged(url: string) {
    this.formGroup?.get('maskUrl').patchValue(url || null);
    this.formGroup.markAsDirty();
  }

  handleCreateClick() {
    this.onCreateLinkClick.emit();
  }

  handleAddMenuItem() {
    const id = uuidv4();
    const control: MenuItemControlType = {
      id,
      controlInstance: `menuItem${id}`,
      isNew: true
    };

    const index = this.menuItemsControls?.push(control);

    this.menuItemsForm.addControl(
      this.menuItemsControls[index - 1]?.controlInstance,
      this._formBuilder.group({
        contentId: ['', Validators.required],
        title: [''],
        disabled: [false]
      })
    );
    this._initUrlsAndPlaceholders();
  }

  handleDeleteMenuItem(control: MenuItemControlType) {
    if (!(this.menuItemsControls?.length > 0)) {
      return;
    }

    const index =
      this.menuItemsControls?.length > 1
        ? this.menuItemsControls?.indexOf(control)
        : 0;

    this.menuItemsControls.splice(index, 1);
    this.menuItemsForm.markAsDirty();
    this.menuItemsForm.removeControl(control?.controlInstance);
    this._initUrlsAndPlaceholders();
  }

  handleHeaderTypeSelection(type: string) {
    this.formGroup?.get('headerType').patchValue(type);
    this.formGroup.markAsDirty();
  }
}
