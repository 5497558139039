<div live-themed [theme]="event?.theme" [applyThemeStyles]="applyTheme">
  <ng-container
    *ngIf="eventLayoutTemplate"
    [ngTemplateOutlet]="eventLayoutTemplate"
  >
  </ng-container>

  <ng-template #classicLeftTpl>
    <lib-registerable-detail-classic-left-view
      [content]="event"
      [userContent]="userEvent"
      [platform]="platform"
      [metadataTemplate]="metadataTpl"
      [mainButtonTemplate]="mainButtonTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-left-view>
  </ng-template>

  <ng-template #classicRightTpl>
    <lib-registerable-detail-classic-right-view
      [content]="event"
      [userContent]="userEvent"
      [platform]="platform"
      [metadataTemplate]="metadataTpl"
      [mainButtonTemplate]="mainButtonTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-right-view>
  </ng-template>

  <ng-template #classicVerticalTpl>
    <lib-registerable-detail-classic-vertical
      [content]="event"
      [userContent]="userEvent"
      [platform]="platform"
      [metadataTemplate]="metadataTpl"
      [mainButtonTemplate]="mainButtonTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-classic-vertical>
  </ng-template>

  <ng-template #largeImageTpl>
    <lib-registerable-detail-large-image
      [content]="event"
      [userContent]="userEvent"
      [platform]="platform"
      [metadataTemplate]="metadataTpl"
      [mainButtonTemplate]="mainButtonTpl"
      (onSubmitAnother)="handleSubmitAnother()"
    >
    </lib-registerable-detail-large-image>
  </ng-template>

  <ng-template #metadataTpl>
    <div
      *ngIf="
        event?.isFull ||
        (event?.hasCapacity && event?.rsvpRestrictions?.displayLimit) ||
        event?.isCanceled ||
        event?.registrationCloseDate
      "
    >
      <root-tag
        *ngIf="event?.isFull || event?.isPastRegistrationDeadline"
        class="mr-3"
      >
        <root-icon size="small" name="lock"></root-icon>
        <span>Registration is closed</span>
      </root-tag>
      <root-tag
        *ngIf="
          event?.registrationCloseDate &&
          !event?.isPastRegistrationDeadline &&
          !event?.isFull
        "
        class="mr-3"
      >
        <root-icon size="small" name="calendar"></root-icon>
        <span *ngIf="isCurrentDate(registrationCloseDate)">
          Register by {{ registrationCloseTime }}</span
        >
        <span
          *ngIf="!isCurrentDate(registrationCloseDate)"
          rootTooltip="{{ 'at ' + registrationCloseTime }}"
        >
          Register by {{ registrationCloseDate }}
        </span>
      </root-tag>
      <root-tag *ngIf="event?.rsvpRestrictions?.displayLimit">
        <root-icon size="small" name="unlock"></root-icon>
        <span>{{ remainingCount }} left</span>
      </root-tag>
      <root-tag *ngIf="event?.isCanceled">
        <root-icon size="small" name="x_circle"></root-icon>
        <span>Canceled</span>
      </root-tag>
    </div>
    <h4 class="summary card-text-color">{{ event.summary }}</h4>
    <h2 class="title card-text-color">
      {{ event?.title }}
    </h2>
    <p *ngIf="event?.subtitle" class="subtitle card-text-color">
      {{ event.subtitle }}
    </p>
    <p *ngIf="event?.contentLocation" class="subtitle card-text-color">
      {{ event?.contentLocation }}
    </p>
    <div *ngIf="priceStr" class="price card-text-color">
      <root-alert [message]="priceStr"> </root-alert>
    </div>
  </ng-template>

  <ng-template #mainButtonTpl>
    <lib-event-main-button-view
      [event]="event"
      [userEvent]="userEvent"
      [isLoading]="isLoading"
      [forceDisabled]="shouldDisableButtons"
    >
    </lib-event-main-button-view>
  </ng-template>
</div>
