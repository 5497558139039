import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule, NorbyIconButtonModule } from 'uikit';
import { NorbyNavigationBarComponent } from '../components/molecules/norby-navigation-bar';

@NgModule({
  declarations: [NorbyNavigationBarComponent],
  imports: [CommonModule, IconModule, NorbyIconButtonModule],
  exports: [NorbyNavigationBarComponent]
})
export class NorbyNavigationBarModule {}
