<form [formGroup]="formGroup">
  <norby-input
    [label]="LABEL"
    formControlName="url"
    [placeholder]="PLACEHOLDER"
    [helperText]="HELPER_TEXT"
    [infoTooltip]="TOOLTIP"
    [isUrl]="true"
  ></norby-input>
</form>
