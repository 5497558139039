import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentRowViewModule } from 'customer-uikit';
import { UserRowViewComponent } from '../views';

@NgModule({
  declarations: [UserRowViewComponent],
  imports: [CommonModule, ContentRowViewModule],
  exports: [UserRowViewComponent]
})
export class UserRowViewModule {}
