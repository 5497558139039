import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevicePreviewViewModule } from 'customer-uikit';
import { LandingPageViewModule } from './landing-page-view.module';
import { LandingPagePreviewComponent } from '../components/landing-page-preview';
import { LiveThemedDirectiveModule } from 'uikit';

@NgModule({
  declarations: [LandingPagePreviewComponent],
  imports: [
    CommonModule,
    DevicePreviewViewModule,
    LandingPageViewModule,
    LiveThemedDirectiveModule
  ],
  exports: [LandingPagePreviewComponent]
})
export class LandingPagePreviewModule {}
