import { Injectable, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { CaptureContext } from './types';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor() {
    Sentry.init({
      dsn: 'https://7b43281f62f748b48996dba91ee10ef3@o411915.ingest.sentry.io/5287884',
      environment: isDevMode() ? 'dev' : 'prod'
    });
  }

  logError(error: Error | string, context?: CaptureContext): void {
    // Capture handled exception and send it to Sentry.
    const formattedError = typeof error === 'string' ? new Error(error) : error;
    Sentry.captureException(formattedError, context);

    // When in development mode, log the error to console for immediate feedback.
    if (isDevMode()) {
      console.error(error);
    }
  }
}
