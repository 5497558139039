import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// Libs
import {
  ContentEvent,
  ContentEventMetadata,
  SETTINGS_EVENTS,
  EVENT_TYPES,
  EventChanges,
  EventMetadataChanges,
  compareEvents,
  compareEventsMetadata,
  UrlValidator
} from 'models';
import {
  MockAnalyticsService,
  BaseComponent,
  DateRangeValidators
} from 'uikit';

import dayjs from 'dayjs';

@Component({
  selector: 'lib-event-details-step',
  templateUrl: './event-details-step.component.html',
  styleUrls: ['./event-details-step.component.less']
})
export class EventDetailsStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() event: ContentEvent;
  @Input() eventMetadata: ContentEventMetadata;
  @Input() zoomIsConnected: boolean;
  @Input() zoomAccountIsMismatched: boolean;
  @Input() isZoomAccountUnlinked: boolean = false;
  @Input() userCanPublish = true;
  @Input() isLoadingTitle = false;

  @Output() onUpdatedEvent: EventEmitter<EventChanges> =
    new EventEmitter<EventChanges>();
  @Output() onUpdatedEventMetadata: EventEmitter<EventMetadataChanges> =
    new EventEmitter<EventMetadataChanges>();
  @Output() onReconnectZoomButtonClicked: EventEmitter<void> =
    new EventEmitter<void>();

  formGroup: UntypedFormGroup;

  readonly ZOOM_MISMATCH_MESSAGE: string =
    'The Zoom account used to generate this event is not the same that you have now connected. Saving changes will permanently disconnect the event from Zoom and clear the old Zoom URL from the event.';
  readonly ZOOM_UNLINKED_MESSAGE: string =
    'The Zoom account used to generate this event is no longer connected. Please reconnect it or this event will be permanently disconnected from Zoom when saved.';
  readonly EVENT_TYPES = EVENT_TYPES;

  private _timeZoneTooltip: string;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private _analytics: MockAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  get contentLocationType(): string {
    return this.formGroup?.get('contentLocationType')?.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        title: this.event?.title,
        contentLocationType: this.event.contentLocationType,
        eventType: this.event?.eventType || 'other',
        contentLocation:
          this.event.contentLocationType === 'irl'
            ? this.eventMetadata?.contentLocation || ''
            : '',
        contentLocationPublic: this.eventMetadata?.contentLocationPublic,
        contentUrl:
          this.event.contentLocationType === 'online' &&
          !this.isZoomAccountUnlinked
            ? this.eventMetadata?.urls?.clickThroughRaw ||
              this.event?.contentUrl ||
              ''
            : '',
        startDate: this.event?.startDate,
        endDate: this.event?.endDate,
        shouldGenerateZoomLink:
          (this.event?.shouldGenerateZoomLink ||
            !!this.eventMetadata?.zoom?.meetingId) &&
          !this.isZoomAccountUnlinked
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group(
      {
        title: [this.event?.title, Validators.required],
        contentLocationType: [this.event.contentLocationType],
        eventType: [this.event?.eventType],
        contentLocation: [
          this.event.contentLocationType === 'irl'
            ? this.eventMetadata?.contentLocation || ''
            : ''
        ],
        contentLocationPublic: [
          this.eventMetadata?.contentLocationPublic || false
        ],
        startDate: [this.event?.startDate],
        endDate: [this.event?.endDate],
        shouldGenerateZoomLink: [
          (this.event?.shouldGenerateZoomLink ||
            !!this.eventMetadata?.zoom?.meetingId) &&
            !this.isZoomAccountUnlinked
        ],
        contentUrl: [
          this.event.contentLocationType === 'online' &&
          !this.isZoomAccountUnlinked
            ? this.eventMetadata?.urls?.clickThroughRaw ||
              this.event?.contentUrl ||
              ''
            : '',
          UrlValidator
        ]
      },
      {
        validators: DateRangeValidators.dateRange('startDate', 'endDate', {
          daterangeerror: true
        })
      }
    );

    this.formGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe((value) => {
        const event = ContentEvent.fromObject({
          ...this.event,
          ...(value?.title && { title: value?.title }),
          shouldGenerateZoomLink:
            value?.shouldGenerateZoomLink && !this.isZoomAccountUnlinked,
          contentLocationType: value?.contentLocationType,
          eventType: value?.eventType,
          contentLocation:
            value?.contentLocationType === 'irl' && value.contentLocationPublic
              ? value?.contentLocation
              : '',
          startDate: value?.startDate,
          endDate: value?.endDate
        });

        const eventMetadata = ContentEventMetadata.fromObject({
          ...this.eventMetadata,
          contentLocation:
            value?.contentLocationType === 'irl'
              ? value?.contentLocation || ''
              : '',
          contentLocationPublic: value?.contentLocationPublic,
          urls: {
            ...this.eventMetadata?.urls,
            clickThroughRaw:
              value?.contentLocationType === 'irl'
                ? ''
                : value?.contentUrl || ''
          }
        }) as ContentEventMetadata;

        if (!compareEvents(this.event, event)) {
          this.onUpdatedEvent.emit({
            isDirty: this.formGroup.dirty,
            isDetailsStepValid: this.formGroup.valid,
            event
          });
        }

        if (!compareEventsMetadata(this.eventMetadata, eventMetadata)) {
          this.onUpdatedEventMetadata.emit({
            isDirty: this.formGroup.dirty,
            isDetailsStepValid: this.formGroup.valid,
            eventMetadata
          });
        }
      });
  }

  handleToggleZoom(addZoom: boolean): void {
    this.formGroup.markAsDirty();
    this.formGroup.patchValue({
      shouldGenerateZoomLink: addZoom,
      contentUrl: ''
    });
    const contentUrlControl = this.formGroup?.get('contentUrl');

    if (addZoom) {
      contentUrlControl.disable();
    } else {
      contentUrlControl.enable();
    }

    this._analytics.track(
      addZoom
        ? SETTINGS_EVENTS.addedZoomToEvent
        : SETTINGS_EVENTS.removedZoomFromEvent
    );

    contentUrlControl.updateValueAndValidity();
    this._cdr.detectChanges();
  }

  get isOnline(): boolean {
    return this.formGroup?.get('contentLocationType')?.value === 'online';
  }

  get isIrl(): boolean {
    return this.formGroup?.get('contentLocationType')?.value === 'irl';
  }

  get shouldGenerateZoomLink(): boolean {
    return (
      this.formGroup?.get('shouldGenerateZoomLink')?.value &&
      this.zoomIsConnected
    );
  }

  get hasTitle(): boolean {
    return !!this.formGroup?.get('title')?.value;
  }

  get timeZoneTooltip(): string {
    if (!this._timeZoneTooltip) {
      this._timeZoneTooltip = `Set the time for your event in your local timezone ${dayjs().format(
        'z'
      )}`;
    }

    return this._timeZoneTooltip;
  }

  handleReconnectZoom(): void {
    this.onReconnectZoomButtonClicked.emit();
  }
}
