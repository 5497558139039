<div class="flex flex-col items-center">
  <img src="assets/svg/up-arrow-1.svg" class="arrow-svg mb-1" />
  <h1 class="mb-1">{{ deliveryType }} Scheduled</h1>
  <p class="mb-2">Your message will be sent {{ scheduleDisplay }}.</p>

  <norby-button
    text="Back to dashboard"
    (buttonClicked)="handleReturnToDashboard()"
  ></norby-button>
</div>
