<form [formGroup]="formGroup">
  <norby-tabs [isContentFullWidth]="true">
    <norby-tab title="Appearance">
      <div class="px-4 pb-4">
        <norby-image-radio-button-group
          [value]="headerType"
          (buttonClicked)="handleHeaderTypeSelection($event)"
        >
          <norby-image-radio-button
            class="w-full"
            buttonLabel="Image and menu"
            buttonValue="imageAndMenu"
            imageUrl="assets/img/imageAndMenu.png"
          ></norby-image-radio-button>

          <norby-image-radio-button
            class="w-full"
            buttonLabel="Image only"
            buttonValue="imageOnly"
            imageUrl="assets/img/imageOnly.png"
          ></norby-image-radio-button>
        </norby-image-radio-button-group>

        <norby-slider
          label="Height"
          formControlName="height"
          [floor]="60"
          [ceil]="500"
        ></norby-slider>

        <!--TODO percentage? -->
        <norby-slider
          label="Size"
          formControlName="imageSize"
          [floor]="0"
          [ceil]="100"
        ></norby-slider>

        <norby-collapsible-group>
          <norby-collapsible title="Logo">
            <div class="flex flex-row justify-center">
              <norby-header-style formControlName="style"></norby-header-style>
            </div>

            <div class="mb-3">
              <norby-file-upload
                label="Image"
                [images]="avatarImage"
                (onUpdatedImages)="handleAvatarUpdated($event)"
              ></norby-file-upload>
            </div>

            <norby-shape-selector
              *ngIf="currentHeaderStyle === 'creator'"
              label="Shape"
              [availableMasks]="AVAILABLE_MASKS"
              [selectedMaskUrl]="formGroup.controls.maskUrl.value"
              (onSelectedMaskUrlChanged)="handleMaskUrlChanged($event)"
            ></norby-shape-selector>
          </norby-collapsible>

          <norby-collapsible title="Background">
            <norby-select
              label="Mode"
              [value]="backgroundMode"
              infoTooltip="Choose a background fill mode"
              (onHandleChange)="handleBackgroundModeChange($event)"
            >
              <option value="transparent">Transparent</option>
              <option value="image">Image</option>
              <option value="color">Color</option>
              <option value="gradient">Gradient</option>
            </norby-select>

            <norby-file-upload
              label="Image"
              *ngIf="backgroundMode === 'image'"
              [images]="backgroundImage"
              (onUpdatedImages)="handleBackgroundImageUpdated($event)"
            ></norby-file-upload>

            <norby-ant-color-picker
              *ngIf="backgroundMode === 'color'"
              label="Background Color"
              formControlName="backgroundColor"
            ></norby-ant-color-picker>
            <norby-gradient-selector
              *ngIf="backgroundMode === 'gradient'"
              label="Gradient"
              formControlName="backgroundGradient"
            ></norby-gradient-selector>
          </norby-collapsible>

          <norby-collapsible
            title="Menu"
            opened="false"
            *ngIf="headerType === 'imageAndMenu'"
          >
            <norby-icon-radio-button-group
              label="Alignment"
              formControlName="menuAlignment"
              size="default"
              class="flex-row"
            >
              <norby-icon-radio-button
                iconName="align_left"
                buttonValue="left"
              ></norby-icon-radio-button>

              <norby-icon-radio-button
                iconName="align_center"
                buttonValue="center"
              ></norby-icon-radio-button>

              <norby-icon-radio-button
                iconName="align_right"
                buttonValue="right"
              ></norby-icon-radio-button>
            </norby-icon-radio-button-group>
            <norby-ant-color-picker
              label="Color"
              formControlName="hamburgerMenuColor"
            ></norby-ant-color-picker>
          </norby-collapsible>
        </norby-collapsible-group>
      </div>
    </norby-tab>

    <norby-tab title="Settings" active="true">
      <div class="px-4 pb-4">
        <norby-collapsible title="Header link" [opened]="true">
          <norby-icon-radio-button-group
            label="Point to"
            formControlName="avatarClickThroughUrlType"
            size="small"
            class="flex-row"
          >
            <norby-icon-radio-button
              iconName="link"
              buttonLabel="URL"
              buttonValue="absolute"
            ></norby-icon-radio-button>

            <norby-icon-radio-button
              iconName="layout"
              buttonLabel="Page"
              buttonValue="collection"
            ></norby-icon-radio-button>
          </norby-icon-radio-button-group>

          <norby-input
            *ngIf="isPointingToUrl"
            formControlName="avatarClickThroughUrl"
            placeholder="https://www.youtube.com/watch?v=9bZkp7q19f0"
            [isUrl]="true"
          ></norby-input>

          <norby-select
            *ngIf="isPointingToPage"
            formControlName="avatarClickThroughUrl"
            [helperText]="
              availablePages?.length
                ? 'Choose a Norby page to point this link to.'
                : 'You have no other Norby pages.'
            "
          >
            <option *ngFor="let page of availablePages" [value]="page?.label">
              {{ page?.title }}
            </option>
          </norby-select>
        </norby-collapsible>

        <norby-collapsible
          *ngIf="headerType === 'imageAndMenu'"
          title="Menu links"
          [opened]="false"
          formGroupName="menuOptions"
        >
          <div
            *ngFor="let control of menuItemsControls; let i = index"
            [formGroupName]="control.controlInstance"
          >
            <div class="w-full flex flex-row justify-end relative">
              <norby-icon-button
                class="absolute top-0"
                iconName="trash"
                (click)="handleDeleteMenuItem(control)"
              >
              </norby-icon-button>
            </div>

            <div class="mb-6">
              <norby-content-selector-by-id
                (onCreateClick)="handleCreateClick()"
                formControlName="contentId"
                [label]="'Link ' + (i + 1).toString()"
                buttonText="New Link"
                contentType="link"
                [isRequired]="true"
                placeholder="Select an option..."
                class="py-2"
              >
              </norby-content-selector-by-id>
            </div>

            <norby-input
              label="Link title"
              formControlName="title"
              [placeholder]="
                menuItemsArray?.length > 0 ? menuItemsArray[i]?.title : ''
              "
              type="text"
            ></norby-input>

            <norby-alert
              type="alert"
              title="Warning"
              *ngIf="
                menuItemsArray?.length > 0 &&
                menuItemsArray[i]?.contentId &&
                menuItemsArray[i]?.urls?.clickthroughRaw === ''
              "
            >
              The URL for this menu option is not valid. Please update it.
            </norby-alert>

            <hr class="mt-2 mb-4" />
          </div>

          <div class="w-full flex center">
            <button
              type="button"
              class="ant-btn-animation flex items-center rounded-sm px-5 py-2 border border-neutral-900 bg-white focus:bg-white flex-1 leading-5 whitespace-nowrap text-neutral-900 mb-3 justify-center hover:bg-neutral-700 hover:text-white"
              (click)="handleAddMenuItem()"
            >
              <norby-icon name="plus"></norby-icon>
              Add menu option
            </button>
          </div>
        </norby-collapsible>
      </div>
    </norby-tab>
  </norby-tabs>
</form>
