import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

// App
import { IconModule } from 'uikit';
import { UserListComponent } from '../components/user-list';
import { UserRowModule } from './user-row.module';

@NgModule({
  declarations: [UserListComponent],
  imports: [
    CommonModule,
    UserRowModule,
    IconModule,
    NzDropDownModule,
    NzListModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzSpinModule,
    NzEmptyModule
  ],
  exports: [UserListComponent]
})
export class UserListModule {}
