import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NorbyInputModule, NorbyIconModule, IconModule } from 'uikit';
import { NorbyEditSocialIconsComponent } from '../components/molecules/norby-edit-social-icons';
import { NorbySocialLinksSelectorModule } from './norby-social-links-selector.module';

@NgModule({
  declarations: [NorbyEditSocialIconsComponent],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    NorbyInputModule,
    NorbySocialLinksSelectorModule,
    NorbyIconModule,
    IconModule
  ],
  exports: [NorbyEditSocialIconsComponent]
})
export class NorbyEditSocialIconsModule {}
