import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbySendsSummaryViewComponent } from '../components/templates/sends/norby-sends-summary-view';
import { SummaryCardViewModule } from './summary-card-view.module';
import {
  NorbyButtonModule,
  NorbyIconButtonWithLabelModule,
  NorbyIconModule,
  NorbySwitchButtonModule,
  SpinModule,
  TooltipModule
} from 'uikit';

@NgModule({
  declarations: [NorbySendsSummaryViewComponent],
  imports: [
    CommonModule,
    NorbyIconButtonWithLabelModule,
    NorbyButtonModule,
    SpinModule,
    NorbySwitchButtonModule,
    TooltipModule,
    NorbyIconModule,
    SummaryCardViewModule
  ],
  exports: [NorbySendsSummaryViewComponent]
})
export class NorbySendsSummaryViewModule {}
