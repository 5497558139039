import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  Firestore,
  query,
  where,
  limit as _limit,
  orderBy as _orderBy,
  startAfter as _startAfter,
  doc,
  setDoc
} from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';

// 3rd party
import { firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Libs
import {
  FIREBASE_COLLECTIONS,
  IntegrationType,
  ISlug,
  ENDPOINTS,
  ApiSurfaces,
  SETTINGS_EVENTS,
  IUserRole,
  RolesEnum,
  PAGE_SIZE
} from 'models';
import { ErrorService, MessageService, MessageType } from 'uikit';
import { ISlugManagementService } from './slug-management.service.interface';
import { AnalyticsService } from '../analytics';
import { ApiService } from '../api';
import { DeviceService } from '../device';

@Injectable({
  providedIn: 'root'
})
export class SlugManagementService implements ISlugManagementService {
  constructor(
    private _device: DeviceService,
    private _api: ApiService,
    private _functions: Functions,
    private _firestore: Firestore,
    private _message: MessageService,
    private _analytics: AnalyticsService,
    private _error: ErrorService
  ) {}

  async getTeamMemberEmails() {
    const slug = this._device.currentSlug;
    const endpoint = `organization/team_member_emails?slug=${slug}`;
    const res = await this._api.get<{ emails: string[] }>(
      ApiSurfaces.API,
      endpoint
    );
    return res?.emails || [];
  }

  async setCurrentSlug(
    slug: Partial<ISlug>,
    merge = true,
    showConfirmation = true
  ) {
    const slugIdentifier = this._device.currentSlug;

    try {
      const ref = doc(
        this._firestore,
        FIREBASE_COLLECTIONS.slugs.all,
        slugIdentifier
      );
      await setDoc(ref, slug, { merge });
      if (showConfirmation) {
        this._message.show({
          text: 'Saved',
          type: MessageType.SUCCESS
        });
      }
      this._analytics.track(SETTINGS_EVENTS.userUpdatedSlug, {
        slug: slugIdentifier
      });
    } catch (e) {
      this._message.show({
        text: e,
        type: MessageType.ERROR
      });
    }
  }

  // Invite a user by uid to the given slug/organization
  async inviteUserToOrganization(input: {
    role: RolesEnum;
    userIdentifier: string;
    slug: string;
    onboarding?: boolean;
    expiresInMinutes?: number;
  }) {
    try {
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        ENDPOINTS.organization.member.invite,
        input
      );
      this._message.show({
        text: 'Invitation sent',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Remove a given member by uid from the given organization
  async removeUserFromOrganization(input: { slug?: string; userId: string }) {
    try {
      const endpoint = `${ENDPOINTS.organization.member.remove}`;
      const ret = await this._api.post<boolean>(
        ApiSurfaces.API,
        endpoint,
        input
      );
      this._message.show({
        text: 'Removed',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Update a user's role in the given organization
  async updateRoleInOrganization(
    input: { slug?: string; userId: string },
    newRole: string
  ) {
    try {
      const endpoint = `${ENDPOINTS.organization.member.update}`;
      const ret = await this._api.patch<boolean>(ApiSurfaces.API, endpoint, {
        slug: input.slug ?? this._device.currentSlug,
        role: newRole,
        userId: input.userId
      });
      this._message.show({
        text: 'Updated',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async acknowledgePendingInvite(
    slug: string,
    acceptInvite: boolean
  ): Promise<boolean> {
    try {
      const action = acceptInvite ? 'accept' : 'decline';
      const endpoint = `${ENDPOINTS.organization.member.inviteAck}`;
      const ret = await this._api.post<boolean>(ApiSurfaces.API, endpoint, {
        slug,
        action
      });
      this._message.show({
        text: `Invite ${acceptInvite ? 'accepted' : 'declined'}`,
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Update the account wide settings for the org
  async setAccountSettings(update: {
    replyTo?: string;
    disableNorbyBranding?: boolean;
    alphaSender?: string;
    termsUrl?: string | null;
    privacyUrl?: string | null;
  }) {
    try {
      const endpoint = `${ENDPOINTS.organization.account_settings}`;
      const ret = await this._api.patch<boolean>(
        ApiSurfaces.API,
        endpoint,
        update
      );
      this._message.show({
        text: 'Updated',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Reset the Norby API key for the current slug
  async resetApiKey() {
    try {
      const endpoint = `${ENDPOINTS.organization.generate_api_key}`;
      const ret = await this._api.post<boolean>(ApiSurfaces.API, endpoint);
      this._message.show({
        text: 'Successfully reset API key',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Unlink the given integration from the given organization
  async unlinkIntegration(input: {
    integration: IntegrationType;
    slug: string;
  }): Promise<boolean> {
    const callable = httpsCallableData<any, boolean>(
      this._functions,
      'unlinkIntegration'
    );
    return firstValueFrom(callable(input));
  }

  // Direct Firestore query for organization members
  getOrganizationMembersCollection(cursor?: any) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, 'userRoles');
        const constraints = [
          where('slug', '==', slug),
          _orderBy('createdAtCursor'),
          ...(cursor ? [_startAfter(cursor)] : []),
          _limit(PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  getOrganizationMembers$(
    accessType: 'temporary' | 'permanent' | 'all',
    cursor?: any
  ): Observable<IUserRole[]> {
    return this.getOrganizationMembersCollection(cursor).pipe(
      map((list) =>
        list
          .map((item) => item.data() as IUserRole)
          .filter((item) => {
            if (accessType === 'temporary') {
              return !!item.accessExpiresAtCursor;
            } else if (accessType === 'permanent') {
              return !item.accessExpiresAtCursor;
            }

            return true;
          })
      )
    );
  }

  async transferOwnership(newOwnerUserId: string) {
    try {
      const endpoint = ENDPOINTS.organization.transferOwnership;
      const ret = await this._api.post<boolean>(ApiSurfaces.API, endpoint, {
        newOwnerUserId,
        slug: this._device.currentSlug
      });
      this._message.show({
        text: 'Transferred ownership',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }
}
