import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef
} from '@angular/core';

import {
  IconService,
  rootShare,
  rootLink,
  rootCode,
  MockShareService,
  MessageService,
  MessageType
} from 'uikit';
import { LandingPage } from 'models';

/*
  Creates a share button for a given page
*/

@Component({
  selector: 'lib-page-share-button-view',
  templateUrl: './page-share-button-view.component.html',
  styleUrls: ['./page-share-button-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageShareButtonViewComponent implements OnInit {
  @Input() page: LandingPage;
  @Input() size: 'default' | 'large' | 'small' = 'large';
  @Input() textLabel = 'Share';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = null;

  @ViewChild('download', { static: false }) download!: ElementRef;
  @ViewChild('qrCanvas', { static: false })
  qrCanvas!: ElementRef<HTMLElement>;

  // Whether the current device supports system share menu
  canUseSystemShare = false;

  constructor(
    private _share: MockShareService,
    private _message: MessageService,
    private _iconService: IconService
  ) {
    this._iconService.registerIcons([rootShare, rootLink, rootCode]);
  }

  ngOnInit(): void {
    this.canUseSystemShare = this._share.canShare;
  }

  get pageUrl(): string {
    return this._share.getUrlForLandingPage(this.page);
  }

  get embedString(): string {
    return this._share.getEmbedCodeForLandingPage(this.page);
  }

  doShare() {
    const title = this.page?.title;
    const url = this.pageUrl;
    this._share.share({ title, url });
  }

  handleCopy() {
    this._message.show({
      text: 'Copied',
      type: MessageType.SUCCESS
    });
  }

  downloadQRCode() {
    const canvas =
      this.qrCanvas.nativeElement?.querySelector<HTMLCanvasElement>('canvas');

    if (canvas) {
      this.download.nativeElement.href = canvas.toDataURL('image/png');
      this.download.nativeElement.download = 'page-qr-code';
      const event = new MouseEvent('click');
      this.download.nativeElement.dispatchEvent(event);
    }
  }
}
