import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyInputModule } from 'uikit';
import { NorbyEditVimeoBlockComponent } from '../components/templates/content-block-editors/norby-edit-vimeo-block';

@NgModule({
  declarations: [NorbyEditVimeoBlockComponent],
  imports: [CommonModule, ReactiveFormsModule, NorbyInputModule],
  exports: [NorbyEditVimeoBlockComponent]
})
export class NorbyEditVimeoBlockModule {}
