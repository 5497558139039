import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  query,
  where,
  limit as _limit,
  orderBy as _orderBy,
  startAfter as _startAfter,
  collectionSnapshots,
  doc,
  docData,
  getDoc,
  getDocs
} from '@angular/fire/firestore';

// 3rd party
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// Libs
import { ErrorService, MessageService, MessageType } from 'uikit';
import {
  LandingPage,
  IContentCancelation,
  IContentMetadata,
  ContentMetadata,
  IContentReferral,
  IContentReferralSummary,
  CreateLandingPageDTO,
  ICreateLinkDTO,
  ENDPOINTS,
  ContentSignup,
  ISignupCreateDTO,
  ISignupUpdateDTO,
  IEventCreateDTO,
  IContentRegisterableCreateDTO,
  IEventUpdateDTO,
  ContentLink,
  ISendTestMessage,
  ISendTestMessageContentData,
  ISendTestMessageSingleSendData,
  UpdateLandingPageDTO,
  IUpdateLinkDTO,
  ApiSurfaces,
  NotificationDefaults,
  UserContentNotifications,
  FIREBASE_COLLECTIONS,
  PAGE_SIZE,
  IUserContentResultsV2,
  ContentType,
  ContentResponseType,
  IContentManagementService,
  URL_PATTERN_FOR_LINKS
} from 'models';
import { DeviceService } from '../device';
import { ApiService } from '../api';

const NULL_CONTENT_RESPONSE: ContentResponseType = {
  contentId: null,
  contentType: null,
  status: null
};

export type RsvpCsvType = { url: string };
export type ContactCsvType = { url: string };
export type UploadFileToFirebaseStorageResponseDTO = { fileUrl: string };

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService implements IContentManagementService {
  constructor(
    private _device: DeviceService,
    private _api: ApiService,
    private _firestore: Firestore,
    private _message: MessageService,
    private _error: ErrorService
  ) {}

  // Remove a given user from an event or drop
  async removeUserFromContent(contentId: string, userId: string) {
    try {
      const endpoint = `content/${contentId}/remove_registration`;
      const ret = await this._api.post<boolean>(ApiSurfaces.API, endpoint, {
        userId
      });
      this._message.show({
        text: 'Removed',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // messages
  async sendTestSingleSendMessage({
    data
  }: {
    data: ISendTestMessageSingleSendData;
  }): Promise<string> {
    try {
      return this.sendTestMessage({
        body: {
          data
        }
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async sendTestContentMessage({
    data
  }: {
    data: ISendTestMessageContentData;
  }): Promise<string> {
    try {
      return this.sendTestMessage({
        body: {
          data
        }
      });
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async sendTestMessage({ body }: { body: ISendTestMessage }): Promise<string> {
    try {
      const endpoint = `message/test`;
      const ret = await this._api.post<any>(ApiSurfaces.API, endpoint, body);
      this._message.show({
        text: 'Sent',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async generateSampleCopy(body: {
    title: string;
    type: ContentType;
  }): Promise<{ title: string; subtitle: string; body: string }> {
    try {
      const ret = await this._api.post<any>(
        ApiSurfaces.API,
        ENDPOINTS.content.sampleCopy,
        {
          contentType: body?.type,
          goal: body?.title
        }
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Landing Pages
  async editLandingPage(
    id: string,
    payload: Partial<UpdateLandingPageDTO>,
    showConfirmation = true
  ): Promise<boolean> {
    const endpoint = `${ENDPOINTS.landingPage}/${id}`;
    try {
      const ret = await this._api.patch<boolean>(
        ApiSurfaces.API,
        endpoint,
        payload
      );

      if (showConfirmation) {
        this._message.show({
          text: 'Updated',
          type: MessageType.SUCCESS
        });
      }
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  async createLandingPage(
    payload: CreateLandingPageDTO
  ): Promise<{ id: string }> {
    try {
      const ret = await this._api.post<{ id: string }>(
        ApiSurfaces.API,
        ENDPOINTS.landingPage,
        payload
      );
      this._message.show({
        text: payload?.published ? 'Published' : 'Saved',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return null;
    }
  }

  async deleteLandingPage(id: string) {
    try {
      const ret = await this._api.delete(
        ApiSurfaces.API,
        `${ENDPOINTS.landingPage}/${id}`
      );
      this._message.show({
        text: 'Deleted',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Content
  async requestEventDuplicate(id: string): Promise<IEventCreateDTO> {
    try {
      return await this._api.get<IEventCreateDTO>(
        ApiSurfaces.API,
        `${ENDPOINTS.event}/${id}/duplicate`
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async requestDropDuplicate(id: string): Promise<ISignupCreateDTO> {
    try {
      return await this._api.get<ISignupCreateDTO>(
        ApiSurfaces.API,
        `${ENDPOINTS.signup}/${id}/duplicate`
      );
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async toggleUserJoinStatus(userId: string, contentId: string, joined = true) {
    try {
      const endpoint = `content/${contentId}/toggle_registrant_joined`;
      const ret = await this._api.post<boolean>(ApiSurfaces.API, endpoint, {
        userId,
        joined
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  private async _editContent(
    id: string,
    payload: ISignupUpdateDTO | IEventUpdateDTO | IUpdateLinkDTO,
    endpointPrefix: string = 'content',
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    const endpoint = `${endpointPrefix}/${id}`;
    try {
      const ret = await this._api.patch<ContentResponseType>(
        ApiSurfaces.API,
        endpoint,
        payload
      );
      if (showConfirmation) {
        this._message.show({
          text: 'Updated',
          type: MessageType.SUCCESS
        });
      }
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return NULL_CONTENT_RESPONSE;
    }
  }

  private async _createContent(
    payload: ICreateLinkDTO | ISignupCreateDTO | IEventCreateDTO,
    endpointPrefix: string,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    try {
      const ret = await this._api.post<ContentResponseType>(
        ApiSurfaces.API,
        endpointPrefix,
        payload
      );
      if (showConfirmation) {
        this._message.show({
          text: payload?.published ? 'Published' : 'Saved',
          type: MessageType.SUCCESS
        });
      }
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return NULL_CONTENT_RESPONSE;
    }
  }

  private async _deleteContent(id: string, endpointPrefix: string = 'content') {
    try {
      const ret = await this._api.delete(
        ApiSurfaces.API,
        `${endpointPrefix}/${id}`
      );
      this._message.show({
        text: 'Deleted',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  fetchNotificationDefaults(
    payload: IContentRegisterableCreateDTO
  ): Promise<NotificationDefaults> {
    return this._api.post(ApiSurfaces.API, 'content/notification_defaults', {
      contentDto: {
        ...payload
      }
    });
  }

  async editLink(
    linkId: string,
    payload: IUpdateLinkDTO
  ): Promise<ContentResponseType> {
    return this._editContent(linkId, payload, ENDPOINTS.link);
  }

  async createLink(
    payload: ICreateLinkDTO,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    return this._createContent(payload, ENDPOINTS.link, showConfirmation);
  }

  async deleteLink(linkId: string) {
    return this._deleteContent(linkId, ENDPOINTS.link);
  }

  async editEvent(
    contentId: string,
    payload: IEventUpdateDTO,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    return this._editContent(
      contentId,
      payload,
      ENDPOINTS.event,
      showConfirmation
    );
  }

  async createEvent(
    payload: IEventCreateDTO,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    return this._createContent(payload, ENDPOINTS.event, showConfirmation);
  }

  async deleteEvent(eventId: string) {
    return this._deleteContent(eventId, ENDPOINTS.event);
  }

  async editSignup(
    contentId: string,
    payload: ISignupUpdateDTO,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    return this._editContent(
      contentId,
      payload,
      ENDPOINTS.signup,
      showConfirmation
    );
  }

  async createSignup(
    payload: ISignupCreateDTO,
    showConfirmation: boolean = true
  ): Promise<ContentResponseType> {
    return this._createContent(payload, ENDPOINTS.signup, showConfirmation);
  }

  async deleteSignup(dropId: string) {
    return this._deleteContent(dropId, ENDPOINTS.signup);
  }

  getContentReferrerSummaries$(
    contentId: string
  ): Observable<IContentReferralSummary[]> {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, 'contentReferralSummary');
        const constraints = [
          where('contentId', '==', contentId),
          where('primarySlugIdentifier', '==', slug),
          _orderBy('createdAtCursor', 'desc'),
          _limit(100)
        ];
        return collectionData(query(ref, ...constraints)).pipe(
          map((list) => list as IContentReferralSummary[])
        );
      })
    );
  }

  getContentReferrals$({
    contentId,
    referrerId
  }: {
    contentId: string;
    referrerId: string;
  }): Observable<IContentReferral[]> {
    const slug = this._device.currentSlug;
    const ref = collection(this._firestore, 'contentReferrals');
    const constraints = [
      where('contentId', '==', contentId),
      where('referrerId', '==', referrerId),
      where('primarySlugIdentifier', '==', slug),
      _orderBy('createdAtCursor', 'desc'),
      _limit(100)
    ];
    return collectionData(query(ref, ...constraints)).pipe(
      map((list) => list as IContentReferral[])
    );
  }

  async getRsvpCsvUrl({ contentId }: { contentId: string }) {
    try {
      const endpoint = `content/${contentId}/rsvp/csv`;
      const ret = (await this._api.get<RsvpCsvType>(ApiSurfaces.API, endpoint))
        ?.url;
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getRsvps(input: {
    contentId: string;
    cursor?: string;
    limit?: number;
    offset?: number;
  }): Promise<IUserContentResultsV2> {
    try {
      const { contentId, cursor, limit, offset } = input;

      const ret = await this._api.get<IUserContentResultsV2>(
        ApiSurfaces.API,
        `v2/content/${contentId}/registration`,
        {
          ...(cursor && { cursor }),
          ...(offset && { offset }),
          ...(limit && { limit })
        }
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async cancelDrop(dropId: string, body: IContentCancelation) {
    return this._cancelContent(dropId, body, ENDPOINTS.signup);
  }

  async cancelEvent(eventId: string, body: IContentCancelation) {
    return this._cancelContent(eventId, body, ENDPOINTS.event);
  }

  private async _cancelContent(
    id: string,
    body: IContentCancelation,
    endpointPrefix: string = 'content'
  ) {
    try {
      const ret = await this._api.post(
        ApiSurfaces.API,
        `${endpointPrefix}/${id}/cancel`,
        body
      );
      this._message.show({
        text: 'Canceled',
        type: MessageType.SUCCESS
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async setContentRegistrationSenderSettings(
    contentId: string,
    userId: string,
    sendNotifications: UserContentNotifications
  ) {
    const endpoint = `content/${contentId}/registration_sender_settings`;
    try {
      const ret = await this._api.post(ApiSurfaces.API, endpoint, {
        userId,
        sendNotifications
      });
      this._message.show({
        text: 'Saved',
        type: MessageType.SUCCESS
      });
      return true;
    } catch (e) {
      this._error.displayError(e);
      return false;
    }
  }

  getContentMetadata$<T extends IContentMetadata>(id: string): Observable<T> {
    const ref = doc(this._firestore, 'contentMetadata', id);
    return docData(ref).pipe(
      map((c) => ContentMetadata.fromObject(c as IContentMetadata) as T)
    );
  }

  async getContentMetadata<T extends IContentMetadata>(id: string): Promise<T> {
    try {
      const ref = doc(this._firestore, 'contentMetadata', id);
      const snapshot = await getDoc(ref);
      return snapshot.exists()
        ? (ContentMetadata.fromObject(snapshot.data() as IContentMetadata) as T)
        : null;
    } catch (e) {
      return null;
    }
  }

  getSlugCollection$({
    limit,
    published,
    startAfter
  }: {
    limit?: number;
    published?: boolean;
    startAfter?: string;
  }) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(this._firestore, FIREBASE_COLLECTIONS.pages);
        const constraints = [
          where('slug', '==', slug),
          where('published', '==', published ?? true),
          _orderBy('modifiedAtCursor', 'desc'),
          ...(startAfter ? [_startAfter(startAfter)] : []),
          _limit(limit ?? PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  // Force round trip
  async getLandingPagesForCurrentSlug({
    limit,
    published
  }: {
    limit?: number;
    published?: boolean;
  }): Promise<LandingPage[]> {
    const slug = this._device.currentSlug;
    const ref = collection(this._firestore, FIREBASE_COLLECTIONS.pages);
    const constraints = [
      where('slug', '==', slug),
      where('published', '==', published ?? true),
      _orderBy('modifiedAtCursor', 'desc'),
      _limit(limit ?? PAGE_SIZE)
    ];
    const docs = await getDocs(query(ref, ...constraints));
    return docs.docs.map((c) => LandingPage.fromObject(c.data()));
  }

  getLandingPagesForCurrentSlug$({
    limit,
    published,
    startAfter
  }: {
    limit?: number;
    published?: boolean;
    startAfter?: string;
  }): Observable<LandingPage[]> {
    return this.getSlugCollection$({ limit, published, startAfter }).pipe(
      map((list) => list.map((c) => LandingPage.fromObject(c)))
    );
  }

  getLinksCollection$({
    published,
    descending,
    limit,
    startAfter
  }: {
    published?: boolean;
    descending?: boolean;
    limit?: number;
    startAfter?: string;
  }) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(
          this._firestore,
          FIREBASE_COLLECTIONS.content.all
        );
        const constraints = [
          where('contentType', '==', 'link'),
          where('primarySlugIdentifier', '==', slug),
          where('published', '==', published ?? true),
          _orderBy('modifiedAtCursor', descending ? 'desc' : 'asc'),
          ...(startAfter ? [_startAfter(startAfter)] : []),
          _limit(limit ?? PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  getLinksForCurrentSlug$({
    published,
    descending,
    limit,
    startAfter
  }: {
    published?: boolean;
    descending?: boolean;
    limit?: number;
    startAfter?: string;
  }): Observable<ContentLink[]> {
    return this.getLinksCollection$({
      startAfter,
      published,
      limit,
      descending
    }).pipe(map((list) => list.map((e) => ContentLink.fromObject(e.data()))));
  }

  getDropsCollection$({
    published,
    limit,
    startAfter
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
  }) {
    return this._device.currentSlug$.pipe(
      switchMap((slug) => {
        const ref = collection(
          this._firestore,
          FIREBASE_COLLECTIONS.content.all
        );
        const constraints = [
          where('contentType', '==', 'drop'),
          where('primarySlugIdentifier', '==', slug),
          where('published', '==', published ?? true),
          _orderBy('modifiedAtCursor', 'desc'),
          ...(startAfter ? [_startAfter(startAfter)] : []),
          _limit(limit ?? PAGE_SIZE)
        ];
        return collectionSnapshots(query(ref, ...constraints));
      })
    );
  }

  getDropsForCurrentSlug$({
    published,
    limit,
    startAfter
  }: {
    published?: boolean;
    limit?: number;
    startAfter?: string;
  }): Observable<ContentSignup[]> {
    return this.getDropsCollection$({ startAfter, published, limit }).pipe(
      map((list) => list.map((e) => ContentSignup.fromObject(e.data())))
    );
  }

  async uploadImageFromUrl(
    fileUrl: string,
    notifyUser: boolean = true
  ): Promise<string> {
    try {
      const encodedFileUrl = encodeURIComponent(fileUrl);
      const validUrl = URL_PATTERN_FOR_LINKS.test(encodedFileUrl);

      if (validUrl) {
        const endpoint = `upload/url_to_storage`;
        const ret =
          await this._api.post<UploadFileToFirebaseStorageResponseDTO>(
            ApiSurfaces.API,
            endpoint,
            {
              fileUrl: fileUrl
            }
          );
        if (notifyUser) {
          this._message.show({
            text: 'File uploaded',
            type: MessageType.SUCCESS
          });
        }
        return ret?.fileUrl || '';
      } else {
        if (notifyUser) {
          this._message.show({
            text: 'Invalid file URL',
            type: MessageType.ERROR
          });
        }
        return '';
      }
    } catch (e) {
      if (notifyUser) {
        this._message.show({
          text: 'An error occurred during upload',
          type: MessageType.ERROR
        });
      }
      this._error.displayError(e);
      return '';
    }
  }
}
