<!-- Show share button if the page is published -->
<button
  *ngIf="page?.published && !textLabel"
  [nzType]="type"
  [nzSize]="size"
  aria-label="Share"
  nz-button
  class="share-button icon-circle"
  nz-dropdown
  [nzDropdownMenu]="shareMenu"
>
  <root-icon name="share"></root-icon>
</button>

<button
  *ngIf="page?.published && textLabel"
  [nzType]="type"
  [nzSize]="size"
  aria-label="Share"
  nz-button
  class="share-button"
  nz-dropdown
  [nzDropdownMenu]="shareMenu"
>
  <root-icon name="share"></root-icon> {{ textLabel }}
</button>

<!-- SHARE MENU -->
<nz-dropdown-menu #shareMenu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngIf="canUseSystemShare" (click)="doShare()">
      <root-icon name="share"></root-icon>
      Share...
    </li>
    <li nz-menu-item [cdkCopyToClipboard]="pageUrl" (click)="handleCopy()">
      <root-icon name="link"></root-icon> Copy link
    </li>
    <li
      *ngIf="page?.published && pageUrl"
      nz-menu-item
      (click)="downloadQRCode()"
    >
      <root-icon name="grid"></root-icon>
      Download QR Code
    </li>
    <li nz-menu-item [cdkCopyToClipboard]="embedString" (click)="handleCopy()">
      <root-icon name="code"></root-icon>Copy embed code
    </li>
  </ul>
</nz-dropdown-menu>

<div #qrCanvas class="hidden">
  <nz-qrcode [nzValue]="pageUrl" nzLevel="M"></nz-qrcode>
  <a #download></a>
</div>
