import { Injectable } from '@angular/core';

// Libs
import {
  ApiSurfaces,
  IPSQLSendResults,
  ENDPOINTS,
  SegmentPreviewResponseDTO,
  CreateSingleSendDTO,
  CreateSingleSendResponseDTO,
  SingleSendTestMessageDTO,
  SegmentPreviewDTO,
  SendSummaryDTO,
  ISingleSendV2,
  SingleSend,
  SingleSendSummary
} from 'models';
import { ErrorService, MessageService, MessageType } from 'uikit';

// App
import { ISendsService } from './sends.service.interface';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class SendsService implements ISendsService {
  constructor(
    private _api: ApiService,
    private _error: ErrorService,
    private _message: MessageService
  ) {}

  getSendById = async ({ id }: { id: string }): Promise<SingleSend> => {
    try {
      const ret = await this._api.get<ISingleSendV2>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2 + `/${id}`
      );
      return SingleSend.fromObject(ret);
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getDeliveryHistory = async (input: {
    id?: string;
    offset?: number;
    after?: string;
    limit?: number;
    filter?: string;
  }): Promise<IPSQLSendResults> => {
    try {
      const { offset, after, limit, id, filter } = input;
      const pagination = offset ? { offset } : after ? { after } : {};
      const ret = await this._api.get<IPSQLSendResults>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2 + `/${id}/delivery_history`,
        {
          ...pagination,
          limit: limit ?? 10,
          ...(filter !== undefined ? { filter } : {})
        }
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getSummary = async (input: { id?: string }): Promise<SendSummaryDTO> => {
    try {
      const { id } = input;
      const ret = await this._api.get<SendSummaryDTO>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2 + `/${id}/summary`
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  unscheduleSend = async (id: string): Promise<void> => {
    try {
      const res = await this._api.patch<any>(
        ApiSurfaces.API,
        `${ENDPOINTS.singleSendV2}/${id}`,
        { draft: true }
      );
      if (res?.singleSendId) {
        this._message.show({
          text: 'Reverted to draft',
          type: MessageType.SUCCESS
        });
      }
    } catch (e) {
      this._error.displayError(e);
    }
  };

  deleteSend = async (id: string): Promise<void> => {
    try {
      const res = await this._api.delete<any>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2 + `/${id}`
      );
      if (res?.singleSendId) {
        this._message.show({
          text: 'Deleted',
          type: MessageType.SUCCESS
        });
      }
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getPreview = async (
    body: SegmentPreviewDTO
  ): Promise<SegmentPreviewResponseDTO> => {
    try {
      const ret = await this._api.post<SegmentPreviewResponseDTO>(
        ApiSurfaces.API,
        ENDPOINTS.segment.preview,
        body
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  editSend = async (
    id: string,
    body: CreateSingleSendDTO
  ): Promise<CreateSingleSendResponseDTO> => {
    try {
      const ret = await this._api.patch<any>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2 + `/${id}`,
        body
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return e;
    }
  };

  createSend = async (
    body: CreateSingleSendDTO
  ): Promise<CreateSingleSendResponseDTO> => {
    try {
      const ret = await this._api.post<any>(
        ApiSurfaces.API,
        ENDPOINTS.singleSendV2,
        body
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
      return e;
    }
  };

  sendTestMessage = async (body: SingleSendTestMessageDTO): Promise<void> => {
    try {
      const res = await this._api.post<any>(
        ApiSurfaces.API,
        `${ENDPOINTS.singleSendV2}/test`,
        body
      );
      if (res.status) {
        this._message.show({
          text: 'Test sent',
          type: MessageType.SUCCESS
        });
      }
    } catch (e) {
      this._error.displayError(e);
    }
  };

  getSingleSendSummaryV2 = async (): Promise<SingleSendSummary> => {
    try {
      const ret = await this._api.get(
        ApiSurfaces.API,
        ENDPOINTS.singleSendSummaryV2
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };
}
