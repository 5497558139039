<div
  class="logo"
  [class.white]="color == 'white'"
  [class.black]="color == 'black'"
  [class.green]="color == 'green'"
  [class.neutral]="color == 'neutral'"
>
  <svg
    *ngIf="!small"
    [attr.height]="height + 'px'"
    viewBox="0 0 510 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="norby" stroke="none">
      <path
        d="M89.1215 4.12939V102.807H68.7359L24.8234 45.3543V102.807H0.761719V4.12939H22.64L65.0598 59.651V4.12939H89.1215Z"
      ></path>
      <path
        d="M285.62 67.9531C298.319 62.5363 305.27 51.8138 305.27 37.6282V37.3618C305.27 27.9935 302.263 20.0682 296.581 14.4073C289.898 7.76952 279.85 4.26196 267.507 4.26196H222.102V102.895H246.453V72.3708H260.489L281.008 102.895H309.615L285.62 67.9309V67.9531ZM266.148 50.5707H246.453V26.4395H265.724C272.52 26.4395 280.629 28.5041 280.629 38.4274V38.6938C280.629 46.1085 275.215 50.5485 266.126 50.5485L266.148 50.5707Z"
      ></path>
      <path
        d="M392.461 51.0351C399.612 46.1289 403.11 39.4468 403.11 30.7445V30.4781C403.11 23.5074 400.793 17.8242 396.07 13.0957C390.188 7.25715 381.143 4.17139 369.869 4.17139H323.773V102.827H370.961C394.778 102.827 408.457 92.704 408.457 75.0108V74.7444C408.457 63.4669 403.489 56.03 392.461 51.0129V51.0351ZM370.961 81.1601H347.59V63.4447H370.293C379.561 63.4447 384.24 66.3973 384.24 72.1692V72.4356C384.24 78.2298 379.761 81.1601 370.961 81.1601ZM378.892 34.2965C378.892 36.3832 378.892 42.7324 365.369 42.7324H347.59V25.8383H366.728C372.276 25.8383 378.892 27.2591 378.892 34.0301V34.2965Z"
      ></path>
      <path
        d="M509.242 4.14917L471.1 63.6667V102.827H446.771V64.0663L408.629 4.14917H437.146L459.092 40.912L481.393 4.14917H509.242Z"
      ></path>
      <path
        d="M192.873 8.9447C183.716 1.15258 168.433 -2.48818 154.486 1.86297C142.321 5.63693 130.313 13.9174 120.688 25.1505C99.1216 50.3028 98.2081 83.5137 115.987 98.6539C121.958 103.715 131.56 108 140.093 108C141.207 108 142.321 107.956 143.48 107.867C159.098 106.646 175.384 97.3885 188.194 82.4259C197.819 71.1928 204.146 58.0727 205.995 45.5077C208.134 31.1 202.03 16.7368 192.873 8.9447ZM140.249 82.1151C134.301 82.4925 129.02 78.2745 128.597 72.325C127.283 54.5874 150.431 25.3281 169.658 25.4391C174.782 25.4613 179.238 29.1909 180.018 34.2302C182.914 52.7226 158.875 80.9163 140.249 82.1151Z"
      ></path>
    </g>
  </svg>

  <svg
    *ngIf="small"
    [attr.height]="height + 'px'"
    viewBox="0 0 140 140"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="norby-small">
      <path
        class="user-theme-text-color-fill"
        d="M17.7622 39.3365C-5.97765 70.9222 -5.57529 109.57 16.9 127.853C17.1874 128.072 17.4748 128.291 17.7622 128.51V39.2818V39.3365Z"
      />
      <path
        class="user-theme-text-color-fill"
        d="M93.4049 76.9382V0.464695C85.4725 -0.520647 77.1376 0.0267686 69.0902 2.43538C60.4105 5.00822 51.7883 9.22329 43.6834 14.7522L93.4049 76.9929V76.9382Z"
      />
      <path
        class="user-theme-text-color-fill"
        d="M46.1032 60.899V139.836C47.3103 139.945 48.46 140 49.6096 140C51.1041 140 52.6561 140 54.2081 139.836C68.3486 138.796 82.9489 133.048 96.2271 123.578L46.1032 60.9538V60.899Z"
      />
      <path
        class="user-theme-text-color-fill"
        d="M121.743 12.1248V98.3422C130.883 85.8612 136.919 72.2306 138.93 59.038C141.747 40.6997 133.815 22.3614 121.743 12.1795V12.1248Z"
      />
    </g>
  </svg>
</div>
