import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { slideIn } from '../../../../animations';

@Component({
  selector: 'lib-event-chat-step',
  templateUrl: './event-chat-step.component.html',
  styleUrls: ['./event-chat-step.component.less'],
  animations: [slideIn]
})
export class EventChatStepComponent implements OnInit, OnChanges {
  @Input() isLoadingResponse = false;
  @Input() showSkipButton = true;
  @Input() message: string;

  @Output() onSubmitReply: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSkipChat: EventEmitter<void> = new EventEmitter<void>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(): void {
    if (this.formGroup?.controls?.reply?.value) {
      this.formGroup.reset();
    }
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      reply: ['', Validators.required]
    });
  }

  get showAskDifferentQuestionButton(): boolean {
    return !this.showSkipButton && !this.isLoadingResponse && !!this.message;
  }

  handleSubmitMessage() {
    if (this.formGroup.valid) {
      this.onSubmitReply.emit(this.formGroup.get('reply').value);
    }
  }

  handleAskDifferentQuestion() {
    this.onSubmitReply.emit('Ask me a different question');
  }

  handleSkip() {
    this.onSkipChat.emit();
  }
}
