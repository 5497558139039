import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';

// 3rd party
import { plainToClass } from 'class-transformer';

// Libs
import { IBillingServiceInterface } from './billing.service.interface';
import { ErrorService, MessageService, MessageType } from 'uikit';
import {
  AddOn,
  AddOnType,
  IAddOnsResult,
  IAttachCardPaymentMethod,
  ISetBillingAddress,
  ISubscriptionSchedule,
  ENDPOINTS,
  IEstimationResult,
  PaginatedQueryFilters,
  ProjectPeriod,
  ApiSurfaces,
  ProjectTiersType,
  IUsageSummary,
  DowngradePromoCopy,
  SubscriptionChangeReason
} from 'models';
import { ApiService } from '../api';
import { DeviceService } from '../device';
import { BillingEstimate, SlugReferralSummary } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class BillingService implements IBillingServiceInterface {
  constructor(
    private _api: ApiService,
    private _device: DeviceService,
    private _firestore: Firestore,
    private _message: MessageService,
    private _error: ErrorService
  ) {}

  async setBillingAddress(payload: ISetBillingAddress) {
    try {
      const endpoint = '/subscription/set_billing_address';
      const ret = await this._api.post<boolean>(
        ApiSurfaces.BILLING,
        endpoint,
        payload
      );
      this._message.show({
        text: ret ? 'Success' : 'Error',
        type: ret ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async attachPaymentCard(
    payload: IAttachCardPaymentMethod,
    acceptTerms = true,
    showConfirmation = true,
    captchaToken: string = null
  ) {
    try {
      const addPaymentEndpoint = '/subscription/attach_card_payment_method';
      let succeeded = await this._api.post<boolean>(
        ApiSurfaces.BILLING,
        addPaymentEndpoint,
        payload,
        null,
        captchaToken
      );

      if (succeeded && acceptTerms) {
        succeeded = await this.acceptTerms();
      }

      if (showConfirmation) {
        this._message.show({
          text: succeeded ? 'Success' : 'Error',
          type: succeeded ? MessageType.SUCCESS : MessageType.ERROR
        });
      }

      return succeeded;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async acceptTerms(showConfirmation = false) {
    try {
      const acceptTermsEndpoint = '/organization/accept_terms';
      const succeeded = await this._api.post<boolean>(
        ApiSurfaces.API,
        acceptTermsEndpoint
      );

      if (showConfirmation) {
        this._message.show({
          text: succeeded ? 'Success' : 'Error',
          type: MessageType.SUCCESS
        });
      }

      return succeeded;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async detachPaymentCard(id: string) {
    try {
      const endpoint = '/subscription/detach_card_payment_method';
      const ret = await this._api.post<boolean>(ApiSurfaces.BILLING, endpoint, {
        paymentMethodId: id
      });
      this._message.show({
        text: ret ? 'Success' : 'Error',
        type: ret ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async setDefaultPaymentMethod(id: string) {
    try {
      const endpoint = '/subscription/set_default_payment_method';
      const ret = await this._api.post<boolean>(ApiSurfaces.BILLING, endpoint, {
        paymentMethodId: id
      });
      this._message.show({
        text: ret ? 'Success' : 'Error',
        type: ret ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async attachAddon(addonType: AddOnType) {
    try {
      const endpoint = '/subscription/attach_addon';
      const ret = await this._api.post<boolean>(ApiSurfaces.BILLING, endpoint, {
        addonType
      });
      this._message.show({
        text: ret ? 'Success' : 'Error',
        type: ret ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async detachAddon(addonType: AddOnType) {
    try {
      const endpoint = '/subscription/detach_addon';
      const ret = await this._api.post<boolean>(ApiSurfaces.BILLING, endpoint, {
        addonType
      });
      this._message.show({
        text: ret ? 'Success' : 'Error',
        type: ret ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  fetchAddons = async (
    filters?: PaginatedQueryFilters
  ): Promise<IAddOnsResult> => {
    try {
      const endpoint = '/subscription/addons';
      const ret = await this._api.get<IAddOnsResult>(
        ApiSurfaces.BILLING,
        endpoint,
        filters
      );

      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  };

  async estimateSubscriptionChange(
    targetProjectTier: ProjectTiersType,
    targetProjectPeriod: ProjectPeriod
  ): Promise<IEstimationResult> {
    try {
      const endpoint = '/subscription/change_plan/estimate';
      const ret = await this._api.post<IEstimationResult>(
        ApiSurfaces.BILLING,
        endpoint,
        {
          targetProjectTier,
          targetProjectPeriod
        }
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async changeSubscription(
    targetProjectTier: ProjectTiersType,
    targetProjectPeriod: ProjectPeriod,
    changeReason?: SubscriptionChangeReason
  ): Promise<boolean> {
    try {
      const endpoint = '/subscription/change_plan';
      const ret = await this._api.post<boolean>(ApiSurfaces.BILLING, endpoint, {
        targetProjectTier,
        targetProjectPeriod,
        retainAddons: [AddOn.PHONE_NUMBER],
        ...(changeReason && { changeReason })
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async fetchDowngradeSchedules(): Promise<ISubscriptionSchedule[]> {
    try {
      const endpoint = '/subscription/schedule';
      const ret = await this._api.get<ISubscriptionSchedule[]>(
        ApiSurfaces.BILLING,
        endpoint
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async cancelPlanChange(): Promise<void> {
    try {
      const endpoint = '/subscription/resume';
      await this._api.post<void>(ApiSurfaces.BILLING, endpoint);
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getBillingToken(): Promise<string> {
    const token = await this._api.post<any>(
      ApiSurfaces.AUTH,
      ENDPOINTS.auth.billing
    );
    return token?.token ?? null;
  }

  async fetchUsageSummary(): Promise<IUsageSummary> {
    try {
      const endpoint = '/subscription/usage_summary';
      const ret = await this._api.get<IUsageSummary>(
        ApiSurfaces.BILLING,
        endpoint
      );
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Check coupon validity
  async checkPromoValidity(promotionCode: string) {
    try {
      const endpoint = '/billing/coupon/valid';
      const slug = this._device.currentSlug;
      const input = { promotionCode, slug };
      const ret = await this._api.post<{ valid: boolean }>(
        ApiSurfaces.API,
        endpoint,
        input
      );
      this._message.show({
        text: ret?.valid ? 'Valid' : 'Invalid',
        type: ret?.valid ? MessageType.SUCCESS : MessageType.ERROR
      });
      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Redeem coupon
  async redeemPromo(promotionCode: string, showConfirmation = true) {
    try {
      const endpoint = '/coupon/redeem';
      const input = { promotionCode };
      const ret = await this._api.post<boolean>(
        ApiSurfaces.BILLING,
        endpoint,
        input
      );

      if (showConfirmation) {
        this._message.show({
          text: ret ? 'Redeemed' : 'Failed',
          type: ret ? MessageType.SUCCESS : MessageType.ERROR
        });
      }

      return ret;
    } catch (e) {
      this._error.displayError(e);
    }
  }

  // Get billing estimate
  async getBillingEstimate(promoCode?: string, excludeUsage = false) {
    try {
      const endpoint = '/organization/billing/estimate';
      const ret = await this._api.get<BillingEstimate>(
        ApiSurfaces.API,
        endpoint,
        {
          ...(promoCode && { promoCode }),
          excludeUsage
        }
      );
      return plainToClass(BillingEstimate, ret);
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getReferralSummary(): Promise<SlugReferralSummary> {
    try {
      const endpoint = '/referral/summary';
      const ret = await this._api.get<SlugReferralSummary>(
        ApiSurfaces.BILLING,
        endpoint
      );
      return plainToClass(SlugReferralSummary, ret);
    } catch (e) {
      this._error.displayError(e);
    }
  }

  async getDowngradePromoCopy(
    tier: ProjectTiersType
  ): Promise<DowngradePromoCopy> {
    const ref = doc(this._firestore, 'retentionPromos', tier);
    const snapshot = await getDoc(ref);
    return snapshot.data() as DowngradePromoCopy;
  }
}
