<root-spin [spinning]="!currentStage">
  <div class="login-container">
    <div class="stepper-container" *ngIf="!error">
      <!-- STEP 0: SELECT TICKET -->
      <lib-content-registration-tickets
        (handleNext)="handleNext()"
        (onPriceRecalculate)="handleRecalculatePrice($event)"
        (onSelectedTicketUpdate)="handleSelectedTicketUpdate($event)"
        *ngIf="currentStage === 'tickets'"
        [content]="content"
        [fees]="fees"
        [selectedTicket]="selectedTicket"
        [subtotal]="subtotal"
        [total]="total"
        [themeClasses]="themeClasses"
        [addedPromoCode]="addedPromoCode"
      ></lib-content-registration-tickets>

      <!-- OPTIONAL STEP 1A: ENTER PHONE -->
      <lib-content-registration-phone-number
        (handleNext)="handlePhoneNumberNext($event)"
        *ngIf="currentStage === 'phone'"
        [content]="content"
        [slug]="slug"
      ></lib-content-registration-phone-number>

      <!-- STEP 1B: ENTER CONFIRMATION CODE -->
      <lib-content-registration-phone-number-confirmation-code
        (handleNext)="handleNext()"
        (handleSetError)="handleSetError($event)"
        (handleSkipEmailStep)="handleSkipEmailStage()"
        *ngIf="currentStage === 'code'"
        [isOnFinalScreen]="isOnFinalScreen"
        [phoneNumber]="phoneNumber"
      ></lib-content-registration-phone-number-confirmation-code>

      <!-- OPTIONAL STEP 2A: ENTER EMAIL -->
      <lib-content-registration-email
        (handleNext)="
          email = $event.email;
          emailVerificationCodeMedium = $event.emailVerificationCodeMedium;
          handleNext()
        "
        (handleSetError)="handleSetError($event)"
        (handleSkipVerificationCodeStage)="handleSkipEmailVerificationStage()"
        *ngIf="currentStage === 'email'"
        [content]="content"
        [emailVerificationCodeMedium]="emailVerificationCodeMedium"
        [email]="email"
      ></lib-content-registration-email>

      <!-- SUPER OPTIONAL STEP 2B: ENTER CONFIRMATION CODE -->
      <lib-content-registration-email-confirmation-code
        (handleNext)="handleNext()"
        (handleSetError)="handleSetError($event)"
        *ngIf="currentStage === 'emailCode'"
        [emailVerificationCodeMedium]="emailVerificationCodeMedium"
        [email]="email"
        [isOnFinalScreen]="isOnFinalScreen"
      ></lib-content-registration-email-confirmation-code>

      <!-- OPTIONAL STEP 3: ENTER REQUIRED USER INFO -->
      <lib-content-registration-profile
        (handleNext)="handleNext()"
        (handleSetError)="handleSetError($event)"
        (unsubscribeUserSubscriptions)="unsubscribeUserSubscriptions()"
        *ngIf="currentStage === 'profile'"
        [content]="content"
        [hasLoadedProfile]="hasLoadedProfile"
        [isOnFinalScreen]="isOnFinalScreen"
        [needsEmail]="needsEmail"
        [needsName]="needsName"
        [stages]="stages"
        [userContent]="userContent"
        [userMetadata]="userMetadata"
        [userProfile]="userProfile"
      ></lib-content-registration-profile>

      <!-- OPTIONAL STEP 4: COLLECT PROMPT RESPONSES -->
      <lib-content-registration-prompts
        (handleNext)="userContent = $event; handleNext()"
        *ngIf="currentStage === 'prompts'"
        [content]="content"
        [userContent]="userContent"
      ></lib-content-registration-prompts>
    </div>

    <!-- OPTIONAL STEP 5: ENTER PAYMENT INFO -->
    <lib-content-registration-purchase
      (handleNext)="handleNext()"
      (handleSetError)="handleSetError($event)"
      (handleSkipPayment)="skipPayment()"
      *ngIf="currentStage === 'purchase'"
      [content]="content"
      [hasLoadedProfile]="hasLoadedProfile"
      [isOnFinalScreen]="isOnFinalScreen"
      [selectedTicket]="selectedTicket"
      [userHasPaid]="userHasPaid"
      [userProfile]="userProfile"
      [themeClasses]="themeClasses"
      [promoCode]="addedPromoCode"
    ></lib-content-registration-purchase>

    <div class="error card-text-color" *ngIf="error">
      <p>{{ error }}</p>
      <root-button
        type="ghost"
        (handleClick)="close()"
        aria-label="Okay"
        role="button"
        name="Okay"
        text="Okay"
      >
      </root-button>
    </div>

    <div class="powered-container margin-top">
      <a
        class="built-with-norby"
        href="https://www.withnorby.com"
        target="_blank"
      >
        <img
          [src]="BUILT_WITH_NORBY_URL"
          width="466"
          height="138"
          style="width: auto; height: 36px"
          alt="Built With Norby"
        />
      </a>

      <div class="links">
        <a
          [href]="PRIVACY_URL"
          class="user-theme-button-secondary"
          target="_blank"
        >
          <span class="user-theme-button-text">Privacy</span></a
        >
        <a
          [href]="TERMS_URL"
          class="user-theme-button-secondary"
          target="_blank"
        >
          <span class="user-theme-button-text">Terms</span></a
        >
      </div>
    </div>
  </div>
</root-spin>
