<div class="flex flex-col items-center">
  <img src="assets/svg/up-arrow-1.svg" class="arrow-svg mb-1" />
  <h1 class="mb-1">Schedule</h1>
  <p class="mb-2 text-neutral-600">When should this be sent?</p>

  <form [formGroup]="formGroup">
    <norby-date-time-picker formControlName="datetime"></norby-date-time-picker>
  </form>

  <norby-button
    class="mb-2"
    text="Schedule send"
    [isDisabled]="!formGroup.valid"
    [isLoading]="isScheduling"
    (buttonClicked)="handleSubmitScheduledSend()"
  ></norby-button>

  <norby-button
    buttonType="secondary"
    text="Cancel"
    (buttonClicked)="handleCancelSend()"
  ></norby-button>
</div>
