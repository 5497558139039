import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { firstValueFrom } from 'rxjs';
import { CORS_ANYWHERE_URL_TOKEN, IImage } from 'models';
import { ContentManagementService } from '../content-management';
@Injectable({
  providedIn: 'root'
})
export class CorsAnywhereService {
  constructor(
    @Inject(CORS_ANYWHERE_URL_TOKEN) private _corsAnywhereBaseUrl,
    @Inject(DOCUMENT) private _document: Document,
    private _http: HttpClient,
    private _contentManagement: ContentManagementService
  ) {}

  private _constructRequestUrl(uri: string) {
    const base = this._corsAnywhereBaseUrl;
    const url = `${base}/${uri}`;
    return url;
  }

  private async _constructHttpOpts(params?: any) {
    return {
      withCredentials: false,
      headers: await this._contructHeaders(),
      ...(params && { params })
    };
  }

  private async _contructHeaders() {
    const headers = {};
    return headers;
  }

  async post<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this._constructRequestUrl(route);
    return firstValueFrom(this._http.post<T>(url, body, opts));
  }

  async put<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this._constructRequestUrl(route);
    return firstValueFrom(this._http.put<T>(url, body, opts));
  }

  async patch<T>(route: string, body?: any) {
    const opts = await this._constructHttpOpts();
    const url = this._constructRequestUrl(route);
    return firstValueFrom(this._http.patch<T>(url, body, opts));
  }

  async delete<T>(route: string, params?: any) {
    const opts = await this._constructHttpOpts(params);
    const url = this._constructRequestUrl(route);
    return firstValueFrom(this._http.delete<T>(url, opts));
  }

  async get<T>(route: string, params?: any) {
    const opts = await this._constructHttpOpts(params);
    const url = this._constructRequestUrl(route);
    return firstValueFrom(
      this._http.get<T>(url, {
        ...opts
      })
    );
  }

  async getString<T>(route: string, params?: any) {
    const opts = await this._constructHttpOpts(params);
    const url = this._constructRequestUrl(route);
    return firstValueFrom(
      this._http.get<T>(url, {
        ...opts,
        responseType: 'text' as any
      })
    );
  }

  async getMetadataForUrl(url: string) {
    const html = await this.getString<string>(url);
    if (!html) {
      return {
        description: '',
        imageUrl: null,
        title: ''
      };
    }

    const tempDiv = this._document?.createElement('div');
    tempDiv.innerHTML = html;

    return {
      description: this._getDescription(tempDiv),
      image: await this._getOgImage(tempDiv, url),
      title: this._getOgTitle(tempDiv)
    };
  }

  private _getDescription(div: HTMLDivElement) {
    return div
      .querySelector('meta[name="description"]')
      ?.getAttribute('content');
  }

  private async _getOgImage(div: HTMLDivElement, url: string) {
    const baseUrl = new URL(url).origin;
    let imageUrl =
      div.querySelector("meta[property='og:image']")?.getAttribute('content') ??
      '';

    if (imageUrl.startsWith('/')) {
      imageUrl = `${baseUrl}${imageUrl}`;
    }

    if (imageUrl.startsWith('http')) {
      const storageImageUrl = await this._contentManagement.uploadImageFromUrl(
        imageUrl,
        false
      );
      return storageImageUrl ? ({ url: storageImageUrl } as IImage) : null;
    }

    return null;
  }

  private _getOgTitle(div: HTMLDivElement) {
    return div
      .querySelector("meta[property='og:title']")
      ?.getAttribute('content');
  }
}
