import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { NorbyGradientSelectorModule } from './norby-gradient-selector.module';
import { NorbyDropShadowPickerModule } from './norby-drop-shadow-picker.module';
import { NorbyFontSelectorModule } from './norby-font-selector.module';
import {
  NorbyCollapsibleGroupModule,
  NorbyCollapsibleModule,
  NorbySelectModule,
  NorbyFileUploadModule,
  NorbySliderModule,
  NorbyInputModule,
  NorbyNumberInputModule,
  NorbyButtonModule,
  NorbyIconModule,
  NorbyIconButtonWithLabelModule,
  NorbyAlertModule,
  NorbyFormcontrolLabelModule,
  TooltipModule
} from 'uikit';
import { NorbyThemeCustomizerComponent } from '../components/organisms/norby-theme-customizer';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';
import { NorbyAntColorPickerModule } from './norby-ant-color-picker.module';

@NgModule({
  declarations: [NorbyThemeCustomizerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyCollapsibleGroupModule,
    NorbyCollapsibleModule,
    NorbySelectModule,
    NorbyAntColorPickerModule,
    NorbyFileUploadModule,
    NorbySliderModule,
    NorbyInputModule,
    NorbyGradientSelectorModule,
    NorbyNumberInputModule,
    NorbyButtonModule,
    NorbyIconModule,
    NorbyIconButtonWithLabelModule,
    NorbyDropShadowPickerModule,
    NorbyAlertModule,
    NorbyFontSelectorModule,
    NorbyFormcontrolLabelModule,
    NorbyCustomMessageTooltipModule,
    TooltipModule,
    RouterModule
  ],
  exports: [NorbyThemeCustomizerComponent]
})
export class NorbyThemeCustomizerModule {}
