import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from './icon.module';
import {
  MessageComponent,
  defaultMessageConfig,
  MESSAGE_CONFIG_TOKEN
} from '../root-components/message';

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule, IconModule],
  exports: [MessageComponent]
})
export class MessageModule {
  static forRoot(
    config = defaultMessageConfig
  ): ModuleWithProviders<MessageModule> {
    return {
      ngModule: MessageModule,
      providers: [
        {
          provide: MESSAGE_CONFIG_TOKEN,
          useValue: { ...defaultMessageConfig, ...config }
        }
      ]
    };
  }
}
