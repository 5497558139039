import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';

// App
import {
  BaseComponent,
  IconService,
  rootShare,
  rootLoader,
  rootLink,
  MessageService,
  MessageType,
  MockShareService
} from 'uikit';
import { CUBE_EVENTS, ContentLink, IUserContent } from 'models';

/*
  Creates a share button for a given link
*/

@Component({
  selector: 'lib-link-share-button-view',
  templateUrl: './link-share-button-view.component.html',
  styleUrls: ['./link-share-button-view.component.less']
})
export class LinkShareButtonViewComponent
  extends BaseComponent
  implements OnInit
{
  @Output() menuStateChanged = new EventEmitter<boolean>();
  @Input() link: ContentLink;
  @Input() userContent: IUserContent;
  @Input() isDisabled = false;
  @Input() isLoading = false;
  @Input() size: 'default' | 'large' | 'small' = 'large';
  @Input() textLabel = 'Share';
  @Input() type: 'primary' | 'dashed' | 'link' | 'text' = null;

  @ViewChild('download', { static: false }) download!: ElementRef;
  @ViewChild('qrCanvas', { static: false })
  qrCanvas!: ElementRef<HTMLElement>;

  // Whether the current device supports system share menu
  canUseSystemShare = false;

  // Analytics events
  userTappedShare = CUBE_EVENTS.userTappedShare;

  constructor(
    private _share: MockShareService,
    private _message: MessageService,
    private _iconService: IconService
  ) {
    super();
    this._iconService.registerIcons([rootShare, rootLoader, rootLink]);
  }

  ngOnInit(): void {
    this.canUseSystemShare = this._share.canShare;
  }

  doShare() {
    this._share.openContentShareSheet(this.link, this.userContent);
  }

  handleCopy() {
    this._message.show({ text: 'Copied', type: MessageType.SUCCESS });
  }

  handleMenuStateChanged(event) {
    this.menuStateChanged.next(event);
  }

  downloadQRCode() {
    const canvas =
      this.qrCanvas.nativeElement?.querySelector<HTMLCanvasElement>('canvas');

    if (canvas) {
      this.download.nativeElement.href = canvas.toDataURL('image/png');
      this.download.nativeElement.download = 'link-qr-code';
      const event = new MouseEvent('click');
      this.download.nativeElement.dispatchEvent(event);
    }
  }
}
