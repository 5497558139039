import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoteViewComponent } from '../views/note-view';
import { IconModule } from 'uikit';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  declarations: [NoteViewComponent],
  exports: [NoteViewComponent],
  imports: [CommonModule, IconModule, NzIconModule, NzPopconfirmModule]
})
export class NoteViewModule {}
