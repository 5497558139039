import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

// 3rd party
import { filter, map } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  DEFAULT_BLOCK_PADDING,
  ISlug,
  SingleSend,
  UpcomingEventsSingleSendBlock,
  UpdatedUpcomingEventsSendBlock
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'edit-email-upcoming-events-block',
  templateUrl: './edit-email-upcoming-events-block.component.html',
  styleUrls: ['./edit-email-upcoming-events-block.component.less']
})
export class EditEmailUpcomingEventsBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() slug: ISlug;
  @Input() send: SingleSend;
  @Input() block: UpcomingEventsSingleSendBlock;
  @Input() contentLabel: string;

  @Output() onUpdatedUpcomingEventsBlock =
    new EventEmitter<UpdatedUpcomingEventsSendBlock>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.formGroup?.patchValue(
      {
        tag: this.block?.tag,
        limit: this.block?.limit,
        reverse: this.block?.reverse,
        paddingTop: this.block?.paddingTop,
        paddingRight: this.block?.paddingRight,
        paddingBottom: this.block?.paddingBottom,
        paddingLeft: this.block?.paddingLeft
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      tag: [this.block?.tag ?? ''],
      limit: [this.block?.limit ?? 10],
      reverse: [this.block?.reverse ?? false],
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) => {
          return plainToClass(UpcomingEventsSingleSendBlock, {
            ...this.block,
            ...value
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((block) =>
        this.onUpdatedUpcomingEventsBlock.emit({
          block,
          shouldDoApiCall: this._shouldDoApiRequest()
        })
      );
  }

  private _shouldDoApiRequest(): boolean {
    return (
      this.formGroup.controls.tag.dirty ||
      this.formGroup.controls.limit.dirty ||
      this.formGroup.controls.reverse.dirty
    );
  }
}
