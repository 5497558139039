import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// lib
import { IFont, NorbySelectorTypesEnum } from 'models';

@Component({
  selector: 'norby-font-selector',
  templateUrl: './norby-font-selector.component.html',
  styleUrls: ['./norby-font-selector.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyFontSelectorComponent),
      multi: true
    }
  ]
})
export class NorbyFontSelectorComponent
  implements ControlValueAccessor, OnChanges
{
  @Input() label: string;
  @Input() placeholder: string;
  @Input() fonts: IFont[];
  @Input() value: IFont;
  @Input() infoTooltip?: string = '';
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() isJoinedRight: boolean = false;
  @Input() isJoinedLeft: boolean = false;

  @Output() onValueChange: EventEmitter<IFont> = new EventEmitter<IFont>();
  @Output() onImportClick: EventEmitter<void> = new EventEmitter<void>();

  readonly NORBY_SELECTOR_TYPES_ENUM = NorbySelectorTypesEnum;
  val: IFont;

  private _onChanged: any = (_: any) => {};
  private _onTouched: any = () => {};
  private _touched = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.val = this.value;
    }
  }

  handleChange(font: IFont) {
    this.val = font;
    this.onValueChange.emit(font);
    this._onChanged(font);
    this._markAsTouched();
  }

  writeValue(value: any) {
    this.val = value;
  }

  registerOnChange(fn: any) {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }
}
