<lib-device-preview-view
  [platform]="platform"
  [isInteractive]="isInteractive"
  [isScrollable]="isScrollable"
  [showAsAbstract]="showAsAbstract"
  [backgroundCss]="backgroundCss"
  *ngIf="isBrowser"
>
  <norby-send-detail-view [send]="send" [slug]="slug"></norby-send-detail-view>
</lib-device-preview-view>
