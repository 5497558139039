import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { plainToClass } from 'class-transformer';
import {
  SegmentFilter,
  SegmentFilterTypes,
  SingleSend,
  SingleSendSearchResults,
  truncateStringDisplay
} from 'models';

@Component({
  selector: 'norby-custom-engagement-filter',
  templateUrl: './norby-custom-engagement-filter.component.html',
  styleUrls: ['./norby-custom-engagement-filter.component.less']
})
export class NorbyCustomEngagementFilterComponent implements OnInit {
  @Input() sendSearchResult: SingleSendSearchResults;

  @Output() onAddFilter = new EventEmitter<SegmentFilter>();
  filterDisplay: string;
  selectedLink: string;

  constructor() {}

  ngOnInit(): void {}

  get isButtonDisabled() {
    return !this.selectedLink || this.selectedLink?.length === 0;
  }

  handleOpenFilterDropdown() {}

  handleSelectLink(link: string) {
    this.selectedLink = link;
  }

  handleAddFilter() {
    const filter = plainToClass(SegmentFilter, {
      type: SegmentFilterTypes.CLICKED_SPECIFIC_SEND_LINK,
      sendId: this.sendSearchResult.send.id,
      rawUrl: this.selectedLink,
      singleSendDisplay: truncateStringDisplay(
        this.sendSearchResult?.send?.subject ||
          this.sendSearchResult?.send?.firstTextBlockContent ||
          ''
      )
    });
    this.selectedLink = null;

    this.onAddFilter.emit(filter);
  }
}
