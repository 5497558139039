import { MessageAttachment } from './general';

export enum InstagramAttachmentSourceTypeEnum {
  FIREBASE_STORAGE = 'firebaseStorage'
}
export type InstagramAttachmentSourceType =
  `${InstagramAttachmentSourceTypeEnum}`;

export const InstagramAttachmentSourceTypes: InstagramAttachmentSourceType[] = [
  'firebaseStorage'
];

export class InstagramAttachment extends MessageAttachment {
  filename?: string;
  sourceType!: InstagramAttachmentSourceType;
  source!: string;
  contentType!: string;
  contentSize!: number;
}

export class InboxInstagramAttachment {
  image_data?: InboxInstagramAttachmentMetadata;
  video_data?: InboxInstagramAttachmentMetadata;
  file_url?: InboxInstagramAttachmentMetadata;
}
export class InboxInstagramAttachmentMetadata {
  width?: number;
  height?: number;
  max_width?: number;
  max_height?: number;
  url?: string;
  preview_url?: string;
}

export class InboxInstagramReaction {
  reaction!: string;
  users!: InboxInstagramUsers[];
}

export class InboxInstagramUsers {
  username!: string;
  id!: string;
}

export type InboxInstagramStoryType = 'mention' | 'reply_to';

export class InboxInstagramStory {
  link?: string;
  id?: string;
}

export class InboxInstagramShare {
  link?: string;
}

export type IFormattedInstagramImageAttachment =
  | FormattedInstagramImageAttachment
  | FormattedInstagramVideoAttachment;

export class FormattedInstagramAttachment {
  attachment_type!: FormattedInstagramAttachmentType;
  source!: string;
  content_type?: string;
  content_size = 0;
  filename?: string;
  height?: number;
  width?: number;
}

export class FormattedInstagramImageAttachment extends FormattedInstagramAttachment {
  attachment_type!: 'image';
}

export class FormattedInstagramVideoAttachment extends FormattedInstagramAttachment {
  attachment_type!: 'video';
}

export type FormattedInstagramAttachmentType = 'image' | 'video';

export enum InstagramStickers {
  HEART = 'like_heart'
}

export class ISendContactBulkMessageBase {
  inboxItemId!: string;
}

export class ISendContactBulkInstagramMessage extends ISendContactBulkMessageBase {
  body?: string;
  attachment?: InstagramAttachment;
  react?: ISendContactInstagramReaction;
  unreact?: IUnsendContactInstagramReaction;
  sticker?: InstagramStickers;
}

export class ISendContactInstagramReaction {
  // Instagram only supports love right now
  type!: 'love';
  message_id!: string;
}

export class IUnsendContactInstagramReaction {
  message_id!: string;
}
