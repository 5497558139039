<div class="contact-details">
  <nz-spin [nzSpinning]="isLoadingContact">
    <div class="top"></div>
    <form nz-form [formGroup]="formGroup" nzLayout="vertical">
      <div class="header">
        <!-- avatar -->
        <nz-spin [nzSpinning]="isUploadingAvatar">
          <nz-avatar
            [nzSize]="80"
            nzIcon="feather/user"
            [nzSrc]="avatarImageUrl"
            nz-dropdown
            [nzDropdownMenu]="menu"
            [style.background-color]="contact?.avatarColor ?? ''"
            class="m-6 border-2 border-white shadow-ant-default"
          >
          </nz-avatar>
        </nz-spin>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <nz-upload
                nzName="image"
                [nzShowUploadList]="false"
                [nzBeforeUpload]="uploadAvatarFile"
                [nzAccept]="IMAGE_MIME_TYPES"
              >
                <div class="upload-icon flex items-center">
                  <root-icon
                    [name]="avatarImageUrl ? 'refresh_cw' : 'upload'"
                  ></root-icon>
                  {{ avatarImageUrl ? 'Swap' : 'Upload' }}
                </div>
              </nz-upload>
            </li>

            <li nz-menu-item *ngIf="avatarImageUrl" (click)="clearAvatarImg()">
              <root-icon name="trash"></root-icon> Remove
            </li>
          </ul>
        </nz-dropdown-menu>

        <!-- name -->
        <div
          *ngIf="contact?.name && !isNameInputVisible"
          class="name-wrapper flex flex-row mt-4 mb-1 w-4/5"
        >
          <h2 class="mb-1">{{ contact?.name }}</h2>
          <button
            nz-button
            nzSize="small"
            (click)="toggleNameInput(true)"
            class="edit-name-btn"
          >
            Edit
          </button>
        </div>

        <div
          *ngIf="isNameInputVisible"
          class="name-wrapper flex flex-col mt-4 mb-1 w-4/5"
        >
          <input nz-input formControlName="nickname" />
          <div class="action-buttons">
            <button nz-button nzSize="small" (click)="updateName()">
              Save
            </button>
            <button nz-button nzSize="small" (click)="toggleNameInput(false)">
              Cancel
            </button>
          </div>
        </div>

        <!-- subtitle -->
        <p class="text-neutral-600">Connected since {{ contactCreatedDate }}</p>
        <ng-content select="[paymentStatus]"></ng-content>
      </div>

      <ng-container *ngTemplateOutlet="actionButtonsTpl"></ng-container>

      <!-- phone numbers -->
      <div *ngIf="contact?.phone_numbers?.length" class="contact-field">
        <div class="field-icon sms-icon">
          <root-icon name="message_circle"></root-icon>
        </div>
        <div>
          <div *ngFor="let number of contact?.phone_numbers">{{ number }}</div>
        </div>
      </div>

      <!-- no phone -->
      <div
        *ngIf="!contact?.phone_numbers?.length"
        class="contact-field missing-info"
      >
        <div class="field-icon sms-icon">
          <root-icon name="message_circle"></root-icon>
        </div>
        <div>No phone number saved</div>
      </div>

      <!-- emails -->
      <div *ngIf="emails?.length" class="contact-field">
        <div class="field-icon email-icon">
          <root-icon name="mail"></root-icon>
        </div>
        <div class="contact-field__content">
          <ng-container *ngFor="let email of emails; index as i">
            <!-- normal rendering of emails -->
            <div
              *ngIf="emailInputVisibleAtIndex !== i"
              class="contact-field__row"
            >
              <div class="email">
                <span class="truncate-email">
                  {{ email }}
                </span>
                <div
                  class="star-icon primary-email-icon"
                  *ngIf="email === primaryEmailAddress"
                >
                  <button
                    nz-button
                    nzSize="small"
                    nzType="text"
                    nz-tooltip
                    nzTooltipTitle="Primary email"
                    (click)="setPrimaryEmail(email)"
                  >
                    <root-icon name="star"></root-icon>
                  </button>
                </div>
              </div>

              <div class="action-buttons">
                <div class="star-icon" *ngIf="email !== primaryEmailAddress">
                  <button
                    nz-button
                    nzSize="small"
                    nzType="text"
                    nz-tooltip
                    nzTooltipTitle="Make primary email"
                    (click)="setPrimaryEmail(email)"
                  >
                    <root-icon name="star"></root-icon>
                  </button>
                </div>
                <button
                  nz-button
                  nzSize="small"
                  (click)="setEmailInputAtIndex(i)"
                >
                  Edit
                </button>
                <button
                  nz-button
                  nzSize="small"
                  (click)="toggleEmailInput(true)"
                >
                  Add
                </button>
                <button
                  nz-button
                  nzSize="small"
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure?"
                  nzOkText="Delete"
                  (nzOnConfirm)="deleteEmail(i)"
                >
                  <span>
                    <root-icon name="x"></root-icon>
                  </span>
                </button>
              </div>
            </div>

            <!-- edit email in line -->
            <div
              *ngIf="emailInputVisibleAtIndex === i"
              class="flex flex-col my-1"
            >
              <input
                nz-input
                formControlName="editEmail"
                placeholder="Email"
                type="email"
              />

              <div class="action-buttons">
                <button nz-button nzSize="small" (click)="updateEmail()">
                  Save
                </button>
                <button nz-button nzSize="small" (click)="hideEditEmailInput()">
                  Cancel
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- no email -->
      <div *ngIf="!emails?.length" class="contact-field missing-info">
        <div class="field-icon email-icon">
          <root-icon name="mail"></root-icon>
        </div>
        <div class="contact-field__content contact-field__row">
          No email saved
          <div class="action-buttons">
            <button nz-button nzSize="small" (click)="toggleEmailInput(true)">
              Add
            </button>
          </div>
        </div>
      </div>

      <!-- new email -->
      <div *ngIf="isEmailInputVisible" class="new-email-row">
        <input
          nz-input
          formControlName="newEmail"
          placeholder="Email"
          type="email"
          class="email-input"
        />
        <div class="action-buttons">
          <button
            nz-button
            (click)="addEmail()"
            nzSize="small"
            type="button"
            [disabled]="!formGroup.get('newEmail').valid || !newEmail"
          >
            Add
          </button>
          <button
            nz-button
            (click)="toggleEmailInput(false)"
            nzSize="small"
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>

      <!-- instagram -->
      <div *ngIf="contact?.instagram_handles?.length" class="contact-field">
        <div class="field-icon">
          <img src="assets/svg/instagram.svg" class="instagram-svg" />
        </div>
        <div>
          <div *ngFor="let handle of contact?.instagram_handles">
            {{ handle }}
          </div>
        </div>
      </div>

      <nz-divider></nz-divider>

      <!-- tags -->
      <nz-form-item>
        <div class="flex flex-col">
          <div class="flex">
            <div class="pr-2" *ngIf="tags?.length > 0">
              <nz-form-label>Tags</nz-form-label>
            </div>
            <div class="tags-container">
              <nz-tag
                *ngFor="let tag of tags"
                nzMode="closeable"
                (nzOnClose)="handleTagClose(tag)"
              >
                {{ tag }}
              </nz-tag>
            </div>
          </div>
          <div class="add-tag-button">
            <button
              (click)="showTagInput()"
              *ngIf="!isTagInputVisible"
              class="editable-tag"
              nz-button
              nzType="dashed"
            >
              <root-icon name="plus"></root-icon>
              Add a tag
            </button>
            <input
              #tagInputElement
              (blur)="handleTagInputConfirm()"
              (keydown.enter)="handleTagInputConfirm()"
              *ngIf="isTagInputVisible"
              nz-input
              style="width: 100px"
              nzSize="small"
              type="text"
            />
          </div>
        </div>
      </nz-form-item>

      <!-- notes -->
      <!-- <nz-form-item>
        <nz-form-label>Notes</nz-form-label>
        <div class="notes-container">
          <div
            *ngFor="let note of contactNotes; index as i"
            class="note"
            (click)="showNoteModal(note, i)"
          >
            <lib-note-view
              (handleDelete)="handleNoteClose($event)"
              [note]="note"
            ></lib-note-view>
          </div>
        </div>
        <div class="add-note-button">
          <button
            (click)="showNoteModal()"
            class="editable-note"
            nz-button
            nzType="dashed"
          >
            <root-icon name="plus"></root-icon>
            Add a note
          </button>
        </div>
      </nz-form-item> -->

      <div>
        <nz-divider class="only:hidden"></nz-divider>
        <ng-content></ng-content>
      </div>

      <nz-divider></nz-divider>

      <!-- delete, only available if not an instagram contact -->
      <button
        *ngIf="!contact?.instagram_handles?.length && showDelete"
        nz-button
        class="delete-button"
        nzBlock
        nz-popconfirm
        nzOkText="Delete"
        nzPopconfirmTitle="Are you sure? All analytics and messages related to this contact will be permanently deleted."
        (nzOnConfirm)="deleteContact()"
        nzDanger
      >
        <root-icon name="trash"></root-icon>
        Delete
      </button>

      <!-- instagram contact delete disclaimer -->
      <p
        *ngIf="contact?.instagram_handles?.length"
        class="instagram-contact__deletion-warning"
      >
        This contact is synced to a connected Instagram account and can't be
        deleted.
      </p>
    </form>
  </nz-spin>
</div>
