import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { filter, map, pairwise } from 'rxjs/operators';

// Lib
import {
  rootLoader,
  rootPlus,
  rootTrash,
  BaseComponent,
  IconService,
  ThemeCustomizerService
} from 'uikit';
import {
  IDropShadowValues,
  IFont,
  IImage,
  ISlug,
  Theme,
  CUSTOM_FONTS,
  ThemeChange,
  IThemeDTO
} from 'models';
import {
  ThemeCustomizerSections,
  THEME_CUSTOMIZER_DEFAULT_SECTIONS
} from './types';

type TypeLevel = {
  identifier: string;
  label: string;
  showSize: boolean;
  tooltip?: string;
};

@Component({
  selector: 'norby-theme-customizer',
  templateUrl: './norby-theme-customizer.component.html',
  styleUrls: ['./norby-theme-customizer.component.less']
})
export class NorbyThemeCustomizerComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() slug: ISlug;
  @Input() theme: Theme;
  @Input() sections: ThemeCustomizerSections =
    THEME_CUSTOMIZER_DEFAULT_SECTIONS;
  @Input() isDisabled: boolean = false;
  @Input() allowComplexBackgrounds: boolean = true;

  @Output() onThemeChange: EventEmitter<ThemeChange> =
    new EventEmitter<ThemeChange>();

  appearanceForm: UntypedFormGroup;
  backgroundMode: 'image' | 'color' | 'gradient';
  availableFonts: IFont[];

  cardDropShadowParts: IDropShadowValues;
  primaryButtonDropShadowParts: IDropShadowValues;
  secondaryButtonDropShadowParts: IDropShadowValues;
  customCardsStyleDropShadowParts: {
    [key: string]: IDropShadowValues;
  };
  customButtonsStyleDropShadowParts: {
    [key: string]: IDropShadowValues;
  };
  tooltipDropShadowParts: IDropShadowValues;
  dropdownDropShadowParts: IDropShadowValues;
  snackbarDropShadowParts: IDropShadowValues;
  textInputDropShadowParts: IDropShadowValues;
  selectDropShadowParts: IDropShadowValues;
  checkboxDropShadowParts: IDropShadowValues;

  isBrowser = false;
  selectedButtonType: string = 'button';
  selectedCardType: string = 'default';
  backgroundImage: IImage[] = [];
  favIcon: IImage[] = [];
  canUpdateFavicon: boolean = false;

  readonly TYPE_LEVELS: TypeLevel[] = [
    {
      identifier: 'font',
      label: 'Default font',
      showSize: false,
      tooltip: 'Fallback font used for all typography'
    },
    {
      identifier: 'h1',
      label: 'Header 1',
      showSize: true
    },
    {
      identifier: 'h2',
      label: 'Header 2',
      showSize: true
    },
    {
      identifier: 'h3',
      label: 'Header 3',
      showSize: true
    },
    {
      identifier: 'h4',
      label: 'Header 4',
      showSize: true
    },
    {
      identifier: 'h5',
      label: 'Header 5',
      showSize: true
    },
    {
      identifier: 'h6',
      label: 'Header 6',
      showSize: true
    },
    {
      identifier: 'p',
      label: 'Paragraph',
      showSize: true
    }
  ];

  buttonTypes = [
    { name: 'Primary', type: 'button' },
    { name: 'Secondary', type: 'secondaryButton' }
  ];

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _formBuilder: UntypedFormBuilder,
    private _iconService: IconService,
    private _themeCustomizerService: ThemeCustomizerService
  ) {
    super();
    this._iconService.registerIcons([rootLoader, rootPlus, rootTrash]);
    this.availableFonts = CUSTOM_FONTS;
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  ngOnInit(): void {
    this._initForm();
    this._initImages();
    this._initBackgroundMode();
    this._initFontFieldsChangeListener();
    this._initCustomArray('cards');
    this._initCustomArray('buttons');
    this._initFormValueListener();
    this._initStatusListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.theme) {
      if (this.theme) {
        this.appearanceForm?.reset(this.theme, { emitEvent: false });
      } else {
        this.appearanceForm?.reset({ emitEvent: false });
      }
      this._initCustomArray('cards');
      this._initCustomArray('buttons');
      this._initImages();
      this._initBackgroundMode();
    }

    this._initFormValueListener();
  }

  trackBy(idx: number, item: TypeLevel) {
    return item.identifier;
  }

  private _initStatusListener() {
    this._themeCustomizerService.status$
      .pipe(this.takeUntilDestroy)
      .subscribe(
        ({ canUpdateFavicon }) => (this.canUpdateFavicon = canUpdateFavicon)
      );
  }

  private _initFormValueListener() {
    if (!this.isBrowser || !this.appearanceForm) {
      return;
    }

    this.appearanceForm.valueChanges
      .pipe(this.takeUntilChanges)
      .subscribe((theme: IThemeDTO) => {
        this.onThemeChange.emit({
          theme: Theme.fromObject(theme),
          isDirty: this.appearanceForm.dirty,
          isValid: this.appearanceForm.valid
        });
      });
  }

  private _initBackgroundMode() {
    if (!this.allowComplexBackgrounds) {
      this.backgroundMode = 'color';
      return;
    }
    const { backgroundImage, backgroundGradient, backgroundColor } =
      this.theme?.layout ?? {};

    if (!!backgroundImage?.url) {
      this.backgroundMode = 'image';
    } else if (!!backgroundGradient) {
      this.backgroundMode = 'gradient';
    } else if (!!backgroundColor) {
      this.backgroundMode = 'color';
    } else {
      this.backgroundMode = null;
    }
  }

  private _initImages() {
    this.backgroundImage = this.theme?.layout?.backgroundImage
      ? [this.theme.layout.backgroundImage]
      : [];

    this.favIcon = this.theme?.metadata?.favicon
      ? [{ url: this.theme?.metadata.favicon }]
      : [];
  }

  private _initForm() {
    this.appearanceForm = this._formBuilder.group({
      layout: this._formBuilder.group({
        backgroundImage: [this.theme?.layout?.backgroundImage],
        backgroundGradient: [this.theme?.layout?.backgroundGradient],
        backgroundColor: [
          this.theme?.layout?.backgroundColor,
          Validators.maxLength(30)
        ]
      }),
      metadata: this._formBuilder.group({
        favicon: [this.theme?.metadata?.favicon]
      }),
      fonts: this._formBuilder.group({
        font: [this.theme?.fonts?.font],
        h1: [this.theme?.fonts?.h1],
        h2: [this.theme?.fonts?.h2],
        h3: [this.theme?.fonts?.h3],
        h4: [this.theme?.fonts?.h4],
        h5: [this.theme?.fonts?.h5],
        h6: [this.theme?.fonts?.h6],
        p: [this.theme?.fonts?.p]
      }),
      text: this._formBuilder.group({
        color: [this.theme?.text?.color, Validators.maxLength(30)]
      }),
      link: this._formBuilder.group({
        textColor: [this.theme?.link?.textColor, Validators.maxLength(30)],
        hoverTextColor: [
          this.theme?.link?.hoverTextColor,
          Validators.maxLength(30)
        ]
      }),
      card: this._formBuilder.group({
        borderRadius: [
          this.theme?.card?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [this.theme?.card?.borderWidth, Validators.maxLength(16)],
        dropShadow: [this.theme?.card?.dropShadow, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.card?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [this.theme?.card?.textColor, Validators.maxLength(30)],
        borderColor: [this.theme?.card?.borderColor, Validators.maxLength(30)],
        imagePosition: [
          this.theme?.card?.imagePosition,
          Validators.maxLength(6)
        ],
        textPosition: [this.theme?.card?.textPosition, Validators.maxLength(6)]
      }),
      customCards: this._formBuilder.array([]),
      customButtons: this._formBuilder.array([]),
      button: this._formBuilder.group({
        borderRadius: [
          this.theme?.button?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [
          this.theme?.button?.borderWidth,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.button?.dropShadow, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.button?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [this.theme?.button?.textColor, Validators.maxLength(30)],
        borderColor: [
          this.theme?.button?.borderColor,
          Validators.maxLength(30)
        ],
        hoverBackgroundColor: [
          this.theme?.button?.hoverBackgroundColor,
          Validators.maxLength(30)
        ],
        hoverTextColor: [
          this.theme?.button?.hoverTextColor,
          Validators.maxLength(30)
        ],
        hoverBorderColor: [
          this.theme?.button?.hoverBorderColor,
          Validators.maxLength(30)
        ]
      }),
      secondaryButton: this._formBuilder.group({
        borderRadius: [
          this.theme?.secondaryButton?.borderRadius,
          Validators.maxLength(16)
        ],
        borderWidth: [
          this.theme?.secondaryButton?.borderWidth,
          Validators.maxLength(16)
        ],
        dropShadow: [
          this.theme?.secondaryButton?.dropShadow,
          Validators.maxLength(30)
        ],
        backgroundColor: [
          this.theme?.secondaryButton?.backgroundColor,
          Validators.maxLength(30)
        ],
        textColor: [
          this.theme?.secondaryButton?.textColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.secondaryButton?.borderColor,
          Validators.maxLength(30)
        ],
        hoverBackgroundColor: [
          this.theme?.secondaryButton?.hoverBackgroundColor,
          Validators.maxLength(30)
        ],
        hoverTextColor: [
          this.theme?.secondaryButton?.hoverTextColor,
          Validators.maxLength(30)
        ],
        hoverBorderColor: [
          this.theme?.secondaryButton?.hoverBorderColor,
          Validators.maxLength(30)
        ]
      }),
      tooltip: this._formBuilder.group({
        textColor: [this.theme?.tooltip?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.tooltip?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.tooltip?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.tooltip?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.tooltip?.dropShadow, Validators.maxLength(30)]
      }),
      dropdown: this._formBuilder.group({
        textColor: [this.theme?.dropdown?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.dropdown?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.dropdown?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.dropdown?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.dropdown?.dropShadow, Validators.maxLength(30)]
      }),
      snackbar: this._formBuilder.group({
        textColor: [this.theme?.snackbar?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.snackbar?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.snackbar?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.snackbar?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.snackbar?.dropShadow, Validators.maxLength(30)]
      }),
      textInput: this._formBuilder.group({
        textColor: [this.theme?.textInput?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.textInput?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.textInput?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.textInput?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [
          this.theme?.textInput?.dropShadow,
          Validators.maxLength(30)
        ]
      }),
      select: this._formBuilder.group({
        textColor: [this.theme?.select?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.select?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.select?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.select?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.select?.dropShadow, Validators.maxLength(30)]
      }),
      checkbox: this._formBuilder.group({
        textColor: [this.theme?.checkbox?.textColor, Validators.maxLength(30)],
        backgroundColor: [
          this.theme?.checkbox?.backgroundColor,
          Validators.maxLength(30)
        ],
        borderColor: [
          this.theme?.checkbox?.borderColor,
          Validators.maxLength(30)
        ],
        borderRadius: [
          this.theme?.checkbox?.borderRadius,
          Validators.maxLength(16)
        ],
        dropShadow: [this.theme?.checkbox?.dropShadow, Validators.maxLength(30)]
      })
    });
  }

  get customCardsStylesFormArray(): UntypedFormArray {
    return this.appearanceForm?.get('customCards') as UntypedFormArray;
  }
  get customButtonsStylesFormArray(): UntypedFormArray {
    return this.appearanceForm?.get('customButtons') as UntypedFormArray;
  }

  private _initFontFieldsChangeListener() {
    if (!this.appearanceForm) {
      return;
    }

    for (let i = 0; i < this.TYPE_LEVELS.length; i++) {
      const typeLevel = this.TYPE_LEVELS[i].identifier;
      const ctrl = this.appearanceForm.get('fonts.' + typeLevel);
      if (ctrl) {
        ctrl.valueChanges
          .pipe(
            pairwise(),
            filter(([prev, next]) => next?.importUrl !== prev?.importUrl),
            this.takeUntilDestroy
          )
          .subscribe(([prev, next]) =>
            ctrl.patchValue(
              {
                displayName: 'Default',
                ...next,
                fontSize: prev?.fontSize || next?.fontSize || null
              },
              { emitEvent: false }
            )
          );
      }
    }
  }

  private _initCustomArray(type: 'cards' | 'buttons') {
    const array: UntypedFormArray =
      type === 'cards'
        ? this.customCardsStylesFormArray
        : this.customButtonsStylesFormArray;

    if (!array) {
      return;
    }

    array.clear({ emitEvent: false });

    if (type === 'cards' && this.theme?.customCards?.length) {
      for (let i = 0; i < this.theme.customCards.length; i++) {
        const item = this.theme.customCards[i];
        array.push(
          this._formBuilder.group({
            name: [item?.name, []],
            borderRadius: [item?.borderRadius, Validators.maxLength(16)],
            borderWidth: [item?.borderWidth, Validators.maxLength(16)],
            dropShadow: [item?.dropShadow, Validators.maxLength(30)],
            backgroundColor: [item?.backgroundColor, Validators.maxLength(16)],
            textColor: [item?.textColor, Validators.maxLength(30)],
            borderColor: [item?.borderColor, Validators.maxLength(30)]
          }),
          { emitEvent: false }
        );
      }
    } else if (this.theme?.customButtons?.length) {
      for (let i = 0; i < this.theme.customButtons.length; i++) {
        const item = this.theme.customButtons[i];
        array.push(
          this._formBuilder.group({
            name: [item?.name, []],
            borderRadius: [item?.borderRadius, Validators.maxLength(16)],
            borderWidth: [item?.borderWidth, Validators.maxLength(16)],
            dropShadow: [item?.dropShadow, Validators.maxLength(30)],
            backgroundColor: [item?.backgroundColor, Validators.maxLength(30)],
            textColor: [item?.textColor, Validators.maxLength(30)],
            borderColor: [item?.borderColor, Validators.maxLength(30)],
            hoverBackgroundColor: [
              item?.hoverBackgroundColor,
              Validators.maxLength(30)
            ],
            hoverTextColor: [item?.hoverTextColor, Validators.maxLength(30)],
            hoverBorderColor: [item?.hoverBorderColor, Validators.maxLength(30)]
          }),
          { emitEvent: false }
        );
      }
    }
  }

  handleBackgroundModeChange(mode: string) {
    if (mode === this.backgroundMode) {
      return;
    }

    this.backgroundImage = [];

    let backgroundImage = null;
    let backgroundColor = '';
    let backgroundGradient = '';

    if (mode === 'image') {
      this.backgroundMode = 'image';
      backgroundImage = this.theme?.layout?.backgroundImage || null;
    } else if (mode === 'color') {
      this.backgroundMode = 'color';
      backgroundColor = this.theme?.layout?.backgroundColor || '';
    } else if (mode === 'gradient') {
      this.backgroundMode = 'gradient';
      backgroundGradient = this.theme?.layout?.backgroundGradient || '';
    }

    this.appearanceForm?.patchValue(
      {
        layout: {
          backgroundImage,
          backgroundColor,
          backgroundGradient
        }
      },
      { emitEvent: false }
    );
  }

  handleBackgroundImageChange(updatedBackgroundImage: IImage[]) {
    this.backgroundImage = updatedBackgroundImage;
    const backgroundImage = updatedBackgroundImage[0];
    this.appearanceForm.markAsTouched();
    this.appearanceForm.markAsDirty();
    this.appearanceForm.patchValue({ layout: { backgroundImage } });
  }

  handleFavIconChange(updatedFavIcon: IImage[]) {
    this.favIcon = updatedFavIcon;
    const favicon = updatedFavIcon[0]?.url || '';
    this.appearanceForm.markAsTouched();
    this.appearanceForm.markAsDirty();
    this.appearanceForm.patchValue({ metadata: { favicon } });
  }

  handleFontSizeChange(fontSize, itemIdentifier) {
    const control = this.appearanceForm.get('fonts')?.get(itemIdentifier);
    if (control) {
      const currentFont: IFont = {
        ...control.value,
        fontSize: +fontSize
      };
      control.markAsTouched();
      control.markAsDirty();
      control.patchValue(currentFont);
    }
  }

  handleButtonTypeChange(type) {
    this.selectedButtonType = type;
  }

  handleCardTypeChange(type) {
    this.selectedCardType = type;
  }

  async onImportButtonClick(itemIdentifier: string) {
    const font = await this._themeCustomizerService.importCustomFont();
    if (font) {
      const existingFonts = this.availableFonts.filter(
        (f) =>
          f?.importUrl === font?.importUrl ||
          f?.displayName === font?.displayName
      );
      if (!existingFonts?.length) {
        this.availableFonts.unshift(font);
      }
      this.appearanceForm.markAsDirty();
      this.appearanceForm.patchValue({ fonts: { [itemIdentifier]: font } });
    }
  }

  getCustomCardDisplayStyle(index: number): string {
    return index.toString() === this.selectedCardType ? 'block' : 'none';
  }

  async onAddCardButtonClick() {
    const cardName = await this._themeCustomizerService.addCardStyle();
    const formArray = this.appearanceForm.get(
      'customCards'
    ) as UntypedFormArray;

    if (cardName && formArray) {
      formArray.push(
        this._formBuilder.group({
          name: [cardName, []],
          borderRadius: [0, Validators.maxLength(16)],
          borderWidth: [1, Validators.maxLength(16)],
          dropShadow: [undefined, Validators.maxLength(30)],
          backgroundColor: [undefined, Validators.maxLength(30)],
          textColor: [undefined, Validators.maxLength(30)],
          borderColor: [undefined, Validators.maxLength(30)]
        })
      );
      this.selectedCardType = (formArray.length - 1).toString();
      this.appearanceForm.markAsDirty();
    }
  }
}
