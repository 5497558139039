import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ContentType,
  SegmentFilterType,
  SegmentFilterTypes,
  SegmentFilter,
  ContentSearchResults,
  Content,
  DeliveryType,
  ContactListSearchResults,
  IContactListSearchResultNode,
  isCustomView,
  SingleSendSearchResults,
  truncateStringDisplay,
  LandingPage
} from 'models';

@Component({
  selector: 'norby-filter-list',
  templateUrl: './norby-filter-list.component.html',
  styleUrls: ['./norby-filter-list.component.less']
})
export class NorbyFilterListComponent {
  @Input() contentFilterOptions: ContentSearchResults[];
  @Input() contactListFilterOptions: ContactListSearchResults[];
  @Input() engagementFilterOptions: SingleSendSearchResults[];
  @Input() landingPageFilterOptions: LandingPage[];
  @Input() tagText: string;
  @Input() deliveryType: DeliveryType = 'email';
  @Input() isSearchingRecipient: boolean;
  @Input() isEventUpdates: boolean;

  @Output() mouseOnDropdown: EventEmitter<boolean> = new EventEmitter();
  @Output() onAddFilter = new EventEmitter<SegmentFilter>();

  filterTypes = SegmentFilterTypes;
  content: Content;
  sendSearchResult: SingleSendSearchResults;

  constructor() {}

  getContentIcon(contentType: ContentType): string {
    return contentType === 'event' ? 'calendar' : 'edit';
  }

  getMessageIcon(deliveryType: DeliveryType): string {
    return deliveryType === 'email' ? 'mail' : 'message-circle';
  }

  getCount(option: ContentSearchResults, type: string) {
    const content = option.content['_' + type];
    const totalEmailMarketing = content._totalEmailMarketing || 0;
    const totalSmsMarketing = content._totalSmsMarketing || 0;
    const total = content._total;
    return this.deliveryType === 'sms'
      ? totalSmsMarketing
      : this.deliveryType === 'email'
        ? totalEmailMarketing
        : total;
  }

  getToolTip(item: {
    _total: number;
    _totalEmailMarketing: number;
    _totalSmsMarketing: number;
  }): string {
    if (!this.deliveryType) {
      return '';
    }

    const totalEmailMarketing = item._totalEmailMarketing || '0';
    const totalSmsMarketing = item._totalSmsMarketing || '0';
    const total = item._total;
    const deliveryType =
      this.deliveryType === 'email' ? 'email marketing' : 'SMS marketing';

    return total === 0
      ? `0 opted into ${deliveryType}`
      : `${
          this.deliveryType === 'email'
            ? totalEmailMarketing
            : totalSmsMarketing
        }/${total} opted into ${deliveryType}`;
  }

  getEngagementTooltip(option: SingleSendSearchResults) {
    if (!this.deliveryType) {
      return '';
    }
    if (
      option?.send?.deliveryType === 'sms' &&
      option?.embeddedLinks?.length === 0
    ) {
      return 'This message did not contain embedded links';
    } else {
      return '';
    }
  }

  truncateStringDisplay(input: string) {
    return truncateStringDisplay(input);
  }

  handleChildDropdownMouseEvent(event) {
    this.mouseOnDropdown.emit(event);
  }

  handleCustomizeContentFilter(option: ContentSearchResults) {
    this.content = option.content;
  }

  handleCustomizeEngagementFilter(option: SingleSendSearchResults) {
    this.sendSearchResult = option;
  }

  handleAddTagFilter() {
    const filter = SegmentFilter.fromObject({
      type: SegmentFilterTypes.TAGGED_CONTACTS,
      tag: this.tagText
    });
    this.onAddFilter.emit(filter);
  }

  handleAddNotTaggedFilter() {
    const filter = SegmentFilter.fromObject({
      type: SegmentFilterTypes.NOT_TAGGED_CONTACTS,
      tag: this.tagText
    });
    this.onAddFilter.emit(filter);
  }

  handleAddFilter(filter: SegmentFilter) {
    this.onAddFilter.emit(filter);
  }

  handleAddContactList(list: IContactListSearchResultNode) {
    if (!isCustomView(list) || list.visibility !== 'public') {
      return;
    }

    const filter = SegmentFilter.fromObject({
      type: SegmentFilterTypes.CONTACT_LIST,
      contactListName: list.name,
      contactListId: list.id
    });

    this.onAddFilter.emit(filter);
  }

  handleAddBasicContentFilter(
    option: ContentSearchResults,
    type: SegmentFilterType
  ) {
    this.content = option.content;

    const filter = SegmentFilter.fromObject({
      type,
      contentId: this.content.contentId,
      contentTitle: this.content.title
    });

    this.onAddFilter.emit(filter);
  }

  handleAddBasicEngagementFilter(
    option: SingleSendSearchResults,
    type: SegmentFilterType
  ) {
    this.sendSearchResult = option;

    const filter = SegmentFilter.fromObject({
      type,
      sendId: option.send.id,
      singleSendDisplay: truncateStringDisplay(
        option.send.subject ||
          this.sendSearchResult?.send?.firstTextBlockContent ||
          ''
      )
    });

    this.onAddFilter.emit(filter);
  }

  handleAddLandingPageFilter(option: LandingPage, type: SegmentFilterType) {
    const filter = SegmentFilter.fromObject({
      type,
      landingPageId: option.id,
      landingPageTitle: option.title
    });

    this.onAddFilter.emit(filter);
  }
}
