import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// 3rd party
import { map } from 'rxjs';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

// Libs
import {
  BaseComponent,
  IconService,
  MessageService,
  MessageType,
  MockAnalyticsService,
  MockContentModalService,
  MockFileUploadService,
  rootPlus
} from 'uikit';
import {
  ClickedUpsellProductAnalyticsLocations,
  ClickedUpsellProductAnalyticsTypes,
  ContentNotificationType,
  DeliveryType,
  ContentEvent,
  ContentEventMetadata,
  INotificationDTO,
  ISendTestMessageContentData,
  ISlug,
  NotificationDefaults,
  ProductAnalyticsEventTypes,
  EventMetadataChanges,
  OFFSET_UNITS,
  MINUTES,
  HOURS,
  DAYS,
  compareEventsMetadata,
  CONTENT_EVENTS,
  INotification,
  MmsAttachment,
  MMS_ATTACHMENT_LIMIT,
  ACCEPTED_MMS_MIME_TYPES_STR
} from 'models';
import { THEME_CUSTOMIZER_EVENT_DETAIL_SECTIONS } from '../../../organisms/norby-theme-customizer';
import { MockDrawerService } from '../../../../services';

@Component({
  selector: 'lib-event-notifications-step',
  templateUrl: './event-notifications-step.component.html',
  styleUrls: ['./event-notifications-step.component.less']
})
export class EventNotificationsStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() event: ContentEvent;
  @Input() eventMetadata: ContentEventMetadata;
  @Input() notificationDefaults: NotificationDefaults;
  @Input() slug: ISlug;
  @Input() authedUserHasPhoneNumber: boolean = false;
  @Input() confirmationHideAiSMSPopup = false;
  @Input() eventReminderHideAiSMSPopup = false;
  @Input() eventStartHideAiSMSPopup = false;

  @Output() onToggleAiSMSPopup = new EventEmitter<string>();
  @Output() onUpdatedEventMetadata = new EventEmitter<EventMetadataChanges>();
  @Output() onComponentLoaded = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  items: any[] = [];
  eventReminderOffsetQt: number = 1;
  eventReminderOffsetUnitsMultiplier: number = -1;
  isUploading = false;
  attachmentFileListRsvpConfirmation: NzUploadFile[] = [];
  attachmentFileListEventReminder: NzUploadFile[] = [];
  attachmentFileListEventStart: NzUploadFile[] = [];
  hasAnyFileOperationHappened = false;
  showingPopup: boolean[] = [false];
  shouldDisableSmsFields = false;
  activeTabIndex: number = 0;

  // upsell analytics
  readonly UPSELL_EVENT = ProductAnalyticsEventTypes.CLICKED_UPSELL;
  readonly UPSELL_CUSTOM_FIELDS_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.EVENT_CUSTOM_FIELDS,
    cta: 'Upgrade your plan to use custom fields.'
  };
  readonly UPSELL_KEYWORD_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.EVENT_KEYWORDS,
    cta: 'Upgrade your plan to use keywords.'
  };
  readonly UPSELL_NOTIFICATION_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location: ClickedUpsellProductAnalyticsLocations.EVENT_NOTIFICATIONS,
    cta: 'Upgrade your plan to use custom notifications.'
  };

  readonly THEME_CUSTOMIZER_SECTIONS = THEME_CUSTOMIZER_EVENT_DETAIL_SECTIONS;
  readonly OFFSET_UNITS = OFFSET_UNITS;
  readonly UPSELL_ATTACHMENTS_PROPERTIES = {
    type: ClickedUpsellProductAnalyticsTypes.UPGRADE,
    location:
      ClickedUpsellProductAnalyticsLocations.EVENT_NOTIFICATIONS_ATTACHMENTS_TOOLTIP,
    cta: 'Upgrade your plan to send attachments.'
  };
  readonly ATTACHMENT_LIMIT = MMS_ATTACHMENT_LIMIT;
  readonly ATTACHMENT_MIME_TYPES = ACCEPTED_MMS_MIME_TYPES_STR;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _contentModal: MockContentModalService,
    private _drawer: MockDrawerService,
    private _iconService: IconService,
    private _analytics: MockAnalyticsService,
    private _message: MessageService,
    private _cdr: ChangeDetectorRef,
    private _upload: MockFileUploadService
  ) {
    super();
    this._iconService.registerIcons([rootPlus]);
  }

  get sendEmailConfirmation(): boolean {
    return this.formGroup?.get('confirmationMedium')?.value === 'email';
  }

  get sendEventStartEmail(): boolean {
    return this.formGroup?.get('eventStartMedium')?.value === 'email';
  }

  get sendEventReminderEmail(): boolean {
    return this.formGroup?.get('eventReminderMedium')?.value === 'email';
  }

  get sendSmsConfirmation(): boolean {
    return this.formGroup?.get('confirmationMedium')?.value === 'sms';
  }

  get sendEventStartSms(): boolean {
    return this.formGroup?.get('eventStartMedium')?.value === 'sms';
  }

  get sendEventReminderSms(): boolean {
    return this.formGroup?.get('eventReminderMedium')?.value === 'sms';
  }

  get collectEmail(): boolean {
    return this.event.privateUserInfoRequirements?.email?.required;
  }

  get collectPhone(): boolean {
    return this.event.privateUserInfoRequirements?.phoneNumber?.required;
  }

  get isIrl(): boolean {
    return this.event.contentLocationType === 'irl';
  }

  get smsEnabledForEvent(): boolean {
    return !!this.event?.privateUserInfoRequirements?.phoneNumber?.required;
  }

  get emailEnabledForEvent(): boolean {
    return !!this.event?.privateUserInfoRequirements?.email?.required;
  }

  get disableSendTest(): boolean {
    return !this.formGroup.valid || !this.event.title;
  }

  get confirmationBodySms(): string {
    return this.formGroup.get('confirmationBodySms').value;
  }

  get eventStartBodySms(): string {
    return this.formGroup.get('eventStartBodySms').value;
  }

  get eventReminderBodySms(): string {
    return this.formGroup.get('eventReminderBodySms').value;
  }

  get eventStartSmsTriggered(): boolean {
    return (
      this.eventMetadata?.notificationDeliveryHistory?.eventStart?.sms
        ?.delivered || false
    );
  }

  get eventStartEmailTriggered(): boolean {
    return (
      this.eventMetadata?.notificationDeliveryHistory?.eventStart?.sms
        ?.delivered || false
    );
  }

  get eventReminderSmsTriggered(): boolean {
    return (
      this.eventMetadata?.notificationDeliveryHistory?.eventReminder?.sms
        ?.delivered || false
    );
  }

  get eventReminderEmailTriggered(): boolean {
    return (
      this.eventMetadata?.notificationDeliveryHistory?.eventReminder?.email
        ?.delivered || false
    );
  }

  get eventStartTriggered(): boolean {
    return this.eventStartSmsTriggered || this.eventStartEmailTriggered;
  }

  get eventReminderTriggered(): boolean {
    return this.eventReminderSmsTriggered || this.eventReminderEmailTriggered;
  }

  ngOnInit(): void {
    this._initForm();
    this._updateAttachments('rsvpConfirmation');
    this._updateAttachments('eventReminder');
    this._updateAttachments('eventStart');
    this.onComponentLoaded.emit();
  }

  private _initForm() {
    const notifications = this._buildNotificationsMap(
      this.eventMetadata?.notifications
    );

    this._initTimeUnits(notifications);

    this.formGroup = this._formBuilder.group({
      confirmationMedium: [notifications?.rsvpConfirmationMedium],
      attachCalendarInvite: [
        notifications?.rsvpConfirmationAttachCalendarInvite
      ],
      confirmationSubject: [notifications?.rsvpConfirmationSubject],
      confirmationBodyEmail: [
        notifications?.rsvpConfirmationMedium === 'email'
          ? notifications?.rsvpConfirmationBody
          : ''
      ],
      confirmationBodySms: [
        notifications?.rsvpConfirmationMedium === 'sms'
          ? notifications?.rsvpConfirmationBody
          : ''
      ],
      confirmationOffset: [0],
      eventStartMedium: [notifications?.eventStartMedium],
      eventStartSubject: [notifications?.eventStartSubject],
      eventStartBodyEmail: [
        notifications?.eventStartMedium === 'email'
          ? notifications?.eventStartBody
          : ''
      ],
      eventStartBodySms: [
        notifications?.eventStartMedium === 'sms'
          ? notifications?.eventStartBody
          : ''
      ],
      eventStartOffset: [0],
      eventReminderMedium: [notifications?.eventReminderMedium],
      eventReminderSubject: [notifications?.eventReminderSubject],
      eventReminderBodyEmail: [
        notifications?.eventReminderMedium === 'email'
          ? notifications?.eventReminderBody
          : ''
      ],
      eventReminderBodySms: [
        notifications?.eventReminderMedium === 'sms'
          ? notifications?.eventReminderBody
          : ''
      ],
      eventReminderOffsetQt: [this.eventReminderOffsetQt],
      eventReminderOffsetUnitsMultiplier: [
        this.eventReminderOffsetUnitsMultiplier
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        map((value) => {
          const notifications = this._extractNotifications();
          return ContentEventMetadata.fromObject({
            ...this.eventMetadata,
            notifications
          });
        }),
        this.takeUntilDestroy
      )
      .subscribe((eventMetadata) => {
        if (!compareEventsMetadata(this.eventMetadata, eventMetadata)) {
          // Once the user touches any notifications field, we consider
          // all notifications user controlled and we disable backend
          // management of notification defaults
          if (eventMetadata.managedNotifications) {
            eventMetadata.updateProperties({ managedNotifications: false });
          }

          this.onUpdatedEventMetadata.emit({
            isDirty: this.formGroup.dirty,
            isNotificationsStepValid: this.formGroup.valid,
            eventMetadata
          });
        }
      });
  }

  private _initTimeUnits(notifications) {
    this.eventReminderOffsetUnitsMultiplier =
      notifications.eventReminderOffset <= -1440
        ? -1440
        : notifications.eventReminderOffset <= -60
          ? -60
          : -1;

    this.eventReminderOffsetQt =
      notifications.eventReminderOffset /
      this.eventReminderOffsetUnitsMultiplier;

    this.handleOffsetUnitsChange(
      this.eventReminderOffsetUnitsMultiplier,
      this.eventReminderOffsetQt
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const notifications = this._buildNotificationsMap(
      this.eventMetadata?.notifications
    );

    this._initTimeUnits(notifications);

    this.formGroup?.patchValue(
      {
        confirmationMedium: notifications?.rsvpConfirmationMedium,
        attachCalendarInvite:
          notifications?.rsvpConfirmationAttachCalendarInvite,
        confirmationSubject: notifications?.rsvpConfirmationSubject,
        confirmationBodyEmail: notifications?.rsvpConfirmationBody,
        confirmationBodySms: this.shouldDisableSmsFields
          ? this.notificationDefaults?.rsvpConfirmation?.sms?.body
          : notifications?.rsvpConfirmationBody,
        confirmationOffset: 0,
        eventStartMedium: notifications?.eventStartMedium,
        eventStartSubject: notifications?.eventStartSubject,
        eventStartBodyEmail: notifications?.eventStartBody,
        eventStartBodySms: this.shouldDisableSmsFields
          ? this.notificationDefaults?.eventStart?.sms?.body
          : notifications?.eventStartBody,
        eventStartOffset: 0,
        eventReminderMedium: notifications?.eventReminderMedium,
        eventReminderSubject: notifications?.eventReminderSubject,
        eventReminderBodyEmail: notifications?.eventReminderBody,
        eventReminderBodySms: this.shouldDisableSmsFields
          ? this.notificationDefaults?.eventReminder?.sms?.body
          : notifications?.eventReminderBody,
        eventReminderOffsetQt: [this.eventReminderOffsetQt],
        eventReminderOffsetUnitsMultiplier: [
          this.eventReminderOffsetUnitsMultiplier
        ]
      },
      { emitEvent: false }
    );
  }

  private _buildNotificationsMap(notifications?: INotificationDTO[]) {
    const defaultSettings = {
      rsvpConfirmationMedium: 'none',
      rsvpConfirmationSubject: '',
      rsvpConfirmationBody: '',
      rsvpConfirmationOffset: 0,
      rsvpConfirmationAttachCalendarInvite: true,
      eventStartMedium: 'none',
      eventStartSubject: '',
      eventStartBody: '',
      eventStartOffset: 0,
      eventReminderMedium: 'none',
      eventReminderSubject: '',
      eventReminderBody: '',
      eventReminderOffset: -60
    };

    if (!notifications) {
      return defaultSettings;
    }

    return notifications.reduce(
      (prev, curr) => ({
        ...prev,
        [curr?.type + 'Medium']: curr?.deliveryType,
        [curr?.type + 'Subject']: curr?.subject,
        [curr?.type + 'Body']: curr?.message,
        [curr?.type + 'Offset']: curr?.offset,
        [curr?.type + 'AttachCalendarInvite']: curr?.attachCalendarInvite
      }),
      defaultSettings
    );
  }

  async sendTest(type: DeliveryType, eventType: ContentNotificationType) {
    const notification = this._extractNotifications()?.find(
      (n) => n.deliveryType === type && n.type === eventType
    );
    if (!notification) return;

    const contentMetadata = ContentEvent.fromObject({
      ...this.event,
      ...this.formGroup.value
    }).toCreateDTO(this.eventMetadata);

    const payload: ISendTestMessageContentData = {
      contentMetadata,
      notification,
      messageType: 'content',
      contentId: this.event?.contentId
    };

    if (type === 'email') {
      this._contentModal.launchContentTestFormDialog(payload);
    } else {
      const hasNumber =
        this.authedUserHasPhoneNumber ||
        (await this._drawer.launchAddUserPhoneNumberDialog());
      if (hasNumber) {
        this._contentModal.launchContentTestFormDialog(payload);
      }
    }
  }

  handleTogglePopup(controlName: string) {
    this.onToggleAiSMSPopup.emit(controlName);
  }

  handleShortenedMessage(userSMS: string, controlName: string) {
    const control = this.formGroup.get(controlName + 'BodySms');
    if (control) {
      control.setValue(userSMS);
      this.onToggleAiSMSPopup.emit(controlName);
    }
  }

  handleTabChange(tabIndex: number) {
    this.activeTabIndex = tabIndex;
  }

  // Marshal form fields into notifications payload for create/update
  private _extractNotifications(): INotificationDTO[] {
    const {
      confirmationMedium,
      confirmationSubject,
      confirmationBodyEmail,
      confirmationBodySms,
      confirmationOffset,
      eventStartMedium,
      eventStartSubject,
      eventStartBodyEmail,
      eventStartBodySms,
      eventStartOffset,
      eventReminderMedium,
      eventReminderSubject,
      eventReminderBodyEmail,
      eventReminderBodySms,
      eventReminderOffsetQt,
      eventReminderOffsetUnitsMultiplier,
      attachCalendarInvite
    } = this.formGroup.value;

    const notifications = new Array<INotificationDTO>();

    if (confirmationMedium === 'email' || confirmationMedium === 'sms') {
      let notification: INotificationDTO = {
        type: 'rsvpConfirmation',
        offset: confirmationOffset || 0,
        deliveryType: confirmationMedium,
        message:
          confirmationMedium === 'email'
            ? confirmationBodyEmail
            : confirmationBodySms,
        subject: confirmationSubject,
        attachCalendarInvite
      };
      if (confirmationMedium === 'sms') {
        notification.attachments =
          this.eventMetadata.notifications?.find(
            (n) => n.type === notification.type
          )?.attachments || [];
      }
      notifications.push(notification);
    }

    if (eventStartMedium === 'email' || eventStartMedium === 'sms') {
      let notification: INotificationDTO = {
        type: 'eventStart',
        offset: eventStartOffset || 0,
        deliveryType: eventStartMedium,
        message:
          eventStartMedium === 'email'
            ? eventStartBodyEmail
            : eventStartBodySms,
        subject: eventStartSubject
      };
      if (eventStartMedium === 'sms') {
        notification.attachments =
          this.eventMetadata.notifications?.find(
            (n) => n.type === notification.type
          )?.attachments || [];
      }
      notifications.push(notification);
    }

    if (eventReminderMedium === 'email' || eventReminderMedium === 'sms') {
      let notification: INotificationDTO = {
        type: 'eventReminder',
        offset: eventReminderOffsetQt * eventReminderOffsetUnitsMultiplier || 0,
        deliveryType: eventReminderMedium,
        message:
          eventReminderMedium === 'email'
            ? eventReminderBodyEmail
            : eventReminderBodySms,
        subject: eventReminderSubject
      };
      if (eventReminderMedium === 'sms') {
        notification.attachments =
          this.eventMetadata.notifications?.find(
            (n) => n.type === notification.type
          )?.attachments || [];
      }
      notifications.push(notification);
    }

    return notifications;
  }

  handleOffsetUnitsChange(event, qt?: number) {
    switch (+event) {
      case -1:
        this.items = MINUTES;
        break;
      case -60:
        this.items = HOURS;
        break;
      case -1440:
        this.items = DAYS;
        break;
    }
    this.eventReminderOffsetQt = qt || 1;
  }

  handleConfirmationMediumChange(medium) {
    const defaults = this.notificationDefaults?.rsvpConfirmation;
    if (defaults) {
      this.formGroup.patchValue({
        confirmationSubject: defaults.email.subject,
        confirmationBodyEmail: defaults.email.body,
        confirmationBodySms: defaults.sms.body
      });
    }
  }

  handleEventStartMediumChange(medium) {
    const defaults = this.notificationDefaults?.eventStart;
    if (defaults) {
      this.formGroup.patchValue({
        eventStartSubject: defaults.email.subject,
        eventStartBodyEmail: defaults.email.body,
        eventStartBodySms: defaults.sms.body
      });
    }
  }

  handleEventReminderMediumChange(medium) {
    const defaults = this.notificationDefaults?.eventReminder;
    if (defaults) {
      this.formGroup.patchValue({
        eventReminderSubject: defaults.email.subject,
        eventReminderBodyEmail: defaults.email.body,
        eventReminderBodySms: defaults.sms.body
      });
    }
  }

  private _updateAttachments(notificationType: string) {
    const attachments: NzUploadFile[] =
      this.eventMetadata.notifications
        ?.find((notification) => notification.type === notificationType)
        ?.attachments?.map((attachment, idx) => ({
          uid: `${idx}`,
          name: `Attachment ${idx + 1}`,
          status: 'done',
          url: attachment.source,
          size: attachment.contentSize,
          type: attachment.contentType
        })) ?? [];

    this._setAttachmentFileList(notificationType, attachments);
    this._cdr.detectChanges();
  }

  private _setAttachmentFileList(
    notificationType: string,
    attachments: NzUploadFile[]
  ) {
    switch (notificationType) {
      case 'rsvpConfirmation':
        this.attachmentFileListRsvpConfirmation = attachments;
        break;
      case 'eventReminder':
        this.attachmentFileListEventReminder = attachments;
        break;
      case 'eventStart':
        this.attachmentFileListEventStart = attachments;
        break;
      default:
        throw new Error(`Invalid notification type: ${notificationType}`);
    }
  }

  // Called by file picker event emitter in UI
  uploadFileRsvpConfirmation = (file: NzUploadFile): boolean => {
    return this._completeFileUpload(file, 'rsvpConfirmation');
  };

  uploadFileEventStart = (file: NzUploadFile): boolean => {
    return this._completeFileUpload(file, 'eventStart');
  };

  uploadFileEventReminder = (file: NzUploadFile): boolean => {
    return this._completeFileUpload(file, 'eventReminder');
  };

  private _completeFileUpload(
    file: NzUploadFile,
    notificationType: string
  ): boolean {
    this.hasAnyFileOperationHappened = true;
    const filename = file.name;
    let updatedAttachmentFileList = [];

    switch (notificationType) {
      case 'rsvpConfirmation':
        updatedAttachmentFileList =
          this.attachmentFileListRsvpConfirmation.concat(file);
        break;
      case 'eventReminder':
        updatedAttachmentFileList =
          this.attachmentFileListEventReminder.concat(file);
        break;
      case 'eventStart':
        updatedAttachmentFileList =
          this.attachmentFileListEventStart.concat(file);
        break;
      default:
        throw new Error(`Invalid notification type: ${notificationType}`);
    }

    const errorMessage = this._upload.fileSizeValidation(
      file,
      updatedAttachmentFileList
    );

    if (errorMessage) {
      this._message.show({
        text: errorMessage,
        type: MessageType.ERROR
      });
      return;
    }

    this.isUploading = true;
    this._cdr.detectChanges();

    this._upload
      .uploadFile(file as any)
      .then((source) => {
        this._analytics.track(CONTENT_EVENTS.userUploadedMmsAttachmentsToEvent);

        const remoteFile: MmsAttachment = {
          source,
          filename,
          sourceType: 'url',
          contentType: file.type || 'application/octet-stream',
          contentSize: file.size
        };

        const notificationToUpdate: INotification =
          this.eventMetadata.notifications?.find(
            (notification) => notification.type === notificationType
          );

        if (notificationToUpdate) {
          const updatedNotification: INotification = {
            ...notificationToUpdate,
            attachments: [
              ...(notificationToUpdate.attachments || []),
              remoteFile
            ]
          };

          const idxToUpdate: number =
            this.eventMetadata.notifications?.findIndex(
              (notification) => notification.type === notificationType
            );

          const eventMetadataCopy: ContentEventMetadata =
            this.eventMetadata.clone();

          eventMetadataCopy.notifications.splice(
            idxToUpdate,
            1,
            updatedNotification
          );

          const eventMetadata: ContentEventMetadata =
            ContentEventMetadata.fromObject({
              ...this.eventMetadata,
              notifications: eventMetadataCopy.notifications,
              managedNotifications: false
            });

          this.onUpdatedEventMetadata.emit({
            isDirty: this.formGroup.dirty || this.hasAnyFileOperationHappened,
            isNotificationsStepValid: this.formGroup.valid,
            eventMetadata
          });

          this._updateAttachments(notificationType);
        }
      })
      .catch((e) => {
        this._message.show({
          text: 'There was an error uploading your attachment.',
          type: MessageType.ERROR
        });
        return null;
      })
      .finally(() => {
        this.isUploading = false;
        this._cdr.detectChanges();
      });

    return false;
  }

  handleUpdatedSmsFieldStatus(disabledMessage: string) {
    this.shouldDisableSmsFields = !!disabledMessage;

    if (this.notificationDefaults && this.shouldDisableSmsFields) {
      const { rsvpConfirmation, eventReminder, eventStart } =
        this.notificationDefaults;

      this.formGroup.patchValue({
        confirmationBodySms: rsvpConfirmation.sms.body,
        eventReminderBodySms: eventReminder.sms.body,
        eventStartBodySms: eventStart.sms.body
      });
    }
  }

  /**
   * Handles File Removals
   */
  handleAttachmentUploadChange(
    { fileList }: NzUploadChangeParam,
    notificationType: string
  ): void {
    this.hasAnyFileOperationHappened = true;

    const attachments: MmsAttachment[] = fileList
      ?.filter((file) => file.status === 'done')
      ?.map((file) => ({
        source: file.url,
        sourceType: 'url',
        contentType: file.type || 'application/octet-stream',
        contentSize: file.size
      }));

    const notificationToUpdate: INotification =
      this.eventMetadata.notifications?.find(
        (notification) => notification.type === notificationType
      );

    if (notificationToUpdate) {
      const updatedNotification: INotification = {
        ...notificationToUpdate,
        attachments
      };

      const idxToUpdate: number = this.eventMetadata.notifications?.findIndex(
        (notification) => notification.type === notificationType
      );

      const eventMetadataCopy: ContentEventMetadata =
        this.eventMetadata.clone();

      eventMetadataCopy.notifications.splice(
        idxToUpdate,
        1,
        updatedNotification
      );

      const eventMetadata: ContentEventMetadata =
        ContentEventMetadata.fromObject({
          ...this.eventMetadata,
          notifications: eventMetadataCopy.notifications
        });

      this.onUpdatedEventMetadata.emit({
        isDirty: this.formGroup.dirty || this.hasAnyFileOperationHappened,
        isNotificationsStepValid: this.formGroup.valid,
        eventMetadata
      });

      this._updateAttachments(notificationType);
      this._cdr.detectChanges();
    }
  }

  handleShowingPopup(showingPopup: boolean, tab: string) {
    this.showingPopup[tab] = showingPopup;
  }
}
