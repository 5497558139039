<label
  *ngIf="label"
  class="form-label inline-block mb-1 text-lg text-neutral-800"
>
  {{ label }}
</label>

<norby-configurable-selector
  key="id"
  label="name"
  [selectorType]="NORBY_SELECTOR_TYPES_ENUM.THEME_SELECTOR"
  [placeholder]="placeholder"
  [items]="themes"
  [value]="val"
  [isDisabled]="isDisabled"
  [optionHeightPx]="85"
  [optionOverflowSize]="4"
  [optionTemplate]="optionTemplate"
  (onValueChange)="handleChange($event)"
></norby-configurable-selector>

<ng-template #optionTemplate let-item>
  <div class="flex flex-row py-2">
    <div>
      <img
        class="object-cover w-27 h-15 rounded"
        [src]="item.responsiveImage?.thumbnail?.url"
      />
    </div>
    <div class="flex flex-col px-2 justify-center overflow-hidden">
      <span class="text-sm truncate">{{ item.name }}</span>
    </div>
    <div class="flex-grow"></div>
  </div>
</ng-template>
