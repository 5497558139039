import { Component, EventEmitter, Input, Output } from '@angular/core';
import { plainToInstance } from 'class-transformer';

// Lib
import { ISlug, SocialIconsPageBlock, SocialIconsSettings } from 'models';

@Component({
  selector: 'norby-edit-social-icons-block',
  templateUrl: './norby-edit-social-icons-block.component.html',
  styleUrls: ['./norby-edit-social-icons-block.component.less']
})
export class NorbyEditSocialIconsBlockComponent {
  @Input() selectedBlock: SocialIconsPageBlock;
  @Input() slug: ISlug;
  @Output() onUpdatedBlock: EventEmitter<SocialIconsPageBlock> =
    new EventEmitter<SocialIconsPageBlock>();

  links: SocialIconsSettings[];

  constructor() {}

  handleUpdatedLinks(settingsV2: SocialIconsSettings[]) {
    console.log('links', settingsV2);
    this.onUpdatedBlock.emit(
      plainToInstance(SocialIconsPageBlock, {
        ...this.selectedBlock,
        settingsV2
      })
    );
  }
}
