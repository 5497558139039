import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzColorPickerModule } from 'ng-zorro-antd/color-picker';

import { NorbyDropShadowPickerComponent } from '../components/molecules/norby-drop-shadow-picker';
import { NorbyDropShadowSlidersModule } from './norby-drop-shadow-sliders.module';
import {
  NorbyButtonModule,
  NorbyFormcontrolLabelModule,
  NorbyHelpertextModule,
  NorbyInputBaseModule
} from 'uikit';

@NgModule({
  declarations: [NorbyDropShadowPickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyFormcontrolLabelModule,
    NorbyDropShadowSlidersModule,
    NorbyHelpertextModule,
    NorbyInputBaseModule,
    NorbyButtonModule,
    NzColorPickerModule
  ],
  exports: [NorbyDropShadowPickerComponent]
})
export class NorbyDropShadowPickerModule {}
