import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { InboxContactDetailsComponent } from '../components/inbox-contact-details';
import { NoteViewModule } from 'customer-uikit';
import { IconModule } from 'uikit';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [InboxContactDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    NoteViewModule,
    IconModule,

    NzAvatarModule,
    NzButtonModule,
    NzCheckboxModule,
    NzDividerModule,
    NzDropDownModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzPopconfirmModule,
    NzSpinModule,
    NzSwitchModule,
    NzTagModule,
    NzToolTipModule,
    NzUploadModule
  ],
  exports: [InboxContactDetailsComponent]
})
export class InboxContactDetailsModule {}
