import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';

import { BaseComponent } from 'uikit';
import { ISlug, Platform, SingleSend } from 'models';

@Component({
  selector: 'app-sends-preview',
  templateUrl: './sends-preview.component.html',
  styleUrls: ['./sends-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendsPreviewComponent
  extends BaseComponent
  implements OnChanges, DoCheck, AfterContentInit
{
  @Input() send: SingleSend;
  @Input() slug: ISlug;
  @Input() platform: Platform = 'mobile';
  @Input() showAsAbstract = false;
  @Input() isInteractive = true;
  @Input() isScrollable = true;

  backgroundCss: string;
  height: string;
  isBrowser = false;

  private _cachedPageHash: number;

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit(): void {
    this.isBrowser = isPlatformBrowser(this._platform);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const didUpdateBackground = this._didUpdateBackground();

    if (didUpdateBackground) {
      this._cdr.detectChanges();
    }
  }

  ngDoCheck() {
    if (this.send && this.send.hash !== this._cachedPageHash) {
      this._didUpdateBackground();
      this._cachedPageHash = this.send.hash;
      this._cdr.markForCheck();
    }
  }

  private _didUpdateBackground(): boolean {
    // TODO: update background when themes are on Sends
    const oldCss = this.backgroundCss;
    return oldCss !== this.backgroundCss;
  }
}
