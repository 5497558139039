import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

// Lib
import {
  FormModule,
  NorbyButtonModule,
  NorbyCollapsibleGroupModule,
  NorbyCollapsibleModule,
  NorbyFormcontrolLabelModule,
  NorbyIconButtonModule,
  NorbyIconModule,
  NorbyInputModule,
  NorbyNumberInputModule,
  NorbySelectModule,
  NorbySwitchButtonModule
} from 'uikit';
import { SignupMonetizationStepComponent } from '../components/templates/signups/signup-monetization-step';

@NgModule({
  declarations: [SignupMonetizationStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    NorbyButtonModule,
    NorbySwitchButtonModule,
    NorbyFormcontrolLabelModule,
    NorbyInputModule,
    NorbySelectModule,
    NorbyIconModule,
    NorbyCollapsibleModule,
    NorbyCollapsibleGroupModule,
    NorbyIconButtonModule,
    NorbyNumberInputModule,
    NzFormModule,
    NzInputModule
  ],
  exports: [SignupMonetizationStepComponent]
})
export class SignupMonetizationStepModule {}
