<form [formGroup]="formGroup">
  <div *ngIf="showImageUploader">
    <norby-file-upload
      label="Image"
      [images]="blockImageArray"
      (onUpdatedImages)="handleImageUpdated($event)"
    ></norby-file-upload>
  </div>
  <div class="mb-4">
    <norby-switch-button
      [value]="block.imageEnabled"
      formControlName="imageEnabled"
      helperText="Show footer image"
      (valueChange)="handleChange($event)"
    ></norby-switch-button>
  </div>
  <norby-input
    label="Title"
    type="text"
    formControlName="title"
    [placeholder]="slug?.accountInfo?.title || 'Title'"
  ></norby-input>
  <norby-input
    label="Copyright"
    type="number"
    [placeholder]="year"
    formControlName="copyright"
  ></norby-input>
  <norby-input
    label="Mailing Address"
    type="text"
    placeholder="123 Main Street"
    formControlName="mailingAddress"
  ></norby-input>
  <div class="mb-4">
    <norby-formcontrol-label label="Social links"></norby-formcontrol-label>
    <norby-edit-social-icons
      [socialLinks]="displayedSocialLinks"
      [slug]="slug"
      [dragDisabled]="isMobile"
      (onUpdatedLinks)="handleUpdatedLinks($event)"
    ></norby-edit-social-icons>
  </div>
  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
