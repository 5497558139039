<div *ngIf="currentStage?.type === 'email'" @slideIn>
  <form
    nz-form
    [formGroup]="emailFormGroup"
    nzLayout="vertical"
    (ngSubmit)="submitEmail()"
  >
    <lib-norby-copy-block
      title="Log in"
      subtitle="Please enter your email address."
      color="#140936"
    ></lib-norby-copy-block>

    <nz-form-item class="small-margin-top">
      <input
        placeholder="your@email.com"
        formControlName="email"
        nz-input
        required
        type="email"
      />
    </nz-form-item>

    <button
      [disabled]="!emailFormGroup.valid"
      [nzLoading]="isLoading"
      nz-button
      nzBlock
      nzType="primary"
      type="submit"
    >
      Next
    </button>
  </form>
</div>

<!-- STEP 1B: ENTER CONFIRMATION CODE -->
<div *ngIf="currentStage?.type === 'emailVerification'" @slideIn>
  <form
    nz-form
    [formGroup]="codeFormGroup"
    nzLayout="vertical"
    (ngSubmit)="submitCode()"
  >
    <lib-norby-copy-block
      title="Enter your code"
      subtitle="Please enter the code we just sent to your email."
      color="#140936"
    ></lib-norby-copy-block>

    <nz-form-item class="small-margin-top">
      <nz-input-group [nzSuffix]="resendButton">
        <input
          nz-input
          type="number"
          name="verificationCode"
          formControlName="verificationCode"
          placeholder="Your verification code"
          required
          minLength="6"
          maxLength="6"
        />
      </nz-input-group>

      <ng-template #resendButton>
        <button
          nz-button
          type="button"
          (click)="submitResendCode()"
          nzSize="small"
          nzType="text"
          [nzLoading]="isResendingCode"
        >
          <root-icon name="rotate_ccw"></root-icon>
          Resend
        </button>
      </ng-template>
    </nz-form-item>

    <button
      nz-button
      nzBlock
      [nzLoading]="isLoading"
      nzType="primary"
      [disabled]="!codeFormGroup.valid"
      type="submit"
    >
      Next
    </button>
  </form>
</div>
