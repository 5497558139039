import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterButtonViewComponent } from '../views/filter-button-view';
import { IconModule } from 'uikit';

@NgModule({
  declarations: [FilterButtonViewComponent],
  imports: [CommonModule, IconModule],
  exports: [FilterButtonViewComponent]
})
export class FilterButtonViewModule {}
