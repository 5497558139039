import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';

// Stripe
import {
  Appearance,
  CssFontSource,
  CustomFontSource,
  loadStripe,
  PaymentRequest,
  Stripe,
  StripeCardElement,
  StripeElements,
  StripeElementsOptions
} from '@stripe/stripe-js';

// App
import {
  ContentRegisterable,
  ISlug,
  ITicket,
  STANDARD_UNEXPECTED_ERROR,
  STRIPE_CLIENT_KEY_TOKEN
} from 'models';
import { BaseComponent } from 'uikit';
import { ContentService, SlugServerService } from '../../services';

@Component({
  selector: 'lib-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent extends BaseComponent implements OnInit {
  @Input() content: ContentRegisterable;
  @Input() ticket: ITicket;
  @Input() name: string;
  @Input() isFinalScreen = false;
  @Input() paymentCallback: (success: boolean, error?: any) => {};
  @Input() themeClasses: string[];
  @Input() promoCode: string;

  isLoading = true;
  isSubmitting = false;
  elements: StripeElements;
  card: StripeCardElement;
  cardInputComplete: boolean;
  stripe: Stripe;
  error = '';
  slug: ISlug;
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentIntentSecret: string;
  stripeAccountId: string;
  paymentMode: 'card' | 'gPay' = 'card';
  browserPaymentSetUp: boolean;

  constructor(
    @Inject(STRIPE_CLIENT_KEY_TOKEN) private stripeClientKey: string,
    private _content: ContentService,
    private _slug: SlugServerService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this._slug
      .getCurrentSlug$()
      .pipe(this.takeUntilDestroy)
      .subscribe((slug) => {
        this.slug = slug;
        this._setupPayment();
      });
  }

  // Check if Apple / Google Pay are available
  private async _setupBrowserPay() {
    // Price should always be represented in cents
    const amount = this.content.tickets?.[0].price;
    if (!amount && amount !== 0) throw new Error(STANDARD_UNEXPECTED_ERROR);
    this.isLoading = true;
    this._cdr.detectChanges();

    const paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: this.content.title ?? this.content.subtitle,
        amount
      },
      requestPayerName: true,
      requestPayerEmail: true
    });
    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest
    });
    const result = await paymentRequest.canMakePayment();
    if (result) {
      prButton.mount('#payment-request-button');
      await this._setupPaymentHandler(paymentRequest);
    } else {
      this.paymentMode = 'card';
      // This comes from the Stripe docs
      document.getElementById('payment-request-button').style.display = 'none';
    }
    this.browserPaymentSetUp = true;
    this.isLoading = false;
    this._cdr.detectChanges();
  }

  // Payment Request Button Interactions
  private async _setupPaymentHandler(paymentRequest: PaymentRequest) {
    if (!this.stripe) {
      return;
    }

    const component = this;
    const callback = component.paymentCallback;
    paymentRequest.on('paymentmethod', (ev) => {
      component.stripe
        .confirmCardPayment(
          this.paymentIntentSecret,
          { payment_method: ev.paymentMethod.id },
          // https://stripe.com/docs/payments/payment-intents/verifying-status#next-actions
          { handleActions: true }
        )
        .then((confirmResult) => {
          if (confirmResult.error) {
            component._setError(confirmResult.error.message);
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Let Stripe.js handle the rest of the payment flow.
            component.stripe
              .confirmCardPayment(this.paymentIntentSecret)
              .then((result) => {
                if (result.error) {
                  // The payment failed -- ask your customer for a new payment method.
                  // callback(false, result.error);
                  component._setError(result.error.message ?? '');
                } else {
                  // The payment has succeeded.
                  callback(true);
                }
              });
          }
        });
    });
  }

  handlePaymentModeChanged(value: 'card' | 'gPay') {
    if (value === 'gPay' && !this.browserPaymentSetUp) {
      this._setupBrowserPay();
    }
  }

  handleSetError(error: any) {
    this._setError(error);
  }

  handleClearError() {
    this._clearError();
  }

  private async _setupPayment() {
    if (this.paymentIntentSecret) {
      return;
    }

    // 1) Fetch the secret first. Must be blocking.
    const intent = await this._content.getPaymentIntentSecret(
      this.content.contentId,
      this.ticket?.label,
      this.ticket?.qty,
      this.promoCode
    );

    if (intent) {
      const { secret, accountId } = intent;
      this.stripeAccountId = accountId;
      this.paymentIntentSecret = secret;

      // 2) Blocking setup stripe, blocking.
      await this._setupStripe();
    }

    this.paymentCallback(false, 'Sorry, something went wrong.');
    this.isLoading = false;
    this._cdr.detectChanges();
  }

  private async _setupStripe() {
    return loadStripe(this.stripeClientKey).then((stripe) => {
      this.stripe = stripe;
      const font = this.slug?.theme?.fonts?.font;
      const familyParts = font?.fontFamily?.split('"') ?? [];
      const inferredFamily =
        familyParts.length > 1 ? familyParts[1] : font?.fontFamily;
      const fonts: (CssFontSource | CustomFontSource)[] = font?.isCustom
        ? [
            {
              src: font?.importUrl,
              family: inferredFamily,
              style: 'normal'
            }
          ]
        : font
          ? [
              {
                cssSrc: font?.importUrl
              }
            ]
          : [];
      const appearance: Appearance = {
        variables: {
          colorText: this.slug?.theme?.card?.textColor ?? '#000',
          colorTextPlaceholder: '#C1C4D6'
        }
      };
      const elements = this.stripe.elements({
        locale: 'en',
        fonts,
        appearance
      });
      this.elements = elements;
    });
  }

  private _clearError() {
    const displayError = document.getElementById('card-errors');
    displayError.textContent = '';
    this.isLoading = false;
    this._cdr.detectChanges();
  }

  private _setError(error: string) {
    const displayError = document.getElementById('card-errors');
    displayError.textContent = error ?? '';
    this.isLoading = false;
    this._cdr.detectChanges();
  }
}
