import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingPageViewComponent } from '../views';
import { AdaptableBlockViewModule } from './adaptable-block-view.module';
import { LiveThemedDirectiveModule } from 'uikit';

@NgModule({
  declarations: [LandingPageViewComponent],
  imports: [CommonModule, AdaptableBlockViewModule, LiveThemedDirectiveModule],
  exports: [LandingPageViewComponent]
})
export class LandingPageViewModule {}
