import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NorbyIconModule, NorbyButtonModule } from 'uikit';
import { NorbySegmentPreviewListComponent } from '../components/organisms/norby-segment-preview-list';

@NgModule({
  declarations: [NorbySegmentPreviewListComponent],
  imports: [CommonModule, NorbyButtonModule, NorbyIconModule],
  exports: [NorbySegmentPreviewListComponent]
})
export class NorbySegmentPreviewListModule {}
