import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

import {
  SocialIconsPageBlock,
  SOCIAL_TYPES,
  IAccountInfo,
  SOCIAL_LINKS,
  buildSocialLinksObjectFromKeys
} from 'models';
import {
  rootWebsite,
  rootTwitter,
  rootInstagram,
  rootLinkedin,
  rootFacebook,
  rootYoutube,
  rootTiktok,
  rootSoundcloud,
  rootClubhouse,
  rootTwitch,
  rootSpotify,
  rootApple,
  rootMedium,
  rootGithub,
  rootDribbble,
  rootPatreon,
  rootDiscord,
  rootPinterest,
  rootBandcamp,
  rootVenmo,
  rootCashapp,
  rootLastfm,
  rootPaypal,
  rootThreads,
  rootXSocial
} from '../../../icons';
import { IconService } from '../../services';

@Component({
  selector: 'lib-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.less']
})
export class SocialIconsComponent implements OnChanges {
  @Input() iconOnly = true;
  @Input() filter: SOCIAL_TYPES[];
  @Input() accountInfo: IAccountInfo;
  @Input() block: SocialIconsPageBlock;
  @Input() size: 'small' | 'default' | 'large' = 'large';
  @Input() preventDefault = false;
  @Input() center = true;
  @Input() type: 'text' | 'primary' | 'default' | 'dashed' = 'text';

  @Output() clicked = new EventEmitter<string>();

  links: Array<{ key: string; icon: string; label: string; url: string }>;

  constructor(private _iconService: IconService) {
    this._iconService.registerIcons([
      rootWebsite,
      rootTwitter,
      rootInstagram,
      rootLinkedin,
      rootFacebook,
      rootYoutube,
      rootTiktok,
      rootSoundcloud,
      rootClubhouse,
      rootTwitch,
      rootSpotify,
      rootApple,
      rootMedium,
      rootGithub,
      rootDribbble,
      rootPatreon,
      rootDiscord,
      rootPinterest,
      rootBandcamp,
      rootVenmo,
      rootCashapp,
      rootLastfm,
      rootPaypal,
      rootThreads,
      rootXSocial
    ]);
  }

  handleClick(event, url: string) {
    if (this.preventDefault) event?.preventDefault();
    this.clicked.emit(url);
  }

  ngOnChanges() {
    this.links = Object.keys(SOCIAL_LINKS)
      .filter(
        (link) =>
          this.accountInfo?.links?.[link]?.length > 0 &&
          (!this.filter || this.filter.includes(link as SOCIAL_TYPES))
      )
      .map((link) => buildSocialLinksObjectFromKeys(link, this.accountInfo));
  }
}
