import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

// 3rd party
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  tap
} from 'rxjs';

// Libs
import {
  ProductAnalyticsEventTypes,
  ClickedElementProductAnalyticsElementTypeEnum,
  ClickedElementProductAnalyticsElementCTAEnum,
  calculateTotalMessageSegments,
  calculateTotalCharsPerSegment,
  estimateSegmentCount
} from 'models';
import { BaseComponent, MockAiService, zoomBigMotion } from 'uikit';

// If the user is typing quickly, wait until they pause to show the
// AI SMS shortening pop up
const AI_SMS_TYPING_DEBOUNCE_TIME = 800;

@Component({
  selector: 'norby-segment-counter',
  templateUrl: './norby-segment-counter.component.html',
  styleUrls: ['./norby-segment-counter.component.less'],
  animations: [zoomBigMotion]
})
export class NorbySegmentCounterComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  private _characterCount = -1;
  private _message = '';
  private _isMessageOverOneSegment = false;
  private _segmentCount$ = new BehaviorSubject<number>(-1);

  @Input() message = '';
  @Input() popupDisplayDirection = 'right';
  @Input() shouldHidePopup = false;

  @Output() onShortenedMessage = new EventEmitter<string>();
  @Output() onTogglePopup = new EventEmitter<void>();

  isLoading = false;

  readonly TRACK_CLICK_EVENT = ProductAnalyticsEventTypes.CLICKED_ENTITY;
  readonly KNOWLEDGE_BASE = {
    elementType: ClickedElementProductAnalyticsElementTypeEnum.BUTTON,
    elementCTA: ClickedElementProductAnalyticsElementCTAEnum.KNOWLEDGE_BASE
  };

  constructor(
    private _ai: MockAiService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this._segmentCount$
      .pipe(
        tap((count) => {
          if (this._isMessageOverOneSegment && count <= 1) {
            this._isMessageOverOneSegment = false;
            this._cdr.markForCheck();
          }
        }),
        filter(() => !this._isMessageOverOneSegment),
        debounceTime(AI_SMS_TYPING_DEBOUNCE_TIME),
        distinctUntilChanged(),
        this.takeUntilDestroy
      )
      .subscribe((count) => {
        this._isMessageOverOneSegment = count > 1;
        this._cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes?.message) {
      this._characterCount = -1;
      this._message = estimateSegmentCount(this.message?.trim() ?? '');
      this._segmentCount$.next(calculateTotalMessageSegments(this._message));
    }
  }

  get characterCount(): number {
    if (this._characterCount === -1) {
      this._characterCount = calculateTotalCharsPerSegment(this._message);
    }

    return this._characterCount;
  }

  get segmentCount(): number {
    return this._segmentCount$.value;
  }

  get shouldShowPopup(): boolean {
    return this._isMessageOverOneSegment && !this.shouldHidePopup;
  }

  handleClickTogglePopup(): void {
    this.onTogglePopup.emit();
  }

  async handleClickShortenMessage(): Promise<void> {
    this.isLoading = true;

    const message = this.message?.trim();
    const response = await this._ai.shortenMessage({ message });
    const shortenedMessage = response?.shortenedMessage;

    this.isLoading = false;

    if (shortenedMessage) {
      this.onShortenedMessage.emit(shortenedMessage);
    }
  }
}
