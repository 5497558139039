import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';

import { ROOT_HOST_TOKEN } from 'models';
import { DeviceService } from '../device';

@Injectable({
  providedIn: 'root'
})
export class CustomerDeviceService extends DeviceService {
  private _slug: string;
  private _slug$ = new BehaviorSubject<string>(null);

  slugInfo$ = this._slug$.asObservable().pipe(
    map((slug) => ({
      rootUrl: `${slug}.${this._host}`,
      referUrl: `nor.by/❤/${slug}`,
      slug
    }))
  );

  currentSlug$ = this._slug$.asObservable().pipe(
    filter((slug) => !!slug),
    distinctUntilChanged()
  );

  constructor(
    @Inject(ROOT_HOST_TOKEN) protected _host: string,
    protected _observer: BreakpointObserver,
    private _router: Router
  ) {
    super(_host, _observer);
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url = (event as NavigationEnd)?.url ?? '';
        this._setPath(url);
      });

    const path = window.location.pathname;
    this._setPath(path);
  }

  get currentSlug(): string {
    return this._slug;
  }

  private _setPath(url: string) {
    const parts = url.split(/\/|\?/);
    this._slug = parts.length > 1 ? parts[1] : null;
    this._slug$.next(this._slug);
  }
}
