import { Component, TemplateRef } from '@angular/core';

// Libs
import { IPSQLContact } from 'models';
import { BaseComponent } from 'uikit';

// 3rd party
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

// App
import { DeviceService } from '../../services/device/device.service';

@Component({
  selector: 'app-contact-details-drawer',
  templateUrl: './contact-details-drawer.component.html',
  styleUrls: ['./contact-details-drawer.component.less']
})
export class ContactDetailsDrawerComponent extends BaseComponent {
  contact: IPSQLContact;
  actionButtonsTpl?: TemplateRef<any>;
  bodyTpl?: TemplateRef<any>;
  showDelete = true;

  constructor(
    private _drawerRef: NzDrawerRef<ContactDetailsDrawerComponent>,
    private _device: DeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    this._device.isMobile$.pipe(this.takeUntilDestroy).subscribe((isMobile) => {
      this._drawerRef.nzWidth = isMobile ? '100%' : '378px';
    });
  }

  handleSave(contact: IPSQLContact): void {
    this.contact = contact;
  }

  handleDelete(): void {
    this._drawerRef.close({ contact: this.contact, shouldDelete: true });
  }

  handleClose(): void {
    this._drawerRef.close();
  }
}
