import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NorbyContentContainerComponent } from '../components/organisms/norby-content-container';
import { NorbyFabModule } from './norby-fab.module';
import { NorbyContentItemModule } from './norby-content-item.module';
import { NorbyFlyoutMenuModule } from './norby-flyout-menu.module';
import { NorbyFlyoutOptionsGroupModule } from './norby-flyout-options-group.module';
import { IconModule } from './icon.module';
import { TooltipModule } from './tooltip.module';

import { NzPopoverModule } from 'ng-zorro-antd/popover';

@NgModule({
  declarations: [NorbyContentContainerComponent],
  imports: [
    CommonModule,
    NorbyFabModule,
    NorbyContentItemModule,
    NorbyFlyoutMenuModule,
    NorbyFlyoutOptionsGroupModule,
    NzPopoverModule,
    DragDropModule,
    IconModule,
    TooltipModule
  ],
  exports: [NorbyContentContainerComponent]
})
export class NorbyContentContainerModule {}
