import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';

// App
import { SlugService } from './slug.service';
import { ISlug, ISlugMetadata } from 'models';

@Injectable({
  providedIn: 'root'
})
export class SlugServerService extends SlugService {
  async getCurrentSlugMetadata(): Promise<ISlugMetadata> {
    return null as ISlugMetadata;
  }

  getCurrentSlug$(): Observable<ISlug> {
    return super.getCurrentSlug$().pipe(take(1));
  }
}
