import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';

// App
import {
  ITicket,
  ISlug,
  cleanRecursive,
  VALID_BILLING_COUNTRIES,
  Theme
} from 'models';
import { LiveThemedService } from 'uikit';

@Component({
  selector: 'lib-card-payment-form',
  templateUrl: './card-payment-form.component.html',
  styleUrls: ['./card-payment-form.component.less']
})
export class CardPaymentFormComponent implements OnInit, OnChanges {
  @Input() ticket: ITicket;
  @Input() stripe: Stripe;
  @Input() elements: StripeElements;
  @Input() paymentIntentSecret: string;
  @Input() name: string;
  @Input() slug: ISlug;
  @Input() themeClasses: string[];

  @Output() handleSetError = new EventEmitter<string>();
  @Output() handleClearError = new EventEmitter<void>();
  @Output() handlePaymentCallback = new EventEmitter<boolean>();

  card: StripeCardElement;
  stripePaymentForm: UntypedFormGroup;
  isSubmitting: boolean;
  isLoading: boolean;
  billingCountries = VALID_BILLING_COUNTRIES;
  cardInputComplete: boolean;
  selectors: string[] = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _themeService: LiveThemedService
  ) {}

  ngOnInit(): void {
    this.stripePaymentForm = this._formBuilder.group({
      name: [this.name ?? '', [Validators.required]],
      line1: ['', [Validators.required]],
      line2: [''],
      postalCode: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['US', [Validators.required]],
      city: ['', [Validators.required]]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { paymentIntentSecret, name, themeClasses } = changes;
    if (themeClasses) {
      this._initSelectors();
    }
    if (paymentIntentSecret) {
      this._initCardPayment();
    }

    if (name) {
      this.stripePaymentForm?.patchValue({
        name: name.currentValue
      });
    }
  }

  private _initSelectors() {
    this.selectors = this.themeClasses.map((selector, index, arr) =>
      arr
        .slice(index)
        .reverse()
        .map((inner) => `.${inner}`)
        .join(' ')
    );
  }

  private _getProperty(field: 'font' | 'color'): string {
    for (const selector of this.selectors) {
      if (field === 'font') {
        if (this._themeService.themes[selector]?.fonts?.font?.fontFamily) {
          return this._themeService.themes[selector]?.fonts?.font?.fontFamily;
        }
      } else {
        if (this._themeService.themes[selector]?.card?.textColor) {
          return this._themeService.themes[selector]?.card?.textColor;
        }
      }
    }
  }

  private _initCardPayment() {
    // Only mount the element the first time
    if (this.card || !this.elements) {
      return;
    }

    const themeColor = this._getProperty('color');
    const iconColor = themeColor ?? '#666EE8';
    const color = themeColor ?? 'rgba(0, 0, 0, 0.85)';
    const fontFamily =
      this._getProperty('font') ??
      '"Noto Sans", sans-serif, -apple-system, Roboto';

    try {
      this.card =
        this.elements.getElement('card') ||
        this.elements.create('card', {
          style: {
            base: {
              iconColor,
              color,
              fontFamily,
              fontWeight: '400',
              fontSize: '16px',
              '::placeholder': {
                color: '#8F9FB2'
              }
            }
          }
        });
    } catch (e) {
      console.log(e);
    }

    this.card.mount('#card-element');
    this.card.on('change', (event) => {
      this.cardInputComplete = event.complete;
      if (event.error) {
        this.handleSetError.emit(event.error.message);
      } else {
        this.handleClearError.emit();
      }
    });
  }

  // Handle manual card payments
  completeCardPayment() {
    if (this.isSubmitting || !this.stripe || !this.card) {
      return;
    }

    const { name, line1, line2, postalCode, state, country, city } =
      this.stripePaymentForm.value;

    this.isSubmitting = true;

    this.stripe
      .confirmCardPayment(this.paymentIntentSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name,
            address: cleanRecursive({
              line1,
              line2,
              postal_code: String(postalCode),
              state,
              country,
              city
            })
          }
        }
      })
      .then((result) => {
        if (result.error) {
          this.handleSetError.emit(result.error.message ?? '');
        } else if (result.paymentIntent?.status === 'succeeded') {
          this.handlePaymentCallback.emit(true);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => (this.isSubmitting = false));
  }
}
