import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { QuillModule } from 'ngx-quill';
import { NzModalModule } from 'ng-zorro-antd/modal';

// Lib
import {
  CardModule,
  DropdownModule,
  NorbyFormcontrolLabelModule,
  NorbyHelpertextModule,
  NorbyIconModule,
  SendsVariableSelectorModule,
  SpinModule
} from 'uikit';
import { NorbyRichTextEditorViewComponent } from '../components/atoms/norby-rich-text-editor-view';

@NgModule({
  declarations: [NorbyRichTextEditorViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    NzModalModule,
    SpinModule,
    CardModule,
    DropdownModule,
    SendsVariableSelectorModule,
    NorbyHelpertextModule,
    NorbyFormcontrolLabelModule,
    NorbyIconModule
  ],
  exports: [NorbyRichTextEditorViewComponent]
})
export class NorbyRichTextEditorViewModule {}
