import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-logo-view',
  templateUrl: './logo-view.component.html',
  styleUrls: ['./logo-view.component.less']
})
export class LogoViewComponent {
  @Input() color: 'white' | 'black' | 'green' | 'neutral' = 'black';
  @Input() height = 18;
  @Input() small = false;
}
