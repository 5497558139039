import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

// 3rd party
import { Subject, catchError, debounceTime, switchMap, tap } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Lib
import { BaseComponent, ErrorService } from 'uikit';
import {
  IContentSearchResultNode,
  SEARCH_DEBOUNCE_TIME,
  SegmentContactPreview,
  ISearchResults,
  IContactSearchResultNode,
  IPSQLContact,
  ContactSearchResults,
  Content,
  ContentSearchResults,
  ContactListSearchResults,
  IContactListSearchResultNode,
  ContactView,
  Segment,
  DeliveryType,
  EditFilterGroupEvent,
  SingleSend,
  ISingleSendSearchResultNode,
  SingleSendSearchResults,
  LandingPage,
  ILandingPage,
  EmailMarketingCommunicationTypes
} from 'models';
import { LandingPageService, SearchService } from '../../services';

@Component({
  selector: 'app-build-filters-controller',
  templateUrl: './build-filters-controller.component.html',
  styleUrls: ['./build-filters-controller.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildFiltersController extends BaseComponent implements OnInit {
  private _search$ = new Subject<string>();
  private _includedContactSearch$ = new Subject<string>();
  private _excludedContactSearch$ = new Subject<string>();

  @Input() isDisabled: boolean;
  @Input() segment: Segment;
  @Input() deliveryType: DeliveryType;
  @Input() marketingCommunicationType: EmailMarketingCommunicationTypes;
  @Input() isSendBuilder: boolean;

  @Output() onUpdateFilterGroup = new EventEmitter<EditFilterGroupEvent>();
  @Output() onDeleteFilter = new EventEmitter<EditFilterGroupEvent>();
  @Output() onAddContact = new EventEmitter<SegmentContactPreview>();
  @Output() onExcludeContact = new EventEmitter<SegmentContactPreview>();
  @Output() onRemoveIncludedContact = new EventEmitter<string>();
  @Output() onRemoveExcludedContact = new EventEmitter<string>();

  tagText: string = '';
  contentFilterOptions: ContentSearchResults[] = [];
  includedContactOptions: ContactSearchResults[] = [];
  contactListFilterOptions: ContactListSearchResults[] = [];
  excludedContactOptions: ContactSearchResults[] = [];
  engagementFilterOptions: SingleSendSearchResults[] = [];
  landingPageFilterOptions: LandingPage[] = [];
  isSearchingFilter: boolean = false;
  isSearchingIncludeContact: boolean = false;
  isSearchingExcludeContact: boolean = false;

  constructor(
    private _search: SearchService,
    private _cdr: ChangeDetectorRef,
    private _error: ErrorService,
    private _landingPageService: LandingPageService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this._initSearchSubscription();
    this._initIncludedContactSearchSubscription();
    this._initExcludedContactSearchSubscription();
    this._getLandingPages();
  }

  private _handleSearchError(e: Error) {
    this.isSearchingFilter = false;
    this._error.displayError(e);
    this._search$ = new Subject<string>();
    this._initSearchSubscription();
    this._cdr.detectChanges();

    return Promise.resolve([
      {
        pageInfo: {},
        edges: []
      } as ISearchResults,
      {
        pageInfo: {},
        edges: []
      } as ISearchResults,
      {
        pageInfo: {},
        edges: []
      } as ISearchResults
    ]);
  }

  private _handleIncludedContactSearchError(e: Error) {
    this.isSearchingIncludeContact = false;
    this._error.displayError(e);
    this._includedContactSearch$ = new Subject<string>();
    this._initIncludedContactSearchSubscription();
    this._cdr.detectChanges();

    return Promise.resolve({
      pageInfo: {},
      edges: []
    } as ISearchResults);
  }

  private _handleExcludedContactSearchError(e: Error) {
    this.isSearchingExcludeContact = false;
    this._error.displayError(e);
    this._excludedContactSearch$ = new Subject<string>();
    this._initExcludedContactSearchSubscription();
    this._cdr.detectChanges();

    return Promise.resolve({
      pageInfo: {},
      edges: []
    } as ISearchResults);
  }

  private _initSearchSubscription() {
    this._search$
      .pipe(
        tap((query) => (this.tagText = query)),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((query) => this._getSearchQueries(query)),
        catchError((e) => this._handleSearchError(e)),
        this.takeUntilDestroy
      )
      .subscribe(([contentResults, contactListResults, sendResults]) => {
        this.contentFilterOptions = this._processContentResults(contentResults);
        this.contactListFilterOptions =
          this._processContactListResults(contactListResults);
        this.engagementFilterOptions =
          this._processSingleSendResults(sendResults);
        this.isSearchingFilter = false;
        this._cdr.detectChanges();
      });
  }

  private _initIncludedContactSearchSubscription() {
    this._includedContactSearch$
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((query) =>
          this._search.searchContacts({
            query,
            offset: 0
          })
        ),
        catchError((e) => this._handleIncludedContactSearchError(e)),
        this.takeUntilDestroy
      )
      .subscribe((rawResults) => {
        this.includedContactOptions = this._processContactResults(rawResults);
        this.isSearchingIncludeContact = false;
        this._cdr.detectChanges();
      });
  }

  private _initExcludedContactSearchSubscription() {
    this._excludedContactSearch$
      .pipe(
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((query) =>
          this._search.searchContacts({
            query,
            offset: 0
          })
        ),
        catchError((e) => this._handleExcludedContactSearchError(e)),
        this.takeUntilDestroy
      )
      .subscribe((rawResults) => {
        this.excludedContactOptions = this._processContactResults(rawResults);
        this.isSearchingExcludeContact = false;
        this._cdr.detectChanges();
      });
  }

  private async _getLandingPages() {
    const landingPageResults = await this._landingPageService.getLandingPages(
      {}
    );

    this.landingPageFilterOptions = landingPageResults?.edges?.map((edge) => {
      const node = edge?.node as ILandingPage;
      return plainToClass(LandingPage, node);
    });
  }

  private _getSearchQueries(query: string) {
    return Promise.all([
      this._search.searchContent({
        query,
        offset: 0,
        digest: true
      }),
      this._search.searchContactLists({
        query,
        digest: true,
        offset: 0
      }),
      this._search.searchSends({
        query,
        offset: 0,
        includeSentOnly: true
      })
    ]);
  }

  handleSearchInput(query: string) {
    this.isSearchingFilter = true;
    this._search$.next(query);
  }

  handleIncludedContactInput(query: string) {
    this.isSearchingIncludeContact = true;
    this._includedContactSearch$.next(query);
  }

  handleExcludedContactInput(query: string) {
    this.isSearchingExcludeContact = true;
    this._excludedContactSearch$.next(query);
  }

  handleUpdateFilterGroup(editFilterGroupEvent: EditFilterGroupEvent) {
    this.onUpdateFilterGroup.emit(editFilterGroupEvent);
  }

  handleDeleteFilter(editFilterGroupEvent: EditFilterGroupEvent) {
    this.onDeleteFilter.emit(editFilterGroupEvent);
  }

  handleAddContact(contact: SegmentContactPreview) {
    this.onAddContact.emit(contact);
  }

  handleExcludeContact(contact: SegmentContactPreview) {
    this.onExcludeContact.emit(contact);
  }

  handleRemoveIncludedContact(contactId: string) {
    this.onRemoveIncludedContact.emit(contactId);
  }

  handleRemoveExcludedContact(contactId: string) {
    this.onRemoveExcludedContact.emit(contactId);
  }

  private _processContactResults(results: ISearchResults) {
    if (!results) {
      return [];
    }

    return results?.edges?.map((edge) => {
      const node = edge?.node as IContactSearchResultNode;
      const contact = plainToClass(IPSQLContact, node);
      const highlightedName =
        node?._highlights?.nickname?.[0] ??
        node?._highlights?.display_name?.[0] ??
        contact?.name;

      const highlightedMetadata = node?._highlights?.emails?.[0];
      return {
        contact,
        highlightedName,
        highlightedMetadata
      };
    });
  }

  private _processContactListResults(results: ISearchResults) {
    if (!results) {
      return [];
    }

    return results?.edges?.map((edge) => {
      const node = edge?.node as IContactListSearchResultNode;
      return {
        list: ContactView.fromObject(node),
        name: node?.name ?? 'List'
      };
    });
  }

  private _processContentResults(results: ISearchResults) {
    if (!results) {
      return [];
    }

    return results?.edges
      ?.map((edge) => {
        const node = edge?.node as IContentSearchResultNode;
        const content = Content.fromObject(node);

        if (content) {
          const highlightedTitle = node?._highlights?.title?.[0] ?? '';

          return {
            content,
            highlightedTitle,
            highlightedMetadata: ''
          };
        }
        return null;
      })
      .filter((result) => result !== null);
  }

  private _processSingleSendResults(results: ISearchResults) {
    if (!results) {
      return null;
    }

    return results?.edges?.map((edge) => {
      const node = edge?.node as ISingleSendSearchResultNode;

      const send = SingleSend.fromObject(node);
      if (send) {
        const highlightedText = node?._highlights?.textFields?.[0] ?? '';
        const embeddedLinks = node?._embeddedLinks ?? [];
        return {
          send,
          highlightedText,
          embeddedLinks
        };
      }
      return null;
    });
  }
}
