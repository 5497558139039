import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NzButtonModule } from 'ng-zorro-antd/button';

// Libs
import { NorbySegmentCounterComponent } from '../components/atoms/norby-segment-counter';
import { TrackProductAnalyticsDirectiveModule } from './track-product-analytics-directive.module';
import { NorbyIconModule } from 'uikit';

@NgModule({
  declarations: [NorbySegmentCounterComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NorbyIconModule,
    TrackProductAnalyticsDirectiveModule
  ],
  exports: [NorbySegmentCounterComponent]
})
export class NorbySegmentCounterModule {}
