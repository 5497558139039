import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';

import { UsageTrackerComponent } from '../views';
import { TrackProductAnalyticsDirectiveModule } from './track-product-analytics-directive.module';

@NgModule({
  declarations: [UsageTrackerComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzProgressModule,
    RouterModule,
    TrackProductAnalyticsDirectiveModule
  ],
  exports: [UsageTrackerComponent]
})
export class UsageTrackerModule {}
