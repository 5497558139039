import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { filter } from 'rxjs';

// Lib
import {
  LandingPage,
  ILandingPage,
  SLUG_PATTERN,
  IImage,
  Theme,
  ThemeLayout,
  ThemeChange
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-landing-page',
  templateUrl: './norby-edit-landing-page.component.html',
  styleUrls: ['./norby-edit-landing-page.component.less']
})
export class NorbyEditLandingPageComponent
  extends BaseComponent
  implements OnInit
{
  @Input() landingPage: LandingPage;
  @Input() rootUrl: string = '';
  @Input() labelInitialValue: string;
  @Input() isDefaultPage: boolean = false;

  @Output() onUpdatedLandingPage: EventEmitter<ILandingPage> =
    new EventEmitter<ILandingPage>();
  @Output() onUpdatedTheme: EventEmitter<ThemeChange> =
    new EventEmitter<ThemeChange>();

  formGroup: UntypedFormGroup;
  previewImage: IImage[] = [];

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.previewImage = this.landingPage?.theme?.layout?.previewImage
      ? [this.landingPage.theme.layout.previewImage]
      : [];

    this.formGroup?.patchValue(
      {
        label: this.landingPage?.label ?? '',
        title: this.landingPage?.title ?? '',
        description: this.landingPage?.description ?? '',
        isDefault: this.landingPage?.isDefault ?? false
      },
      { emitEvent: false }
    );
  }

  get shouldDisplayUrlBreakingWarning(): boolean {
    return (
      this.landingPage?.published &&
      this.labelInitialValue !== this.formGroup.value.label
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      label: [
        this.landingPage?.label ?? '',
        [Validators.required, Validators.pattern(SLUG_PATTERN)]
      ],
      title: [this.landingPage?.title ?? '', Validators.required],
      description: [this.landingPage?.description ?? ''],
      isDefault: [this.landingPage?.isDefault ?? false]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        this.takeUntilDestroy
      )
      .subscribe((updatedPage: ILandingPage) =>
        this.onUpdatedLandingPage.emit(updatedPage)
      );
  }

  handlePreviewImageUpdated(images: IImage[]) {
    const theme = this.landingPage.theme
      ? this.landingPage.theme.clone()
      : Theme.new();

    const previewImage = images.length ? images[0] : null;
    const layout = ThemeLayout.fromObject({
      ...(theme.layout || {}),
      previewImage
    });
    theme.updateProperties({ layout });
    this.onUpdatedTheme.emit({ theme, isDirty: true, isValid: true });
  }
}
