<form [formGroup]="formGroup">
  <norby-custom-message-tooltip
    action="use a Calendly block"
    (onMessageUpdated)="child.isDisabled = !!$event"
  >
    <norby-input
      #child
      [label]="LABEL"
      formControlName="url"
      [placeholder]="PLACEHOLDER"
      [helperText]="HELPER_TEXT"
      [infoTooltip]="TOOLTIP"
      [isUrl]="true"
    ></norby-input>
  </norby-custom-message-tooltip>
</form>
