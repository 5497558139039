<nz-select
  class="w-full configurable-ant-select {{ selectorType }}"
  [(ngModel)]="val"
  [nzServerSearch]="!filterLocalResults"
  [nzPlaceHolder]="placeholder"
  [nzDisabled]="isDisabled"
  [nzShowSearch]="isSearchable"
  [nzAllowClear]="isClearable"
  [nzNotFoundContent]="notFoundTemplate"
  [nzDropdownRender]="isLoading ? null : footerTemplate"
  [nzCustomTemplate]="customOptionTemplate"
  [nzOptionHeightPx]="optionHeightPx"
  [nzLoading]="isLoading"
  [nzOptionOverflowSize]="optionOverflowSize"
  (ngModelChange)="handleChange($event)"
  (nzOnSearch)="handleOnSearch($event)"
  [compareWith]="compareFn"
>
  <nz-option
    nzCustomContent
    [nzValue]="item"
    [nzLabel]="item?.[label] || placeholder"
    *ngFor="let item of items; let i = index"
  >
    <ng-template
      [ngTemplateOutlet]="optionTemplate"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-template>
  </nz-option>
</nz-select>

<ng-template #notFoundTemplate>
  <div class="flex justify-center m-3">
    <p class="m-0">{{ isLoading ? 'Loading...' : 'No results' }}</p>
  </div>
</ng-template>
