import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// 3rd party
import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

// Lib
import { UpcomingEventsPageBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'norby-edit-events-list-block',
  templateUrl: './norby-edit-events-list-block.component.html',
  styleUrls: ['./norby-edit-events-list-block.component.less']
})
export class NorbyEditEventsListBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() selectedBlock: UpcomingEventsPageBlock;

  @Output() onUpdatedBlock: EventEmitter<UpcomingEventsPageBlock> =
    new EventEmitter<UpcomingEventsPageBlock>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        tag: this.selectedBlock?.tag,
        limit: this.selectedBlock?.limit ?? 10,
        reverse: !!this.selectedBlock?.reverse,
        startAt: this.selectedBlock?.startAt,
        groupBy: this.selectedBlock?.groupBy ?? 'default'
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      tag: [this.selectedBlock?.tag],
      limit: [
        this.selectedBlock?.limit ?? 10,
        [Validators.required, Validators.min(0), Validators.max(25)]
      ],
      reverse: [!!this.selectedBlock?.reverse, Validators.required],
      startAt: [this.selectedBlock?.startAt],
      groupBy: [this.selectedBlock?.groupBy ?? 'default', Validators.required]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(UpcomingEventsPageBlock, {
            ...this.selectedBlock,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => {
        this.onUpdatedBlock.emit(block);
      });
  }
}
