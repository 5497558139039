import { DragRef, DropListRef } from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { SingleAxisSortStrategy } from './cdk/sorting/single-axis-sort-strategy';
import { isPointerNearDomRect } from './cdk/dom/dom-rect';

/**
 * Proximity, as a ratio to width/height, at which a
 * dragged item will affect the drop container.
 * Unchanged from original source code
 */
const DROP_PROXIMITY_THRESHOLD = 0.05;

export class DropListHijacker {
  static DefaultSortItem = DropListRef.prototype._sortItem;
  static DefaultStart = DropListRef.prototype.start;

  static restoreDefaults() {
    DropListRef.prototype._sortItem = this.DefaultSortItem;
    DropListRef.prototype.start = this.DefaultStart;
  }

  // We leverage prototype jacking here to replace the _sortItem
  // function on DropListRef. This function in turn calls the
  // sort function on SingleAxisScrollStrategy.
  //
  // The only difference in our version of _sortItem is that we
  // first adjust the current coordinates of the user's pointer
  // before passing them through to the sort functions.
  //
  // The global coordinates of the user's pointer are used to determine
  // where the placeholder item and list items should animate to. We
  // need to jack the _sortItem function every time a drag starts to
  // make sure that the value we use for currentScaleMultiplier when
  // making our adjustments to the coordinate space is up to date.
  static hijackDropListRefSortItem(
    scaleMultiplier: number,
    scrollContainer: CdkScrollable
  ) {
    DropListRef.prototype._sortItem = function (
      item: DragRef,
      pointerX: number,
      pointerY: number,
      pointerDelta: { x: number; y: number }
    ): void {
      // These are the key changes to this method and the only place it differs
      // from the original _sortItem. The pointer position captured by pointerX
      // and pointerY is in global coordinate space; however, we know that the
      // drag and drop list inside the DevicePreviewView is in a scaled coordinate
      // space. We therefore adjust the pointer coordinates that are used for
      // calculations by the scroll strategy to account for scale differences
      // within the DevicePreviewView, so the values used in calculation align
      // with where the user visually sees their pointer above different draggable
      // elements in the DevicePreviewView.
      const containerRect = this.element.getBoundingClientRect() as ClientRect;

      // The scaled X coordinate inside the DevicePreviewView
      const scaledAreaX = (pointerX - containerRect.left) / scaleMultiplier;

      // The scaled Y coordinate inside the DevicePreviewView
      const scaledAreaY = (pointerY - containerRect.top) / scaleMultiplier;

      // Y coordinate should also adjust for scroll offset inside the nearest
      // ancestor cdkScrollable, which in this case is the DevicePreviewView
      const scrollOffset = scrollContainer?.measureScrollOffset('top') ?? 0;
      const scaledScrollOffset = scrollOffset * scaleMultiplier;

      // Calculate final adjusted pointer coordinates
      const adjustedPointerX = containerRect.left + scaledAreaX;
      const adjustedPointerY =
        containerRect.top + scaledAreaY + scaledScrollOffset;

      // The rest of this method is unchanged from the original _sortItems
      if (
        this.sortingDisabled ||
        !this._domRect ||
        !isPointerNearDomRect(
          this._domRect,
          DROP_PROXIMITY_THRESHOLD,
          pointerX,
          pointerY
        )
      ) {
        return;
      }

      const result = this._sortStrategy.sort(
        item,
        adjustedPointerX,
        adjustedPointerY,
        pointerDelta
      );

      if (result) {
        this.sorted.next({
          previousIndex: result.previousIndex,
          currentIndex: result.currentIndex,
          container: this,
          item
        });
      }
    };
  }

  // We also use prototype jacking to replace the start function
  // on DropListRef. The start function is called every time a drag
  // is initialized. This code is actually identical to the original
  // implementation of start, the only difference being that it
  // swaps out the _sortStrategy used before running _draggingStarted
  // and _notifyReceivingSiblings. We replace the original implementation
  // of SingleAxisSortStrategy with our tweaked version, which is nearly
  // identical but uses a different methodology for measuring client
  // rects (measuring in global unscaled coordinate space and not taking
  // into account any applied transforms).
  static hijackDropListRefStart() {
    DropListRef.prototype.start = function () {
      // Swap out the original SingleAxisSortStrategy for our modified
      // version.
      this._sortStrategy = new SingleAxisSortStrategy(
        this.element,
        this._dragDropRegistry
      );
      this._sortStrategy.withSortPredicate((index, item) =>
        this.sortPredicate(index, item, this)
      );

      // These two lines are identical to the original implementation.
      this._draggingStarted();
      this._notifyReceivingSiblings();
    };
  }
}
