import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

// 3rd party
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';

// Lib
import {
  FormModule,
  NorbyFormcontrolLabelModule,
  NorbyInputModule,
  NorbyCheckboxModule,
  NorbySwitchButtonModule,
  NorbyIconModule,
  NorbyCollapsibleModule,
  IconModule,
  NorbySelectModule,
  TooltipModule,
  NorbyIconButtonModule,
  NorbyNumberInputModule,
  ButtonModule,
  TagModule,
  NorbyCollapsibleGroupModule,
  NorbyDateTimePickerModule
} from 'uikit';
import { PhonePipeModule } from './phone-pipe.module';
import { SignupRegistrationStepComponent } from '../components/templates/signups/signup-registration-step';
import { NorbyCustomMessageTooltipModule } from './norby-custom-message-tooltip.module';
import { CamelCaseToWordsPipeModule } from './camel-case-to-words.module';

@NgModule({
  declarations: [SignupRegistrationStepComponent],
  imports: [
    CommonModule,
    FormModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    RouterModule,
    PhonePipeModule,
    NorbyInputModule,
    NorbyNumberInputModule,
    NorbyFormcontrolLabelModule,
    NorbySwitchButtonModule,
    NorbyCheckboxModule,
    NorbyIconModule,
    NorbyIconButtonModule,
    NorbyCollapsibleModule,
    NorbyCollapsibleGroupModule,
    NorbySwitchButtonModule,
    NorbyDateTimePickerModule,
    NorbyCustomMessageTooltipModule,
    NorbySelectModule,
    ButtonModule,
    TagModule,
    IconModule,
    TooltipModule,
    NzInputModule,
    NzButtonModule,
    CamelCaseToWordsPipeModule
  ],
  exports: [SignupRegistrationStepComponent]
})
export class SignupRegistrationStepModule {}
