import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

// Libs
import {
  ContentSignup,
  ContentSignupMetadata,
  SignupChanges,
  SignupMetadataChanges,
  compareSignups,
  UI_MOCK_URLS
} from 'models';
import { BaseComponent } from 'uikit';

@Component({
  selector: 'lib-signup-details-step',
  templateUrl: './signup-details-step.component.html',
  styleUrls: ['./signup-details-step.component.less']
})
export class SignupDetailsStepComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() signup: ContentSignup;
  @Input() signupMetadata: ContentSignupMetadata;
  @Input() userCanPublish = true;
  @Input() isFlowFormPermitted: boolean = false;

  @Output() onUpdatedSignup: EventEmitter<SignupChanges> =
    new EventEmitter<SignupChanges>();
  @Output() onUpdatedSignupMetadata: EventEmitter<SignupMetadataChanges> =
    new EventEmitter<SignupMetadataChanges>();

  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.formGroup?.patchValue(
      {
        title: this.signup?.title,
        signupType: this.signup?.signupType || 'flow'
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      title: [this.signup?.title, Validators.required],
      signupType: [this.signup?.signupType || 'flow']
    });

    this.formGroup.valueChanges
      .pipe(this.takeUntilDestroy)
      .subscribe((value) => {
        const signup = ContentSignup.fromObject({
          ...this.signup,
          ...(value?.title && { title: value?.title }),
          signupType: value?.signupType
        });

        if (!compareSignups(this.signup, signup)) {
          this.onUpdatedSignup.emit({
            isDirty: this.formGroup.dirty,
            isDetailsStepValid: this.formGroup.valid,
            signup
          });
        }
      });
  }

  get hasTitle(): boolean {
    return !!this.formGroup?.get('title')?.value;
  }

  get isInline(): boolean {
    return this.formGroup?.get('signupType')?.value === 'form';
  }

  get isPopup(): boolean {
    return this.formGroup?.get('signupType')?.value !== 'form';
  }

  get mockUrl(): string {
    return this.isInline ? UI_MOCK_URLS.forms : UI_MOCK_URLS.signups;
  }
}
