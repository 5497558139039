import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import {
  CardModule,
  IconModule,
  NorbyButtonModule,
  NorbyFabModule,
  NorbyFileUploadModule,
  NorbyFlyoutMenuModule,
  NorbyFlyoutOptionsGroupModule,
  NorbyIconModule,
  NorbyIconRadioButtonGroupModule,
  NorbyIconRadioButtonModule,
  NorbyInputModule,
  NorbySliderModule,
  SafeHtmlPipeModule,
  TooltipModule
} from 'uikit';
import { NorbySendsDesignViewComponent } from '../components/templates/sends/norby-sends-design-view';
import { ExpandableBottomSheetViewModule } from './expandable-bottom-sheet-view.module';
import { NorbyFooterModule } from './norby-footer.module';
import { DevicePreviewViewModule } from './device-preview-view.module';
import { EmailBlockEditViewsModule } from './email-block-edit-views.module';
import { EmailBlockEditorsModule } from './email-block-editors.module';
import { NorbyThemeSelectorModule } from './norby-theme-selector.module';
import { NorbyThemeCustomizerModule } from './norby-theme-customizer.module';
import { CamelCaseToWordsPipeModule } from './camel-case-to-words.module';

@NgModule({
  declarations: [NorbySendsDesignViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyIconModule,
    CardModule,
    TooltipModule,
    IconModule,
    NorbyFileUploadModule,
    NorbySliderModule,
    NorbyInputModule,
    ExpandableBottomSheetViewModule,
    SafeHtmlPipeModule,
    NorbyFooterModule,
    NorbyFabModule,
    NorbyFlyoutMenuModule,
    NorbyFlyoutOptionsGroupModule,
    NzPopoverModule,
    NzDividerModule,
    DragDropModule,
    DevicePreviewViewModule,
    NorbyIconRadioButtonGroupModule,
    NorbyIconRadioButtonModule,
    EmailBlockEditViewsModule,
    EmailBlockEditorsModule,
    NorbyThemeSelectorModule,
    NorbyThemeCustomizerModule,
    CamelCaseToWordsPipeModule
  ],
  exports: [NorbySendsDesignViewComponent]
})
export class NorbySendsDesignViewModule {}
