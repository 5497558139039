import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'norby-sends-verification-view',
  templateUrl: './norby-sends-verification-view.component.html',
  styleUrls: ['./norby-sends-verification-view.component.less']
})
export class NorbySendsVericationViewComponent {
  @Input() isSending: boolean = false;
  @Output() onSubmitSend: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancelSend: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  handleSubmitSend(): void {
    this.onSubmitSend.emit();
  }

  handleCancelSend(): void {
    this.onCancelSend.emit();
  }
}
