<form [formGroup]="formGroup">
  <div class="mb-6">
    <norby-input
      label="Title"
      placeholder="Sign up for my newsletter"
      formControlName="title"
      type="text"
    ></norby-input>
  </div>

  <div class="mb-6">
    <norby-input
      label="Subtitle"
      placeholder="Get weekly updates and announcements in your inbox"
      formControlName="subtitle"
      type="text"
    ></norby-input>
  </div>

  <hr class="mb-6" />

  <div class="mb-6">
    <norby-input
      label="Post-Submit Title"
      placeholder="Thanks!"
      formControlName="postSubmitTitle"
      type="text"
    ></norby-input>
  </div>

  <div class="mb-6">
    <norby-input
      label="Post-Submit Subtitle"
      placeholder="You're subscribed."
      formControlName="postSubmitMessage"
      type="text"
    ></norby-input>
  </div>

  <hr class="mb-6" />

  <div class="mb-6">
    <norby-input
      label="Subscribe Button Label"
      placeholder="Subscribe"
      formControlName="buttonLabel"
      type="text"
    ></norby-input>
  </div>
  <div class="mb-6">
    <norby-tag-selector
      [tags]="tags"
      label="Tags"
      (onUpdatedTags)="handleUpdateTags($event)"
    >
    </norby-tag-selector>
  </div>
</form>

<norby-file-upload
  label="Images"
  [images]="images"
  [allowMultiple]="true"
  (onUpdatedImages)="handleUpdatedImages($event)"
></norby-file-upload>
