import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NorbyCollapsibleGroupModule,
  NorbyCollapsibleModule,
  NorbyFileUploadModule,
  NorbyHeaderStyleModule,
  NorbyInputModule,
  NorbyRadioButtonGroupModule,
  NorbyIconRadioButtonGroupModule,
  NorbyRadioButtonModule,
  NorbyIconRadioButtonModule,
  NorbySelectModule,
  NorbySliderModule,
  NorbyTabModule,
  NorbyTabsModule,
  NorbyButtonModule,
  NorbyIconModule,
  NorbyIconButtonModule,
  NorbyImageRadioButtonGroupModule,
  NorbyImageRadioButtonModule,
  NorbyAlertModule
} from 'uikit';
import { NorbyGradientSelectorModule } from './norby-gradient-selector.module';
import { NorbyShapeSelectorModule } from './norby-shape-selector.module';
import { NorbyEditHeaderBlockComponent } from '../components/templates/content-block-editors/norby-edit-header-block';
import { NorbyContentSelectorByIdModule } from './norby-content-selector-by-id.module';

@NgModule({
  declarations: [NorbyEditHeaderBlockComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NorbyButtonModule,
    NorbyIconModule,
    NorbyIconButtonModule,
    NorbyContentSelectorByIdModule,
    NorbyTabModule,
    NorbyTabsModule,
    NorbyRadioButtonModule,
    NorbyIconRadioButtonModule,
    NorbyIconRadioButtonGroupModule,
    NorbyImageRadioButtonModule,
    NorbyImageRadioButtonGroupModule,
    NorbyRadioButtonGroupModule,
    NorbySliderModule,
    NorbyCollapsibleModule,
    NorbyCollapsibleGroupModule,
    NorbySelectModule,
    NorbyInputModule,
    NorbyFileUploadModule,
    NorbyShapeSelectorModule,
    NorbyHeaderStyleModule,
    NorbyGradientSelectorModule,
    NorbyAlertModule
  ],
  exports: [NorbyEditHeaderBlockComponent]
})
export class NorbyEditHeaderBlockModule {}
