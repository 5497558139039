import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// 3rd party
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  private _mutationObserver: MutationObserver;
  private _mutationObserverNode: any;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router
  ) {
    if (this._hsConversations) {
      this._onConversationsAPIReady();
    } else {
      (window as any).hsConversationsOnReady = [this._onConversationsAPIReady];
    }

    this._router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.refresh());
  }

  private _onConversationsAPIReady = () => {
    this._initMutationObserver();
  };

  get _hsConversations() {
    return (window as any)?.HubSpotConversations;
  }

  get _widget() {
    return this._hsConversations?.widget;
  }

  hideChat() {
    this._document?.body?.classList?.remove('enable-chat-session');
  }

  showChat() {
    this._widget?.load();
    this._widget?.open();
    this._document?.body?.classList?.add('enable-chat-session');
    this._initMutationObserver();
  }

  refresh() {
    this._widget?.refresh();
    this._initMutationObserver();
  }

  // Subscribe to DOM changes to detect chat widget state
  private _initMutationObserver() {
    if (this._mutationObserverNode?.isConnected) return;

    this._mutationObserver?.disconnect();
    this._mutationObserverNode = this._document.querySelector(
      '#hubspot-messages-iframe-container > .shadow-container'
    );

    if (this._mutationObserverNode) {
      this._mutationObserver = new MutationObserver((mutations) => {
        if (!this._mutationObserverNode?.classList?.contains('active')) {
          this.hideChat();
        }
      });

      this._mutationObserver.observe(this._mutationObserverNode, {
        attributeFilter: ['class']
      });
    }
  }
}
