<div class="signup-container">
  <form [formGroup]="formGroup" class="signup-wrapper">
    <div>
      <h1>Details</h1>
      <p class="text-neutral-600">
        First, give your signup form a name. For example, "Sign up for my weekly
        newsletter" or "Get a text when my album drops."
      </p>
    </div>

    <norby-formcontrol-label
      label="Title"
      [isRequired]="true"
    ></norby-formcontrol-label>
    <div class="mb-4">
      <norby-input
        formControlName="title"
        placeholder="e.g. 'Get a text when we're going live' or 'Sign up for weekly merch updates'"
      ></norby-input>
    </div>

    <norby-formcontrol-label
      label="How do you want to display your form?"
      infoTooltip="Select how your form is presented to the people who register"
    ></norby-formcontrol-label>
    <div class="mb-4">
      <norby-radio-button-group formControlName="signupType" [block]="true">
        <norby-radio-button
          class="block-radio-button"
          buttonLabel="Popup"
          buttonValue="flow"
        ></norby-radio-button>

        <norby-radio-button
          class="block-radio-button"
          [buttonLabel]="
            isFlowFormPermitted ? 'Inline' : 'Inline (Coming soon)'
          "
          buttonValue="form"
          [isDisabled]="!isFlowFormPermitted"
        ></norby-radio-button>
      </norby-radio-button-group>
    </div>

    <div class="flex flex-row md:flex-col" *ngIf="isFlowFormPermitted">
      <div
        class="order-2 rounded-r flex-none w-5/12 max-w-xs bg-azure-800 py-4 px-2 md:rounded-r-none md:rounded-t md:w-auto md:order-1 md:max-w-none"
      >
        <img
          [src]="mockUrl"
          alt="Email builder"
          width="1440"
          height="1040"
          class="max-w-full h-auto"
        />
      </div>
      <div
        class="order-1 flex justify-center items-center rounded-l bg-neutral-100 p-5 md:rounded-l-none md:rounded-b md:order-2"
      >
        <div class="text-neutral-700">
          <h3>{{ isInline ? 'Inline forms' : 'Popup forms' }}</h3>
          <p *ngIf="isInline">
            Inline forms have their own page and can be embedded anywhere on the
            web.
          </p>
          <p *ngIf="isPopup">
            Popup forms launch in a modal flow triggered by clicking a signup
            button.
          </p>
        </div>
      </div>
    </div>
  </form>
</div>
